<!--
Common base view class for listings and details views with sub navigation bar
-->

<template>
  <div
    class="d-flex flex-column justify-content-start mx-auto px-2"
  >
    <vue-headful :title="browserHistoryTitle" />

    <b-row
      v-if="breadcrumbs.length > 0"
    >
      <b-breadcrumb
        :items="breadcrumbs"
      />
    </b-row>

    <slot name="page-navigation">
      <SubNavBar
        v-if="showPageNavigation && !error"
        :parent="bus"
        :title="title"
        :navigation-filters="navigationFilters"
        :navigation-actions="navigationActions"
        :search-visible="searchVisible"
        @events="eventHandler"
      >
        <template slot="sub-nav-heading">
          <slot name="sub-nav-heading" />
        </template>
        <template slot="sub-nav-filters">
          <slot name="sub-nav-filters" />
        </template>
        <template slot="sub-nav-buttons">
          <slot name="sub-nav-buttons" />
        </template>
        <template slot="sub-nav-search">
          <slot name="sub-nav-search" />
        </template>
      </SubNavBar>
    </slot>

    <b-row v-if="error">
      <b-col>
        <ErrorMessage
          :error="error"
          :reload-button-clicked="$emit('notifications', 'reload-page')"
          :close-error-clicked="$emit('notifications', 'close-errors')"
          @events="eventHandler"
        />
      </b-col>
    </b-row>

    <slot
      scope="page"
      name="modal"
    />

    <slot
      scope="page"
      name="content"
    />
  </div>
</template>

<script>
import BaseComponent from '@/components/base/BaseComponent.vue'
import SubNavBar from '@/components/elements/SubNavBar.vue'
import ErrorMessage from '@/components/common/ErrorMessage.vue'

export default {
  name: 'BaseView',

  components: {
    ErrorMessage,
    SubNavBar
  },

  extends: BaseComponent,

  props: {
    parent: {
      type: Object,
      required: true
    },
    error: {
      type: [String, Error],
      default: undefined
    },
    // Title to push into navigation history
    navigationHeading: {
      type: [String, undefined],
      default: undefined
    },
    // Title to be shown on top of base container
    title: {
      type: [String, undefined],
      default: 'NO TITLE'
    },
    breadcrumbs: {
      type: Array,
      default: () => ([])
    },
    showPageNavigation: {
      type: Boolean,
      default: true
    },
    navigationFilters: {
      type: Array,
      default: () => ([])
    },
    navigationActions: {
      type: Array,
      default: () => ([])
    },
    searchVisible: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    browserHistoryTitle () {
      return this.navigationHeading !== undefined ? this.navigationHeading : this.title
    }
  },

  methods: {

    eventHandler (action, ...args) {
      /*
      Send base view and navigation bar events to parent
       */
      switch (action) {
        case 'switch-page': {
          this.$emit('events', 'switch-page', args[0])
          break
        }
        case 'button-click': {
          this.$emit('events', 'button-clicked', args[0])
          break
        }
        case 'search': {
          this.$emit('events', 'search', args[0])
          break
        }
        case 'close-errors': {
          this.$emit('events', 'close-errors')
          break
        }
        case 'reload-page': {
          this.$router.go()
          break
        }
      }
    }

  }
}
</script>
