
import Vue from 'vue'
import Vuex from 'vuex'
import VueCookie from 'vue-cookie'

import auth from './modules/auth'

import employeesChoices from './modules/autocomplete/Employees'
import employeeTypesChoices from './modules/autocomplete/EmployeeTypes'
import employmentContractTypesChoices from './modules/autocomplete/EmploymentContractTypes'
import employeeJoinableProjects from './modules/autocomplete/EmployeeJoinableProjects'
import projectCategoriesChoices from './modules/autocomplete/ProjectCategories'
import projectMemberRolesChoices from './modules/autocomplete/ProjectMemberRoles'
import projectJoinableEmployeesChoices from './modules/autocomplete/ProjectJoinableEmployees'
import projectTypesChoices from './modules/autocomplete/ProjectTypes'
import remunerationTypesChoices from './modules/autocomplete/RemunerationTypes'
import timesheetRecordTypesChoices from './modules/autocomplete/TimesheetRecordTypes'
import workWeekChoices from './modules/autocomplete/WorkWeekChoices'

import billingContacts from './modules/project/BillingContact'
import employees from './modules/employee/Employee'
import employeeProjects from './modules/employee/EmployeeProjects'
import employeeWorkDays from './modules/employee/WorkDays'
import employmentContractPeriods from './modules/employee/ContractPeriod'
import employeeRemunerations from './modules/employee/Remuneration'

import employeeBillingRates from './modules/report/EmployeeBillingRates'
import employeeWorkSummaries from './modules/report/EmployeeWorkSummaries'
import projectBillingReports from './modules/report/ProjectBillingSummary'

import holidays from './modules/calendar/Holiday'
import projects from './modules/project/Project'
import projectMembers from './modules/project/Member'
import projectTasks from './modules/project/Task'
import timesheetRecords from './modules/timesheet/TimesheetRecord'

// Set samesite cookie policy to strict explicitly
VueCookie.set('SameSite', 'Lax')

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    employeesChoices,
    employeeTypesChoices,
    employeeJoinableProjects,
    employmentContractTypesChoices,
    projectCategoriesChoices,
    projectMemberRolesChoices,
    projectJoinableEmployeesChoices,
    projectTypesChoices,
    remunerationTypesChoices,
    timesheetRecordTypesChoices,
    workWeekChoices,
    billingContacts,
    employees,
    employeeBillingRates,
    employeeWorkSummaries,
    employeeProjects,
    employeeWorkDays,
    employmentContractPeriods,
    holidays,
    projects,
    projectBillingReports,
    projectMembers,
    projectTasks,
    employeeRemunerations,
    timesheetRecords
  },
  strict: debug
})
