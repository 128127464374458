/*
 Route guards
 */

import store from '../store'

function requireAuth (to, from, next) {
  /*
  Router navigation guard for logged in users

  Check user is authenticated by verifying JWT token before entering route
  */

  if (store.getters.hasAuthenticationToken(store)) {
    let profile = store.getters.getUserProfile(store)
    if (profile !== null) {
      if (profile.type === 'unconfigured') {
        /*
        User has not been configured to the backend
         */
        next('/notready')
      } else {
        next()
      }
    } else {
      /*
      User has login cookie but no profile. Try to fetch profile
       */
      store.dispatch('loadUserProfile')
        .then((response) => {
          if (response.type === 'unconfigured') {
            next('/notready')
          } else {
            next()
          }
        })
        .catch(() => {
          next('/logout')
        })
    }
  } else {
    next('/')
  }
}

export {
  requireAuth
}
