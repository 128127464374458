<!--
Show details for selected project or create new project
-->

<template>
  <PagedEditorView
    :parent="bus"
    :loading="loading"
    :error="error"
    :editing="editing"
    :show-page-navigation="showPageNavigation"
    :navigation-filters="navigationFilters"
    :navigation-actions="navigationActions"
    :breadcrumbs="breadcrumbs"
    :project="record"
    :title="projectTitle"
    :navigation-heading="navigationHeading"
    :page-name="pageName"
    :can-edit="canEdit"
    @events="eventHandler"
  >
    <template slot="sub-nav-heading">
      <div
        v-if="showPageNavigation"
        class="profile"
      >
        <h5>{{ pageTitle }}</h5>
      </div>
    </template>

    <template slot="details-view">
      <ProjectDetails
        v-if="!loading && selectedPage === 'details'"
        :parent="bus"
        :record="record"
      />
    </template>

    <template slot="details-editor">
      <ProjectEditForm
        v-if="!loading && selectedPage === 'details'"
        :parent="bus"
        :record="record"
        :editing="editing"
        @events="eventHandler"
      />
      <ProjectEditForm
        v-if="selectedPage === 'new'"
        :parent="bus"
        :editing="editing"
        @events="eventHandler"
      />
    </template>

    <template slot="editor-controls">
      <span
        v-if="selectedPage === 'members' && canJoin"
      >
        <b-navbar-nav
          class="sub-nav-buttons"
        >
          <b-nav-item
            key="join-project"
            @click="joinClicked"
          >
            JOIN
          </b-nav-item>
        </b-navbar-nav>
      </span>
      <span
        v-if="selectedPage === 'tasks'"
      />
    </template>

    <template slot="details-listing">
      <ProjectMembers
        v-if="selectedPage === 'members'"
        :parent="bus"
        :project="record"
      />
      <ProjectTasks
        v-if="selectedPage === 'tasks'"
        :parent="bus"
        :project="record"
      />
    </template>
  </PagedEditorView>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { cloneDeep, find, isEmpty } from 'lodash'

import PagedEditor from '@/components/base/PagedEditor.vue'
import ProjectEditForm from '@/components/project/ProjectEditForm.vue'
import ProjectDetails from '@/components/project/ProjectDetails.vue'
import ProjectMembers from '@/components/project/ProjectMembers.vue'
import ProjectTasks from '@/components/project/ProjectTasks.vue'

export default {
  name: 'Project',

  components: {
    ProjectEditForm,
    ProjectDetails,
    ProjectMembers,
    ProjectTasks
  },

  extends: PagedEditor,

  data: function () {
    return {
      listRouteName: 'project-list',
      detailRouteName: 'project-details',
      createRouteName: 'project-create'
    }
  },

  computed: {
    ...mapGetters([
      'getProjectDetails',
      'loadingProjectDetails',
      'errorLoadingProjectDetails'
    ]),

    loading () {
      return this.loadingProjectDetails
    },

    error () {
      return this.errorLoadingProjectDetails
    },

    listRouteTitle () {
      return 'Projects'
    },

    detailRouteTitle () {
      return this.projectTitle
    },

    pageTitle () {
      return `${this.pageName} ${this.projectCode}`
    },

    projectCode () {
      /*
      Project for currently handled project from query params
       */
      if (this.$route.params.projectCode !== undefined) {
        return this.$route.params.projectCode
      } else {
        return undefined
      }
    },

    projectTitle () {
      /*
      Return project code and name when available
      */
      if (!isEmpty(this.record)) {
        return `${this.record.project_code} ${this.record.name}`
      } else {
        return ''
      }
    },

    detailRouteParams () {
      /*
      Params to set to route when going to detail route
       */
      return {
        projectCode: this.projectCode
      }
    },

    navigationFilters () {
      if (!this.projectCode) {
        return []
      }
      return [
        {
          key: 'new',
          label: 'Create project',
          hidden: true,
          selected: this.selectedPage === 'new'
        },
        {
          key: 'details',
          label: 'Details',
          selected: this.selectedPage === 'details'
        },
        {
          key: 'members',
          label: 'Members',
          selected: this.selectedPage === 'members'
        },
        {
          key: 'tasks',
          label: 'Tasks',
          selected: this.selectedPage === 'tasks'
        }
      ]
    },

    navigationHeading () {
      /*
      Page navigation heading for browser history
       */
      if (this.record !== undefined) {
        return `${this.pageName} - ${this.record.project_code} ${this.record.name}`
      } else {
        return this.pageName
      }
    },

    pageName () {
      switch (this.selectedPage) {
        case 'new': {
          return 'Create Project'
        }
        case 'details': {
          return this.newRecord ? 'Create Project' : 'Project Details'
        }
        case 'members': {
          return 'Project Members'
        }
        case 'tasks': {
          return 'Project Tasks'
        }
        default: {
          return 'Project'
        }
      }
    },

    activeProjectMembership () {
      /*
      Return logged in employee's current project membership record.

      Memberships are not supposed to overlap in time.
      */
      let now = new Date()
      // Lookup a membership where time period is valid
      return find(this.loggedInEmployeeMemberships, (item) => {
        if (item.starts === null && item.ends === null) {
          return item
        }
        if (item.starts <= now && (item.ends === null || item.ends > now)) {
          return item
        }
        return undefined
      })
    },

    isActiveProjectMember () {
      /*
      Checks if employee has active project membership in project
      */
      return this.activeProjectMembership !== undefined
    },

    isActiveProjectManager () {
      /*
      Checks if employee's active membership has manager role
      */
      let membership = this.activeProjectMembership
      if (membership !== undefined) {
        return membership.role === 'manager'
      } else {
        return false
      }
    },

    canJoin () {
      /*
      Check if logged in employee can join project
       */
      if (this.record !== undefined) {
        return this.record.can_join
      } else {
        return false
      }
    },

    canDelete () {
      /*
      Check if logged in employee can delete project
       */
      return this.isAdminUser
    },

    canEdit () {
      /*
      Check if logged in emplyoee can edit project
       */
      switch (this.selectedPage) {
        case 'new': {
          // This does not refer to the form
          return false
        }
        case 'details': {
          return !isEmpty(this.getProjectDetails) && this.getProjectDetails.can_edit
        }
        case 'members': {
          return this.isAdminUser || this.record.can_edit
        }
        case 'tasks': {
          return !isEmpty(this.getProjectDetails) && this.getProjectDetails.can_edit
        }
        default: {
          return false
        }
      }
    }

  },

  watch: {
    '$route.params.projectCode' () {
      this.loadData()
    }
  },

  mounted () {
    const $vm = this
    Vue.nextTick(function () {
      $vm.newRecord = $vm.projectCode === undefined
      $vm.editing = !!$vm.newRecord
    })
  },

  methods: {

    loadData () {
      /*
      Update form data
      */
      if (this.projectCode) {
        this.$store.dispatch('loadProjectDetails', this.projectCode)
          .then(response => {
            this.record = cloneDeep(this.getProjectDetails)
          })
          .catch((error) => {
            this.$emit('error', error.response)
          })
      } else {
        this.editNewRecord()
      }
    },

    recordCreated (record) {
      /*
      Jump back to project list after creating new project
       */
      this.$router.push({ name: 'project-list' })
    },

    loadCreatedRecord (record) {
      /*
      Load new project record with project-detail route after
       */
      this.$router.push({
        name: this.detailRouteName,
        params: {
          projectCode: record.project_code
        },
        query: {
          page: 'details'
        }
      })
      this.selectedPage = 'details'
    },

    joinClicked () {
      /*
      Employee clicked 'join project' button
       */
      this.bus.$emit('notification', 'join-clicked')
    }

  }
}
</script>
