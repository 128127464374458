<!--
Component to show weekly hours for each project
-->

<template>
  <div class="project-totals-list">
    <div
      v-for="summary in projectTotals"
      :key="summary.project_code"
      class="project-total-hours"
    >
      <div class="project-code">
        {{ summary.projectCode }}
      </div>
      <div class="project-name">
        {{ summary.projectName }}
      </div>
      <div class="duration">
        {{ summary.totalDuration }}
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy } from 'lodash'
import { formatDurationString, getTotalDuration } from '@/components/utils/timeUtils'

export default {
  name: 'PeriodProjectTotals',

  props: {
    projects: {
      type: Array,
      required: true
    },
    records: {
      type: Array,
      required: true
    }
  },

  computed: {
    projectTotals () {
      /*
      Create summary of hours per project based on reported hours
       */
      let summary = {}
      let totals = []
      this.records.forEach(record => {
        if (summary[record.project_code] === undefined) {
          summary[record.project_code] = {
            projectCode: record.project_code,
            projectName: this.projects.find(item => item.project_code === record.project_code).name,
            durations: []
          }
        }
        summary[record.project_code].durations.push(record.duration)
      })
      Object.keys(summary).forEach(projectCode => {
        totals.push({
          projectCode: projectCode,
          projectName: summary[projectCode].projectName,
          value: getTotalDuration(summary[projectCode].durations),
          totalDuration: formatDurationString(getTotalDuration(summary[projectCode].durations))
        })
      })
      totals = sortBy(totals, 'value').reverse()
      return totals
    }
  }
}
</script>

<style lang="scss" scoped>
div.project-total-hours {
  display: flex;
  flex-directory: row;
  justify-content: center;
  align-items: center;
  div.project-code {
    min-width: 2em;
    margin-right: 0.5em;
    font-weight: bold;
  }
  div.project-name {
    width: 16rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    margin: auto;
  }
  div.total-hours {
    min-width: 6em;
  }
}
</style>
