/*
Vue template filters for date values
 */

import Vue from 'vue'
import { format } from 'date-fns'

Vue.filter('date', function (value, showSign = false) {
  /*
  Show date in short format
   */
  return format(value, Vue.prototype.$dateFormat)
})

Vue.filter('apiDate', function (value, showSign = false) {
  /*
  Show date in short format
   */
  return format(value, Vue.prototype.$apiDateFormat)
})

Vue.filter('shortDate', function (value, showSign = false) {
  /*
  Show date in long format
   */
  return format(value, Vue.prototype.$shortDateFormat)
})

Vue.filter('monthString', function (value, showSign = false) {
  /*
  Show month from date in month format
   */
  return format(value, Vue.prototype.$monthFormat)
})
