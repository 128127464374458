
/*
PDF template with logo and common basic formatting
*/

import JsPDF from 'jspdf'
import 'jspdf-autotable'
import Logo from '@/assets/codento_logo.png'

// Default font size in PDF
const defaultFontSize = 8

// Configure logo sizes, flowing offset of elements and line heights
const config = {
  logo: {
    x: 10,
    y: 7,
    width: 43,
    height: 7.4
  },
  heading: {
    x: 10,
    y: 20,
    lineheight: 1.0
  },
  banner: {
    x: 120,
    y: 20,
    lineheight: 1.0
  },
  section: {
    x: 10,
    margin_top: 5,
    lineheight: 1.0
  },
  table: {
    x: 10,
    margin_top: 5,
    lineheight: 0.8
  }
}

export default class PDFDocument {
  /*
  Custom exported PDF document with defafut formatting
   */

  constructor (fontSize = defaultFontSize) {
    this.document = new JsPDF()
    this.fontSize = fontSize
    this.offset = 0
  }

  updateTextOffset (text, margin = 0, fontSize = defaultFontSize) {
    /*
    Update document Y offset from top after adding text
     */
    this.offset += parseInt(margin + this.document.getTextDimensions(text).h)
  }

  addHeading (text) {
    /*
    Add default heading with our log and specified text

    Moves page global offset down by height of heading text
     */
    this.document.addImage(
      Logo,
      config.logo.x,
      config.logo.y,
      config.logo.width,
      config.logo.height
    )
    this.document.setFontSize(this.fontSize)
    this.document.setLineHeightFactor(config.heading.lineheight)
    this.document.text(
      text,
      config.heading.x,
      config.heading.y,
      { align: 'left' }
    )
    this.updateTextOffset(text, config.heading.y)
  }

  addBanner (section) {
    /*
    Add text banner right of logos with specified text lines

    Note: this does NOT change globabl offset
     */
    this.document.setLineHeightFactor(config.banner.lineheight)
    this.document.text(
      section,
      config.banner.x,
      config.banner.y,
      { align: 'left' }
    )
  }

  addSection (section) {
    /*
    Add text section to pdf with specified text lines

    Moves page global offset down by height of section text
     */
    this.document.setLineHeightFactor(config.section.lineheight)
    this.document.text(
      section,
      config.section.x,
      this.offset + config.section.margin_top,
      { align: 'left' }
    )
    this.updateTextOffset(section, config.section.margin_top)
  }

  addTable ({ items = [], head = [], columns = [], columnStyles = {}, tableStyles = {} }) {
    /*
    Add pdf table configured with our default settings and config
     */

    const doc = this.document.autoTable({
      styles: tableStyles,
      columnStyles: columnStyles,
      columns: columns,
      head: head,
      body: items,
      startY: this.offset,
      margin: { top: 20, right: 10, bottom: 20, left: 10 },
      showHead: 'never'
    })
    this.offset = doc.lastAutoTable.finalY
  }

  save (filename) {
    /*
    Save PDF file with specified filename.

    This triggers a download from browser
     */
    this.document.save(filename)
  }
}
