<!--
Common base class for non-editable data view forms
-->

<template>
  <div />
</template>

<script>
import { isEmpty } from 'lodash'

import BaseComponent from '@/components/base/BaseComponent.vue'
export default {
  name: 'DataView',

  extends: BaseComponent,

  props: {
    // Property linking parent view methods to this
    parent: {
      type: Object,
      required: true
    },
    // Record to show in data view
    record: {
      type: Object,
      required: true
    }
  },

  computed: {
    loaded () {
      /*
      Check if record has been loaded
       */
      return !isEmpty(this.record)
    }
  },

  created () {
    this.setupSignals()
  },

  methods: {
    setupSignals () {
      /*
      Setup signals for component
       */
      this.parent.$on('reload', this.reload)
    },

    reload () {
      /*
      Reload component data
       */
    },

    parseDuration (value, placeholder = undefined) {
      /*
      Parse a duration string to HH:MM

      Backend returns also seconds, but we don't use seconds
       */
      if (value !== null) {
        return value.split(':').splice(0, 2).join(':')
      } else {
        return placeholder
      }
    }
  }
}
</script>
