<!--
Dropdown form field

Options must be specified as array of objects with id and label.
-->

<template>
  <div>
    <field-legend
      :id="field.name"
      :label="field.inputs[field.name].label"
      :description="field.inputs[field.name].description"
      :tooltip-variant="tooltipVariant"
      :tooltip-placement="tooltipPlacement"
    />
    <v-select
      :id="id"
      v-model="value"
      :clearable="clearable"
      :disabled="disabled"
      :options="availableOptions"
      :name="`dropdown-${field.name}`"
      @input="$emit('input', field.name, $event)"
    />
    <div
      v-if="error"
      class="field-validation-error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { concat } from 'lodash'
import Field from './Field.vue'

export default {
  name: 'DropdownField',

  components: {
    vSelect
  },

  extends: Field,

  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    noSelectionLabel: {
      type: String,
      default: '-- no selection --'
    }
  },

  computed: {
    availableOptions () {
      /*
      Calculate options, appending noSelectionLabel if field is not required
       */
      return this.required ? this.field.options : concat({
        label: this.noSelectionLabel,
        id: null
      }, this.field.options)
    }
  },

  methods: {
    loadData () {
      let id = null
      let label = this.noSelectionLabel
      if (this.field.options !== undefined) {
        let value = this.field.options.find(item => item.id === this.field.values[this.field.name])
        if (value !== undefined) {
          id = value.id
          label = value.label
        }
      }
      this.value = {
        id: id,
        label: label
      }
    },

    updateData (key, value) {
      if (this.value !== null && this.value.id !== null) {
        value = this.value.id
      }
      this.$emit('update', 'update-value', this.field.name, this.field.name, value)
    }

  }

}
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/bootstrap_overrides.scss';
  @import '../../assets/scss/theme_constants.scss';
  @import '../../../node_modules/vue-select/dist/vue-select.css';
  :deep(.vs__search::placeholder),
  :deep(.vs__dropdown-toggle),
  :deep(.vs__dropdown-menu) {
    text-overflow:ellipsis;
    white-space:nowrap;
  }
  :deep(.vs__selected-options) {
    overflow: hidden;
    text-overflow:ellipsis;
  }
  :deep(.vs__search) {
    min-width: 0
  }
  :deep(.vs__clear),
  :deep(.vs__open-indicator) {
    fill: $primary-color;
  }
</style>
