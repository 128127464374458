<!--
Common element for editor form views with
- title for edited item
- navigation filters list
- navigation buttons list
- form label with show_/ edit button
- container for displayed form data

This component combines editing of the main resource (employee, project) and items directly
related to such items (project resources, employee resources)
-->

<template>
  <BaseView
    :parent="bus"
    :loading="loading"
    :error="error"
    :page-name="pageName"
    :navigation-heading="navigationHeading"
    :title="pageTitle"
    :breadcrumbs="breadcrumbs"
    :navigation-filters="navigationFilters"
    :navigation-actions="navigationActions"
    :show-page-navigation="showPageNavigation"
    @events="eventHandler"
  >
    <template slot="modal">
      <slot name="confirmation-modal" />
    </template>

    <template slot="sub-nav-heading">
      <slot name="sub-nav-heading" />
    </template>

    <template
      slot="content"
    >
      <div
        class="outer-container"
      >
        <div class="paged-navigation top title-row">
          <b-navbar>
            <div left>
              <slot name="heading">
                <h5 class="crop-overflow">
                  {{ pageName }}
                </h5>
              </slot>
            </div>
          </b-navbar>

          <b-navbar
            class="ml-auto float-right editor-controls"
          >
            <div
              v-if="canEdit && selectedPage === 'details'"
              class="float-right mt-1"
              @click="$emit('events', 'toggle-editing')"
            >
              <span v-if="!editing">
                <font-awesome-icon icon="pen" />
              </span>
              <span v-else>
                <font-awesome-icon icon="undo-alt" />
              </span>
            </div>
            <div
              v-if="noEdit.indexOf(selectedPage) === -1"
              class="float-right mt-1"
            >
              <span
                @click="$emit('events', 'edit-new-record')"
              >
                <font-awesome-icon icon="plus" />
              </span>
            </div>
            <slot
              name="editor-controls"
            />
          </b-navbar>
        </div>

        <div
          class="bottom"
        >
          <slot
            v-if="!editing && selectedPage === 'details'"
            name="details-view"
          />
          <slot
            v-if="editing && selectedPage === 'details' || selectedPage === 'new'"
            name="details-editor"
          />
          <slot
            v-if="selectedPage !== 'details'"
            name="details-listing"
          />
        </div>

        <Loading
          v-if="loading && !dataVisible"
          :message="`Loading ${pageName}`"
        />
      </div>
    </template>
  </BaseView>
</template>

<script>
import { isEmpty } from 'lodash'

import BaseComponent from '@/components/base/BaseComponent.vue'
import BaseView from '@/components/elements/BaseView.vue'
import Loading from '@/components/common/Loading.vue'

export default {
  name: 'PagedEditorView',

  components: {
    BaseView,
    Loading
  },

  extends: BaseComponent,

  props: {
    // Parent component for receiving events to child
    parent: {
      type: Object,
      default: undefined
    },
    navigationHeading: {
      type: String,
      default: undefined
    },
    pageName: {
      type: String,
      default: ''
    },
    breadcrumbs: {
      type: Array,
      default: () => ([])
    },
    navigationFilters: {
      type: Array,
      default: () => ([])
    },
    navigationActions: {
      type: Array,
      default: () => ([])
    },
    showPageNavigation: {
      type: Boolean,
      default: true
    },
    noEdit: {
      type: Array,
      default: () => (['details', 'new'])
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: false
    },
    // Property to indicate if page is loading
    loading: {
      type: Boolean,
      required: true
    },
    // Property to show page loading errors
    error: {
      type: [String, Error],
      default: ''
    }
  },

  data: function () {
    return {
      selectedPage: ''
    }
  },

  computed: {

    pageTitle () {
      /*
      Page title to push to navigation
       */
      return `${this.pageName}`
    },

    dataVisible () {
      /*
      Decides if the data for period should be visible
       */
      // No data to show yet
      if (this.loading && isEmpty(this.rows)) {
        return false
      }
      // There may be other cases to consider
      return true
    },
    rows () {
      return []
    }
  },

  watch: {
    selectedPage () {
      /*
      Change loaded sub page by pushing page name to route
       */
      if (this.$route.query.page !== this.selectedPage) {
        this.$router.push({
          name: this.$route.name,
          query: {
            page: this.selectedPage
          }
        })
      }
    }
  },

  created () {
    /*
    Listen on notifications from parent if parent is specified
     */
    if (this.parent) {
      this.parent.$on('notification', this.notificationHandler)
    }
  },

  methods: {

    notificationHandler (action, ...args) {
      switch (action) {
        case 'page-changed': {
          this.$set(this, 'selectedPage', args[0])
          break
        }
      }
    },

    eventHandler (action, ...args) {
      switch (action) {
        case 'toggle-editing': {
          this.$emit('events', 'toggle-editing')
          break
        }
        case 'switch-page': {
          this.$emit('events', 'switch-page', args[0])
          break
        }
      }
    }

  }

}
</script>

<style lang="scss" scoped>

div.paged-navigation {
  min-height: 3.5em;
}
div.slot-missing-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 1rem 0;
  h3 {
    color: red;
  }
}
</style>
