<!--
View to show summary of reported records
-->

<template>
  <b-container fluid>
    <b-row>
      <b-col cols="3">
        Project
      </b-col>
      <b-col cols="9">
        {{ summary.project_code }} - {{ summary.name }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        Reporting period
      </b-col>
      <b-col cols="9">
        {{ summary.reporting_period_start | date }} -
        {{ summary.reporting_period_end | date }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        Billable Hours
      </b-col>
      <b-col cols="9">
        {{ summary.total_billable_hours | durationString }}
      </b-col>
    </b-row>
    <b-row v-if="containsBillableTravel">
      <b-col cols="3">
        Billable Travel
      </b-col>
      <b-col cols="9">
        {{ summary.total_billable_travel_hours | durationString }}
      </b-col>
    </b-row>
    <b-row v-if="containsNonBillable">
      <b-col cols="3">
        Non Billable Hours
      </b-col>
      <b-col cols="9">
        {{ summary.total_non_billable_hours | durationString }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'ReportedRecordsSummary',

  props: {
    summary: {
      type: Object,
      required: true
    },
    containsBillableTravel: {
      type: Boolean,
      default: true
    },
    containsNonBillable: {
      type: Boolean,
      default: true
    }
  }

}
</script>
