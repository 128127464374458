<!--
View to list project members for a project
-->

<template>
  <div
    v-if="!loading"
  >
    <b-modal
      v-model="editModalVisible"
      name="record-edit-modal"
      hide-header
      centered
      hide-footer
      no-close-on-backdrop
      @hide="editCanceled"
    >
      <ProjectMemberEditForm
        v-if="newRecord"
        :parent="bus"
        :employee="selectedEmployee"
        :project="project"
        @events="eventHandler"
      />
    </b-modal>

    <b-table
      :fields="['member']"
      :items="rows"
      responsive
      hover
      thead-class="d-none"
      @row-clicked="rowClickedCallback"
    >
      <template v-slot:cell(member)="row">
        <ProjectMember
          :parent="bus"
          :project="project"
          :employee="row.item.employee"
          :membership="visibleMemberShip(row.item.memberships)"
          @events="eventHandler"
        />
      </template>

      <template v-slot:row-details="row">
        <EmployeeProjectMemberships
          :parent="bus"
          :project="project"
          :employee="row.item.employee"
          :memberships="row.item.memberships"
          @events="eventHandler"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { find, isEmpty, cloneDeep } from 'lodash'
import { formatDateRange } from '@/components/utils/timeUtils'

import ProjectDataEditor from '@/components/project/ProjectDataEditor.vue'
import ProjectMember from '@/components/project/ProjectMember.vue'
import ProjectMemberEditForm from '@/components/project/ProjectMemberEditForm.vue'
import EmployeeProjectMemberships from '@/components/project/EmployeeProjectMemberships.vue'

export default {
  name: 'ProjectMembers',

  components: {
    EmployeeProjectMemberships,
    ProjectMember,
    ProjectMemberEditForm
  },

  extends: ProjectDataEditor,

  data: () => {
    return {
      selectedEmployee: undefined
    }
  },

  computed: {
    ...mapGetters([
      'getProjectDetails',
      'getProjectMembers',
      'loadingProjectDetails',
      'loadingProjectMembers',
      'errorLoadingProjectDetails',
      'errorLoadingProjectMembers'
    ]),
    loading () {
      return this.loadingProjectDetails || this.loadingProjectMembers
    },
    error () {
      return this.errorLoadingProjectDetails || this.errorLoadingProjectMembers
    },
    showJoinLeaveActions () {
      /*
      Boolean to check if join / leave actions should be visible
      */
      return this.record.can_join || this.isActiveProjectMember
    },
    rows () {
      return cloneDeep(this.getProjectMembers)
    }
  },

  methods: {

    localNotificationHandler (notification, ...args) {
      /*
      Editor specific local notification handler
       */
      switch (notification) {
        case 'join-clicked': {
          this.joinProject()
          break
        }
      }
    },

    loadData () {
      /*
      Load project members for project
       */
      console.log('load project member data')
      if (this.project.project_code !== undefined) {
        this.$store.dispatch('resetProjectMembers')
        this.$store.dispatch('loadProjectMembers', {
          project_code: this.project.project_code
        })
      }
    },

    activeMembership (memberships) {
      const now = new Date()
      return find(memberships, (item) => {
        if (item.starts === null && item.ends === null) {
          return item
        }
        if (item.starts <= now && (item.ends === null || item.ends > now)) {
          return item
        }
        return undefined
      })
    },

    visibleMemberShip (memberships) {
      /*
      Get visible membership for memberships
       */
      let membership
      if (memberships.length === 1) {
        return memberships[0]
      }
      membership = this.activeMembership()
      if (membership !== undefined) {
        return membership
      }
      memberships.forEach(item => {
        if (membership === undefined || item.starts > membership.starts) {
          membership = item
        }
      })
      return membership
    },

    joinProject () {
      /*
      Join project
       */
      this.selectedEmployee = this.loggedInEmployee
      this.editNewRecord()
    },

    timerange (membership) {
      /*
      Return formatted membership time range
      */
      if (!isEmpty(membership)) {
        return formatDateRange(membership.start_date, membership.end_date)
      } else {
        return ''
      }
    },

    rowClickedCallback (item) {
      /*
      Row was clickled in page listing
       */
      this.$set(item, '_showDetails', !item._showDetails)
    }

  }

}
</script>
