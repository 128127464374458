<!--
Single input checkbox field
-->

<template>
  <div>
    <b-checkbox
      v-model="value"
      :class="{ 'custom-switch': switches, 'form-inline': true, 'validation-error': error }"
      :name="`input-${field.name}`"
      :autofocus="autofocus"
      :disabled="disabled"
      :required="required"
      type="check"
      @change="$emit('input', field.name, $event)"
    >
      {{ field.inputs[field.name].label }}
    </b-checkbox>
    <div
      v-if="error"
      class="field-validation-error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import Field from './Field.vue'

export default {
  name: 'CheckboxField',

  extends: Field,

  props: {
    name: {
      type: String,
      default: undefined
    },
    size: {
      type: String,
      default: undefined
    },
    switches: {
      type: Boolean,
      default: true
    },
    stacked: {
      type: Boolean,
      default: false
    }
  }

}
</script>

<style lang="scss">
.form-inline .custom-control-label {
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
