<!--
Report for employee billing rates by project
-->

<template>
  <BrowsableListingView
    :parent="bus"
    title="Employee Billing Rates"
    :navigation-filters="navigationFilters"
    :navigation-actions="navigationActions"
    :loading="loadingEmployeeBillingRates"
    :error="errorLoadingEmployeeBillingRates"
    :rows="getEmployeeBillingRates"
    :fields="fields"
  >
    <template slot="sub-nav-heading">
      <h5 class="crop-overflow">
        Employee Billing Rates {{ periodStartDay() | date }} - {{ periodEndDay() | date }}
      </h5>
    </template>

    <template v-slot:cell(roles)="row">
      <h3>Details for {{ row.item.first_name }} {{ row.item.last_name }}</h3>
      <b-card>
        <b-row
          v-for="project in row.item.projects"
          :key="project.key"
        >
          <b-col cols="2">
            <div
              class="billable"
            >
              <font-awesome-icon
                v-if="project.billable"
                icon="euro-sign"
              />
            </div>
            <div class="percent">
              {{ project.percent }} %
            </div>
          </b-col>
          <b-col
            cols="1"
            class="text-right"
          >
            {{ project.hours | durationValue }}
          </b-col>
          <b-col cols="9">
            <b-container>
              <b-row>
                <b-col cols="4">
                  {{ project.first_entry_date }} - {{ project.last_entry_date }}
                </b-col>
                <b-col
                  cols="2"
                  class="project-code"
                >
                  {{ project.project_code }}
                </b-col>
                <b-col cols="6">
                  {{ project.name }}
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-card>
    </template>

    <template v-slot:cell(percentage_by_project_code)="row">
      <ProjectPercentages
        :projects="row.item.projects"
        :height="30"
      />
    </template>
  </BrowsableListingView>
</template>

<script>
import BrowsableListingView from '../../components/elements/BrowsableListingView.vue'
import TimeRangeBrowser from '../../components/base/TimeRangeBrowser.vue'
import ProjectPercentages from '../../components/charts/ProjectPercentages.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'EmployeeBillingRates',

  components: {
    BrowsableListingView,
    ProjectPercentages
  },

  extends: TimeRangeBrowser,

  data () {
    return {
      defaultPeriod: 'month',
      fields: [
        'name',
        'total_hours',
        'billing_rate',
        'percentage_by_project_code'
      ]
    }
  },

  computed: {
    ...mapGetters([
      'getEmployeeBillingRates',
      'loadingEmployeeBillingRates',
      'errorLoadingEmployeeBillingRates'
    ]),
    navigationFilters () {
      return [
        {
          key: 'previous',
          label: 'Previous',
          callback: this.showPreviousPeriod
        },
        {
          key: 'next',
          label: 'Next',
          callback: this.showNextPeriod
        }
      ]
    }
  },

  methods: {
    loadRecords () {
      let params = this.getDateQueryArgs()
      this.$store.dispatch('resetEmployeeBillingRates')
      this.$store.dispatch('loadEmployeeBillingRates', params)
    },

    filteredItems (filters) {
      /*
      Filter items by self.queryFilters values
      */
      let items = this.items
      if (items === undefined) {
        return []
      }
      return items
    },

    setFilters (value) {
      /*
      Filter items
       */
      switch (value) {
        case 'internal':
          self.queryFilters['is_billable'] = false
          break
        case 'billable':
          self.queryFilters['is_billable'] = true
          break
      }
    }
  }

}
</script>

<style lang="scss">
.billable {
  display: inline-block;
  width: 0.75em;
}
.project-code {
  text-align: right;
}
.percent {
  display: inline-block;
  text-align: right;
  min-width: 4em;
}
</style>
