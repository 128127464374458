/*
Vue router main configuration

Route guards can be seen in ./guards.js
 */

import Vue from 'vue'
import Router from 'vue-router'

import Index from '@/views/Index.vue'
import Logout from '@/views/Logout.vue'
import PermissionDenied from '@/views/error/PermissionDenied.vue'
import NotFound from '@/views/error/NotFound.vue'
import NotReady from '@/views/error/NotReady.vue'
import EmployeeList from '@/views/employee/EmployeeList.vue'
import EmployeeHours from '@/views/timetracking/EmployeeHours.vue'
import Employee from '@/views/employee/Employee.vue'
import ProjectList from '@/views/project/ProjectList.vue'
import Project from '@/views/project/Project.vue'

import EmployeeDailyHours from '@/views/report/EmployeeDailyHours.vue'
import EmployeeBillingRates from '@/views/report/EmployeeBillingRates.vue'
import EmployeeWorkSummaries from '@/views/report/EmployeeWorkSummaries.vue'
import ProjectBillingReports from '@/views/report/ProjectBillingReports.vue'
import ProjectBillingReportDetails from '@/views/report/ProjectBillingReportDetails.vue'

import * as guards from './guards'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/notready',
      name: 'notready',
      component: NotReady
    },

    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: PermissionDenied
    },

    {
      path: '/hours',
      name: 'employee-hours',
      component: EmployeeHours,
      beforeEnter: guards.requireAuth
    },

    {
      path: '/profile/details',
      name: 'my-profile',
      component: Employee,
      beforeEnter: guards.requireAuth
    },

    {
      path: '/employees',
      name: 'employee-list',
      component: EmployeeList,
      beforeEnter: guards.requireAuth
    },

    {
      path: '/employees/new',
      name: 'employee-create',
      component: Employee,
      beforeEnter: guards.requireAuth
    },

    {
      path: '/employees/details/:email',
      name: 'employee-details',
      component: Employee,
      beforeEnter: guards.requireAuth
    },

    {
      path: '/projects',
      name: 'project-list',
      component: ProjectList,
      beforeEnter: guards.requireAuth
    },

    {
      path: '/projects/new',
      name: 'project-create',
      component: Project,
      beforeEnter: guards.requireAuth
    },

    {
      path: '/projects/details/:projectCode',
      name: 'project-details',
      component: Project,
      beforeEnter: guards.requireAuth
    },

    {
      path: '/reports/employee-daily-hours',
      name: 'employee-reported-hours',
      beforeEnter: guards.requireAuth,
      component: EmployeeDailyHours
    },

    {
      path: '/reports/employee-billing-rates',
      name: 'employee-billing-rates',
      beforeEnter: guards.requireAuth,
      component: EmployeeBillingRates
    },

    {
      path: '/reports/employee-work-summaries',
      name: 'employee-work-summaries',
      beforeEnter: guards.requireAuth,
      component: EmployeeWorkSummaries
    },

    {
      path: '/reports/projects',
      name: 'monthly-billing-reports',
      beforeEnter: guards.requireAuth,
      component: ProjectBillingReports
    },

    {
      path: '/reports/project-details/:projectCode/:month',
      name: 'billing-report-details',
      component: ProjectBillingReportDetails,
      beforeEnter: guards.requireAuth
    },

    {
      path: '/employee',
      redirect: { name: 'employee-hours' }
    },

    {
      path: '/employee/hours',
      redirect: { name: 'employee-hours' }
    },

    {
      path: '/',
      name: 'Index',
      component: Index
    },

    {
      path: '/login',
      redirect: { name: 'Index' }
    },

    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },

    {
      path: '*',
      name: 'NotFound',
      component: NotFound
    }

  ]

})
