<!--
Base component for fields with range

Handles loading and updating of the range start and end fields
-->

<template>
  <div />
</template>

<script>
import Field from './Field.vue'

export default {
  name: 'RangeField',

  extends: Field,

  data: function () {
    return {
      range_start: undefined,
      range_end: undefined
    }
  },

  computed: {
    range_start_label () {
      return this.field.inputs['range_start'].label
    },
    range_start_description () {
      return this.field.inputs['range_start'].description
    },
    range_end_label () {
      return this.field.inputs['range_end'].label
    },
    range_end_description () {
      return this.field.inputs['range_end'].description
    },
    range_start_error () {
      return this.field.errors[this.field.inputs.range_start.key]
    },
    range_end_error () {
      return this.field.errors[this.field.inputs.range_end.key]
    },
    range_error () {
      return this.field.errors.range_error
    }
  }

}
</script>
