<!--
Component to show list of employee's project memberships
-->

<template>
  <b-container
    fluid
    class="mx-0"
  >
    <b-modal
      v-model="editModalVisible"
      name="record-edit-modal"
      hide-header
      centered
      hide-footer
      no-close-on-backdrop
      @hide="editCanceled"
    >
      <ProjectMemberEditForm
        :parent="bus"
        :project="project"
        :employee="employee"
        :record="record"
        @events="eventHandler"
      />
    </b-modal>

    <b-table

      :items="memberships"
      :fields="['member']"
      responsive
      hover
      borderless
      thead-class="d-none"
      @row-clicked="membershipClicked"
    >
      <template v-slot:cell(member)="row">
        <b-row>
          <b-col>
            {{ row.item.role }}
          </b-col>
          <b-col>
            {{ timerange(row.item) }}
          </b-col>
          <b-col>
            {{ allocation(row.item) }}
          </b-col>
          <b-col>
            {{ hourly_price(row.item) }}
          </b-col>
        </b-row>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import { isEmpty } from 'lodash'
import { formatDateRange } from '@/components/utils/timeUtils'

import Editor from '@/components/base/Editor.vue'
import ProjectMemberEditForm from '@/components/project/ProjectMemberEditForm.vue'

export default {
  name: 'EmployeeProjectMemberships',

  components: {
    ProjectMemberEditForm
  },

  extends: Editor,

  props: {
    project: {
      type: Object,
      required: true
    },
    employee: {
      type: Object,
      required: true
    },
    memberships: {
      type: Array,
      required: true
    }
  },

  computed: {
    canEdit () {
      return this.project.can_edit || this.loggedInEmployeeEmail === this.employee.email
    }
  },

  methods: {

    loadData () {
      /*
      Gets actually data from parent, ignore
       */
    },

    membershipClicked (membership) {
      /*
      Clicked on a membership
       */
      if (this.canEdit) {
        this.editRecord(membership)
      }
    },

    timerange (membership) {
      /*
      Return formatted membership time range
      */
      if (!isEmpty(membership)) {
        return formatDateRange(membership.start_date, membership.end_date)
      } else {
        return ''
      }
    },

    allocation (membership) {
      if (!isEmpty(membership)) {
        if (membership.allocation_rate !== null) {
          return `${membership.allocation_rate} %`
        } else if (membership.allocation_days !== null) {
          return `${membership.allocation_days} days`
        }
      }
      return ''
    },

    hourly_price (membership) {
      if (!isEmpty(membership)) {
        if (membership.hourly_price !== null) {
          return `${membership.hourly_price} EUR/h`
        }
      }
      return ''
    }

  }
}
</script>
