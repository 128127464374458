var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-auto px-0"},[(_vm.isAuthenticated && !_vm.isUnconfigured)?_c('b-navbar',{attrs:{"toggleable":"sm","type":"dark","variant":"info"}},[_c('b-navbar-brand',[_c('router-link',{attrs:{"to":{
          name: 'employee-hours',
          params: {},
          query: {
            period: 'week'
          }
        }}},[_vm._v(" Hours ")])],1),_c('b-navbar-toggle',{staticClass:"ml-auto",attrs:{"target":"nav-text-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-text-collapse","is-nav":""}},[_c('b-navbar-nav',{attrs:{"fill":""}},[(_vm.isAdminUser || _vm.isHRUser || _vm.isFinanceUser)?_c('b-nav-item',{attrs:{"to":{
            name: 'employee-list',
            params: {},
            query: { is_active: true }
          }}},[_vm._v(" Employees ")]):_vm._e(),(_vm.isInternal)?_c('b-nav-item',{attrs:{"to":{
            name: 'project-list',
            params: {},
            query: { is_archived: false }
          }}},[_vm._v(" Projects ")]):_vm._e(),(_vm.isInternal)?_c('b-nav-item-dropdown',{attrs:{"text":"Reports"}},[_c('b-dropdown-item',{attrs:{"to":{ name: 'employee-reported-hours', params: {}}}},[_vm._v(" Daily Reported Hours ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'employee-billing-rates', params: {}}}},[_vm._v(" Employee Billing Rates ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'employee-work-summaries', params: {}}}},[_vm._v(" Employee Work Summaries ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'monthly-billing-reports', params: {}}}},[_vm._v(" Monthly Billing Reports ")])],1):_vm._e()],1),_c('b-navbar-nav',{staticClass:"ml-auto",attrs:{"fill":""}},[_c('b-nav-item',{attrs:{"to":{name: 'Logout'},"title":"logout"}},[_c('i',{staticClass:"fa fa-sign-out",attrs:{"aria-hidden":"true"}}),_vm._v(" Logout ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }