/*
Employee remuneration state
 */

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadRemunerations: ({ commit }, params = {}) => {
    /*
    Load list of remunerations
    */
    return helpers.loadListing(
      commit,
      'timesheet/remunerations',
      params,
      types.REMUNERATION_LIST_LOAD,
      types.REMUNERATION_LIST_LOAD_SUCCESS,
      types.REMUNERATION_LIST_LOAD_ERROR
    )
  },
  loadRemunerationDetails: ({ commit }, email) => {
    return helpers.loadDetails(
      commit,
      `timesheet/remunerations/${email}`,
      types.REMUNERATION_DETAILS_LOAD,
      types.REMUNERATION_DETAILS_LOAD_SUCCESS,
      types.REMUNERATION_DETAILS_LOAD_ERROR
    )
  },
  createRemuneration: ({ commit }, params) => {
    /*
    Add new remuneration
     */
    return helpers.postRecord(
      commit,
      'timesheet/remunerations',
      params,
      types.REMUNERATION_CREATE,
      types.REMUNERATION_CREATE_SUCCESS,
      types.REMUNERATION_CREATE_ERROR
    )
  },
  updateRemuneration: ({ commit }, params) => {
    /*
    Update existing remuneration
     */
    return helpers.updateRecord(
      commit,
      `timesheet/remunerations/${params.id}`,
      params,
      types.REMUNERATION_UPDATE,
      types.REMUNERATION_UPDATE_SUCCESS,
      types.REMUNERATION_UPDATE_ERROR
    )
  },
  deleteRemuneration: ({ commit }, params) => {
    /*
    Delete remuneration
     */
    return helpers.deleteRecord(
      commit,
      `timesheet/remunerations/${params.id}`,
      types.REMUNERATION_DELETE,
      types.REMUNERATION_DELETE_SUCCESS,
      types.REMUNERATION_DELETE_ERROR
    )
  },
  resetRemunerations: ({ commit }, params) => {
    commit(types.REMUNERATION_LIST_CLEAR)
  }
}

const mutations = {
  [types.REMUNERATION_LIST_CLEAR] (state) {
    helpers.mutateResetData(state)
  },
  [types.REMUNERATION_LIST_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.REMUNERATION_LIST_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.REMUNERATION_LIST_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  },
  [types.REMUNERATION_CREATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.REMUNERATION_CREATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.REMUNERATION_CREATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.REMUNERATION_UPDATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.REMUNERATION_UPDATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.REMUNERATION_UPDATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.REMUNERATION_DELETE] (state) {
    helpers.mutateItemDeleting(state)
  },
  [types.REMUNERATION_DELETE_SUCCESS] (state) {
    helpers.mutateItemDeleted(state)
  },
  [types.REMUNERATION_DELETE_ERROR] (state, error) {
    helpers.mutateItemDeleteFailed(state, error)
  }
}

const getters = {
  getRemunerations: () => {
    /*
    Get all loaded remunerations
    */
    return state.items
  },
  loadingRemunerations: () => {
    /*
    Check if remuneration list is loading
     */
    return state.loading
  },
  loadingRemunerationDetails: () => {
    /*
    Check if remuneration details are loading
     */
    return state.loadingDetails
  },
  updatingRemunerationDetails: () => {
    /*
    Check if remuneration record is being updated
     */
    return state.updating
  },
  deletingRemunerationDetails: () => {
    /*
    Check if remuneration record is being deleted
     */
    return state.deleting
  },
  errorLoadingRemunerations: () => {
    /*
    Check if loading timesheet record list caused errors
     */
    return state.errors.loading
  },
  errorLoadingRemunerationDetails: () => {
    /*
    Check if loading timesheet record details caused errors
     */
    return state.errors.loadingDetails
  },
  errorUpdatingRemuneration: () => {
    /*
    Check if updating remuneration details caused errors
     */
    return state.errors.updating
  },
  errorDeletingRemuneration: () => {
    /*
    Check if deleting remuneration details caused errors
     */
    return state.errors.deleting
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
