<!--
Main Vue app for jaggers
-->

<template>
  <div
    id="app"
    class="d-flex flex-column justify-content-start h-100"
  >
    <NavBar v-if="!fullScreenRouteView" />
    <div
      id="jaggers-main"
      class="flex-grow-1 h-100"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/elements/NavBar.vue'
const fullScreenViews = [
  'login',
  'error'
]

export default {
  name: 'App',

  components: {
    NavBar
  },

  computed: {
    fullScreenRouteView () {
      /*
      Returns true if current view is shown as full screen, without navigation or footer
       */
      return fullScreenViews.indexOf(this.$route.name) !== -1
    }
  },

  created () {
    window.google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      hosted_domain: process.env.VUE_APP_GOOGLE_HOSTED_DOMAIN,
      state_cookie_domain: process.env.VUE_APP_GOOGLE_HOSTED_DOMAIN,
      callback: this.googleOneTapCallback,
      ux_mode: 'popup',
      scope: 'profile'
    })
  },

  methods: {
    googleOneTapCallback (response) {
      this.$store.dispatch('googleLoginSuccess', response)
    }
  }
}

</script>

<style lang='scss'>
  @import './assets/scss/App';
  @import './assets/scss/bootstrap_overrides';
  @import './assets/scss/theme_constants.scss';

  .bg-navmain {
    background-color: $brand-primary !important;
  }
  nav.navbar.bg-info {
    background-color: $brand-primary !important;
  }
  div.navbar-brand {
    padding: 0;
    font-size: 1rem;
    a {
      font-weight: bold;
      color: #fff;
    }
  }
  ul.navbar-brand a,
  li.nav-item,
  a.nav-link em,
  a.nav-link span {
    font-weight: bold;
    color: #fff;
    :after {
      color: #fff;
    }
  }
  ul.navbar-nav a.dropdown-item {
    :focus {
      outline: none;
    }
  }
  li.nav-item {
    list-style: none;
  }
  * {
    font-family: 'century-gothic', sans-serif;
  }
  @font-face {
    font-family: century-gothic;
    src: url(./assets/gothic.ttf);
  }
</style>
