
export const LOCAL_AUTH_REQUEST = 'LOCAL_AUTH_REQUEST'
export const LOCAL_AUTH_SUCCESS = 'LOCAL_AUTH_SUCCESS'
export const LOCAL_AUTH_ERROR = 'LOCAL_AUTH_ERROR'
export const LOCAL_AUTH_LOGOUT = 'LOCAL_AUTH_LOGOUT'

export const GOOGLE_AUTH_REQUEST = 'GOOGLE_AUTH_REQUEST'
export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS'
export const GOOGLE_AUTH_SIGNOUT = 'GOOGLE_AUTH_SIGNOUT'
export const GOOGLE_AUTH_ERROR = 'GOOGLE_AUTH_ERROR'

export const AUTH_PROFILE_LOAD_REQUEST = 'AUTH_PROFILE_REQUEST'
export const AUTH_PROFILE_LOAD_SUCCESS = 'AUTH_PROFILE_SUCCESS'
export const AUTH_PROFILE_LOAD_ERROR = 'AUTH_PROFILE_LOAD_ERROR'

export const EMPLOYEES_CHOICES_LOAD = 'EMPLOYEES_CHOICES_LOAD'
export const EMPLOYEES_CHOICES_LOAD_SUCCESS = 'EMPLOYEES_CHOICES_LOAD_SUCCESS'
export const EMPLOYEES_CHOICES_LOAD_ERROR = 'EMPLOYEES_CHOICES_LOAD_ERROR'

export const EMPLOYMENT_CONTRACT_TYPES_CHOICES_LOAD = 'EMPLOYMENT_CONTRACT_TYPES_CHOICES_LOAD'
export const EMPLOYMENT_CONTRACT_TYPES_CHOICES_LOAD_SUCCESS = 'EMPLOYMENT_CONTRACT_TYPES_CHOICES_LOAD_SUCCESS'
export const EMPLOYMENT_CONTRACT_TYPES_CHOICES_LOAD_ERROR = 'EMPLOYMENT_CONTRACT_TYPES_CHOICES_LOAD_ERROR'

export const EMPLOYEE_TYPES_CHOICES_LOAD = 'EMPLOYEE_TYPES_CHOICES_LOAD'
export const EMPLOYEE_TYPES_CHOICES_LOAD_SUCCESS = 'EMPLOYEE_TYPES_CHOICES_LOAD_SUCCESS'
export const EMPLOYEE_TYPES_CHOICES_LOAD_ERROR = 'EMPLOYEE_TYPES_CHOICES_LOAD_ERROR'

export const PROJECT_CATEGORIES_CHOICES_LOAD = 'PROJECT_CATEGORIES_CHOICES_LOAD'
export const PROJECT_CATEGORIES_CHOICES_LOAD_SUCCESS = 'PROJECT_CATEGORIES_CHOICES_LOAD_SUCCESS'
export const PROJECT_CATEGORIES_CHOICES_LOAD_ERROR = 'PROJECT_CATEGORIES_CHOICES_LOAD_ERROR'

export const PROJECT_MEMBER_ROLES_CHOICES_LOAD = 'PROJECT_MEMBER_ROLES_CHOICES_LOAD'
export const PROJECT_MEMBER_ROLES_CHOICES_LOAD_SUCCESS = 'PROJECT_MEMBER_ROLES_CHOICES_LOAD_SUCCESS'
export const PROJECT_MEMBER_ROLES_CHOICES_LOAD_ERROR = 'PROJECT_MEMBER_ROLES_CHOICES_LOAD_ERROR'

export const PROJECT_TYPES_CHOICES_LOAD = 'PROJECT_TYPES_CHOICES_LOAD'
export const PROJECT_TYPES_CHOICES_LOAD_SUCCESS = 'PROJECT_TYPES_CHOICES_LOAD_SUCCESS'
export const PROJECT_TYPES_CHOICES_LOAD_ERROR = 'PROJECT_TYPES_CHOICES_LOAD_ERROR'

export const REMUNERATION_TYPES_CHOICES_LOAD = 'REMUNERATION_TYPES_CHOICES_LOAD'
export const REMUNERATION_TYPES_CHOICES_LOAD_SUCCESS = 'REMUNERATION_TYPES_CHOICES_LOAD_SUCCESS'
export const REMUNERATION_TYPES_CHOICES_LOAD_ERROR = 'REMUNERATION_TYPES_CHOICES_LOAD_ERROR'

export const TIMESHEET_RECORD_TYPES_CHOICES_LOAD = 'TIMESHEET_RECORD_TYPES_CHOICES_LOAD'
export const TIMESHEET_RECORD_TYPES_CHOICES_LOAD_SUCCESS = 'TIMESHEET_RECORD_TYPES_CHOICES_LOAD_SUCCESS'
export const TIMESHEET_RECORD_TYPES_CHOICES_LOAD_ERROR = 'TIMESHEET_RECORD_TYPES_CHOICES_LOAD_ERROR'

export const WORK_WEEK_CHOICES_LOAD = 'WORK_WEEK_CHOICES_LOAD'
export const WORK_WEEK_CHOICES_LOAD_SUCCESS = 'WORK_WEEK_CHOICES_LOAD_SUCCESS'
export const WORK_WEEK_CHOICES_LOAD_ERROR = 'WORK_WEEK_CHOICES_LOAD_ERROR'

export const HOLIDAY_LIST_LOAD = 'HOLIDAY_LIST_LOAD'
export const HOLIDAY_LIST_LOAD_SUCCESS = 'HOLIDAY_LIST_LOAD_SUCCESS'
export const HOLIDAY_LIST_LOAD_ERROR = 'HOLIDAY_LIST_LOAD_ERROR'

export const CONTRACT_PERIOD_LIST_LOAD = 'CONTRACT_PERIOD_LIST_LOAD'
export const CONTRACT_PERIOD_LIST_LOAD_SUCCESS = 'CONTRACT_PERIOD_LIST_LOAD_SUCCESS'
export const CONTRACT_PERIOD_LIST_LOAD_ERROR = 'CONTRACT_PERIOD_LIST_LOAD_ERROR'
export const CONTRACT_PERIOD_LIST_RESET = 'CONTRACT_PERIOD_LIST_RESET'

export const CONTRACT_PERIOD_CREATE = 'CONTRACT_PERIOD_CREATE'
export const CONTRACT_PERIOD_CREATE_SUCCESS = 'CONTRACT_PERIOD_CREATE_SUCCESS'
export const CONTRACT_PERIOD_CREATE_ERROR = 'CONTRACT_PERIOD_CREATE_ERROR'

export const CONTRACT_PERIOD_UPDATE = 'CONTRACT_PERIOD_UPDATE'
export const CONTRACT_PERIOD_UPDATE_SUCCESS = 'CONTRACT_PERIOD_UPDATE_SUCCESS'
export const CONTRACT_PERIOD_UPDATE_ERROR = 'CONTRACT_PERIOD_UPDATE_ERROR'

export const CONTRACT_PERIOD_DELETE = 'CONTRACT_PERIOD_DELETE'
export const CONTRACT_PERIOD_DELETE_SUCCESS = 'CONTRACT_PERIOD_DELETE_SUCCESS'
export const CONTRACT_PERIOD_DELETE_ERROR = 'CONTRACT_PERIOD_DELETE_ERROR'

export const EMPLOYEE_LIST_LOAD = 'EMPLOYEE_LIST_LOAD'
export const EMPLOYEE_LIST_LOAD_SUCCESS = 'EMPLOYEE_LIST_LOAD_SUCCESS'
export const EMPLOYEE_LIST_LOAD_ERROR = 'EMPLOYEE_LIST_LOAD_ERROR'

export const EMPLOYEE_PROJECTS_LOAD = 'EMPLOYEE_PROJECTS_LOAD'
export const EMPLOYEE_PROJECTS_LOAD_SUCCESS = 'EMPLOYEE_PROJECTS_LOAD_SUCCESS'
export const EMPLOYEE_PROJECTS_LOAD_ERROR = 'EMPLOYEE_PROJECTS_LOAD_ERROR'

export const JOINABLE_PROJECTS_LOAD = 'JOINABLE_PROJECTS_LOAD'
export const JOINABLE_PROJECTS_LOAD_SUCCESS = 'JOINABLE_PROJECTS_LOAD_SUCCESS'
export const JOINABLE_PROJECTS_LOAD_ERROR = 'JOINABLE_PROJECTS_LOAD_ERROR'

export const JOINABLE_PROJECT_MEMBER_CHOICES_LOAD = 'JOINABLE_PROJECT_MEMBER_CHOICES_LOAD'
export const JOINABLE_PROJECT_MEMBER_CHOICES_LOAD_SUCCESS = 'JOINABLE_PROJECT_MEMBER_CHOICES_LOAD_SUCCESS'
export const JOINABLE_PROJECT_MEMBER_CHOICES_LOAD_ERROR = 'JOINABLE_PROJECT_MEMBER_CHOICES_LOAD_ERROR'

export const EMPLOYEE_DETAILS_LOAD = 'EMPLOYEE_DETAILS_LOAD'
export const EMPLOYEE_DETAILS_LOAD_SUCCESS = 'EMPLOYEE_DETAILS_LOAD_SUCCESS'
export const EMPLOYEE_DETAILS_LOAD_ERROR = 'EMPLOYEE_DETAILS_LOAD_ERROR'

export const EMPLOYEE_CREATE = 'EMPLOYEE_CREATE'
export const EMPLOYEE_CREATE_SUCCESS = 'EMPLOYEE_CREATE_SUCCESS'
export const EMPLOYEE_CREATE_ERROR = 'EMPLOYEE_CREATE_ERROR'

export const EMPLOYEE_UPDATE = 'EMPLOYEE_UPDATE'
export const EMPLOYEE_UPDATE_SUCCESS = 'EMPLOYEE_UPDATE_SUCCESS'
export const EMPLOYEE_UPDATE_ERROR = 'EMPLOYEE_UPDATE_ERROR'

export const EMPLOYEE_DELETE = 'EMPLOYEE_DELETE'
export const EMPLOYEE_DELETE_SUCCESS = 'EMPLOYEE_DELETE_SUCCESS'
export const EMPLOYEE_DELETE_ERROR = 'EMPLOYEE_DELETE_ERROR'

export const EMPLOYEE_WORK_DAYS_LOAD = 'EMPLOYEE_WORK_DAYS_LOAD'
export const EMPLOYEE_WORK_DAYS_LOAD_SUCCESS = 'EMPLOYEE_WORK_DAYS_LOAD_SUCCESS'
export const EMPLOYEE_WORK_DAYS_LOAD_ERROR = 'EMPLOYEE_WORK_DAYS_LOAD_ERROR'

export const BILLING_CONTACT_LIST_LOAD = 'BILLING_CONTACT_LIST_LOAD'
export const BILLING_CONTACT_LIST_LOAD_SUCCESS = 'BILLING_CONTACT_LIST_LOAD_SUCCESS'
export const BILLING_CONTACT_LIST_LOAD_ERROR = 'BILLING_CONTACT_LIST_LOAD_ERROR'

export const PROJECT_MEMBER_LIST_LOAD = 'PROJECT_MEMBER_LIST_LOAD'
export const PROJECT_MEMBER_LIST_LOAD_SUCCESS = 'PROJECT_MEMBER_LIST_LOAD_SUCCESS'
export const PROJECT_MEMBER_LIST_LOAD_ERROR = 'PROJECT_MEMBER_LIST_LOAD_ERROR'
export const PROJECT_MEMBER_LIST_RESET = 'PROJECT_MEMBER_LIST_RESET'

export const PROJECT_MEMBER_DETAILS_LOAD = 'PROJECT_MEMBER_DETAILS_LOAD'
export const PROJECT_MEMBER_DETAILS_LOAD_SUCCESS = 'PROJECT_MEMBER_DETAILS_LOAD_SUCCESS'
export const PROJECT_MEMBER_DETAILS_LOAD_ERROR = 'PROJECT_MEMBER_DETAILS_LOAD_ERROR'

export const PROJECT_MEMBER_CREATE = 'PROJECT_MEMBER_CREATE'
export const PROJECT_MEMBER_CREATE_SUCCESS = 'PROJECT_MEMBER_CREATE_SUCCESS'
export const PROJECT_MEMBER_CREATE_ERROR = 'PROJECT_MEMBER_CREATE_ERROR'

export const PROJECT_MEMBER_UPDATE = 'PROJECT_MEMBER_UPDATE'
export const PROJECT_MEMBER_UPDATE_SUCCESS = 'PROJECT_MEMBER_UPDATE_SUCCESS'
export const PROJECT_MEMBER_UPDATE_ERROR = 'PROJECT_MEMBER_UPDATE_ERROR'

export const PROJECT_MEMBER_DELETE = 'PROJECT_MEMBER_DELETE'
export const PROJECT_MEMBER_DELETE_SUCCESS = 'PROJECT_MEMBER_DELETE_SUCCESS'
export const PROJECT_MEMBER_DELETE_ERROR = 'PROJECT_MEMBER_DELETE_ERROR'

export const PROJECT_LIST_LOAD = 'PROJECT_LIST_LOAD'
export const PROJECT_LIST_LOAD_SUCCESS = 'PROJECT_LIST_LOAD_SUCCESS'
export const PROJECT_LIST_LOAD_ERROR = 'PROJECT_LIST_LOAD_ERROR'
export const PROJECT_LIST_LOAD_CLEAR = 'PROJECT_LIST_LOAD_CLEAR'
export const PROJECT_LIST_RESET = 'PROJECT_LIST_RESET'

export const PROJECT_DETAILS_LOAD = 'PROJECT_DETAILS_LOAD'
export const PROJECT_DETAILS_LOAD_SUCCESS = 'PROJECT_DETAILS_LOAD_SUCCESS'
export const PROJECT_DETAILS_LOAD_ERROR = 'PROJECT_DETAILS_LOAD_ERROR'

export const PROJECT_CREATE = 'PROJECT_CREATE'
export const PROJECT_CREATE_SUCCESS = 'PROJECT_CREATE_SUCCESS'
export const PROJECT_CREATE_ERROR = 'PROJECT_CREATE_ERROR'

export const PROJECT_UPDATE = 'PROJECT_UPDATE'
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS'
export const PROJECT_UPDATE_ERROR = 'PROJECT_UPDATE_ERROR'

export const PROJECT_DELETE = 'PROJECT_DELETE'
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS'
export const PROJECT_DELETE_ERROR = 'PROJECT_DELETE_ERROR'

export const PROJECT_TASK_LIST_LOAD = 'PROJECT_TASK_LIST_LOAD'
export const PROJECT_TASK_LIST_LOAD_SUCCESS = 'PROJECT_TASK_LIST_LOAD_SUCCESS'
export const PROJECT_TASK_LIST_LOAD_ERROR = 'PROJECT_TASK_LIST_LOAD_ERROR'
export const PROJECT_TASK_LIST_RESET = 'PROJECT_TASK_LIST_RESET'

export const PROJECT_TASK_CREATE = 'PROJECT_TASK_CREATE'
export const PROJECT_TASK_CREATE_SUCCESS = 'PROJECT_TASK_CREATE_SUCCESS'
export const PROJECT_TASK_CREATE_ERROR = 'PROJECT_TASK_CREATE_ERROR'

export const PROJECT_TASK_UPDATE = 'PROJECT_TASK_UPDATE'
export const PROJECT_TASK_UPDATE_SUCCESS = 'PROJECT_TASK_UPDATE_SUCCESS'
export const PROJECT_TASK_UPDATE_ERROR = 'PROJECT_TASK_UPDATE_ERROR'

export const PROJECT_TASK_DELETE = 'PROJECT_TASK_DELETE'
export const PROJECT_TASK_DELETE_SUCCESS = 'PROJECT_TASK_DELETE_SUCCESS'
export const PROJECT_TASK_DELETE_ERROR = 'PROJECT_TASK_DELETE_ERROR'

export const EMPLOYEE_BILLING_RATES_LOAD = 'EMPLOYEE_BILLING_RATES_LOAD'
export const EMPLOYEE_BILLING_RATES_LOAD_SUCCESS = 'EMPLOYEE_BILLING_RATES_LOAD_SUCCESS'
export const EMPLOYEE_BILLING_RATES_LOAD_ERROR = 'EMPLOYEE_BILLING_RATES_LOAD_ERROR'
export const EMPLOYEE_BILLING_RATES_LOAD_RESET = 'EMPLOYEE_BILLING_RATES_LOAD_RESET'

export const EMPLOYEE_WORK_SUMMARIES_LOAD = 'EMPLOYEE_WORK_SUMMARIES_LOAD'
export const EMPLOYEE_WORK_SUMMARIES_LOAD_SUCCESS = 'EMPLOYEE_WORK_SUMMARIES_LOAD_SUCCESS'
export const EMPLOYEE_WORK_SUMMARIES_LOAD_ERROR = 'EMPLOYEE_WORK_SUMMARIES_LOAD_ERROR'
export const EMPLOYEE_WORK_SUMMARIES_LOAD_RESET = 'EMPLOYEE_WORK_SUMMARIES_LOAD_RESET'

export const EMPLOYEE_WORK_SUMMARY_DETAILS_LOAD = 'EMPLOYEE_WORK_SUMMARY_DETAILS_LOAD'
export const EMPLOYEE_WORK_SUMMARY_DETAILS_LOAD_SUCCESS = 'EMPLOYEE_WORK_SUMMARY_DETAILS_LOAD_SUCCESS'
export const EMPLOYEE_WORK_SUMMARY_DETAILS_LOAD_ERROR = 'EMPLOYEE_WORK_SUMMARY_DETAILS_LOAD_ERROR'

export const PROJECT_BILLING_REPORT_LIST_LOAD = 'PROJECT_BILLING_REPORT_LIST_LOAD'
export const PROJECT_BILLING_REPORT_LIST_LOAD_SUCCESS = 'PROJECT_BILLING_REPORT_LIST_LOAD_SUCCESS'
export const PROJECT_BILLING_REPORT_LIST_LOAD_ERROR = 'PROJECT_BILLING_REPORT_LIST_LOAD_ERROR'
export const PROJECT_BILLING_REPORT_LIST_RESET = 'PROJECT_BILLING_REPORT_LIST_RESET'

export const PROJECT_BILLING_REPORT_DETAILS_LOAD = 'PROJECT_BILLING_REPORT_DETAILS_LOAD'
export const PROJECT_BILLING_REPORT_DETAILS_LOAD_SUCCESS = 'PROJECT_BILLING_REPORT_DETAILS_LOAD_SUCCESS'
export const PROJECT_BILLING_REPORT_DETAILS_LOAD_ERROR = 'PROJECT_BILLING_REPORT_DETAILS_LOAD_ERROR'

export const LEAVE_LIST_LOAD = 'LEAVE_LIST_LOAD'
export const LEAVE_LIST_LOAD_SUCCESS = 'LEAVE_LIST_LOAD_SUCCESS'
export const LEAVE_LIST_LOAD_ERROR = 'LEAVE_LIST_LOAD_ERROR'

export const LEAVE_DETAILS_LOAD = 'LEAVE_DETAILS_LOAD'
export const LEAVE_DETAILS_LOAD_SUCCESS = 'LEAVE_DETAILS_LOAD_SUCCESS'
export const LEAVE_DETAILS_LOAD_ERROR = 'LEAVE_DETAILS_LOAD_ERROR'

export const LEAVE_CREATE = 'LEAVE_CREATE'
export const LEAVE_CREATE_SUCCESS = 'LEAVE_CREATE_SUCCESS'
export const LEAVE_CREATE_ERROR = 'LEAVE_CREATE_ERROR'

export const LEAVE_UPDATE = 'LEAVE_UPDATE'
export const LEAVE_UPDATE_SUCCESS = 'LEAVE_UPDATE_SUCCESS'
export const LEAVE_UPDATE_ERROR = 'LEAVE_UPDATE_ERROR'

export const LEAVE_DELETE = 'LEAVE_DELETE'
export const LEAVE_DELETE_SUCCESS = 'LEAVE_DELETE_SUCCESS'
export const LEAVE_DELETE_ERROR = 'LEAVE_DELETE_ERROR'

export const REMUNERATION_LIST_LOAD = 'REMUNERATION_LIST_LOAD'
export const REMUNERATION_LIST_LOAD_SUCCESS = 'REMUNERATION_LIST_LOAD_SUCCESS'
export const REMUNERATION_LIST_LOAD_ERROR = 'REMUNERATION_LIST_LOAD_ERROR'
export const REMUNERATION_LIST_CLEAR = 'REMUNERATION_LIST_CLEAR'

export const REMUNERATION_DETAILS_LOAD = 'REMUNERATION_DETAILS_LOAD'
export const REMUNERATION_DETAILS_LOAD_SUCCESS = 'REMUNERATION_DETAILS_LOAD_SUCCESS'
export const REMUNERATION_DETAILS_LOAD_ERROR = 'REMUNERATION_DETAILS_LOAD_ERROR'

export const REMUNERATION_CREATE = 'REMUNERATION_CREATE'
export const REMUNERATION_CREATE_SUCCESS = 'REMUNERATION_CREATE_SUCCESS'
export const REMUNERATION_CREATE_ERROR = 'REMUNERATION_CREATE_ERROR'

export const REMUNERATION_UPDATE = 'REMUNERATION_UPDATE'
export const REMUNERATION_UPDATE_SUCCESS = 'REMUNERATION_UPDATE_SUCCESS'
export const REMUNERATION_UPDATE_ERROR = 'REMUNERATION_UPDATE_ERROR'

export const REMUNERATION_DELETE = 'REMUNERATION_DELETE'
export const REMUNERATION_DELETE_SUCCESS = 'REMUNERATION_DELETE_SUCCESS'
export const REMUNERATION_DELETE_ERROR = 'REMUNERATION_DELETE_ERROR'

export const TIMESHEET_DAILY_SUMMARY_LIST_LOAD = 'TIMESHEET_DAILY_SUMMARY_LIST_LOAD'
export const TIMESHEET_DAILY_SUMMARY_LIST_LOAD_SUCCESS = 'TIMESHEET_DAILY_SUMMARY_LIST_LOAD_SUCCESS'
export const TIMESHEET_DAILY_SUMMARY_LIST_LOAD_ERROR = 'TIMESHEET_DAILY_SUMMARY_LIST_LOAD_ERROR'

export const TIMESHEET_RECORD_LIST_LOAD = 'TIMESHEET_RECORD_LIST_LOAD'
export const TIMESHEET_RECORD_LIST_LOAD_SUCCESS = 'TIMESHEET_RECORD_LIST_LOAD_SUCCESS'
export const TIMESHEET_RECORD_LIST_LOAD_ERROR = 'TIMESHEET_RECORD_LIST_LOAD_ERROR'
export const TIMESHEET_RECORD_LIST_CLEAR = 'TIMESHEET_RECORD_LIST_CLEAR'

export const TIMESHEET_RECORD_CREATE = 'TIMESHEET_RECORD_CREATE'
export const TIMESHEET_RECORD_CREATE_SUCCESS = 'TIMESHEET_RECORD_CREATE_SUCCESS'
export const TIMESHEET_RECORD_CREATE_ERROR = 'TIMESHEET_RECORD_CREATE_ERROR'

export const TIMESHEET_RECORD_UPDATE = 'TIMESHEET_RECORD_UPDATE'
export const TIMESHEET_RECORD_UPDATE_SUCCESS = 'TIMESHEET_RECORD_UPDATE_SUCCESS'
export const TIMESHEET_RECORD_UPDATE_ERROR = 'TIMESHEET_RECORD_UPDATE_ERROR'

export const TIMESHEET_RECORD_DELETE = 'TIMESHEET_RECORD_DELETE'
export const TIMESHEET_RECORD_DELETE_SUCCESS = 'TIMESHEET_RECORD_DELETE_SUCCESS'
export const TIMESHEET_RECORD_DELETE_ERROR = 'TIMESHEET_RECORD_DELETE_ERROR'

export const TIMESHEET_RECORD_BULK_UPDATE = 'TIMESHEET_RECORD_BULK_UPDATE'
export const TIMESHEET_RECORD_BULK_UPDATE_SUCCESS = 'TIMESHEET_RECORD_BULK_UPDATE_SUCCESS'
export const TIMESHEET_RECORD_BULK_UPDATE_ERROR = 'TIMESHEET_RECORD_BULK_UPDATE_ERROR'

export const TIMESHEET_RECORD_CLEAR_ERRORS = 'TIMESHEET_RECORD_CLEAR_ERRORS'
