<!--
Custom title + search, filters and actions navigation for editor pages
-->

<template>
  <div class="sub-nav mt-1">
    <div class="sub-nav-row w-100">
      <b-navbar
        v-if="visibleFilters.length"
        class="w-100"
      >
        <b-navbar-nav left>
          <b-nav-item disabled>
            <slot name="sub-nav-heading">
              <h5 class="crop-overflow">
                {{ title }}
              </h5>
            </slot>
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav
          v-if="searchVisible && visibleActions.length === 0"
          class="ml-4 mr-auto sub-nav-navbar sub-nav-search"
        >
          <slot
            name="sub-nav-search"
          >
            <b-form-input
              v-if="searchVisible"
              v-model="searchKeyWords"
              placeholder="Search"
              type="text"
              @input="searchKeywordUpdated"
            />
          </slot>
        </b-navbar-nav>

        <b-navbar-nav
          class="sub-nav-navbar flex-wrap sub-nav-filters ml-auto mt-auto"
        >
          <slot
            name="sub-nav-filters"
          >
            <b-nav-item
              v-for="item in visibleFilters"
              :key="item.key"
              :disabled="item.disabled"
              :active="item.selected"
              @click="clickAction(item)"
            >
              <div
                :class="{ 'selected': item.selected }"
              >
                {{ item.label }}
              </div>
            </b-nav-item>
          </slot>
        </b-navbar-nav>
      </b-navbar>
    </div>

    <div
      v-if="visibleActions.length > 0"
      class="sub-nav-row w-100"
    >
      <b-navbar
        class="w-100"
      >
        <b-navbar-nav
          v-if="searchVisible && visibleActions.length > 0"
          class="sub-nav-navbar sub-nav-search"
        >
          <slot
            name="sub-nav-search"
          >
            <b-form-input
              v-if="searchVisible"
              v-model="searchKeyWords"
              placeholder="Search"
              type="text"
              @input="searchKeywordUpdated"
            />
          </slot>
        </b-navbar-nav>
        <b-navbar-nav
          class="ml-auto sub-nav-buttons sub-nav-actions"
        >
          <slot
            name="sub-nav-buttons"
          >
            <b-nav-item
              v-for="item in visibleActions"
              :key="item.key"
              :disabled="item.disabled"
              @click="clickAction(item)"
            >
              <b-button
                :class="{ 'selected': item.selected }"
              >
                {{ item.label }}
              </b-button>
            </b-nav-item>
          </slot>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SubNavBar',

  props: {
    title: {
      type: String,
      default: ''
    },
    navigationFilters: {
      type: Array,
      default: () => ([])
    },
    navigationActions: {
      type: Array,
      default: () => ([])
    },
    searchVisible: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      // Search value to send to parent
      searchKeyWords: ''
    }
  },

  computed: {
    visibleFilters () {
      /*
      Dynamically set list of filters: parent may hiden them on the fly
       */
      return this.navigationFilters.filter(item => item.hidden ? item.hidden !== true : true)
    },
    visibleActions () {
      /*
      Dynamically set list of actions: parent may hiden them on the fly
       */
      return this.navigationActions.filter(item => item.hidden ? item.hidden !== true : true)
    }
  },

  methods: {
    searchKeywordUpdated () {
      /*
      Send event when search field is updated
       */
      this.$emit('events', 'search', this.searchKeyWords)
    },

    clickAction (button) {
      /*
      Handle actions on clicked navigation items
       */
      if (button.callback) {
        // Call button specific callback function
        button.callback(button)
      } else if (button.route) {
        // Got to route specifed in button
        this.$router.push(button.route)
      } else if (button.key) {
        // Switch page
        this.$emit('events', 'switch-page', button.key)
      } else {
        // Emit button click event and let parent worry about it
        this.$emit('events', 'button-click', button)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/theme_constants';

.sub-nav {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-end;
  min-height: 3.5rem;
  margin-bottom: 0.5rem;

  .sub-nav-row {
    display: flex;
    flex-direction: row;
  }

  h5 {
    margin: 0;
  }
  nav, div {
    max-width: 100%;
    margin:0;
    padding: 0;
    div, ul, li {
      max-width: 100%;
    }
  }
  .sub-nav-title {
    .nav-link.disabled {
      color: $secondary-color;
    }
  }
  .sub-nav-navbar {
    margin-bottom: 0.25rem;
    .nav-link {
      border-radius: 10px;
    }
    .nav-link:hover {
      color: $primary-slide-5;
      background: $gray-4;
    }
    .router-link-exact-active {
      color: $secondary-slide-3;
      background: $gray-6;
    }
  }
  .sub-nav-buttons {
    margin-bottom: 0.25rem;
    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.selected {
  color: $secondary-color;
}
</style>
