<!--
Form to edit project
-->

<template>
  <div>
    <div
      v-if="canEdit"
    >
      <ErrorMessage
        v-if="record && !canEdit"
        title="Permission denied"
        error="You don't have permission to edit project"
        :show-close-button="false"
      />
      <ErrorMessage
        v-if="!record && !canEdit"
        title="Permission denied"
        error="You don't have permission to create new projects"
        :show-close-button="false"
      />
    </div>

    <b-form
      v-if="!loadingFormData && canEdit"
    >
      <b-row class="mt-2">
        <b-col cols="4">
          <field-label :field="fields.category" />
        </b-col>
        <b-col>
          <dropdown-field
            :parent="bus"
            :field="fields.category"
            :disabled="processing"
            :options="fields.type.options"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="typeVisible"
        class="mt-2"
      >
        <b-col cols="4">
          <field-label :field="fields.type" />
        </b-col>
        <b-col>
          <dropdown-field
            :parent="bus"
            :field="fields.type"
            :disabled="projectTypeSelectorDisabled"
            :options="fields.type.options"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="mustSetProjectCode"
        class="mt-2"
      >
        <b-col cols="4">
          <field-label :field="fields.project_code" />
        </b-col>
        <b-col>
          <number-field
            :parent="bus"
            :field="fields.project_code"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="4">
          <field-label :field="fields.name" />
        </b-col>
        <b-col>
          <text-field
            :parent="bus"
            :field="fields.name"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="4">
          <field-label :field="fields.description" />
        </b-col>
        <b-col>
          <text-field
            :parent="bus"
            :field="fields.description"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="4">
          <field-label :field="fields.date_range" />
        </b-col>
        <b-col cols="6">
          <date-range-field
            :parent="bus"
            :field="fields.date_range"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="optionsVisible"
        class="mt-2"
      >
        <b-col cols="4">
          <field-label :field="fields.options" />
        </b-col>
        <b-col>
          <checkbox-group-field
            :parent="bus"
            :field="fields.options"
            :disabled="processing"
            :switches="true"
            stacked="stacked"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <div
        v-if="isCustomerProject"
      >
        <b-row
          class="mb-2"
        >
          <b-col>
            <h3>Billing Details</h3>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="4">
            <field-label :field="fields.fixed_price" />
          </b-col>
          <b-col>
            <number-field
              :parent="bus"
              :field="fields.fixed_price"
              :disabled="processing"
              @update="updateField"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="4">
            <field-label :field="fields.minimum_billable_time" />
          </b-col>
          <b-col>
            <time-field
              :parent="bus"
              :field="fields.minimum_billable_time"
              :disabled="processing"
              @update="updateField"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="4">
            <field-label :field="fields.billing_resolution" />
          </b-col>
          <b-col>
            <time-field
              :parent="bus"
              :field="fields.billing_resolution"
              :disabled="processing"
              @update="updateField"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="4">
            <field-label :field="fields.record_qranularity" />
          </b-col>
          <b-col>
            <time-field
              :parent="bus"
              :field="fields.record_qranularity"
              :disabled="processing"
              @update="updateField"
            />
          </b-col>
        </b-row>
      </div>

      <FormButtons
        :processing="processing"
        :can-cancel="canCancel"
        :can-delete="canDelete"
        :can-save="canSave"
        :cancel-disabled="cancelDisabled"
        :delete-disabled="deleteDisabled"
        :save-disabled="saveDisabled"
        @events="updateField"
      />
    </b-form>
  </div>
</template>

<script>
import ErrorMessage from '@/components/common/ErrorMessage.vue'
import Form from '@/forms/Form.vue'
import FormButtons from '@/forms/FormButtons.vue'
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  name: 'ProjectEditForm',

  components: {
    ErrorMessage,
    FormButtons
  },

  mixins: [
    Form
  ],

  data: function () {
    return {
      actions: {
        create: 'createProject',
        update: 'updateProject',
        delete: 'deleteProject'
      },
      'formFields': {
        category: {
          label: 'Project Category',
          options: this.getProjectCategoryChoices,
          required: true
        },
        type: {
          label: 'Project type',
          options: this.projectTypeChoicesForCategory,
          required: true
        },
        project_code: {
          label: 'Project Code'
        },
        name: {
          label: 'Project Name',
          required: true
        },
        description: {
          label: 'Description'
        },
        date_range: {
          label: 'Project Validity',
          inputs: {
            range_start: {
              key: 'start_date',
              label: 'Start date',
              description: 'Project start date'
            },
            range_end: {
              key: 'end_date',
              label: 'End date',
              description: 'Project end date'
            }
          },
          required: {
            start_date: true
          }
        },
        options: {
          label: 'Options',
          inputs: {
            is_archived: {
              label: 'Archived',
              description: 'Is project archived'
            },
            is_locked: {
              label: 'Locked',
              description: 'Is project locked from editing'
            },
            is_billable: {
              label: 'Billable',
              description: 'Is project a billable customer project'
            },
            is_always_visible: {
              label: 'Always visible to internal employees',
              description: 'Is project always visible to all internal employees'
            },
            allow_member_task_management: {
              label: 'Allow all members to manage tasks',
              description: 'Is each project member allowed to manage project tasks'
            },
            require_task_form_records: {
              label: 'Require all timesheet records to specify task',
              description: 'Require all timesheet records to specify task'
            },
            group_billing_details_by_task: {
              label: 'Group items by task in billing report',
              description: 'Decides if reported items in billing report are grouped by task'
            }
          }
        },
        fixed_price: {
          label: 'Fixed price',
          description: 'Fixed price for customer project'
        },
        minimum_billable_time: {
          label: 'Minimum Billable Time',
          description: 'Minimum time in minutes to be billed from customer'
        },
        billing_resolution: {
          label: 'Billing resolution',
          description: 'Rounding of billed items in minutes'
        },
        record_qranularity: {
          label: 'Record Granularity',
          description: 'Granularity of billed items in minutes'
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'isAdminUser',
      'isInternal',
      'getProjectCategoryChoices',
      'getProjectTypeChoices',
      'loadingProjectCategoryChoices',
      'loadingProjectTypeChoices'
    ]),
    canEdit () {
      /*
      Checks if user can edit or create project
       */
      if (!isEmpty(this.record)) {
        // Check project edit permission
        return this.record.can_edit
      } else {
        // Check project create permission
        return this.isAdminUser || this.isInternal
      }
    },
    canDelete () {
      /*
      Checks if user can delete project
       */
      if (!isEmpty(this.record)) {
        return this.record.can_delete
      } else {
        return false
      }
    },
    canSave () {
      /*
      Checks if user can delete project
       */
      return this.hasData && !this.hasPendingFields && this.formReady && this.canEdit
    },
    mustSetProjectCode () {
      /*
      Checks if project code must be set or is selected automatically
       */
      return this.fields.category.values.category !== 'customer'
    },
    isCustomerProject () {
      /*
      Checks if selected project is for customers
       */
      return this.fields.category.values.category === 'customer'
    },
    isBillable () {
      /*
      Checks if selected record is billable
       */
      return this.fields.options.values.is_billable === true
    },
    projectTypeSelectorDisabled () {
      /*
      Disable project type selector when there is just one choice
       */
      return this.processing || (this.fields.type.options === undefined || this.fields.type.options.length === 1)
    },
    loadingOptions () {
      /*
      Delay loading until all dropdown options are available
       */
      return this.loadingProjectCategoryChoices || this.loadingProjectTypeChoices
    },
    typeVisible () {
      /*
      Boolean to check if project type selector is visible
      */
      return this.fields.category.values.category === 'customer'
    },
    optionsVisible () {
      /*
      Boolean to check if list of options is visible at all

      Only shown when project category is selected
       */
      return this.fields.category.values.category !== undefined
    }
  },

  watch: {
    'fields.category.values.category' () {
      this.updateProjectTypeChoices()
    }
  },

  methods: {

    updateProjectTypeChoices () {
      /*
      Update project type choices based on selected project category
       */

      // Update choices available in 'type' dropdown based on selected category
      if (this.fields.category.values.category !== undefined) {
        if (this.fields.category.values.category === 'customer') {
          this.fields.type.options = this.getProjectTypeChoices.filter(option => option.id !== 'internal')
        } else {
          this.fields.type.options = this.getProjectTypeChoices.filter(option => option.id === 'internal')
        }
      } else {
        this.fields.type.options = []
      }

      // Ensure the type field value is a valid choice from the dropdown current options
      if (!this.fields.type.values.type !== undefined) {
        let valid = false
        if (!isEmpty(this.fields.type.options)) {
          if (this.fields.type.options.find(option => option.id === this.fields.type.values.type)) {
            valid = true
          }
        }
        if (!valid) {
          if (this.fields.type.options.length > 0) {
            if (this.fields.type.options.length === 1) {
              // Single choice available, select that
              this.fields.type.values.type = this.fields.type.options[0].id
            } else {
              // Multiple choices, reset to undefined
              this.fields.type.values.type = null
            }
          } else {
            // No choices, reset field
            this.fields.type.values.type = null
          }
        }
      }

      // Set flags based on category
      this.setCategoryDependentFlags(this.fields.category.values.category)

      // Emit change to update actual dropdown
      this.bus.$emit('reload-options', 'type')
    },

    setCategoryDependentFlags (category) {
      if (category === 'customer') {
        this.fields.options.inputs.is_always_visible.visible = false
        this.fields.options.inputs.is_billable.visible = true
        this.fields.options.inputs.group_billing_details_by_task.visible = true
        this.fields.options.values.is_always_visible = false
        this.fields.options.values.is_billable = true
      } else {
        this.fields.options.inputs.is_always_visible.visible = true
        this.fields.options.inputs.is_billable.visible = false
        this.fields.options.inputs.group_billing_details_by_task.visible = false
        this.fields.options.values.is_billable = false
        this.fields.options.values.group_billing_details_by_task = false
      }
      // Emit change to update visible options
      this.bus.$emit('reload-options', 'options')
    },

    loadFormOptions () {
      /*
      Load options for form dropdown fields
       */
      this.$store.dispatch('loadProjectCategoryChoices')
        .then((data) => {
          this.updateOptions('category', this.getProjectCategoryChoices)
        })
      this.$store.dispatch('loadProjectTypeChoices')
        .then((data) => {
          this.updateProjectTypeChoices()
        })
    }
  }
}
</script>
