<!--
View to list project tasks for a project
-->

<template>
  <div
    v-if="!loading"
  >
    <b-modal
      v-model="editModalVisible"
      name="record-edit-modal"
      hide-header
      centered
      hide-footer
      no-close-on-backdrop
      @hide="editCanceled"
    >
      <ProjectTaskEditForm
        :parent="bus"
        :project="project"
        :record="record"
        @events="eventHandler"
      />
    </b-modal>

    <b-table
      :fields="['task']"
      :items="rows"
      responsive
      hover
      thead-class="d-none"
      @row-clicked="rowClickedCallback"
    >
      <template v-slot:cell(task)="row">
        <ProjectTask
          :parent="bus"
          :project="project"
          :record="row.item"
          @events="eventHandler"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep, isEmpty } from 'lodash'

import ProjectDataEditor from '@/components/project/ProjectDataEditor.vue'
import ProjectTask from '@/components/project/ProjectTask.vue'
import ProjectTaskEditForm from '@/components/project/ProjectTaskEditForm.vue'

export default {
  name: 'Remunerations',

  components: {
    ProjectTask,
    ProjectTaskEditForm
  },

  extends: ProjectDataEditor,

  computed: {
    ...mapGetters([
      'getProjectDetails',
      'getProjectTasks',
      'loadingProjectDetails',
      'loadingProjectTasks',
      'errorLoadingProjectDetails',
      'errorLoadingProjectTasks'
    ]),
    projectCode () {
      return this.project.project_code
    },
    loading () {
      return this.loadingProjectDetails || this.loadingProjectTasks
    },
    error () {
      return this.errorLoadingProjectDetails || this.errorLoadingProjectTasks
    },
    canEdit () {
      /*
      Return True if user can edit project, project allows all users task editing or user is admin
       */
      if (!isEmpty(this.project)) {
        if (this.project.can_edit || this.project.allow_member_task_management) {
          return true
        }
      }
      return this.isAdminUser
    },
    canDelete () {
      return this.isAdminUser || this.isHRUser || this.isFinanceUser
    },
    rows () {
      return cloneDeep(this.getProjectTasks)
    }
  },

  methods: {

    loadData () {
      /*
      Load leaves for specified employee
       */
      this.$store.dispatch('resetProjectTasks')
      this.$store.dispatch('loadProjectTasks', {
        project_code: this.projectCode
      })
    }

  }

}
</script>
