<!--
Short summary of employee work for listings
-->

<template>
  <b-container
    fluid
    class="summary px-0"
  >
    <div class="totals">
      <div class="info">
        <div class="employee">
          {{ employee.name }}
        </div>
        <div class="duration-details">
          <div class="duration">
            {{ duration(getDuration('total_hours')) }}
          </div>
          <div
            class="work-delta-hours"
            :class="durationDeltaClass( getDuration('total_hours'), getDuration('total_expected'))"
          >
            {{ totalDurationDeltaHours }}
          </div>
        </div>
      </div>

      <div class="summary">
        <div class="categories">
          <CategoryPercentage
            :period="period"
            :employee="employee"
            :category="getCategoryDetails('total_billable')"
          />
          <CategoryPercentage
            :period="period"
            :employee="employee"
            :category="getCategoryDetails('total_internal')"
          />
          <CategoryPercentage
            :period="period"
            :employee="employee"
            :category="getCategoryDetails('unavailable')"
          />
        </div>
        <div
          v-if="missingDates.length > 0"
          class="missing-date-details"
        >
          <div class="warning">
            Missing Records
          </div>
          <div
            class="missing-dates"
          >
            <div
              v-for="date in missingDates"
              :key="date"
              :class="missingDateClass"
              @click.stop="clickMissingDate(date)"
            >
              {{ date }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { durationDelta, durationDeltaInMinutes } from '@/components/utils/timeUtils'

import BaseView from '@/components/elements/BaseView.vue'
import CategoryPercentage from '@/components/report/CategoryPercentage.vue'

export default {
  name: 'PeriodWorkSummary',

  components: {
    CategoryPercentage
  },

  extends: BaseView,

  props: {

    employee: {
      type: Object,
      required: true
    },
    period: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    summary: {
      type: Array,
      required: true
    },
    missingDates: {
      type: Array,
      required: true
    }
  },

  computed: {
    parentPeriod () {
      /*
      Parent level period
       */
      switch (this.period) {
        case 'week': {
          return 'month'
        }
        case 'month': {
          return 'year'
        }
        default: {
          return undefined
        }
      }
    },
    childPeriod () {
      /*
      Child level period
       */
      switch (this.period) {
        case 'year': {
          return 'month'
        }
        case 'month': {
          return 'week'
        }
        default: {
          return undefined
        }
      }
    },
    summaryCategories () {
      return this.summary.map(item => item.category)
    },

    totalDurationDeltaHours () {
      /*
      Return total hours duration delta as string with +- sign
       */
      return durationDelta(this.getDuration('total_hours'), this.getDuration('total_expected'))
    },

    totalDeltaWarningHours () {
      /*
      Get warning level for queried period
       */
      switch (this.period) {
        case 'year': {
          return 20
        }
        case 'month': {
          return 10
        }
        case 'week': {
          return 2
        }
        default: {
          return undefined
        }
      }
    },
    totalDeltaCriticalHours () {
      /*
      Get warning level for queried period
       */
      switch (this.period) {
        case 'year': {
          return 40
        }
        case 'month': {
          return 20
        }
        case 'week': {
          return 4
        }
        default: {
          return undefined
        }
      }
    },
    missingDateClass () {
      /*
      Class for missing date, depending on logged in employee
       */
      if (this.loggedInEmployeeEmail === this.employee.email) {
        return { 'clickable-date': true }
      } else {
        return {}
      }
    }
  },

  mounted () {
    this.parent.$on('events', this.eventHandler)
  },

  methods: {

    eventHandler (action, ...args) {
      /*
      Event handler for events from parents and children
       */
      switch (action) {
        case 'row-clicked': {
          break
        }
      }
    },

    clickMissingDate (date) {
      /*
      Click missing date.

      Does nothing if not clicking own details, for your own hours brings you to
      editor for correct date.
       */
      if (this.loggedInEmployeeEmail === this.employee.email) {
        this.$router.push({
          name: 'employee-hours',
          query: {
            period: 'week',
            date: date
          }
        })
      }
    },

    durationDeltaClass (a, b) {
      /*
      Return css class for delta between two values as a duration string
       */
      const deltaHours = Math.abs(durationDeltaInMinutes(a, b)) / 60
      let critical = false
      let warning = false
      if (deltaHours > this.totalDeltaCriticalHours) {
        critical = true
      } else if (deltaHours > this.totalDeltaWarningHours) {
        warning = true
      }
      return {
        critical: critical,
        warning: warning
      }
    },

    getDuration (category) {
      /*
      Get duration for category
       */
      const item = this.summary.find(item => item.category === category)
      if (item !== undefined) {
        return item.duration
      } else {
        return { hours: 0, minutes: 0 }
      }
    },

    getCategoryDetails (category) {
      /*
      Get category details from summary
       */
      return this.summary.find(item => item.category === category)
    }

  }

}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/bootstrap_overrides.scss';
@import '../../assets/scss/theme_constants.scss';

div.totals {
  display: flex;
  flex-direction: row;
  flex-wrap: 1;

  div.info {
    padding-right: 1rem;
    width: 11rem;
    div.duration-details {
      display: flex;
      flex-direction: row;
      div {
        display: flex;
        flex-direction: row;
      }
      div.duration {
        justify-content: flex-start;
        width: 8rem;
      }
      div.work-delta-hours {
        display: flex;
        flex-direction: row;
        width: 4rem;
        justify-content: flex-end;
        &.critical {
          margin: 0;
          font-weight: bold;
          color: $brand-danger;
        }
        &.warning {
          margin: 0;
          font-weight: bold;
          color: $brand-warning;
        }
      }
    }
  }
  div.summary {
    display: flex;
    flex-direction: column;
    flex-wrap: 1;
    div.categories {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    div.missing-date-details {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-left: 0.5rem;
      padding-left: 0.25rem;
      background: #eee;
      div.warning {
        font-weight: bold;
        color: red;
      }
      div.missing-dates {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        div {
          padding-right: 0.5rem;
        }
        .clickable-date {
          cursor: pointer;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

</style>
