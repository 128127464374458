<!--
View to show project task details

TODO actually use this. Idea is to get rid of ugly rendering from
ProjectTasks.<template> with b-model, instead showing each member
and it's editor inline nicely.
-->

<template>
  <div>
    <b-row>
      <b-col>
        {{ record.label }}
      </b-col>
      <b-col>
        {{ record.description }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DataView from '@/forms/DataView.vue'
export default {
  name: 'ProjectTask',

  mixins: [
    DataView
  ],

  props: {
    project: {
      type: Object,
      required: true
    }
  }

}
</script>
