<!--
Form to edit remuneration for employee
-->

<template>
  <div>
    <b-form v-if="!loadingFormData">
      <b-row>
        <b-col>
          <dropdown-field
            :parent="bus"
            :field="fields.type"
            :options="fields.type.options"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <date-field
            :parent="bus"
            :field="fields.date"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <text-area-field
            :parent="bus"
            :field="fields.reason"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <duration-field
            :parent="bus"
            :field="fields.duration"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <form-buttons
        :processing="processing"
        :can-cancel="canCancel"
        :can-delete="canDelete"
        :can-save="canSave"
        :cancel-disabled="cancelDisabled"
        :delete-disabled="deleteDisabled"
        :save-disabled="saveDisabled"
        @events="updateField"
      />
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Form from '@/forms/Form.vue'
import FormButtons from '@/forms/FormButtons.vue'

export default {
  name: 'RemunerationEditForm',

  components: {
    FormButtons
  },

  mixins: [
    Form
  ],

  props: {
    employee: {
      type: Object,
      default: undefined
    }
  },

  data: function () {
    return {
      // Store actions to dispatch
      actions: {
        create: 'createRemuneration',
        update: 'updateRemuneration',
        delete: 'deleteRemuneration'
      },
      // All form fields
      'formFields': {
        id: {
          label: 'Record ID'
        },
        // Set to employee updating record
        created_by: {
          label: 'Created By'
        },
        employee: {
          label: 'Employee'
        },
        date: {
          label: 'Date',
          required: true,
          inputs: {
            date: {
              label: 'Date'
            }
          }
        },
        duration: {
          label: 'Duration',
          required: true,
          inputs: {
            duration: {
              label: 'Hours and minutes to be remunerated'
            }
          }
        },
        type: {
          label: 'type',
          required: true,
          options: [],
          inputs: {
            type: {
              label: 'Type of remuneration'
            }
          }
        },
        reason: {
          label: 'Reason',
          inputs: {
            reason: {
              label: 'Reason for remuneration'
            }
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'isAdminUser',
      'isHRUser',
      'isFinanceUser',
      'loggedInEmployeeEmail',
      'getEmployeesChoices',
      'getRemunerationTypeChoices',
      'loadingEmployeesChoices',
      'loadingRemunerationTypeChoices',
      'errorLoadingEmployeesChoices',
      'errorLoadingRemunerationTypeChoices'
    ]),
    canDelete () {
      return this.isAdminUser || this.isHRUser || this.isFinanceUser
    },
    loadingOptions () {
      return this.loadingEmployeesChoices || this.loadingRemunerationTypeChoices
    }
  },

  methods: {

    finishLoading () {
      this.setValue('created_by', 'created_by', this.loggedInEmployeeEmail)
      this.setValue('employee', 'employee', this.employee.email)
    },

    loadFormOptions () {
      /*
      Load any options for dropdown fields
       */
      this.$store.dispatch('loadRemunerationTypeChoices')
        .then((data) => {
          this.updateOptions('type', this.getRemunerationTypeChoices)
        })
      this.$store.dispatch('loadEmployeesChoices', {
        has_remuneration_permissions: true
      })
        .then((data) => {
          this.updateOptions('created_by', this.getEmployeesChoices)
        })
    }
  }

}
</script>
