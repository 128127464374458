<!--
Form to edit project tasks
-->

<template>
  <div>
    <b-form v-if="!loadingFormData">
      <b-row class="mb-2">
        <b-col cols="10">
          <text-field
            :parent="bus"
            :field="fields.label"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col cols="10">
          <text-field
            :parent="bus"
            :field="fields.name"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col cols="10">
          <text-field
            :parent="bus"
            :field="fields.description"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <FormButtons
        :processing="processing"
        :can-cancel="canCancel"
        :can-delete="canDelete"
        :can-save="canSave"
        :cancel-disabled="cancelDisabled"
        :delete-disabled="deleteDisabled"
        :save-disabled="saveDisabled"
        @events="updateField"
      />
    </b-form>
  </div>
</template>

<script>

import Form from '@/forms/Form.vue'
import FormButtons from '@/forms/FormButtons.vue'

export default {
  name: 'ProjectTaskEditForm',

  components: {
    FormButtons
  },

  extends: Form,

  props: {
    project: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      // Store actions to dispatch
      actions: {
        create: 'createProjectTask',
        update: 'updateProjectTask',
        delete: 'deleteProjectTask'
      },
      // All form fields
      'formFields': {
        id: {
          label: 'Record ID'
        },
        project: {
          label: 'Project Code'
        },
        label: {
          label: 'Task Label',
          required: true,
          inputs: {
            label: {
              label: 'Label',
              description: 'API visible label tag for item'
            }
          }
        },
        name: {
          label: 'Task Name',
          required: true,
          inputs: {
            name: {
              label: 'Name',
              description: 'Name for task'
            }
          }
        },
        description: {
          label: 'Task Description',
          inputs: {
            description: {
              label: 'Description',
              description: 'Description of task'
            }
          }
        }
      }
    }
  },

  computed: {
    loadingOptions () {
      /*
      Check additional form options getters and load form when ready
       */
      return false
    }
  },

  methods: {
    finishLoading () {
      /*
      Load any options for dropdown fields
       */
      this.fields.project.values.project = this.project.project_code
    }
  }

}
</script>
