/*
Various validators for field values

Validators return key with error and error message if validation
failed or undefined if validation is ok
*/

import { isEmpty } from 'lodash'
import dayjs from 'dayjs'

export const validateStringField = (field, key, value, maxLength) => {
  /*
  Validator for string fields requiring value to be entered.

  Empty string after trimming is not considered valid
   */

  if (value !== undefined) {
    if (typeof value !== 'string' && !(value instanceof String)) {
      return {
        key: key,
        error: 'Value is not a valid string value'
      }
    }
    value = value.trim()
    // OK it's a string, let's make sure it's not empty
    if (value === '' && field.required[key]) {
      return {
        key: key,
        error: 'Value must not be empty'
      }
    }
    if (maxLength !== undefined) {
      // Check string is not too long
      if (value.length > maxLength) {
        return {
          key: key,
          error: `Value must not be longer than ${maxLength} characters`
        }
      }
    }
  }
  return {
    key: key,
    error: undefined
  }
}

export const validateIntegerField = (field, key, value) => {
  /*
  Validate integer value

  Field may define minimum and maximum values (inclusive)
   */
  let error
  let minimum = field.minimum !== undefined ? parseInt(field.minimum) : undefined
  let maximum = field.maximum !== undefined ? parseInt(field.maximum) : undefined
  if (value !== undefined && value !== '') {
    if (value.match('[-0-9]*')[0] === value) {
      value = parseInt(value)
      if (Number.isInteger(value)) {
        // Validate integer ranges
        if (minimum !== undefined && value < minimum) {
          error = `Too small value. Minimum value is ${minimum}`
        }
        if (maximum !== undefined && value > maximum) {
          error = `Too large value. Maximum value is ${maximum}`
        }
      }
    } else {
      error = 'Invalid value'
    }
  }
  return {
    key: key,
    error: error
  }
}

export const validatePercentageField = (field, key, value) => {
  /*
  Validate percentage value. Value MAY be float

  Field may define minimum and maximum. Otherwise range 0 - 100 is used
   */
  let minimum = field.minimum !== undefined ? field.minimum : 0
  let maximum = field.maximum !== undefined ? field.maximum : 100

  if (value !== undefined && value !== '') {
    if (value.match('[-0-9.]*')[0] === value) {
      value = parseFloat(value)
      if (Number.isNaN(value)) {
        return {
          key: key,
          error: 'Invalid value'
        }
      }

      if (value < minimum) {
        return {
          key: key,
          error: `Smallest allowed value is ${minimum}`
        }
      }
      if (value > maximum) {
        return {
          key: key,
          error: `Largest allowed value is ${maximum}`
        }
      }
    } else {
      return {
        key: key,
        error: 'Invalid value'
      }
    }
  }
  return {
    key: key,
    error: undefined
  }
}

export const validateDurationField = (field, key, value) => {
  /*
  Validate duration field values
   */
  let error
  if (!isEmpty(value)) {
    switch (key) {
      case 'hours': {
        let match = value.match('[0-9]*')
        if (match && match[0] === value) {
          value = parseInt(value)
          if (value < 0) {
            error = 'Hours must be a positive number'
          }
        } else {
          error = 'Invalid hours value'
        }
        break
      }
      case 'minutes': {
        let match = value.match('[0-9]*')
        if (match && match[0] === value) {
          value = parseInt(value)
          if (value < 0 || value > 59) {
            error = 'Minutes must be in range 0-59'
          }
        } else {
          error = 'Invalid minutes value'
        }
        break
      }
    }
  }
  return {
    key: key,
    error: error
  }
}

export const validateDateField = (field, key, value) => {
  /*
  Validate date string value

  Field may define minimum and maximum dates (inclusive)
   */
  let error
  if (!isEmpty(value) && !dayjs(value, 'YYYY-MM-DD', true).isValid()) {
    error = 'Invalid date'
  }
  return {
    key: key,
    error: error
  }
}

export const validateDateRangeField = (field, key, value) => {
  /*
  Validate date range field
   */
  key = 'range_error'
  let minimum = field.minimum !== undefined ? dayjs(field.minimum, 'YYYY-MM-DD', true) : undefined
  let maximum = field.maximum !== undefined ? dayjs(field.maximum, 'YYYY-MM-DD', true) : undefined
  let start = field.values[field.inputs.range_start.key] !== undefined
    ? dayjs(field.values[field.inputs.range_start.key], 'YYYY-MM-DD', true)
    : undefined
  let end = field.values[field.inputs.range_end.key] !== undefined
    ? dayjs(field.values[field.inputs.range_end.key], 'YYYY-MM-DD', true)
    : undefined

  if (start !== undefined && !field.inputs.range_start.pending) {
    if (minimum !== undefined && start < minimum) {
      return {
        key: key,
        error: `Start date is too early: first allowed date is ${field.minimum}`
      }
    }
    if (maximum !== undefined && start > maximum) {
      return {
        key: key,
        error: `Start date is too late: latest allowed date is ${field.maximum}`
      }
    }
  }
  if (end !== undefined && !field.inputs.range_start.pending) {
    if (minimum !== undefined && end < minimum) {
      return {
        key: key,
        error: `End date is too early: first allowed date is ${field.minimum}`
      }
    }
    if (maximum !== undefined && end > maximum) {
      return {
        key: key,
        error: `End date is too late: latest allowed date is ${field.maximum}`
      }
    }
  }
  if (!field.inputs.range_start.pending && !field.inputs.range_end.pending) {
    if (start !== undefined && end !== undefined) {
      if (start > end) {
        return {
          key: key,
          error: 'Start date must not be after end date'
        }
      }
    }
  }
  return {
    key: key,
    error: undefined
  }
}

export const validateTimeWithSecondsField = (field, key, value, timeFormat) => {
  /*
  Validate format of time field with seconds
   */
  return validateTimeField(field, key, value, 'H:mm:ss')
}

export const validateTimeField = (field, key, value, timeFormat) => {
  /*
  Validate format of time field value

  By default expects HH:MM value

  Optionally validates against minimum and maximum if specified for field
  */
  let error
  let parsedFormat
  if (timeFormat === undefined) {
    timeFormat = 'H:mm'
  }

  if (value !== undefined && value !== '') {
    let match = value.match('[-0-9]*:')
    if (match !== null && match[0] === value) {
      // Remove trailing to validate hours part
      value = parseInt(value)
    }

    parsedFormat = ['H', 'H:mm', 'H:mm:ss']
    // console.log('parse date value', value, parsedFormat)
    value = dayjs(value, parsedFormat, true)

    if (!value.isValid()) {
      error = 'Invalid time.'
    }
    // Value is valid, check ranges
    if (value.isValid()) {
      let minimum = field.minimum !== undefined ? dayjs(field.minimum, timeFormat, true) : undefined
      let maximum = field.maximum !== undefined ? dayjs(field.maximum, timeFormat, true) : undefined
      if (minimum !== undefined) {
        if (!minimum.isValid()) {
          alert(`Invalid ${field.name} minimum ${key} value ${field.minimum}`)
        }
        if (value < minimum) {
          error = `Too small time value. Minimum value is ${field.minimum}`
        }
      }
      if (maximum !== undefined) {
        if (!maximum.isValid()) {
          alert(`Invalid ${field.name} maximum ${key} value ${field.maximum}`)
        }
        if (value < minimum) {
          error = `Too large time value. Maximum value is ${field.maximum}`
        }
      }
    }
  } else {
    if (field.required[key] === true) {
      // Only raise empty error with empty string entry, not undefined
      if (value === '') {
        error = 'Value must not be empty'
      }
    }
  }
  return {
    key: key,
    error: error
  }
}

export const validateTimeRangeWithSecondsField = (field, key, value) => {
  return validateTimeRangeField(field, key, value, 'H:mm:ss')
}

export const validateTimeRangeField = (field, key, value, timeFormat) => {
  /*
  Validate date range field
   */
  key = 'range_error'
  if (timeFormat === undefined) {
    timeFormat = 'H:mm'
  }
  let minimum = field.minimum !== undefined ? dayjs(field.minimum, '', true) : undefined
  let maximum = field.maximum !== undefined ? dayjs(field.maximum, timeFormat, true) : undefined
  let start = field.values[field.inputs.range_start.key] !== undefined
    ? dayjs(field.values[field.inputs.range_start.key], timeFormat, true)
    : undefined
  let end = field.values[field.inputs.range_end.key] !== undefined
    ? dayjs(field.values[field.inputs.range_end.key], timeFormat, true)
    : undefined
  if (start !== undefined) {
    if (minimum !== undefined && start < minimum) {
      return {
        key: key,
        error: `Start time is too early: first allowed value is ${field.minimum}`
      }
    }
    if (maximum !== undefined && start > maximum) {
      return {
        key: key,
        error: `Start time is too late: latest allowed value is ${field.maximum}`
      }
    }
  }
  if (end !== undefined) {
    if (minimum !== undefined && end < minimum) {
      return {
        key: key,
        error: `End time is too early: first allowed value is ${field.minimum}`
      }
    }
    if (maximum !== undefined && end > maximum) {
      return {
        key: key,
        error: `End time is too late: latest allowed value is ${field.maximum}`
      }
    }
  }
  if (start !== undefined && end !== undefined) {
    if (start >= end) {
      return {
        key: key,
        error: 'Start time must be before end time'
      }
    }
  }
  return {
    key: key,
    error: undefined
  }
}
