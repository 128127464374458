<!--
View to show employee unpaid hours
-->

<template>
  <div
    v-if="!loading"
  >
    <b-modal
      v-model="editModalVisible"
      name="record-edit-modal"
      hide-header
      centered
      hide-footer
      no-close-on-backdrop
      @hide="editCanceled"
    >
      <ContractPeriodEditForm
        v-if="editModalVisible"
        :parent="bus"
        :employee="employee"
        :record="record"
        @events="formEventHandler"
      />
    </b-modal>

    <b-table
      :fields="fields"
      :items="rows"
      responsive
      hover
      show-empty
      thead-class="d-none"
      @row-clicked="rowClickedCallback"
    >
      <template
        v-for="(_, slot) of $scopedSlots"
        v-slot:[slot]="scope"
      >
        <slot
          scope="row"
          :name="slot"
          v-bind="scope"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

import { getTotalDuration, formatDurationString } from '@/components/utils/timeUtils'

import EmployeeDataEditor from '@/components/employee/EmployeeDataEditor.vue'

export default {
  name: 'UnpaidHours',

  extends: EmployeeDataEditor,

  data: function () {
    return {
      fields: [
        {
          key: 'date',
          label: 'Date'
        },
        {
          key: 'duration',
          label: 'Duration'
        },
        {
          key: 'project_code',
          label: 'Project code'
        },
        {
          key: 'name',
          label: 'Project'
        }
      ],
      records: {}
    }
  },

  computed: {
    ...mapGetters([
      'getEmployeeDetails',
      'getTimesheetRecords',
      'loadingEmployeeDetails',
      'loadingTimesheetRecords',
      'errorLoadingEmployeeDetails',
      'errorLoadingTimesheetRecords'
    ]),
    loading () {
      return this.loadingEmployeeDetails || this.loadingTimesheetRecords
    },
    error () {
      return this.errorLoadingEmployeeDetails || this.errorLoadingTimesheetRecords
    },
    rows () {
      /*
      Rows to display
      */
      return cloneDeep(this.getTimesheetRecords)
    },
    canLock () {
      /*
      Boolean to check if there is anything to lock

      TODO - implement properly
      */
      return true
    },
    totalDuration () {
      /*
      Total duration of timesheet records
      */
      return formatDurationString(
        getTotalDuration(this.getTimesheetRecords.map(record => record.duration)),
        false,
        true
      )
    }
  },

  created () {
    /*
    Listen on notifications from parent if parent is specified
     */
    if (this.parent) {
      this.parent.$on('notification', this.notificationHandler)
    }
  },

  methods: {

    loadData () {
      /*
      Load employee data and employee's timesheet records
       */
      this.$store.dispatch('resetTimesheetRecords')
      this.$store.dispatch('loadTimesheetRecords', {
        is_paid: false,
        email: this.employee.email
      })
    },

    notificationHandler (notification, ...args) {
      /*
      Handle events from parent component toolbar
       */
      switch (notification) {
        case 'lock-unpaid-hours': {
          this.lockUnpaidHours()
          break
        }
      }
    },

    lockUnpaidHours () {
      /*
      Bulk API update to lock visible unpaid timesheet record items
       */
      let ids = this.getTimesheetRecords.map(record => record.id)
      this.$store.dispatch('timesheetRecordBulkUpdate', {
        is_paid: true,
        ids: ids
      })
        .then((data) => {
          this.loadData()
        })
        .catch(err => {
          alert('error locking records', err)
          Promise.reject(err)
        })
    }
  }
}
</script>
