<!--
Component to list employee hours grouped by week
-->

<template>
  <div class="outer-container">
    <b-row class="top-row mt-2 mb-2 mr-1 ml-1">
      <b-col v-if="startDate && endDate">
        <h4>{{ name }} {{ formattedStartDate }} - {{ formattedEndDate }}</h4>
      </b-col>
      <b-col v-else>
        <h4>{{ name }}</h4>
      </b-col>
      <b-col class="text-right">
        Total: {{ totalDuration }}
      </b-col>
    </b-row>

    <div
      v-for="week in weeklyRecords"
      :key="week.startDate"
      class="card"
    >
      <div class="card-header d-flex flex-row">
        <b-col>
          <h4>Week {{ week.weekNumber }} {{ week.startDate }} - {{ week.endDate }}</h4>
        </b-col>
        <b-col class="text-right">
          Total {{ week.totalDuration }}
        </b-col>
      </div>

      <div class="card-body">
        <b-row
          v-for="record in week.records"
          :key="record.id"
          class="d-flex"
        >
          <b-col class="date">
            {{ record.date }}
          </b-col>
          <b-col class="duration">
            {{ record.duration | durationValue }}
          </b-col>
          <b-col class="project-code">
            {{ record.project_code }}
          </b-col>
          <b-col class="project-name">
            {{ record.name }}
            <div
              v-if="record.task"
              class="project-task"
            >
              {{ record.task.name }}
            </div>
          </b-col>
          <b-col>
            <div
              v-for="comment in record.comments"
              :key="comment"
            >
              {{ comment }}
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { format, parse, startOfWeek, endOfWeek, getISOWeek } from 'date-fns'
import { getTotalDuration, formatDurationString } from '../utils/timeUtils'

export default {
  name: 'WeeklyRecords',

  props: {
    name: {
      type: String,
      required: true
    },
    startDate: {
      type: Date,
      default: undefined
    },
    endDate: {
      type: Date,
      default: undefined
    },
    records: {
      type: Array,
      required: true
    }
  },

  computed: {
    formattedStartDate () {
      if (this.startDate !== undefined) {
        return format(this.startDate, this.$dateFormat)
      } else {
        return undefined
      }
    },
    formattedEndDate () {
      if (this.endDate !== undefined) {
        return format(this.endDate, this.$dateFormat)
      } else {
        return undefined
      }
    },
    totalDuration () {
      /*
      Return total duration of all shown records
      */
      return formatDurationString(
        getTotalDuration(this.records.map(record => record.duration))
      )
    },
    weeklyRecords () {
      /*
      Return records grouped by week, with week start and end dates and week number

      Also calculate total hours reported during this week
       */

      let weeks = {}
      // Group records by week
      this.records.forEach(record => {
        let weekStart = startOfWeek(parse(record.date), { weekStartsOn: this.$weekStartsOn })
        if (!weeks[weekStart]) {
          weeks[weekStart] = {
            start: weekStart,
            startDate: format(weekStart, this.$dateFormat),
            endDate: format(endOfWeek(weekStart, { weekStartsOn: this.$weekStartsOn }), this.$dateFormat),
            weekNumber: getISOWeek(weekStart),
            records: []
          }
        }
        weeks[weekStart].records.push(record)
      })

      // Sort weeks by week start date
      weeks = weeks.sort((a, b) => (a.start > b.start) ? 1 : -1)

      // Calculate total number of hours reported during week
      weeks.forEach(week => {
        week.totalDuration = formatDurationString(
          getTotalDuration(week.records.map(record => record.duration))
        )
      })

      return weeks
    }
  },

  methods: {

  }
}
</script>
