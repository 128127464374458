<!--
Show form field legend heading
-->

<template>
  <div>
    <legend
      v-if="label"
      :id="id"
      class="col-form-label"
    >
      {{ label }}
    </legend>
  </div>
</template>

<script>
export default {
  props: {
    tooltipVariant: {
      type: String,
      default: 'danger'
    },
    tooltipPlacement: {
      type: String,
      default: 'top'
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: undefined
    },
    description: {
      type: String,
      default: undefined
    }
  }
}
</script>
