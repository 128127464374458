/*
Employew work days state
 */

import { parse } from 'date-fns'

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadEmployeeWorkDays: ({ commit }, params = {}) => {
    /*
    Load list of employee work days
    */
    return helpers.loadListing(
      commit,
      'timesheet/employee/work-days',
      params,
      types.EMPLOYEE_WORK_DAYS_LOAD,
      types.EMPLOYEE_WORK_DAYS_LOAD_SUCCESS,
      types.EMPLOYEE_WORK_DAYS_LOAD_ERROR
    )
  }
}

const mutations = {
  [types.EMPLOYEE_WORK_DAYS_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.EMPLOYEE_WORK_DAYS_LOAD_SUCCESS] (state, data) {
    data = data.map(function (item) {
      item.date = parse(item.date).valueOf()
      return item
    })
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.EMPLOYEE_WORK_DAYS_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  }
}

const getters = {
  getEmployeeWorkDays: (state) => {
    /*
    Get employee work days list
    */
    return state.items
  },
  getWorkDayForDate: (state) => (date) => {
    /*
    Find work day for specified date
    */
    return state.items.find(function (item) {
      return item.date === date
    })
  },
  loadingEmployeeWorkDays: () => {
    /*
    Check if employee work days are loading
    */
    return state.loading
  },
  errorLoadingEmployeeWorkDays: () => {
    /*
    Check if loading employee work days caused errors
    */
    return state.errors.loading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
