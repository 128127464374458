<!--
Common base component for views filtered by query  parameters
-->

<template>
  <div />
</template>

<script>
import Vue from 'vue'
import { isEqual } from 'lodash'

import BaseComponent from '@/components/base/BaseComponent.vue'

export default {
  name: 'FilteredList',

  extends: BaseComponent,

  data () {
    return {
      bus: new Vue(),
      // Query string filters
      queryFilters: {},
      // Filter groups to define navigation filters dynamically
      filterGroups: {}
    }
  },

  computed: {

    title () {
      /*
      Dynamically set title based on filter groups
       */
      let title = ''
      Object.values(this.filterGroups).forEach(group => {
        if (isEqual(this.$route.query, group.query)) {
          title = group.title
        }
      })
      return title
    },

    navigationFilters () {
      /*
      Dynamically build navigation filters groups from folter groups
       */
      let filters = []
      Object.keys(this.filterGroups).forEach(key => {
        filters.push(
          {
            key: key,
            label: this.filterGroups[key].label,
            selected: isEqual(this.$route.query, this.filterGroups[key].query),
            route: {
              name: this.$route.name,
              query: this.filterGroups[key].query
            }
          }
        )
      })
      return filters
    },

    navigationActions () {
      return []
    },
    activeFilters () {
      /*
      Active filters used in query filtering
      */
      return Object.keys(this.queryFilters)
        .filter(key => this.queryFilters[key] !== undefined)
        .reduce((obj, key) => {
          obj[key] = this.queryFilters[key]
          return obj
        }, {})
    }
  },

  watch: {
    '$route' () {
      /*
      Load records after changes in active filters
       */
      this.parseQueryParams()
      this.loadRecords()
    }
  },

  mounted () {
    this.parseQueryParams()
  },

  methods: {
    loadRecords () {

    },

    parseQueryParams () {
      /*
      Parse initial query parameters
      */
      Object.keys(this.queryFilters).forEach(arg => {
        let value = this.$route.query[arg]
        this.queryFilters[arg] = value !== undefined ? value : undefined
      })
    },

    setFilter (field, value) {
      /*
      Set filter field value
      */
      this.queryFilters[field] = value
    }

  }
}
</script>
