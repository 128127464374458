<!--
Summary of daily hours reported for each employee
-->

<template>
  <BrowsableListingView
    :parent="bus"
    :title="title"
    :navigation-filters="navigationFilters"
    :navigation-actions="navigationActions"
    :loading="loadingTimesheetRecords"
    :error="errorLoadingTimesheetRecords"
    :rows="timesheetRecordsByEmployee"
    :fields="fields"
  >
    <template slot="sub-nav-heading">
      <h5 class="crop-overflow">
        Daily Reported Hours {{ periodStartDay() | date }} - {{ periodEndDay() | date }}
      </h5>
    </template>

    <template
      slot="content-heading"
    >
      <div
        v-if="limitedVisibility"
        class="notice ml-2 mb-2"
      >
        Note: you are not authorized to see full details for every employee.
      </div>
    </template>

    <template
      v-slot:cell(info)="row"
    >
      <div>{{ row.item.name }}</div>
      <div>{{ totalDurationString(row.item.records) }}</div>
    </template>

    <template
      v-slot:cell(daily_hours)="row"
    >
      <DailyHourSummary
        :dates="periodDates"
        :holidays="getHolidays"
        :records="row.item.records"
      />
    </template>

    <template v-slot:row-details="row">
      <WeeklyRecords
        :name="row.item.name"
        :start-date="periodStartDay()"
        :end-date="periodEndDay()"
        :records="row.item.records"
      />
    </template>
  </BrowsableListingView>
</template>

<script>
import BrowsableListingView from '../../components/elements/BrowsableListingView.vue'
import TimeRangeBrowser from '../../components/base/TimeRangeBrowser.vue'
import DailyHourSummary from '../../components/report/DailyHourSummary.vue'
import WeeklyRecords from '../../components/report/WeeklyRecords.vue'
import { mapGetters } from 'vuex'
import { getTotalDuration, formatDurationString } from '../../components/utils/timeUtils'

export default {
  name: 'EmployeeDailyHours',

  components: {
    BrowsableListingView,
    DailyHourSummary,
    WeeklyRecords
  },

  extends: TimeRangeBrowser,

  data () {
    return {
      defaultPeriod: 'month',
      rows: [],
      fields: [
        'info',
        'daily_hours'
      ]
    }
  },

  computed: {
    ...mapGetters([
      'getHolidays',
      'isAdminUser',
      'isHRUser',
      'isFinanceUser',
      'timesheetRecordsByEmployee',
      'loadingTimesheetRecords',
      'errorLoadingTimesheetRecords'
    ]),
    navigationFilters () {
      return [
        {
          key: 'previous',
          label: 'Previous',
          callback: this.showPreviousPeriod
        },
        {
          key: 'next',
          label: 'Next',
          callback: this.showNextPeriod
        }
      ]
    },
    title () {
      /*
      Dynamic title for page with viewing period
      */
      return `Daily Reported Hours`
    },
    limitedVisibility () {
      /*
      Check if user has limited visibility to data
       */
      return (!this.isAdminUser && !this.isHRUser && !this.isFinanceUser)
    }
  },

  methods: {
    loadRecords () {
      let params = this.getDateQueryArgs()
      params.page_size = 3000
      this.rows = []
      this.$store.dispatch('resetTimesheetRecords')
      this.$store.dispatch('loadTimesheetRecords', params)
    },
    totalDurationString (records) {
      /*
      Return total duration as string from records
      */
      return formatDurationString(
        getTotalDuration(records.map(record => record.duration))
      )
    }
  }

}
</script>

<style lang="scss" scoped>
div.notice {
  font-style: italic;
}
</style>
