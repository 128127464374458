<!--
Field to input duration in hours and minutes to split fields

This is different from time field because this field targets cases where you
want to input things like '84 hours, 30 minutes' not '7:30'

Also durations larger than one day are received from API as 'days hh:mm'
and need different kind of loading than daily hours.
-->

<template>
  <div>
    <field-legend
      :id="`${field.name}-hours`"
      :label="label"
      :description="description"
      :tooltip-variant="tooltipVariant"
      :tooltip-placement="tooltipPlacement"
    />
    <b-form-input
      v-model="hours"
      :class="{ 'form-inline': true, 'validation-error': error }"
      :name="`input-${field.name}-hours`"
      placeholder="hours"
      :disabled="disabled"
      @keyup.enter="save"
      @input="$emit('input', field.name, $event)"
    />
    <b-form-input
      v-model="minutes"
      :class="{ 'form-inline': true, 'validation-error': error }"
      :name="`input-${field.name}-minutes`"
      placeholder="minutes"
      :disabled="disabled"
      @keyup.enter="save"
      @input="$emit('input', field.name, $event)"
    />
    <div
      v-if="error"
      class="field-validation-error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

import Field from './Field.vue'
import { validateDurationField } from '../validators'

export default {
  name: 'DurationField',

  extends: Field,

  data: function () {
    return {
      hours: undefined,
      minutes: undefined,
      defaultValidators: [
        validateDurationField
      ]
    }
  },

  computed: {
    label () {
      return this.field.inputs[this.field.name] !== undefined ? this.field.inputs[this.field.name].label : undefined
    },
    description () {
      return this.field.inputs[this.field.name] !== undefined ? this.field.inputs[this.field.name].description : undefined
    }
  },

  methods: {

    formatValue (key, value) {
      /*
      Format time field value
      We may receive seconds from API but let's drop that value is without seconds
       */
      // Drop seconds from value

      let days = 0

      if (!isEmpty(value)) {
        // Get days from value
        const withDaysMatch = value.match('[0-9]* [0-9]*:[0-9]*:[0-9]*')
        if (withDaysMatch && withDaysMatch[0] === value) {
          days = parseInt(value.split(' ')[0])
          value = value.split(' ')[1]
        }

        value = !isEmpty(value) ? value.split(':').splice(0, 2).join(':') : ''
        this.hours = parseInt(value.split(':')[0]) + 24 * days
        this.minutes = parseInt(value.split(':')[1])
      }
      return value
    },

    formatTimeForAPI (value) {
      /*
      Ensure time sent to API is always with seconds

      Also takes care of sending > 24 hours as days
       */
      let days
      let hours = this.hours
      let minutes = this.minutes

      if (hours >= 24) {
        days = Math.floor(hours / 24)
        hours = hours - days * 24
      }

      if (days !== undefined) {
        return `${days} ${hours}:${minutes}:00`
      } else {
        return `${hours}:${minutes}:00`
      }
    },

    setFieldValue (key, value) {
      /*
       Set value back to field
       */
      this.value = value
      this.$emit('update', 'update-value', this.field.name, key, this.formatTimeForAPI(value))
    },

    validatorCallback (callback, field, key, value) {
      /*
      Run validator separately against 'hours' and 'minutes' virtual fields
       */
      let response = callback(field, 'hours', this.hours)
      if (response.error === undefined) {
        response = callback(field, 'minutes', this.minutes)
      }
      return {
        key: key,
        error: response.error
      }
    }

  }
}
</script>
