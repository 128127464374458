
import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'
import { formatAPIURL } from '@/components/utils/urls'

const state = helpers.initializeAPIState()

const actions = {
  loadWorkWeekChoices: ({ commit }, params = {}) => {
    /*
    Load employee type choices
    */
    return helpers.loadListing(
      commit,
      'timesheet/autocomplete/work-week-definitions',
      params,
      types.WORK_WEEK_CHOICES_LOAD,
      types.WORK_WEEK_CHOICES_LOAD_SUCCESS,
      types.WORK_WEEK_CHOICES_LOAD_ERROR
    )
  }
}

const mutations = {
  [types.WORK_WEEK_CHOICES_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.WORK_WEEK_CHOICES_LOAD_SUCCESS] (state, data) {
    data.forEach(item => {
      item.url = formatAPIURL(`timesheet/work-weeks/${item.id}`)
    })
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.WORK_WEEK_CHOICES_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  }
}

const getters = {
  getWorkWeekChoices: () => {
    /*
    Return all retrieved work week choices autocompletions for v-select
    */
    return state.items
  },
  loadingWorkWeekChoices: () => {
    /*
    Check if work week choices are loading
     */
    return state.loading
  },
  errorLoadingWorkWeekChoices: () => {
    /*
    Check if work week choices caused errors
     */
    return state.errors.loading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
