var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"align-top"},[_c('b-col',{staticClass:"time",attrs:{"sm":"6"}},[(_vm.range_start_label)?_c('field-legend',{attrs:{"id":_vm.range_start_label,"label":_vm.range_start_label,"description":_vm.range_start_description,"tooltip-variant":_vm.tooltipVariant,"tooltip-placement":_vm.tooltipPlacement}}):_vm._e(),_c('b-form-input',{class:{ 'form-inline': true, 'validation-error': _vm.error },attrs:{"name":"input-range_start","autofocus":_vm.autofocus,"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined))return null;return _vm.deleteChar(
        _vm.field.inputs['range_start'].key,
        _vm.field.values[_vm.field.inputs['range_start'].key]
      )},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.save.apply(null, arguments)},"input":function($event){return _vm.$emit('input', _vm.field.inputs['range_start'].key, $event)}},model:{value:(_vm.range_start),callback:function ($$v) {_vm.range_start=$$v},expression:"range_start"}}),(_vm.range_start_error)?_c('div',{staticClass:"field-validation-error"},[_vm._v(" "+_vm._s(_vm.range_start_error)+" ")]):_vm._e()],1),_c('b-col',{staticClass:"time",attrs:{"sm":"6"}},[_c('b-input-group',[(_vm.range_end_label)?_c('field-legend',{attrs:{"id":_vm.range_end_label,"label":_vm.range_end_label,"description":_vm.range_end_description,"tooltip-variant":_vm.tooltipVariant,"tooltip-placement":_vm.tooltipPlacement}}):_vm._e(),_c('b-form-input',{class:{ 'form-inline': true, 'validation-error': _vm.error },attrs:{"name":"input-range_end","autofocus":_vm.autofocus,"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined))return null;return _vm.deleteChar(
          _vm.field.inputs['range_end'].key,
          _vm.field.values[_vm.field.inputs['range_end'].key]
        )},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.save.apply(null, arguments)},"input":function($event){return _vm.$emit('input', _vm.field.inputs['range_end'].key, $event)}},model:{value:(_vm.range_end),callback:function ($$v) {_vm.range_end=$$v},expression:"range_end"}}),_c('b-input-group-text',{staticClass:"cursor-pointer",attrs:{"slot":"append"},on:{"click":_vm.toggleClicked},slot:"append"},[_c('font-awesome-icon',{attrs:{"icon":"exchange-alt"}})],1)],1),(_vm.range_end_error)?_c('div',{staticClass:"field-validation-error"},[_vm._v(" "+_vm._s(_vm.range_end_error)+" ")]):_vm._e()],1),(_vm.range_error)?_c('b-col',{staticClass:"mb-2 field-validation-error"},[_vm._v(" "+_vm._s(_vm.range_error)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }