<!--
Component to render a single day in hour reporting view.

Entry details and editing forms are opened from this component.
-->

<template>
  <div class="outer-container">
    <div
      class="day py-2"
      :class="{
        'holiday': isHoliday,
        'weekend': isWeekend,
        'missing-hours': isMissingHours,
        'today': isDayToday,
        'no-bottom-border': day.isExpanded
      }"
      :title="day.nonworkDayName"
      @click="toggleIsDayExpanded"
    >
      <b-row class="vertical-margin d-flex align-middle px-0 my-0">
        <div class="d-flex justify-content-start align-items-center px-2">
          <span class="weekday-name">
            {{ day.weekDay.name }}
          </span>
          <span class="weekday-date">
            {{ day.date | shortDate }}
          </span>
          <span
            v-if="!day.isExpanded && (holidayName || hasConsumedFlexiHours)"
            class="holiday-flag ml-3"
          >
            <font-awesome-icon
              v-if="hasConsumedFlexiHours"
              icon="clock"
            />
            <font-awesome-icon
              v-if="isPaidHoliday"
              icon="dove"
            />
            <font-awesome-icon
              v-if="isUnpaidHoliday"
              icon="flag"
            />
          </span>
        </div>

        <div class="d-flex flex-grow-1 justify-content-end align-items-center px-2">
          <b-row class="d-flex justify-content-end align-items-center">
            <b-col>
              <div
                v-if="showTotalHours"
                class="float-right"
              >
                <div class="side-by-side">
                  <div class="delta-duration">
                    {{ deltaHoursFormatted }}
                  </div>
                  <div class="total-duration">
                    Total: {{ totalHours }}
                  </div>
                  <div class="expansion-icon">
                    <span>
                      <font-awesome-icon :icon="day.isExpanded ? 'angle-up' : 'angle-down'" />
                    </span>
                  </div>
                </div>
              </div>
            </b-col>
            <div class="form-actions">
              <div
                v-if="hasNegativeDelta"
                class="flexi-top-up"
                @click="createMissingFlexiHours"
              >
                <span>
                  <font-awesome-icon icon="balance-scale" />
                </span>
              </div>
              <div
                v-if="addRecordButtonVisible"
                class="btn-link btn-sm"
                @click="addNewRecordClicked"
              >
                <font-awesome-icon icon="plus" />
              </div>
              <div
                v-if="!addRecordButtonVisible && day.isEditing"
                class="btn-link btn-sm"
                @click="editCanceled"
              >
                <font-awesome-icon icon="undo-alt" />
              </div>
            </div>
          </b-row>
        </div>
      </b-row>
      <b-row
        v-if="holidayName && day.isExpanded"
        class="vertical-margin d-flex align-middle my-0 px-2"
      >
        <b-col class="holiday-name">
          {{ holidayName }}
          <span v-if="isNationalHoliday"> (national holiday) </span>
          <span v-if="isCompynyHoliday"> (contractual holiday) </span>
        </b-col>
      </b-row>
    </div>

    <div
      v-if="day.isExpanded"
      :class="{
        'today': isDayToday,
        'missing-hours': isMissingHours,
        'no-top-border': true
      }"
    >
      <b-row
        v-if="record"
        class="new-input-row"
      >
        <b-col class="px-0">
          <TimesheetRecordEditForm
            :parent="bus"
            :day="day"
            :record="record"
            :editing="day.isEditing"
            :can-edit="true"
            :can-delete="false"
            @events="eventHandler"
          />
        </b-col>
      </b-row>

      <div
        v-for="item in records"
        :key="item.id"
        @click="toggleRecordEditForm(item)"
      >
        <TimesheetRecord
          v-if="!record || record.id !== item.id"
          :record="item"
          :class="{'opened': isRecordOpenForEditing(item)}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import { isToday, addMonths, format, getDay, getMonth, getYear } from 'date-fns'
import {
  formatDurationString,
  getTotalDuration,
  parseDurationInMinutes,
  parseHoursAndMinutesFromMinutes
} from '../utils/timeUtils'

import TimesheetRecord from './TimesheetRecord.vue'
import TimesheetRecordEditForm from '@/components/timetracking/TimesheetRecordEditForm.vue'

export default {
  name: 'Day',

  components: {
    TimesheetRecord,
    TimesheetRecordEditForm
  },

  props: {
    parent: {
      type: Object,
      required: true
    },
    workDays: {
      type: Array,
      default: function () { return [] }
    },
    day: {
      type: Object,
      required: true
    },
    records: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    period: {
      type: String,
      default: undefined
    },
    anyDayOpenForEditing: {
      type: Boolean,
      required: true
    },
    allDaysExpanded: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      bus: new Vue(),
      isDayToday: isToday(this.day.date),
      record: undefined

    }
  },

  computed: {
    ...mapGetters([
      'loggedInEmployee',
      'getHolidayForDate',
      'getWorkDayForDate'
    ]),

    date () {
      /*
      Returns date being shown
      */
      return this.day !== undefined ? this.day.date : undefined
    },

    totalHours () {
      /*
      Returns total hours/minutes worked in day
      */
      return formatDurationString(this.totalDuration)
    },

    totalDuration () {
      /*
      Returns sum of total work done in day as minutes

      Exclude consumed flexible hours
       */
      const durations = this.records.filter(record => {
        return record.project_code !== this.$flexibleHoursProjectCode
      }).map(record => record.duration)
      return getTotalDuration(durations)
    },

    showTotalHours () {
      /*
      Boolean to check if total should be hidden

      Total is hidden for paid holidays with no hours entered
       */
      if (this.isPaidHoliday || this.isWeekend) {
        return this.totalDuration !== 0
      }
      return true
    },

    hasConsumedFlexiHours () {
      /*
      Check if there are any consumed flexible hours
       */
      const matches = this.records.find(
        record => record.project_code === this.$flexibleHoursProjectCode
      )
      return matches !== undefined
    },

    totalConsumedFlexi () {
      /*
      Returns sum of total consumed flexi hours in day as minutes
       */
      const durations = this.records.filter(record => {
        return record.project_code === this.$flexibleHoursProjectCode
      }).map(record => record.duration)
      return getTotalDuration(durations)
    },

    hasRecords () {
      /*
      Check if day has any records
       */
      return !isEmpty(this.records)
    },

    hasNegativeDelta () {
      /*
      Return boolean to indicate if number of hours is negative (missign flexible hours)
       */
      return this.deltaHours < 0
    },

    deltaHours () {
      /*
      Returns delta in hours against expected work day hours

      Only fulltime employees have concept of expected work hours per day
      */
      if (this.loading || this.isMissingHours) {
        return undefined
      }
      let workDay = this.getWorkDayForDate(this.date.valueOf())
      if (workDay !== undefined) {
        let workDayMinutes = workDay.hours * 60 + workDay.minutes
        let total = this.totalDuration + this.totalConsumedFlexi
        let deltaMinutes = total - workDayMinutes
        if (deltaMinutes !== 0) {
          return deltaMinutes
        } else {
          return undefined
        }
      } else {
        return undefined
      }
    },

    deltaHoursFormatted () {
      /*
      Return delta minutes as formatted string
       */
      let deltaMinutes = this.deltaHours
      if (deltaMinutes !== undefined) {
        return formatDurationString(deltaMinutes, true)
      }
      return undefined
    },

    workDay () {
      /*
      Lookup up work day object (with expected day duration) for this date
      */
      return this.getWorkDayForDate(this.date.valueOf())
    },

    holiday () {
      /*
      Lookup holiday matching this date
      */
      return this.getHolidayForDate(this.date.valueOf())
    },

    holidayName () {
      /*
       Return calendar name for holiday or empty string if not holiday
      */
      let holiday = this.holiday
      if (holiday !== undefined) {
        return holiday.name
      } else {
        return ''
      }
    },

    isMissingHours () {
      /*
      Return true if date is missing hours and should have some

      Does not report future dates (there is no 'work day' for future dates)
      */
      if (this.loading) {
        return false
      }
      if (this.getWorkDayForDate(this.date.valueOf()) !== undefined) {
        return isEmpty(this.records)
      } else {
        return false
      }
    },

    isNationalHoliday () {
      return this.holiday !== undefined && this.holiday.is_public_holiday
    },

    isCompynyHoliday () {
      return this.holiday !== undefined && (!this.holiday.is_public_holiday && this.holiday.is_company_holiday)
    },

    isPaidHoliday () {
      return this.holiday !== undefined && (this.holiday.is_public_holiday || this.holiday.is_company_holiday)
    },

    isUnpaidHoliday () {
      return this.holiday !== undefined && (!this.holiday.is_public_holiday && !this.holiday.is_company_holiday)
    },

    isHoliday () {
      /*
       Return true if holiday is public / company agreed free day
      */
      let holiday = this.holiday
      if (holiday !== undefined) {
        return holiday.is_public_holiday || holiday.is_company_holiday
      } else {
        return false
      }
    },

    isWeekend () {
      /*
      Return boolean to indicate if date is weekened

      Weekend is defined as a day when no hours are expected
      */
      let index = getDay(this.date.valueOf(), { weekStartsOn: this.$weekStartsOn })
      let duration = parseDurationInMinutes(this.loggedInEmployee.weekly_work_days[index])
      return duration === 0
    },

    isDayLocked () {
    /*
     Return true when day is old enough to be locked automatically
     */
      const oneMonthAgo = addMonths(new Date(), -1)
      const lockLimitDate = new Date(getYear(oneMonthAgo), getMonth(oneMonthAgo), 1)
      return this.day.date < lockLimitDate
    },

    addRecordButtonVisible () {
      /*
      Rules when 'add record' button is shown
       */
      // Do not show for larger views than week
      if (this.period !== 'week') {
        return false
      }
      // Do not show when we are already editing a record
      if (this.record) {
        return false
      }
      // Do not show if date is locked
      if (this.isDayLocked) {
        return false
      }
      return true
    }
  },

  watch: {
    'day.isExpanded' () {
      /*
      Watch for day's opening status.

      When day is closed, also close any records being edited without saving
       */
      if (!this.day.isExpanded) {
        // Hide edit form when day is closed
        this.day.isEditing = false
        this.record = undefined
      } else if (isEmpty(this.records)) {
        // Open new record form when day is empty
        this.initializeNewRecord()
      }
    },
    'day.isEditing' () {
      /*
      Scroll to date if opened for editing and not expanding all days
       */
      if (this.day.isEditing && !this.allDaysExpanded) {
        this.scrollToView()
      }
    },
    'allDaysExpanded' () {
      /*
      Watch for collapse / expand all flag
      */
      this.autoShowToday()
    },
    'record' () {
      /*
      Watch changes in record to edit
      */
      if (this.record !== undefined) {
        this.$emit('events', 'set-day-open-for-editing', this.day.date)
        this.scrollToView()
      }
    }
  },

  mounted () {
    /*
    Open today by default
     */
    const $vm = this
    this.autoShowToday()
    this.parent.$on('notification', this.notificationHandler)
    Vue.nextTick(function () {
      if ($vm.$route.query.id) {
        $vm.loadRecord($vm.$route.query.id)
      }
    })
  },

  methods: {

    eventHandler (action, ...args) {
      switch (action) {
        case 'created': {
          this.recordCreated()
          break
        }
        case 'updated': {
          this.recordUpdated()
          break
        }
        case 'deleted': {
          this.recordDeleted()
          break
        }
        case 'canceled': {
          this.editCanceled()
          break
        }
        case 'clear-errors': {
          this.$emit('events', 'clear-errors')
          break
        }
      }
    },

    notificationHandler (notification, ...args) {
      switch (notification) {
        default: {
          const date = args[0]
          if (this.day.date.valueOf() === date.valueOf()) {
            this.$emit('events', 'show-day-details', this.day.date)
            if (!this.records) {
              this.initializeNewRecord()
            }
          }
        }
      }
    },

    autoShowToday () {
      /*
      Automatically show today, but ONLY if today has less than expected number
      of hours. If today's work has already been entered skip this.
      */
      if (this.isDayToday && !this.allDaysExpanded && !this.anyDayOpenForEditing) {
        if (this.perid === 'week') {
          // Also check if are missing hours and only then show today
          if (this.isMissingHours) {
            this.$emit('events', 'show-day-details', this.day.date)
            // Only auto-open editor in week view
            if (!this.records) {
              this.initializeNewRecord()
            }
          }
        }
      } else if (this.allDaysExpanded) {
        this.clearRecordEditForm()
      }
    },

    loadRecord (id) {
      /*
      Load record specified by id to editor

      Record must be one of records loaded to this day's view
       */
      id = parseInt(this.$route.query.id)
      this.records.some(record => {
        if (record.id === id) {
          this.$emit('events', 'toggle-day-expanded', this.day.date)
          this.day.isEditing = true
          this.record = record
          return record
        }
      })
    },

    setEditingStatus (date) {
      /*
      Set editing status based on received date
       */
      if (this.day.date !== date) {
        this.clearRecordEditForm()
      }
    },

    toggleRecordEditForm (record) {
      /*
       Toggle visibility of editing form for specified record
       */
      if (!record.is_locked) {
        if (this.isRecordOpenForEditing(record)) {
          this.day.isEditing = !this.day.isEditing
          if (this.day.isEditing) {
            this.record = record
          }
        } else {
          this.day.isEditing = true
          this.record = record
        }
      }
    },

    clearRecordEditForm () {
      /*
      Clear any data on record edit form
       */
      this.record = undefined
      this.day.isEditing = false
    },

    toggleIsDayExpanded () {
      /*
       Toggle visibility of the day items in parent component
       */
      this.$emit('events', 'toggle-day-expanded', this.day.date)
    },

    isRecordOpenForEditing (record) {
      /*
      Checks if record is open for editing
       */
      return this.record && this.record.id === record.id
    },

    createMissingFlexiHours (event) {
      /*
      Create automatically a record with missing flexible hours duration
       */

      if (event !== undefined) {
        event.stopPropagation()
      }
      return new Promise((resolve, reject) => {
        let record = {
          date: format(this.day.date, this.$apiDateFormat),
          email: this.loggedInEmployee.email,
          project_code: this.$flexibleHoursProjectCode,
          duration: `${parseHoursAndMinutesFromMinutes(Math.abs(this.deltaHours)).join(':')}:0`,
          record_type: 'non-billable'
        }
        this.$store.dispatch('createTimesheetRecord', record)
          .then((response) => {
            this.$emit('events', 'record-created', record)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    addNewRecordClicked (event) {
      /*
       Open form to add new record
       */
      if (event !== undefined) {
        event.stopPropagation()
      }
      this.initializeNewRecord()
      this.$emit('events', 'show-day-details', this.day.date)
    },

    initializeNewRecord () {
      /*
      Initialize new record for editing
       */
      this.record = {}
      this.day.isEditing = true
      this.$emit('events', 'set-day-open-for-editing', this.day.date)
    },

    recordCreated (record) {
      /*
       Callback from edit form when record is updatd
       */
      const $vm = this
      this.$emit('events', 'record-created', record)
      this.clearRecordEditForm()
      Vue.nextTick(function () {
        $vm.initializeNewRecord()
      })
    },

    recordUpdated (record) {
      /*
       Callback from edit form when record is updatd
       */
      this.$emit('events', 'record-updated', record)
      this.clearRecordEditForm()
    },

    recordDeleted () {
      /*
       Callback from edit form when record is deleted
       */
      this.$emit('events', 'record-deleted')
      this.clearRecordEditForm()
      this.clearRecordEditForm()
    },

    editCanceled () {
      /*
      Editing canceled

      If today has no records keep empty form still open
       */
      this.clearRecordEditForm()
      if (isEmpty(this.records)) {
        this.initializeNewRecord()
      }
    },

    scrollToView () {
      /*
       When form is ready, scroll it to viewport center
       */
      if (this.$el.scrollIntoView) {
        this.$el.scrollIntoView({ block: 'start' })
      }
    }

  }

}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/theme_constants.scss';

.outer-container {
  border: 1px solid $gray-2;
  border-radius: 0px;
}
.vertical-margin {
  margin: 10px 0 10px 0;
}
.new-input-row {
  border-top: 1px solid $gray-4;
  margin: 0;
  padding: 5px 0 5px 0;
}
div.form-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 1em;
  .btn-link {
    color: $secondary-slide-6;
    padding-left: 0.5em;
  }
  .btn-link:hover {
    color: white;
    background-color: $primary-color-dark;
  }
}
.day {
  background-color: $gray-6;
  padding: 1px 0 1px 0;
  border-radius: none;
  font-weight: bold;

  .missing-hours {
    color: $primary-slide-7;
  }
}
.day-icons {
  margin-right: 0.5em;
}
.delta-duration {
  margin-right: 0.25em;
}
.flexi-top-up  {
  margin-right: 0.25em;
}
.opened {
  border: 1px solid $primary-slide-3;
}
.weekday-name {
  display: inline-block;
  text-align: right;
  width: 2.5rem;
  margin-right: 0.25rem;
}
.weekday-date {
  display: inline-block;
  text-align: right;
  width: 3rem;
  margin-right: 0.25rem;
}
.holiday-flag {
  font-size: smaller;
  font-style: italic;
  color: $primary-slide-3;
  svg {
    margin-right: 0.25rem;
  }
}
.holiday-name {
  font-size: smaller;
  font-style: italic;
}
.holiday {
  background-color: $gray-5;
}
.missing-hours {
  border: 1px solid $gray-2;
}
.weekend {
  background-color: $background-color;
}
.no-bottom-border {
  border-bottom: none;
}
.no-top-border {
  border-top: none;
}
.side-by-side {
  display: flex;
  flex-direction: row;
}
.total-duration {
  min-width: 6em;
  text-align: right;
}
.close-new-record-input {
  cursor: pointer;
}
.expansion-icon {
  margin-left: 5px;
}
</style>
