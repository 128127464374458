<!--
Form to edit employee details
-->

<template>
  <div>
    <b-form
      v-if="!loadingFormData"
    >
      <b-row
        v-if="canUpdateEmployeeType"
        class="mt-2"
      >
        <b-col cols="4">
          <field-label :field="fields.type" />
        </b-col>
        <b-col>
          <dropdown-field
            :parent="bus"
            :field="fields.type"
            :disabled="processing"
            :options="fields.type.options"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="4">
          <field-label :field="fields.email" />
        </b-col>
        <b-col>
          <text-field
            :parent="bus"
            :field="fields.email"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="4">
          <field-label :field="fields.first_name" />
        </b-col>
        <b-col>
          <text-field
            :parent="bus"
            :field="fields.first_name"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="4">
          <field-label :field="fields.last_name" />
        </b-col>
        <b-col>
          <text-field
            :parent="bus"
            :field="fields.last_name"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="4">
          <field-label :field="fields.preferred_time_format" />
        </b-col>
        <b-col>
          <dropdown-field
            :parent="bus"
            :field="fields.preferred_time_format"
            :disabled="processing"
            :switches="true"
            :default="fields.preferred_time_format.default"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="roleChoicesVisible"
        class="mt-2"
      >
        <b-col cols="4">
          <field-label :field="fields.is_active" />
        </b-col>
        <b-col>
          <checkbox-field
            :parent="bus"
            :field="fields.is_active"
            :disabled="processing"
            :switches="true"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="roleChoicesVisible"
        class="mt-2"
      >
        <b-col cols="4">
          <field-label :field="fields.roles" />
        </b-col>
        <b-col>
          <checkbox-group-field
            :parent="bus"
            :field="fields.roles"
            :disabled="processing"
            :switches="true"
            stacked="stacked"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <field-label :field="fields.flexible_hours_reset_date" />
        </b-col>
        <b-col>
          <date-field
            :parent="bus"
            :field="fields.flexible_hours_reset_date"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <FormButtons
        :processing="processing"
        :can-cancel="canCancel"
        :can-delete="canDelete"
        :can-save="canSave"
        :cancel-disabled="cancelDisabled"
        :delete-disabled="deleteDisabled"
        :save-disabled="saveDisabled"
        @events="updateField"
      />
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Form from '@/forms/Form.vue'
import FormButtons from '@/forms/FormButtons.vue'

export default {
  name: 'EmployeeEditForm',

  components: {
    FormButtons
  },

  mixins: [
    Form
  ],

  data: function () {
    return {
      // Store actions to dispatch
      actions: {
        create: 'createEmployee',
        update: 'updateEmployee',
        delete: 'deleteEmployee'
      },
      // All form fields
      'formFields': {
        id: {
          label: 'Record ID'
        },
        email: {
          label: 'Email',
          required: true
        },
        type: {
          label: 'Employee type',
          required: true,
          options: this.getEmployeeTypeChoices
        },
        first_name: {
          label: 'First Name',
          required: true
        },
        last_name: {
          label: 'Last Name',
          required: true
        },
        is_active: {
          label: 'Status',
          inputs: {
            is_active: {
              label: 'Is active',
              default: true
            }
          }
        },
        preferred_time_format: {
          label: 'Preferred Time Format',
          default: 'duration',
          options: [
            {
              id: 'time',
              label: 'Time range'
            },
            {
              id: 'duration',
              label: 'Duration'
            }
          ]
        },
        flexible_hours_reset_date: {
          label: 'Flexible Hours Calculation Start Date',
          inputs: {
            flexible_hours_reset_date: {
            }
          }
        },
        roles: {
          label: 'Roles',
          inputs: {
            is_admin: {
              label: 'Has full admin permissions'
            },
            is_hr: {
              label: 'Has HR permisisons'
            },
            is_finance: {
              label: 'Has finance permisssions'
            },
            is_billing_reporter: {
              label: 'Has billing reporting read-only permissions'
            }
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'isAdminUser',
      'isHRUser',
      'getEmployeeTypeChoices'
    ]),
    canUpdateEmployeeType () {
      /*
      Boolean to check if form should show fields for editing employee type
       */
      return this.isAdminUser === true || this.isHRUser === true
    },
    roleChoicesVisible () {
      /*
      Hide things which are not public information about employee roles
       */
      return this.isAdminUser === true || this.isHRUser === true
    }
  },

  methods: {
    loadFormOptions () {
      /*
      Load any options for dropdown fields
       */
      this.$store.dispatch('loadEmployeeTypeChoices')
        .then((data) => {
          if (this.employee) {
            this.setValue('email', 'email', this.employee.email)
          }
          if (!this.isAdminUser) {
            this.fields.roles.inputs.is_admin.visible = false
          }
          if (!this.isAdminUser && !this.isHRUser) {
            this.fields.is_active.inputs.is_active.visible = false
            this.fields.roles.inputs.is_hr.visible = false
            this.fields.roles.inputs.is_finance.visible = false
            this.fields.roles.inputs.is_billing_reporter.visible = false
          }
          this.parent.$emit('reload', 'roles')
          this.updateOptions('type', this.getEmployeeTypeChoices)
        })
    }
  }

}
</script>
