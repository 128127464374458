/*
Global vue app imports, configuration and settings

Shared by main.js and unit tests
 */

import vueHeadful from 'vue-headful'
import VueCookie from 'vue-cookie'
import Vue2TouchEvents from 'vue2-touch-events'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// Load common filters as global objects in apps
import './components/filters/Date'
import './components/filters/Time'

import BootstrapVue from 'bootstrap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faClock, faEuroSign, faDove, faFlag, faPen, faPlaneDeparture, faTrash, faWrench, faTasks, faUsers, faEdit,
  faCommentAlt, faTimes, faAngleDown, faAngleUp, faLock, faPlusCircle, faMinusCircle,
  faArrowAltCircleLeft, faArrowAltCircleRight, faUndoAlt, faExchangeAlt, faBalanceScale, faHandsWash,
  faRunning, faPersonBooth, faUserClock, faUserPlus, faUserMinus, faUserEdit, faPlus, faMinus
} from '@fortawesome/free-solid-svg-icons'

library.add([
  faClock, faEuroSign, faDove, faFlag, faPen, faPlaneDeparture, faTrash, faWrench, faTasks, faUsers, faEdit,
  faCommentAlt, faTimes, faAngleDown, faAngleUp, faLock, faPlusCircle, faMinusCircle,
  faArrowAltCircleLeft, faArrowAltCircleRight, faUndoAlt, faExchangeAlt, faBalanceScale, faHandsWash,
  faRunning, faPersonBooth, faUserClock, faUserPlus, faUserMinus, faUserEdit, faPlus, faMinus
])

function configureVueApp (Vue) {
  /*
  Configure specified vue instance with our imports and global variables
   */

  Vue.use(BootstrapVue)
  Vue.use(VueCookie)

  Vue.use(Vue2TouchEvents, {
    swipeTolerance: 150
  })

  Vue.component('font-awesome-icon', FontAwesomeIcon)
  Vue.component('vue-headful', vueHeadful)

  // Define some global formatting options
  Vue.prototype.$appName = 'Jaggers'
  Vue.prototype.$weekStartsOn = 1
  Vue.prototype.$monthFormat = 'YYYY-MM'
  Vue.prototype.$shortDateFormat = 'D.M.'
  Vue.prototype.$dateFormat = 'D.M.YYYY'
  Vue.prototype.$apiDateFormat = 'YYYY-MM-DD'
  Vue.prototype.$apiMonthFormat = 'YYYY-MM'
  Vue.prototype.$validTimePeriods = ['week', 'month', 'year', 'dates']

  // Critical and warning levels for flexible hours delta
  Vue.prototype.$flexibleHoursProjectCode = 909
  Vue.prototype.$flexiHoursCritical = 24
  Vue.prototype.$flexiHoursWarning = 15

  // Mark Saturday and Sunday weekend days
  Vue.prototype.$weekendDays = [0, 6]

  Vue.config.productionTip = false

  // Add custom date / time parser formats
  dayjs.extend(customParseFormat)
}

export default {
  configureVueApp
}
