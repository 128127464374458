/*
Vue application bootstrap main.js
*/

import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import config from '@/config'

// CSS asserts separately to not confuse jest and unit tests
import assets from './assets' // eslint-disable-line no-unused-vars

config.configureVueApp(Vue)

function createApp () {
  /*
   Create Vue app
   */
  new Vue({
    router,
    store,
    template: '<App/>',
    render: h => h(App)
  }).$mount('#app')
}
window.addEventListener('load', createApp)
