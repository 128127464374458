
/*
Axios API client to use django APIs with JWT token authentication
*/

import axios from 'axios'
import router from '../router'
import store from '../store'

import qs from 'qs'
const AUTH_TOKEN_KEY = 'jaggers_api_token'

const APIService = axios.create({
  /*
  Axios service root with baseURL from .env in project configuration

  The JWT authorization header is set from vuex module for auth in
  store/modules/auth.js and verified by route navigation guard
  */
  baseURL: process.env.VUE_APP_API_ROOT,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      arrayFormat: 'repeat'
    })
  }

})

// Intercept 401 errors from response (session expire)
APIService.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      store.dispatch('logout')
      router.push({ name: 'Index' })
    } else {
      return Promise.reject(error)
    }
  }
)

APIService.interceptors.request.use((config) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY)
  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`
  } else {
    config.headers.Authorization = null
  }
  return config
})

export default {
  /*
  Generic API handling for django with JWT tokens and DRF APIs
  */

  get: (url, params = {}) => {
    /* Get paginated data from django

    This function will get ALL paginated results from django, not just
    one page. You may wish to limit the number of pages retrieved and
    also return the next page to retrieve, or something like that.
    */

    function getData (url, params = {}) {
      /* Get paginaged data

      Top promise to handle fetching of paginated results
      */

      return new Promise(function (resolve, reject) {
        let records = []

        function getPaginated (url, params = {}) {
          /* Get paginated results

          Return paginated results from django default pagination.

          If results has 'next' link, fetch it as well until all is done.

          This likely should have a limitation on number of results to get
          in one go.
          */
          APIService.get(url, {
            params: params
          })
            .then((response) => {
              if (response.data.results) {
                records = records.concat(response.data.results)
                if (response.data.next) {
                  getPaginated(response.data.next)
                } else {
                  resolve(records)
                }
              } else {
                resolve(response.data)
              }
            })
            .catch(err => {
              reject(err)
            })
        }

        getPaginated(url, params)
      })
    }

    // Use getData promise to get the paginated results
    return getData(url, params)
      .then((records) => {
        return records
      })
  },

  post: (url, params, config) => {
    /*
    POST data to API
    */
    return APIService.post(url, params, config)
  },

  put: (url, params) => {
    /*
    PUT data with API
     */
    return APIService.put(url, params)
  },

  delete: (url, params) => {
    /*
    Delete data with API
     */
    return APIService.delete(url, params)
  }

}
