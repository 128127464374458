<!--
Component to render form field labels with optional flags

This component is passed a 'field' structure from Form.vue
-->

<template>
  <div>
    <b>{{ field.label }}</b>
    <span
      v-if="field.required[field.name]"
    >
      *
    </span>
  </div>
</template>

<script>
export default {
  name: 'Label',

  props: {
    'field': {
      type: Object,
      required: true
    }
  }

}
</script>
