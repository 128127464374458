/*
Utility functions for URLs
 */

import qs from 'qs'

export const formatAPIURL = (path, query) => {
  /*
  Format full API URL with query parameters and FQDN
   */
  if (query) {
    query = qs.stringify(query, {
      arrayFormat: 'repeat'
    })
    return `${process.env.VUE_APP_API_ROOT}${path}${query}`
  }
  return `${process.env.VUE_APP_API_ROOT}${path}`
}
