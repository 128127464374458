<!--
View to show a single employee remuneration in lists
-->

<template>
  <div>
    <b-row>
      <b-col class="date">
        {{ remuneration.date | date }}
      </b-col>
      <b-col class="duration">
        {{ remuneration.duration | durationValue }}
      </b-col>
      <b-col>
        {{ remuneration.created_by }}
      </b-col>
      <b-col>
        {{ remuneration.reason }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Remuneration',

  props: {
    employee: {
      type: Object,
      required: true
    },
    remuneration: {
      type: Object,
      required: true
    }
  }
}
</script>
