<!--
Common 'loading' spinner component.

Visibility is controlled from calling component.
-->

<template>
  <b-container
    fluid
    class="d-flex flex-column justify-content-center align-items-center v-100 mt-4 mb-4"
  >
    <b-spinner variant="primary" />
    <div
      v-if="message"
    >
      {{ message }}
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'Loading',

  props: {
    message: {
      type: String,
      default: undefined
    }
  }
}
</script>
