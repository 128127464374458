<!--
Common base component for models extending employee object with data
-->

<template>
  <div />
</template>

<script>

import Editor from '@/components/base/Editor.vue'

export default {
  name: 'EmployeeDataEditor',

  extends: Editor,

  props: {
    employee: {
      type: Object,
      required: true
    }
  },

  computed: {
    email () {
      return this.$route.params.email
    },
    canEdit () {
      return this.isAdminUser || this.isHRUser
    },
    canDelete () {
      return this.isAdminUser || this.isHRUser
    }
  },

  watch: {
    'employee.email' () {
      this.loadData()
    }
  }

}

</script>
