<!--
Component to show a single category details in period work summary
-->

<template>
  <div class="category-details">
    <div class="details-row">
      <div
        v-if="category.label"
        class="label"
      >
        {{ category.label }}
      </div>

      <div
        class="duration"
      >
        {{ duration(category.duration) }}
      </div>
    </div>
    <div
      v-if="category.percentage"
      class="details-row"
    >
      <div
        class="percentage"
      >
        <div :style="percentageStyle" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseComponent from '@/components/base/BaseComponent.vue'

export default {
  name: 'CategoryPercentage',

  extends: BaseComponent,

  props: {
    period: {
      type: String,
      required: true
    },
    employee: {
      type: Object,
      required: true
    },
    category: {
      type: Object,
      required: true
    }
  },

  computed: {
    percentageStyle () {
      /*
      Return class for percentage bar based on percentage value
       */
      if (this.category.percentage !== undefined) {
        return {
          width: `${this.category.percentage}%`
        }
      } else {
        return {}
      }
    }
  }

}
</script>

<style lang="scss" scoped>
div.category-details {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;

  div.details-row {
    display: flex;
    flex-direction: row;
    width: 100%;

    div.label, div.description {
      margin-right: 1rem;
      width: 4rem;
    }

    div.duration {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 8rem;
    }

    div.percentage {
      display: flex;
      flex-direction: row;
      width: 100%;
      background-color:#ccc;
      div {
        height: 5px;
        background-color: green;
      }
    }

  }
}
</style>
