<!--
Common base component for models extending project object with data
-->

<template>
  <div />
</template>

<script>

import Editor from '@/components/base/Editor.vue'

export default {
  name: 'ProjectDataEdtor',

  extends: Editor,

  props: {
    project: {
      type: Object,
      required: true
    }
  },

  computed: {
  },

  watch: {
    'project.project_code' () {
      console.log('project code updated', this.project)
      this.loadData()
    }
  }

}

</script>
