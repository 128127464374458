<!--
Single line text field form component with optional autocomplete
-->

<template>
  <div>
    <field-legend
      :id="field.name"
      :label="field.inputs[field.name].label"
      :description="field.inputs[field.name].description"
      :tooltip-variant="tooltipVariant"
      :tooltip-placement="tooltipPlacement"
    />
    <b-form-input
      :id="`input-${field.name}`"
      v-model="value"
      :class="{ 'form-inline': true, 'validation-error': error }"
      :name="`input-${field.name}`"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :autofocus="autofocus"
      :disabled="disabled"
      @keyup.enter="save"
      @input="$emit('input', field.name, $event)"
    />

    <div
      v-if="error"
      class="field-validation-error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import Field from './Field.vue'
import { validateStringField } from '../validators'
export default {
  name: 'TextField',

  extends: Field,

  props: {
    autocomplete: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },

  data: function () {
    return {
      defaultValidators: [
        validateStringField
      ]
    }
  }
}
</script>
