<!--
Component to show box with period overall summary details from PeriodWorkDetails
-->

<template>
  <div
    class="period-summary p-2"
  >
    <div class="totals">
      <div class="heading mt-2">
        Summary
      </div>
      <div
        v-for="item in totals"
        :key="item.label"
      >
        <div class="percentage">
          <span v-if="item.percentage">
            {{ item.percentage }} %
          </span>
        </div>
        <div class="duration">
          {{ duration(item.duration) }}
        </div>
        <div class="description">
          {{ item.description }}
        </div>
      </div>
    </div>
    <div class="totals">
      <div class="heading mt-2">
        Internal work
      </div>
      <div
        v-for="item in categoriesOrderedByDuration"
        :key="item.label"
      >
        <div class="percentage">
          <span v-if="item.percentage">
            {{ item.percentage }} %
          </span>
        </div>
        <div class="duration">
          {{ duration(item.duration) }}
        </div>
        <div class="description">
          {{ item.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, orderBy } from 'lodash'

import BaseComponent from '@/components/base/BaseComponent.vue'

// These items are shown in left side box
const TOTALS_CATEGORIES_ORDERING = [
  'total_hours',
  'total_expected',
  'total_period_hours',
  'consumed_flexible_hours',
  'total_billable',
  'total_internal'
]

export default {
  name: 'PeriodCategoryDetails',

  extends: BaseComponent,

  props: {
    summary: {
      type: Object,
      required: true
    },
    employee: {
      type: Object,
      required: true
    },
    period: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },

  computed: {
    totals () {
      let categories = []
      TOTALS_CATEGORIES_ORDERING.forEach(category => {
        const item = cloneDeep(this.summary.summary.find(item => item.category === category))
        categories.push(item)
      })
      return categories
    },
    categoriesOrderedByDuration () {
      /*
      Return projects ordered by total duration
       */
      let categories = []
      this.summary.summary.filter(record => {
        return TOTALS_CATEGORIES_ORDERING.indexOf(record.category) === -1
      }).forEach(record => {
        let item = cloneDeep(record)
        item.total_minutes = item.duration.hours * 60 + item.duration.minutes
        if (item.total_minutes) {
          categories.push(item)
        }
      })
      return orderBy(categories, ['total_minutes']).reverse()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/bootstrap_overrides.scss';
@import '../../assets/scss/theme_constants.scss';

div.period-summary {
  display: flex;
  flex-direction: column;
  min-width: 24rem;
  .heading {
    font-weight: bold;
  }
  .totals {
    display: flex;
    flex-direction: column;
    div {
      display: flex;
      flex-direction: row;
      .duration {
        justify-content: flex-end;
        min-width: 7rem;
        padding-right: 1rem;
      }
      .percentage {
        width: 6rem;
        justify-content: flex-end;
        padding-right: 1rem;
      }
      .description {
      }
    }
  }
}
</style>
