<!--
Common parent component for components that edit records
-->

<template>
  <div />
</template>

<script>
import { isEmpty } from 'lodash'

import BaseComponent from '@/components/base/BaseComponent.vue'

export default {
  name: 'EditorComponent',

  extends: BaseComponent,

  props: {
    parent: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      pageName: undefined,
      processing: false,
      newRecord: false,
      editModalVisible: false,
      record: {}
    }
  },

  computed: {
    editing () {
      /*
      Checks if item is being edited

      Either we have a non-empty record to edit or newRecord flag is set
       */
      return this.newRecord || !isEmpty(this.record)
    },
    canEdit () {
      return false
    },
    canDelete () {
      return false
    },
    canCancel () {
      return true
    }
  },

  watch: {
    'editing' () {
      this.editModalVisible = this.editing
    }
  },

  created () {
    this.parent.$on('notification', this.notificationHandler)
  },

  mounted () {
    this.loadData()
  },

  methods: {

    notificationHandler (notification, ...args) {
      /*
      Handler for notifications from the parent component
       */
      switch (notification) {
        case 'edit-new-record': {
          this.editNewRecord()
          break
        }
        case 'page-changed': {
          // TODO - do something sensible on page change
          break
        }
        default: {
          this.localNotificationHandler(notification, args)
        }
      }
    },

    localNotificationHandler (notification, ...args) {
      /*
      Editor specific local notification handler
       */
    },

    eventHandler (action, ...args) {
      /*
      Generic handler for form events
       */
      switch (action) {
        case 'created': {
          this.recordCreated(args[0])
          this.$emit('events', 'created', args[0])
          break
        }
        case 'updated': {
          this.recordUpdated(args[0])
          this.$emit('events', 'updated', args[0])
          break
        }
        case 'deleted': {
          this.recordDeleted()
          this.$emit('events', 'deleted')
          break
        }
        case 'canceled': {
          this.editCanceled()
          break
        }
        case 'toggle-editing': {
          this.toggleEditing()
          break
        }
        case 'api-error': {
          console.log('Editor received API error', args)
          break
        }
        case 'clear-errors': {
          this.$emit('events', 'clear-errors')
          break
        }
      }
    },

    rowClickedCallback (item) {
      /*
      Row was clickled in page listing
       */
      if (this.canEdit) {
        this.editRecord(item)
      }
      this.$emit('events', 'row-clicked', item)
      this.$set(item, '_showDetails', !item._showDetails)
    },

    editRecord (record) {
      /*
      Start editing existing record
       */
      this.newRecord = false
      this.record = record
    },

    editNewRecord () {
      /*
      Start creating new record
       */
      this.record = {}
      this.newRecord = true
    },

    clearEditedRecord () {
      /*
      Cancel record editing
      */
      this.processing = false
      this.newRecord = false
      this.record = {}
    },

    loadData () {
    },

    recordCreated () {
      /*
      Callback to run after successful record creation
       */
      this.clearEditedRecord()
      this.loadData()
    },

    recordUpdated () {
      /*
      Callback to run after successful record update
       */
      this.clearEditedRecord()
      this.loadData()
    },

    recordDeleted () {
      /*
      Callback to run after successful record deletion
       */
      this.clearEditedRecord()
      this.loadData()
    },

    editCanceled () {
      /*
      Callback to run when edit is canceled
       */
      this.clearEditedRecord()
    }

  }
}
</script>
