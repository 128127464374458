<!--
Text field with multiple lines of text
-->

<template>
  <div>
    <field-legend
      :id="field.name"
      :label="field.inputs[field.name].label"
      :description="field.inputs[field.name].description"
      :tooltip-variant="tooltipVariant"
      :tooltip-placement="tooltipPlacement"
    />
    <div
      v-for="(item, i) in values"
      :key="i"
    >
      <b-input-group>
        <b-form-input
          :id="`input-${field.name}-${i}`"
          :key="i"
          :ref="`ref-${field.name}-${i}`"
          v-model="values[i]"
          :class="{ 'form-inline': true, 'validation-error': error }"
          :name="`input-${field.name}`"
          :autocomplete="autocomplete"
          :placeholder="placeholder"
          :autofocus="autofocus"
          :disabled="disabled"
          @keydown.backspace="deleteEntry(i)"
          @keyup.enter="addEntry(i)"
          @input="$emit('input', field.name, $event)"
        />
        <b-input-group-text
          slot="append"
          class="cursor-pointer"
          @click="deleteEntry(i, true)"
        >
          <font-awesome-icon icon="trash" />
        </b-input-group-text>
        <b-input-group-text
          slot="append"
          class="cursor-pointer"
          @click="addEntry(i)"
        >
          <font-awesome-icon icon="plus-circle" />
        </b-input-group-text>
      </b-input-group>
      <div
        v-if="errors[i]"
        class="field-validation-error"
      >
        {{ errors[i] }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { isEmpty } from 'lodash'
import { validateStringField } from '../validators'

import TextField from './TextField.vue'

export default {
  name: 'MultiLineTextField',

  extends: TextField,

  data: function () {
    return {
      values: [],
      errors: [],
      defaultValidators: [
        validateStringField
      ]
    }
  },

  methods: {

    loadData () {
      /*
      Load value with indexes to this.values
       */
      this.values = []
      let value = this.field.values[this.field.name]
      if (!isEmpty(value)) {
        value.forEach(item => { this.values.push(item) })
      }
      this.errors = Array.from({ length: this.valueslength })
      this.ensureEmptyField()
    },

    parseInputValue (index, value) {
      return this.values
    },

    validatorCallback (callback, field, key, value) {
      /*
      Validator for multiple text lines

      Quick hack to disable validation until multiple lines validation
      is properly implemented.
       */
      return {
        key: key,
        error: undefined
      }
    },

    setFieldValue (key, value) {
      /*
      Cunning like a fox, send the list of fields not initial value

      But do NOT send empty strings
       */
      value = this.values.filter(item => !isEmpty(item))
      if (isEmpty(value)) {
        value = []
      }
      this.$emit('update', 'update-value', this.field.name, this.field.name, value)
    },

    ensureEmptyField () {
      /*
      If there is no values we must have one field or it's all hidden
       */
      if (isEmpty(this.values)) {
        this.values = ['']
        this.errors = []
      }
    },

    deleteEntry (index, deleteAlways) {
      /*
      Delete entry if it's empty and focus previous
      */
      const $vm = this
      const id = `ref-${this.field.name}-${index - 1}`
      if (deleteAlways || isEmpty(this.values[index])) {
        this.values.splice(index, 1)
        this.errors.splice(index, 1)
        Vue.nextTick(() => {
          if ($vm.$refs[id]) {
            $vm.$refs[id][0].focus()
          }
        })
      }
      this.ensureEmptyField()
      this.setFieldValue()
    },

    addEntry (index) {
      /*
      Add empty entry and focus it
       */
      const $vm = this
      const id = `ref-${this.field.name}-${index + 1}`
      this.values.splice(index + 1, 0, '')
      this.errors.splice(index + 1, 0, '')
      Vue.nextTick(() => {
        if ($vm.$refs[id]) {
          $vm.$refs[id][0].focus()
        }
      })
      this.ensureEmptyField()
      this.setFieldValue()
    }

  }

}
</script>
