
/*
Monthly billing report PDF generation
*/

import { format } from 'date-fns'

import PDFDocument from '@/pdf/template'

import { getTotalDuration, parseHoursAndMinutesFromMinutes, formatDurationValue } from '@/components/utils/timeUtils'

export const formatDurationString = function (hours, minutes) {
  if (hours && minutes) {
    return `${hours} h ${minutes} min`
  } else if (hours) {
    return `${hours} h`
  } else if (minutes) {
    return `${minutes} min`
  } else {
    return ''
  }
}

export const createMonthlyBillingReport = function (report) {
  const pdf = new PDFDocument()
  // Add standard heading with logo
  let heading_lines = [
    report.title,
    `Reporting period: ${report.startDate} - ${report.endDate}`,
    `Billable hours: ${formatDurationString(report.billable_hours, report.billable_minutes)}`
  ]
  if (report.billable_travel_hours || report.billable_travel_minutes) {
    heading_lines.push(
      `Billable travel: ${formatDurationString(report.billable_travel_hours, report.billable_travel_minutes)}`
    )
  }
  pdf.addHeading(heading_lines)

  // Summary of hours wored grouped by employee
  const summary = []
  Object.keys(report.totals).map(employee => {
    let duration = formatDurationString(
      report.totals[employee].billable.hours,
      report.totals[employee].billable.minutes
    )
    summary.push(`${employee} billable hours: ${duration}`)
    if (report.totals[employee].billable_travel.hours || report.totals[employee].billable_travel.minutes) {
      let duration_travel = formatDurationString(
        report.totals[employee].billable_travel.hours,
        report.totals[employee].billable_travel.minutes
      )
      summary.push(`${employee} billable travel: ${duration_travel}`)
    }
  })
  pdf.addSection(summary)

  const columns = [
    { 'header': 'Date', dataKey: 'date' },
    { 'header': 'Duration', dataKey: 'duration' },
    { 'header': 'Employee', dataKey: 'employee' },
    { 'header': 'Comments', dataKey: 'comments' }
  ]
  const tableStyles = {
    fontSize: 8
  }
  const columnStyles = {
    date: {
      halign: 'left',
      cellWidth: 18
    },
    duration: {
      halign: 'left',
      cellWidth: 12,
      minCellWidth: 12
    },
    employee: {
      halign: 'left',
      cellWidth: 40,
      overflow: 'linebreak'
    },
    comments: {
      halign: 'left',
      cellWidth: 120,
      overflow: 'linebreak'
    }
  }

  const addDetailRecordTableToPdf = function (records) {
    /*
     Items for detailed records table

     We only report billable records
     */
    let items = []
    let date
    let duration
    let employee
    records.filter(record => record.record_type.is_billable).forEach(record => {
      date = format(record.date, 'DD.MM.YYYY')
      duration = formatDurationValue(record.billed_duration)
      employee = `${record.employee.first_name} ${record.employee.last_name}`
      if (record.record_type.category == 'travel') {
        duration = `${duration} T`
      }
      if (record.comments.length <= 1) {
        // Add normal item
        items.push(
          {
            date: date,
            employee: employee,
            duration: duration,
            comments: record.comments
          }
        )
      } else {
        let comments = record.comments.slice()
        // Add first line for multiple comments entry normally
        items.push(
          {
            date: date,
            employee: employee,
            duration: duration,
            comments: comments[0]
          }
        )
        // Add dummy lines with comments for rest of comments
        comments.splice(1).forEach(comment => {
          items.push({
            date: '',
            employee: '',
            duration: '',
            comments: comment
          })
        })
      }
    })

    // Add detailed table of worked hours
    pdf.addTable({
      items: items,
      columns: columns,
      columnStyles: columnStyles,
      tableStyles: tableStyles
    })
  }

  if (report.grouped) {
    // Add summaries grouped by task

    // Add a heading and detailed listing of records for each task
    report.details.forEach(task => {
      pdf.addSection(task.name)
      pdf.addSection(
        formatDurationString(
          ...parseHoursAndMinutesFromMinutes(getTotalDuration(task.records.map(record => record.billed_duration)))
        )
      )

      addDetailRecordTableToPdf(task.records)
    })
  } else {
    addDetailRecordTableToPdf(report.details)
  }

  pdf.save(report.filename)
  return pdf
}
