<!--
View to show project details
-->

<template>
  <b-form
    fluid
  >
    <b-row class="mb-2">
      <b-col cols="4">
        <b>Category</b>
      </b-col>
      <b-col>
        {{ record.category }}
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="4">
        <b>Type</b>
      </b-col>
      <b-col>
        {{ record.type }}
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="4">
        <b>Status</b>
      </b-col>
      <b-col>
        {{ record.is_archived ? 'Archived' : 'Active' }}
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="4">
        <b>Name</b>
      </b-col>
      <b-col>
        {{ record.name }}
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="4">
        <b>Description</b>
      </b-col>
      <b-col>
        {{ record.description }}
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="4">
        <b>Start Date</b>
      </b-col>
      <b-col>
        {{ record.start_date !== null ? record.start_date : 'not set' }}
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="4">
        <b>End Date</b>
      </b-col>
      <b-col>
        {{ record.end_date !== null ? record.end_date : 'not set' }}
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="4">
        <b>Latest Record</b>
      </b-col>
      <b-col>
        {{ record.latest_record_date !== null ? record.latest_record_date : 'no records' }}
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="4">
        <b>Flags</b>
      </b-col>
      <b-col>
        <div>{{ record.is_billable ? 'Billable' : 'Non-billable' }}</div>
        <div>{{ record.is_locked ? 'Locked' : 'Not locked' }}</div>
        <div>{{ record.is_always_visible ? 'Always visible' : '' }}</div>
        <div>
          {{ record.allow_member_task_management
            ? 'Allow all members to manage tasks'
            : 'Only manager can manage tasks'
          }}
        </div>
        <div>
          {{ record.group_billing_details_by_task
            ? 'Group items by task in billing report'
            : 'Items are listed by date in billing report'
          }}
        </div>
      </b-col>
    </b-row>

    <div v-if="isBillableProject">
      <b-row
        class="mb-2"
      >
        <b-col>
          <h3>Billing Details</h3>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col cols="4">
          <b>Latest billed</b>
        </b-col>
        <b-col>
          <div v-if="record.latest_billed_date">
            {{ record.latest_billed_date }}
          </div>
          <div v-else>
            -
          </div>
        </b-col>
      </b-row>

      <b-row

        class="mb-2"
      >
        <b-col cols="4">
          <b>Fixed Price (<font-awesome-icon icon="euro-sign" />)</b>
        </b-col>
        <b-col>
          {{ record.fixed_price !== null ? record.fixed_price : 'not set' }}
        </b-col>
      </b-row>

      <b-row
        class="mb-2"
      >
        <b-col>
          <h3>Billing Rounding</h3>
        </b-col>
      </b-row>

      <b-row
        class="mb-2"
      >
        <b-col cols="4">
          <b>Minimum Billable Time</b>
        </b-col>
        <b-col>
          {{ parseDuration(record.minimum_billable_time, 'default') }}
        </b-col>
      </b-row>

      <b-row
        class="mb-2"
      >
        <b-col cols="4">
          <b>Billing Resolution</b>
        </b-col>
        <b-col>
          {{ parseDuration(record.billing_resolution, 'default') }}
        </b-col>
      </b-row>

      <b-row
        class="mb-2"
      >
        <b-col cols="4">
          <b>Record Granularity</b>
        </b-col>
        <b-col>
          {{ parseDuration(record.record_qranularity, 'default') }}
        </b-col>
      </b-row>
    </div>
  </b-form>
</template>

<script>
import DataView from '@/forms/DataView.vue'

export default {
  name: 'Employee',

  mixins: [
    DataView
  ],

  computed: {
    isBillableProject () { return this.record.is_billable }
  }

}
</script>
