<!--
Show custom error message when user employee profile is not yet ready (has not yet been configured)
-->

<template>
  <b-container
    fluid
    class="d-flex flex-column justify-content-center align-items-center h-100 api-error"
  >
    <h3>Not yet configured</h3>
    <div>
      Your account for time tracking is not yet configured.
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'EmployeeNotReady'
}
</script>
