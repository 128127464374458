/*
Project state
 */

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadProjects: ({ commit }, params = {}) => {
    return helpers.loadListing(
      commit,
      'timesheet/projects',
      params,
      types.PROJECT_LIST_LOAD,
      types.PROJECT_LIST_LOAD_SUCCESS,
      types.PROJECT_LIST_LOAD_ERROR
    )
  },
  loadProjectDetails: ({ commit }, projectCode = {}) => {
    return helpers.loadDetails(
      commit,
      `timesheet/projects/${projectCode}`,
      types.PROJECT_DETAILS_LOAD,
      types.PROJECT_DETAILS_LOAD_SUCCESS,
      types.PROJECT_DETAILS_LOAD_ERROR
    )
  },
  createProject: ({ commit }, params = {}) => {
    /*
    Create a project record
     */
    return helpers.postRecord(
      commit,
      'timesheet/projects',
      params,
      types.PROJECT_CREATE,
      types.PROJECT_CREATE_SUCCESS,
      types.PROJECT_CREATE_ERROR
    )
  },
  updateProject: ({ commit }, params = {}) => {
    /*
     Update existing project
     */
    return helpers.updateRecord(
      commit,
      `timesheet/projects/${params.project_code}`,
      params,
      types.PROJECT_UPDATE,
      types.PROJECT_UPDATE_SUCCESS,
      types.PROJECT_UPDATE_ERROR
    )
  },
  deleteProject: ({ commit }, params = {}) => {
    /*
     Delete existing project
     */
    return helpers.deleteRecord(
      commit,
      `timesheet/projects/${params.project_code}`,
      types.PROJECT_DELETE,
      types.PROJECT_DELETE_SUCCESS,
      types.PROJECT_DELETE_ERROR
    )
  },
  resetProjects: ({ commit }, params = {}) => {
    commit(types.PROJECT_LIST_RESET)
  }
}

const mutations = {
  [types.PROJECT_LIST_RESET] (state) {
    helpers.mutateResetData(state)
  },
  [types.PROJECT_LIST_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.PROJECT_LIST_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.PROJECT_LIST_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  },
  [types.PROJECT_DETAILS_LOAD] (state) {
    helpers.mutateAPIDetailsLoad(state)
  },
  [types.PROJECT_DETAILS_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIDetailsLoaded(state, data)
  },
  [types.PROJECT_DETAILS_LOAD_ERROR] (state, error) {
    helpers.mutateAPIDetailsLoadFailed(state, error)
  },
  [types.PROJECT_CREATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.PROJECT_CREATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.PROJECT_CREATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.PROJECT_UPDATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.PROJECT_UPDATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.PROJECT_UPDATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.PROJECT_DELETE] (state, error) {
    helpers.mutateItemDeleting(state)
  },
  [types.PROJECT_DELETE_SUCCESS] (state, error) {
    helpers.mutateItemDeleted(state)
  },
  [types.PROJECT_DELETE_ERROR] (state, error) {
    helpers.mutateItemDeleteFailed(state)
  }
}

const getters = {
  getProjects: () => {
    /*
    Get list of projects
    */
    return state.items
  },
  getProjectDetails: () => {
    /*
    Get details for a project
     */
    return state.details
  },
  loadingProjects: () => {
    /*
    Check if project list is loading
     */
    return state.loading
  },
  loadingProjectDetails: () => {
    /*
    Check if project details are loading
     */
    return state.loadingDetails
  },
  updatingProjectDetails: () => {
    /*
    Check if project record is being updated
     */
    return state.updating
  },
  deletingProjectDetails: () => {
    /*
    Check if project record is being deleted
     */
    return state.deleting
  },
  errorLoadingProjects: () => {
    /*
    Check if loading project list caused errors
     */
    return state.errors.loading
  },
  errorLoadingProjectDetails: () => {
    /*
    Check if loading project details caused errors
     */
    return state.errors.loadingDetails
  },
  errorUpdatingProject: () => {
    /*
    Check if updating project details caused errors
     */
    return state.errors.updating
  },
  errorDeletingProject: () => {
    /*
    Check if deleting project details caused errors
     */
    return state.errors.deleting
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
