
<!--
Component to show records as a table in various reports
-->

<template>
  <div>
    <b-table
      :items="items"
      :fields="fields"
      responsive
      striped
    >
      <template v-slot:cell(duration)="row">
        {{ row.item.billed_duration | durationValue }}
        <span v-if="row.item.record_type.is_billable && row.item.record_type.category == 'travel'">
          *
        </span>
        <span v-if="containsNonBillable && !row.item.record_type.is_billable">
          *
        </span>
      </template>

      <template v-slot:cell(employee)="row">
        {{ row.item.employee.first_name }} {{ row.item.employee.last_name }}
      </template>

      <template v-slot:cell(task)="row">
        {{ row.item.task !== null ? row.item.task.name : '' }}
      </template>

      <template v-slot:cell(comments)="row">
        <div
          v-for="(comment, index) in row.item.comments"
          :key="index"
        >
          {{ comment }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'ReportedRecords',

  props: {
    containsBillableTravel: {
      type: Boolean,
      default: true
    },
    containsNonBillable: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(td.date) {
  width: 8rem;
}
:deep(d.duration) {
  width: 5rem;
  text-align: left;
}
:deep(td.employee) {
  width: 12rem;
  text-align: left;
}
</style>
