/*
Employee work summary report state
 */

import { orderBy } from 'lodash'

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadEmployeeWorkSummaries: ({ commit }, params = {}) => {
    /*
    Load list of employee work summaries
     */
    return helpers.loadListing(
      commit,
      'reports/employee-work-summaries',
      params,
      types.EMPLOYEE_WORK_SUMMARIES_LOAD,
      types.EMPLOYEE_WORK_SUMMARIES_LOAD_SUCCESS,
      types.EMPLOYEE_WORK_SUMMARIES_LOAD_ERROR
    )
  },
  loadEmployeeWorkSummaryDetails: ({ commit }, params = {}) => {
    /*
    Load work summary work single employee
     */
    return helpers.loadListing(
      commit,
      'reports/employee-work-summaries',
      params,
      types.EMPLOYEE_WORK_SUMMARY_DETAILS_LOAD,
      types.EMPLOYEE_WORK_SUMMARY_DETAILS_LOAD_SUCCESS,
      types.EMPLOYEE_WORK_SUMMARY_DETAILS_LOAD_ERROR
    )
  },
  resetEmployeeWorkSummaries: ({ commit }, params = {}) => {
    commit(types.EMPLOYEE_WORK_SUMMARIES_LOAD_RESET)
  }
}

const mutations = {
  [types.EMPLOYEE_WORK_SUMMARIES_LOAD_RESET] (state) {
    helpers.mutateResetData(state)
  },
  [types.EMPLOYEE_WORK_SUMMARIES_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.EMPLOYEE_WORK_SUMMARIES_LOAD_SUCCESS] (state, data) {
    data.forEach(record => {
      record.summaries.forEach(summary => {
        summary.projects.forEach(project => {
          project.duration = project.total_hours.hours * 60 + project.total_hours.minutes
        })
        summary.projects = orderBy(summary.projects, ['duration']).reverse()
      })
    })
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.EMPLOYEE_WORK_SUMMARIES_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  },
  [types.EMPLOYEE_WORK_SUMMARY_DETAILS_LOAD] (state) {
    helpers.mutateAPIDetailsLoad(state)
  },
  [types.EMPLOYEE_WORK_SUMMARY_DETAILS_LOAD_SUCCESS] (state, data) {
    if (data) {
      data = data[0]
    }
    helpers.mutateAPIDetailsLoaded(state, data)
  },
  [types.EMPLOYEE_WORK_SUMMARY_DETAILS_LOAD_ERROR] (state, error) {
    helpers.mutateAPIDetailsLoadFailed(state, error)
  }
}

const getters = {
  getEmployeeWorkSummaries: () => {
    return state.items
  },
  getEmployeeWorkSummaryDetails: () => {
    return state.details
  },
  loadingEmployeeWorkSummaries: () => {
    /*
    Check if employee work summaries list is loading
     */
    return state.loading
  },
  loadingEmployeeWorkSummaryDetails: () => {
    /*
    Check if employee work summaries list is loading
     */
    return state.loadingDetails
  },
  errorLoadingEmployeeWorkSummaries: () => {
    /*
    Check if loading employee work summaries list caused errors
     */
    return state.errors.loading
  },
  errorLoadingEmployeeWorkSummaryDetails: () => {
    /*
    Check if loading employee work summaries list caused errors
     */
    return state.errors.loadingDetails
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
