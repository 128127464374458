<!--
Field with a list of checkboxes
-->

<template>
  <div>
    <b-form-group
      :id="`form-group-${name}`"
      :label="name"
    >
      <b-form-checkbox-group
        :id="id"
        v-model="selected"
        :stacked="stacked"
        :size="size"
        :switches="switches"
        :disabled="disabled"
        :options="options"
        @change="$emit('input', $event)"
      />
    </b-form-group>
  </div>
</template>

<script>
import Field from './Field.vue'

export default {
  name: 'CheckboxGroupField',

  extends: Field,

  props: {
    name: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default: 'checkbox-group'
    },
    size: {
      type: String,
      default: undefined
    },
    switches: {
      type: Boolean,
      default: false
    },
    stacked: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      selected: [],
      options: [],
      values: {}
    }
  },

  methods: {

    loadData () {
      /*
      Load checkbox field values
       */
      this.options = []
      this.selected = []
      this.values = {}
      Object.values(this.field.inputs).forEach(input => {
        if (input.visible) {
          this.options.push({
            text: input.label,
            value: input.key
          })
          if (this.field.values[input.key]) {
            this.selected.push(input.key)
          }
          this.values[input.key] = this.field.values[input.key]
        }
      })
    },

    reloadFieldOptions () {
      /*
      Reload checkbox field opti visibility
       */
      this.loadData()
    },

    updateData (selected) {
      /*
      Update choices in checkboxes
       */
      Object.values(this.field.inputs).forEach(input => {
        if (input.visible) {
          let value = selected.indexOf(input.key) !== -1
          if (value !== this.values[input.key]) {
            this.values[input.key] = value
            this.$emit('update', 'update-value', this.field.name, input.key, value)
          }
        }
      })
    }

  }
}
</script>
