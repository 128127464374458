<!--
View to show a single employee contract period details in other components
-->

<template>
  <div>
    <b-row>
      <b-col>
        {{ description }}
      </b-col>
      <b-col>
        {{ dateRange(contract.start_date, contract.end_date) }}
      </b-col>
      <b-col>
        {{ percentage }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BaseComponent from '@/components/base/BaseComponent.vue'

export default {
  name: 'EmployeeContractPeriod',

  extends: BaseComponent,

  props: {
    employee: {
      type: Object,
      required: true
    },
    contract: {
      type: Object,
      required: true
    }
  },

  computed: {
    percentage () {
      /*
      Return percentage of work of fulltime employement included in this contract period
       */
      switch (this.contract.type) {
        case 'leave':
          return ''
        case 'studyleave':
          return ''
      }
      return this.contract.percentage !== null ? `${this.contract.percentage} %` : ''
    },

    description () {
      /*
      Return a description string based on contract period type

      TODO -  we should pick the description from API /timesheet/autocomplete/employment-contract-types
       */
      let description
      switch (this.contract.type) {
        case 'fulltime':
          description = 'Full time'
          break
        case 'parttime':
          description = 'Part time'
          break
        case 'leave':
          description = 'Personal leave'
          break
        case 'studyleave':
          description = 'Study leave'
          break
        case 'maternity-leave':
          description = 'Maternity leave'
          break
        default:
          description = this.contract.type
          break
      }
      return description
    }

  }
}
</script>
