<!--
Component to show box with period project summary details from PeriodWorkDetails
-->

<template>
  <div class="period-project-summary p-2">
    <div class="heading my-2">
      Projects
    </div>
    <div
      v-for="project in projectsOrderedByDuration"
      :key="project.project_code"
      class="project"
    >
      <div class="percentage">
        {{ project.percentage }} %
      </div>
      <div class="duration">
        {{ duration(project.total_hours) }}
      </div>
      <div class="project_code">
        {{ project.project_code }}
      </div>
      <div class="project_name">
        {{ project.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, orderBy } from 'lodash'

import BaseComponent from '@/components/base/BaseComponent.vue'

export default {
  name: 'PeriodProjectDetails',

  extends: BaseComponent,

  props: {
    employee: {
      type: Object,
      required: true
    },
    period: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    projects: {
      type: Array,
      required: true
    }
  },

  computed: {
    projectsOrderedByDuration () {
      /*
      Return projects ordered by total duration
       */
      let projects = cloneDeep(this.projects)
      projects.forEach(item => {
        item.duration = item.total_hours.hours * 60 + item.total_hours.minutes
      })
      projects = orderBy(projects, ['duration']).reverse()
      return projects
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/bootstrap_overrides.scss';
@import '../../assets/scss/theme_constants.scss';

div.period-project-summary {
  display: flex;
  flex-direction: column;
  min-width: 24rem;
  .heading {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .project {
    display: flex;
    flex-direction: row;
    width: 100%;
    .percentage {
      display: flex;
      min-width: 6rem;
      justify-content: flex-end;
      padding-right: 1rem;
    }
    .duration {
      display: flex;
      min-width: 7rem;
      justify-content: flex-end;
      padding-right: 1rem;
    }
    .project_code {
      display: flex;
      padding-right: 0.5rem;
    }
  }
}
</style>
