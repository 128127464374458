<!--
View to browse employee employee work summaries by year, month and week
-->

<template>
  <BrowsableListingView
    :parent="bus"
    :loading="loading"
    :error="error"
    :navigation-filters="navigationFilters"
    :navigation-actions="navigationActions"
    :fields="['summary']"
    :rows="rows"
    :search-fields="searchFields"
    :show-heading="false"
    title="Employee Work Summaries"
    :row-details-allowed="rowDetailsAllowed"
    @events="eventHandler"
  >
    <template slot="sub-nav-heading">
      <h5 class="crop-overflow">
        Work Summaries {{ periodStartDay() | monthString }}
      </h5>
    </template>

    <template v-slot:cell(summary)="row">
      <PeriodWorkSummary
        :parent="bus"
        :employee="row.item.employee"
        :period="period"
        :start-date="row.item.summaries[0].start"
        :end-date="row.item.summaries[0].end"
        :summary="row.item.summaries[0].summary"
        :missing-dates="row.item.summaries[0].missing_dates"
      />
    </template>

    <template v-slot:row-details="row">
      <PeriodWorkDetails
        :parent="bus"
        :employee="row.item.employee"
        :period="period"
        :start-date="row.item.summaries[0].start"
        :end-date="row.item.summaries[0].end"
      />
    </template>
  </BrowsableListingView>
</template>

<script>
import { mapGetters } from 'vuex'

import TimeRangeBrowser from '../../components/base/TimeRangeBrowser.vue'
import BrowsableListingView from '../../components/elements/BrowsableListingView.vue'
import PeriodWorkSummary from '@/components/report/PeriodWorkSummary.vue'
import PeriodWorkDetails from '@/components/report/PeriodWorkDetails.vue'

export default {
  name: 'EmployeeWorkSummaries',

  components: {
    BrowsableListingView,
    PeriodWorkDetails,
    PeriodWorkSummary
  },

  extends: TimeRangeBrowser,

  data () {
    return {
      initializing: true,
      defaultPeriod: 'month',
      searchFields: [],
      rows: []
    }
  },

  computed: {
    ...mapGetters([
      'getEmployeeWorkSummaries',
      'loadingEmployeeWorkSummaries',
      'errorLoadingEmployeeWorkSummaries'
    ]),
    loading () {
      return this.loadingEmployeeWorkSummaries
    },
    error () {
      return this.errorLoadingEmployeeWorkSummaries
    },
    navigationFilters  () {
      return [
        {
          key: 'previous',
          label: 'Previous',
          callback: this.showPreviousPeriod
        },
        {
          key: 'next',
          label: 'Next',
          callback: this.showNextPeriod
        }
      ]
    }
  },

  methods: {

    rowDetailsAllowed (row) {
      /*
      Checks if user is allowed to show details

      Allows HR and Finance to see all details and employee see their own details
       */
      return this.isHRUser || this.isFinanceUser || row.employee.email === this.loggedInEmployeeEmail
    },

    eventHandler (action, ...args) {
      switch (action) {
        case 'row-clicked': {
          this.bus.$emit('events', 'row-clicked', args[0].email)
          break
        }
      }
    },

    loadRecords () {
      /*
      Note: this API is used in nested components, you need to assing
      data here
       */
      let params = this.getDateQueryArgs()
      this.$store.dispatch('resetEmployeeWorkSummaries')
      this.$store.dispatch('loadEmployeeWorkSummaries', params)
        .then((records) => {
          this.rows = records.filter(
            record => record.summaries.length > 0
          )
          this.rows.forEach(record => {
            record.employee = {
              email: record.email,
              name: `${record.first_name} ${record.last_name}`
            }
          })
        })
    }
  }

}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/bootstrap_overrides.scss';
@import '../../assets/scss/theme_constants.scss';

:deep(table-body) {
  tr {
    &:focus {
      outline: none;
      background-color: $gray-6;
    }
  }
  td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0px;
    padding-right: 0px;
  }
}
:deep(.b-table-has-details) {
  background-color: $gray-6;
  td {
    border-bottom: none;
  }
}
:deep(.b-table-details) {
  td {
    margin: 0;
    padding: 0;
    background-color: $gray-6;
  }
}
</style>
