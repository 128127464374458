/*
Employee billing rate summary report state
 */

import { formatDurationString, getTotalDuration } from '../../../components/utils/timeUtils'
import { orderBy, sumBy } from 'lodash'

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadEmployeeBillingRates: ({ commit }, params = {}) => {
    return helpers.loadListing(
      commit,
      'reports/employee-billing-rate',
      params,
      types.EMPLOYEE_BILLING_RATES_LOAD,
      types.EMPLOYEE_BILLING_RATES_LOAD_SUCCESS,
      types.EMPLOYEE_BILLING_RATES_LOAD_ERROR
    )
  },
  resetEmployeeBillingRates: ({ commit }, params = {}) => {
    commit(types.EMPLOYEE_BILLING_RATES_LOAD_RESET)
  }
}

const mutations = {
  [types.EMPLOYEE_BILLING_RATES_LOAD_RESET] (state) {
    helpers.mutateResetData(state)
  },
  [types.EMPLOYEE_BILLING_RATES_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.EMPLOYEE_BILLING_RATES_LOAD_SUCCESS] (state, data) {
    /*
    Group received items by employee
     */
    data.forEach(item => {
      item.key = `${item.project_code}-${item.billable ? 'billable' : 'non-billable'}`
      item.name = `${item.first_name} ${item.last_name}`
      return item
    })
    Object.values(data).forEach(item => {
      // Order employee projects by percent
      item.projects = orderBy(item.projects, ['billable', 'percent']).reverse()
      // Calculate total percentage of billable hours
      item.billing_rate = parseInt(sumBy(item.projects.filter(p => p.billable), 'percent'))
      // Calculate a total number of hours for each item
      item.total_hours = formatDurationString(getTotalDuration(item.projects.map(p => p.hours)))
    })
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.EMPLOYEE_BILLING_RATES_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  }
}

const getters = {
  getEmployeeBillingRates: () => {
    return state.items
  },
  loadingEmployeeBillingRates: () => {
    /*
    Check if employee billing rate list is loading
     */
    return state.loading
  },
  errorLoadingEmployeeBillingRates: () => {
    /*
    Check if loading employee billing rate list caused errors
     */
    return state.errors.loading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
