<!--
Multi line textarea text input component for descriptions and similar long multiline strings
-->

<template>
  <div>
    <field-legend
      :id="field.name"
      :label="field.inputs[field.name].label"
      :description="field.inputs[field.name].description"
      :tooltip-variant="tooltipVariant"
      :tooltip-placement="tooltipPlacement"
    />
    <b-form-textarea
      v-model="value"
      class="form-inline"
      :name="`input-${field.name}`"
      :autofocus="autofocus"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="$emit('input', field.name, $event)"
    />
    <div
      v-if="error"
      class="field-validation-error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import TextField from './TextField.vue'

export default {
  name: 'TextAreaField',

  extends: TextField
}
</script>
