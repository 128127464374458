/*
Project member state
 */

import { concat, orderBy } from 'lodash'
import { parse } from 'date-fns'

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadProjectMembers: ({ commit }, params = {}) => {
    /*
     Load project members
     */
    return helpers.loadListing(
      commit,
      'timesheet/project-members',
      params,
      types.PROJECT_MEMBER_LIST_LOAD,
      types.PROJECT_MEMBER_LIST_LOAD_SUCCESS,
      types.PROJECT_MEMBER_LIST_LOAD_ERROR
    )
  },
  loadProjectMemberDetails: ({ commit }, id = {}) => {
    /*
     Load details for a project member
    */
    return helpers.loadDetails(
      commit,
      `timesheet/project-members/${id}`,
      types.PROJECT_MEMBER_DETAILS_LOAD,
      types.PROJECT_MEMBER_DETAILS_LOAD_SUCCESS,
      types.PROJECT_MEMBER_DETAILS_LOAD_ERROR
    )
  },
  createProjectMember: ({ commit }, params = {}) => {
    /*
    Create a project member record
     */
    return helpers.postRecord(
      commit,
      'timesheet/project-members',
      params,
      types.PROJECT_MEMBER_CREATE,
      types.PROJECT_MEMBER_CREATE_SUCCESS,
      types.PROJECT_MEMBER_CREATE_ERROR
    )
  },
  updateProjectMember: ({ commit }, params = {}) => {
    /*
     Update existing project member
     */
    return helpers.updateRecord(
      commit,
      `timesheet/project-members/${params.id}`,
      params,
      types.PROJECT_MEMBER_UPDATE,
      types.PROJECT_MEMBER_UPDATE_SUCCESS,
      types.PROJECT_MEMBER_UPDATE_ERROR
    )
  },
  deleteProjectMember: ({ commit }, params = {}) => {
    /*
     Delete existing project member
     */
    return helpers.deleteRecord(
      commit,
      `timesheet/project-members/${params.id}`,
      types.PROJECT_MEMBER_DELETE,
      types.PROJECT_MEMBER_DELETE_SUCCESS,
      types.PROJECT_MEMBER_DELETE_ERROR
    )
  },
  resetProjectMembers: ({ commit }, params = {}) => {
    commit(types.PROJECT_MEMBER_LIST_RESET)
  }
}

const mutations = {
  [types.PROJECT_MEMBER_LIST_RESET] (state) {
    helpers.mutateResetData(state)
  },
  [types.PROJECT_MEMBER_LIST_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.PROJECT_MEMBER_LIST_LOAD_SUCCESS] (state, data) {
    data.forEach(item => {
      // Add parsed start and end times to data
      item.starts = item.start_date !== null ? parse(item.start_date) : null
      item.ends = item.end_date !== null ? parse(item.end_date) : null
      // Format employee full name
      item.employee.name = `${item.employee.first_name} ${item.employee.last_name}`
    })
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.PROJECT_MEMBER_LIST_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  },
  [types.PROJECT_MEMBER_DETAILS_LOAD] (state) {
    helpers.mutateAPIDetailsLoad(state)
  },
  [types.PROJECT_MEMBER_DETAILS_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIDetailsLoaded(state, data)
  },
  [types.PROJECT_MEMBER_DETAILS_LOAD_ERROR] (state, error) {
    helpers.mutateAPIDetailsLoadFailed(state, error)
  },
  [types.PROJECT_MEMBER_CREATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.PROJECT_MEMBER_CREATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.PROJECT_MEMBER_CREATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.PROJECT_MEMBER_UPDATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.PROJECT_MEMBER_UPDATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.PROJECT_MEMBER_UPDATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.PROJECT_MEMBER_DELETE] (state, error) {
    helpers.mutateItemDeleting(state)
  },
  [types.PROJECT_MEMBER_DELETE_SUCCESS] (state, error) {
    helpers.mutateItemDeleted(state)
  },
  [types.PROJECT_MEMBER_DELETE_ERROR] (state, error) {
    helpers.mutateItemDeleteFailed(state)
  }
}

const getters = {
  getProjectMembers: () => {
    /*
    Return project members, grouping memberships by employee
     */
    let members = {}
    let visible
    let now = new Date()
    state.items.forEach(member => {
      if (!(member.employee.email in members)) {
        members[member.employee.email] = {
          employee: member.employee,
          memberships: []
        }
      }
      members[member.employee.email].memberships = concat(
        members[member.employee.email].memberships,
        member
      )
    })
    // Group memberships by employee as list
    members = orderBy(
      Object.values(members),
      ['employee.first_name', 'employee.last_name']
    )
    // For each  member, find active or latest membership
    members.forEach(member => {
      visible = undefined
      member.memberships.forEach(item => {
        if (visible) {
          if (item.starts > visible.starts) {
            visible = item
          }
        } else {
          visible = item
        }
      })
      let active = member.memberships.find(item => {
        if (item.starts === null && item.ends === null) {
          return item
        }
        if (item.starts <= now && (item.ends === null || item.ends > now)) {
          return item
        }
      })
      member.visible = active !== undefined ? active : visible
      // Order memberships by start date
      member.memberships = orderBy(member.memberships, ['starts'])
    })
    return members
  },
  loadingProjectMembers: () => {
    /*
    Check if project member list is loading
     */
    return state.loading
  },
  loadingProjectMemberDetails: () => {
    /*
    Check if project member details are loading
     */
    return state.loadingDetails
  },
  updatingProjectMemberDetails: () => {
    /*
    Check if project member record is being updated
     */
    return state.updating
  },
  deletingProjectMemberDetails: () => {
    /*
    Check if project member record is being deleted
     */
    return state.deleting
  },
  errorLoadingProjectMembers: () => {
    /*
    Check if loading project member list caused errors
     */
    return state.errors.loading
  },
  errorLoadingProjectMemberDetails: () => {
    /*
    Check if loading project member details caused errors
     */
    return state.errors.loadingDetails
  },
  errorUpdatingProjectMember: () => {
    /*
    Check if updating project member details caused errors
     */
    return state.errors.updating
  },
  errorDeletingProjectMember: () => {
    /*
    Check if deleting project member details caused errors
     */
    return state.errors.deleting
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
