<!--
Component for date range entry with start and end dates
-->

<template>
  <b-row class="align-top">
    <b-col
      sm="6"
      class="date"
    >
      <field-legend
        :id="range_start_label"
        :label="range_start_label"
        :description="range_start_description"
        :tooltip-variant="tooltipVariant"
        :tooltip-placement="tooltipPlacement"
      />
      <b-form-datepicker
        v-model="range_start"
        :class="{ 'form-inline': true, 'validation-error': error}"
        name="input-range_start"
        size="sm"
        start-weekday="1"
        locale="fi"
        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
        :autofocus="autofocus"
        :disabled="disabled"
        :reset-button="true"
        @keyup.enter="save"
        @input="$emit('input', field.inputs['range_start'].key, $event)"
      />
      <div
        v-if="range_start_error"
        class="field-validation-error"
      >
        {{ range_start_error }}
      </div>
    </b-col>
    <b-col
      sm="6"
      class="date"
    >
      <field-legend
        :id="range_end_label"
        :label="range_end_label"
        :description="range_end_description"
        :tooltip-variant="tooltipVariant"
        :tooltip-placement="tooltipPlacement"
      />
      <b-form-datepicker
        v-model="range_end"
        :class="{ 'form-inline': true, 'validation-error': error}"
        name="input-range_end"
        size="sm"
        start-weekday="1"
        locale="fi"
        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
        :disabled="disabled"
        :reset-button="true"
        @keyup.enter="save"
        @input="$emit('input', field.inputs['range_end'].key, $event)"
      />
      <div
        v-if="range_end_error"
        class="field-validation-error"
      >
        {{ range_end_error }}
      </div>
    </b-col>
    <b-col
      v-if="range_error"
      class="mb-2 field-validation-error"
    >
      {{ range_error }}
    </b-col>
  </b-row>
</template>

<script>

import dayjs from 'dayjs'
import RangeField from './RangeField.vue'
import { validateDateField, validateDateRangeField } from '../validators'

export default {
  name: 'DateRangeField',

  mixins: [
    RangeField
  ],

  data: function () {
    return {
      defaultValidators: [
        validateDateField,
        validateDateRangeField
      ]
    }
  },

  methods: {

    loadData () {
      /*
      Load range start and end values from configurable keys for field
       */
      this.range_start = this.formatValue(
        this.field.inputs['range_start'].key,
        this.field.values[this.field.inputs['range_start'].key]
      )
      this.range_end = this.formatValue(
        this.field.inputs['range_end'].key,
        this.field.values[this.field.inputs['range_end'].key]
      )
    },

    checkIncompleteValue (key, value) {
      /*
      Check if year makes sense for validation purposes
       */
      let pending = false
      let date = dayjs(value, this.$apiDateFormat, true)
      if (date.isValid()) {
        let year = date.year()
        // Check year makes some sense (not being entered), set pending if not
        if (year < 1900 || year > 2100) {
          pending = true
        }
      }
      return pending
    }

  }
}
</script>
