<!--
Common base component to use with base view, to avoid duplicating common properties
-->

<template>
  <div />
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { formatDateRange, formatDurationString, durationDeltaInMinutes } from '@/components/utils/timeUtils'
import { isEmpty } from 'lodash'

export default {
  name: 'BaseComponent',

  data: function () {
    return {
      bus: undefined
    }
  },

  computed: {
    /*
    Following getters must be available on each and every page for basic logic
     */
    ...mapGetters([
      'loggedInEmployee',
      'loggedInEmployeeEmail',
      'isAdminUser',
      'isFinanceUser',
      'isHRUser',
      'isInternal',
      'isContractor'
    ]),
    periodTitle () {
      /*
      Return title for time period
       */
      switch (this.period) {
        case 'week': {
          return 'Week'
        }
        case 'month': {
          return `Month`
        }
        case 'year': {
          return 'Year'
        }
        default: {
          return ''
        }
      }
    }
  },

  created () {
    if (this.bus === undefined) {
      this.bus = new Vue()
    }
  },

  methods: {
    duration (value) {
      /*
      Return duration as a string based on if there are hours and minutes

      Value must be object with hours and minutes
      */
      if (isEmpty(value)) {
        return '-'
      }
      let hours = value.hours && value.hours > 0 ? value.hours : undefined
      let minutes = value.minutes && value.minutes > 0 ? value.minutes : undefined
      if (hours !== undefined && minutes !== undefined) {
        return `${hours} h ${minutes} min`
      } else if (hours) {
        return `${hours} h`
      } else if (minutes) {
        return `${minutes} min`
      } else {
        return '-'
      }
    },

    durationDelta (a, b) {
      /*
      Return delta between two values as a duration string
       */
      return formatDurationString(durationDeltaInMinutes(a, b), { showSign: true })
    },

    dateRange (startDate, endDate) {
      /*
      Return date range as formatted string
      */
      return formatDateRange(startDate, endDate)
    }

  }

}
</script>
