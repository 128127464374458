<!--
Date form field
-->

<template>
  <div>
    <field-legend
      :id="field.name"
      :label="field.inputs[field.name].label"
      :description="field.inputs[field.name].description"
      :tooltip-variant="tooltipVariant"
      :tooltip-placement="tooltipPlacement"
    />
    <b-form-input
      v-model="value"
      :class="{ 'form-inline': true, 'validation-error': error }"
      :name="`input-${field.name}`"
      :autofocus="autofocus"
      :disabled="disabled"
      :required="required"
      type="date"
      @keyup.enter="save"
      @input="$emit('input', field.name, $event)"
    />
    <div
      v-if="error"
      class="field-validation-error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import Field from './Field.vue'
import { validateDateField } from '../validators'

export default {
  name: 'DateField',

  extends: Field,

  data: function () {
    return {
      defaultValidators: [
        validateDateField
      ]
    }
  }

}
</script>
