<!--
Single line number field form component with optional autocomplete
-->

<template>
  <div>
    <field-legend
      :id="field.name"
      :label="field.inputs[field.name].label"
      :description="field.inputs[field.name].description"
      :tooltip-variant="tooltipVariant"
      :tooltip-placement="tooltipPlacement"
    />
    <b-input-group>
      <b-form-input
        v-model="value"
        :class="{ 'form-inline': true, 'validation-error': error }"
        :name="`input-${field.name}`"
        :placeholder="placeholder"
        :disabled="disabled"
        type="number"
        size="4"
        :min="field.minimum"
        :max="field.maximum"
        @keyup.enter="save"
        @input="$emit('input', field.name, $event)"
      />
      <b-input-group-text
        v-if="typeIndicator"
        slot="append"
        class="cursor-pointer"
        @click="toggleType"
      >
        {{ typeIndicator }}
      </b-input-group-text>
    </b-input-group>
    <div
      v-if="error"
      class="field-validation-error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import Field from './Field.vue'
import { validateIntegerField } from '../validators'
export default {
  name: 'NumberField',

  extends: Field,

  props: {
    placeholder: {
      type: String,
      default: ''
    },
    typeIndicator: {
      type: String,
      default: undefined
    }
  },

  data: function () {
    return {
      defaultValidators: [
        validateIntegerField
      ]
    }
  },

  methods: {
    toggleType () {
      this.$emit('toggleType')
    },

    checkIncompleteValue (key, value) {
      /*
      Do not try to validate empty number values

      Currently just checks for empty string, extend as needed
       */
      if (value === '') {
        return true
      }
      return false
    }

  }

}
</script>
