/*
Projects employee can join
 */

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadEmployeeJoinableProjects: ({ commit }, params = {}) => {
    /*
     Load projects logged in employee can join
     */
    return helpers.loadListing(
      commit,
      'timesheet/autocomplete/joinable-projects',
      params,
      types.JOINABLE_PROJECTS_LOAD,
      types.JOINABLE_PROJECTS_LOAD_SUCCESS,
      types.JOINABLE_PROJECTS_LOAD_ERROR
    )
  }
}

const mutations = {
  [types.JOINABLE_PROJECTS_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.JOINABLE_PROJECTS_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.JOINABLE_PROJECTS_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  }
}

const getters = {
  getJoinableProjects: (state) => {
    /*
    Return all employee joinable projects
    */
    return state.items
  },
  loadingEmployeeJoinableProjects: () => {
    /*
    Check if employee joinable project list is loading
     */
    return state.loading
  },
  errorLoadingEmployeeJoinableProjects: () => {
    /*
    Check if loading employee joinable project list caused errors
     */
    return state.errors.loading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
