/*
Project task state
 */

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadProjectTasks: ({ commit }, params = {}) => {
    /*
    Load project tasks
    */
    return helpers.loadListing(
      commit,
      'timesheet/project-tasks',
      params,
      types.PROJECT_TASK_LIST_LOAD,
      types.PROJECT_TASK_LIST_LOAD_SUCCESS,
      types.PROJECT_TASK_LIST_LOAD_ERROR
    )
  },
  createProjectTask: ({ commit }, params = {}) => {
    /*
    Create a project task record
     */
    return helpers.postRecord(
      commit,
      'timesheet/project-tasks',
      params,
      types.PROJECT_TASK_CREATE,
      types.PROJECT_TASK_CREATE_SUCCESS,
      types.PROJECT_TASK_CREATE_ERROR
    )
  },
  updateProjectTask: ({ commit }, params = {}) => {
    /*
     Update existing project task
     */
    return helpers.updateRecord(
      commit,
      `timesheet/project-tasks/${params.id}`,
      params,
      types.PROJECT_TASK_UPDATE,
      types.PROJECT_TASK_UPDATE_SUCCESS,
      types.PROJECT_TASK_UPDATE_ERROR
    )
  },
  deleteProjectTask: ({ commit }, params = {}) => {
    /*
     Delete existing project task
     */
    return helpers.deleteRecord(
      commit,
      `timesheet/project-tasks/${params.id}`,
      types.PROJECT_TASK_DELETE,
      types.PROJECT_TASK_DELETE_SUCCESS,
      types.PROJECT_TASK_DELETE_ERROR
    )
  },
  resetProjectTasks: ({ commit }, params = {}) => {
    commit(types.PROJECT_TASK_LIST_RESET)
  }
}

const mutations = {
  [types.PROJECT_TASK_LIST_RESET] (state) {
    helpers.mutateResetData(state)
  },
  [types.PROJECT_TASK_LIST_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.PROJECT_TASK_LIST_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.PROJECT_TASK_LIST_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  },
  [types.PROJECT_TASK_CREATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.PROJECT_TASK_CREATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.PROJECT_TASK_CREATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.PROJECT_TASK_UPDATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.PROJECT_TASK_UPDATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.PROJECT_TASK_UPDATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.PROJECT_TASK_DELETE] (state, error) {
    helpers.mutateItemDeleting(state)
  },
  [types.PROJECT_TASK_DELETE_SUCCESS] (state, error) {
    helpers.mutateItemDeleted(state)
  },
  [types.PROJECT_TASK_DELETE_ERROR] (state, error) {
    helpers.mutateItemDeleteFailed(state)
  }
}

const getters = {
  getProjectTasks: (state) => {
    return state.items
  },
  hasProjectTasks: (state) => {
    return state.items.length > 0
  },
  loadingProjectTasks: () => {
    /*
    Check if project task list is loading
     */
    return state.loading
  },
  loadingProjectTaskDetails: () => {
    /*
    Check if project task details are loading
     */
    return state.loadingDetails
  },
  updatingProjectTaskDetails: () => {
    /*
    Check if project task record is being updated
     */
    return state.updating
  },
  deletingProjectTaskDetails: () => {
    /*
    Check if project task record is being deleted
     */
    return state.deleting
  },
  errorLoadingProjectTasks: () => {
    /*
    Check if loading project task list caused errors
     */
    return state.errors.loading
  },
  errorLoadingProjectTaskDetails: () => {
    /*
    Check if loading project task details caused errors
     */
    return state.errors.loadingDetails
  },
  errorUpdatingProjectTask: () => {
    /*
    Check if updating project task details caused errors
     */
    return state.errors.updating
  },
  errorDeletingProjectTask: () => {
    /*
    Check if deleting project task details caused errors
     */
    return state.errors.deleting
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
