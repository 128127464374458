<!--
Main navigation bar with menus
-->

<template>
  <div
    class="my-auto px-0"
  >
    <b-navbar
      v-if="isAuthenticated && !isUnconfigured"
      toggleable="sm"
      type="dark"
      variant="info"
    >
      <b-navbar-brand>
        <router-link
          :to="{
            name: 'employee-hours',
            params: {},
            query: {
              period: 'week'
            }
          }"
        >
          Hours
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle
        target="nav-text-collapse"
        class="ml-auto"
      />

      <b-collapse
        id="nav-text-collapse"
        is-nav
      >
        <b-navbar-nav
          fill
        >
          <b-nav-item
            v-if="isAdminUser || isHRUser || isFinanceUser"
            :to="{
              name: 'employee-list',
              params: {},
              query: { is_active: true }
            }"
          >
            Employees
          </b-nav-item>

          <b-nav-item
            v-if="isInternal"
            :to="{
              name: 'project-list',
              params: {},
              query: { is_archived: false }
            }"
          >
            Projects
          </b-nav-item>

          <b-nav-item-dropdown
            v-if="isInternal"
            text="Reports"
          >
            <b-dropdown-item :to="{ name: 'employee-reported-hours', params: {}}">
              Daily Reported Hours
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'employee-billing-rates', params: {}}">
              Employee Billing Rates
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'employee-work-summaries', params: {}}">
              Employee Work Summaries
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'monthly-billing-reports', params: {}}">
              Monthly Billing Reports
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <b-navbar-nav
          fill
          class="ml-auto"
        >
          <b-nav-item
            :to="{name: 'Logout'}"
            title="logout"
          >
            <i
              class="fa fa-sign-out"
              aria-hidden="true"
            /> Logout
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavBar',

  computed: {
    ...mapGetters([
      'isAuthenticated',
      'isUnconfigured',
      'loggedInEmployeeEmail',
      'loggedInEmployee',
      'getFullName',
      'isAdminUser',
      'isHRUser',
      'isFinanceUser',
      'isInternal'
    ])
  }
}
</script>
