<!--
Form to edit timesheet record
-->

<template>
  <div>
    <b-form
      v-if="!loadingFormData"
      @keyup.esc="cancelClicked"
    >
      <b-row>
        <b-col
          sm="1"
          class="text-center"
        >
          <font-awesome-icon icon="wrench" />
        </b-col>
        <b-col>
          <dropdown-field
            :id="fields.project_code.label"
            :parent="bus"
            :field="fields.project_code"
            :disabled="processing"
            :autofocus="true"
            :options="fields.project_code.options"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row
        class="text-center justify-content-start mt-2"
      >
        <b-col
          sm="1"
          class="text-center"
        >
          <font-awesome-icon icon="clock" />
        </b-col>

        <b-col
          v-if="timeFormat === 'time'"
          sm="6"
        >
          <time-range-field
            class="justify-content-start"
            :parent="bus"
            :field="fields.time_range"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>

        <b-col
          v-if="timeFormat === 'duration'"
          sm="3"
        >
          <time-field
            class="justify-content-start"
            :parent="bus"
            :field="fields.duration"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="projectHasTasks"
        class="mt-2"
      >
        <b-col
          sm="1"
          class="text-center"
        >
          <font-awesome-icon icon="tasks" />
        </b-col>
        <b-col>
          <dropdown-field
            :parent="bus"
            :field="fields.task"
            :disabled="processing"
            :options="fields.task.options"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col
          sm="1"
          class="text-center"
        >
          <font-awesome-icon icon="comment-alt" />
        </b-col>
        <b-col>
          <multi-line-text-field
            :parent="bus"
            :field="fields.comments"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <FormButtons
        :width="6"
        :processing="processing"
        :can-cancel="canCancel"
        :can-delete="canDelete"
        :can-save="canSave"
        :cancel-disabled="cancelDisabled"
        :delete-disabled="deleteDisabled"
        :save-disabled="saveDisabled"
        @events="updateField"
      >
        <template
          slot="button-row-items"
        >
          <b-col
            sm="1"
            class="text-center mt-2"
          >
            <font-awesome-icon icon="euro-sign" />
          </b-col>
          <b-col
            class="mt-2"
          >
            <dropdown-field
              :parent="bus"
              :field="fields.record_type"
              :disabled="processing || !projectCode"
              :options="fields.record_type.options"
              @update="updateField"
            />
          </b-col>
        </template>
      </FormButtons>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format, parse } from 'date-fns'
import { isEmpty } from 'lodash'

import Form from '@/forms/Form.vue'
import FormButtons from '@/forms/FormButtons.vue'

export default {
  name: 'TimesheetRecordEditForm',

  components: {
    FormButtons
  },

  mixins: [
    Form
  ],

  props: {
    day: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      // Store actions to dispatch
      actions: {
        create: 'createTimesheetRecord',
        update: 'updateTimesheetRecord',
        delete: 'deleteTimesheetRecord'
      },
      // Toggle between duration and time
      timeFormat: 'duration',
      // Project selected from project options
      project: undefined,
      // All form fields
      'formFields': {
        id: {
          label: 'Record ID'
        },
        date: {
          label: 'Date of Record',
          inputs: {
            date: {
              label: 'Date'
            }
          },
          required: true
        },
        email: {
          label: 'Employee',
          inputs: {
            email: {
              label: 'Employee'
            }
          },
          required: true,
          loader: function (record) {
            return record.employee.email
          }
        },
        project_code: {
          label: 'Project Code',
          required: true,
          options: []
        },
        record_type: {
          label: 'Timesheet Record Type',
          required: true,
          options: []
        },
        time_range: {
          label: 'Times',
          inputs: {
            range_start: {
              key: 'start'
            },
            range_end: {
              key: 'end'
            }
          }
        },
        duration: {
          label: 'Duration of Work'
        },
        task: {
          label: 'Project Task',
          loader: function (record) {
            return record.task !== null ? record.task.id : undefined
          },
          options: []
        },
        comments: {
          label: 'Comment'
        }
      }
    }
  },

  computed: {
    // Add getters for data and data load status
    ...mapGetters([
      'loggedInEmployee',
      'preferredTimeFormat',
      'getEmployeeProjects',
      'getRecentEmployeeProjects',
      'getEmployeeNonRecentProjects',
      'getProjectTasks',
      'getTimesheetRecordTypeChoices'
    ]),
    loadingOptions () {
      /*
      Check additional form options getters and load form when ready
       */
      return false
    },
    projectCode () {
      if (this.fields.project_code.values.project_code !== undefined) {
        return this.fields.project_code.values.project_code
      } else {
        return undefined
      }
    },
    projectHasTasks () {
      /*
      Checks if project has any tasks
       */
      if (this.project !== undefined) {
        return this.getProjectTasks.filter(task => task.project === this.project.project_code).length > 0
      } else {
        return false
      }
    },
    canDelete () {
      /*

       */
      return (!isEmpty(this.record) && !this.record.is_locked)
    }
  },

  watch: {
    'project' () {
      /*
      Check for errors in selecting project
       */
      this.setValidationError('project_code', 'project_code', undefined)
      if (this.project !== undefined) {
        let date = parse(this.fields.date.values.date)
        if (this.project.is_locked) {
          this.setValidationError('project_code', 'project_code', 'Project is locked')
        }
        if (this.project.is_archived) {
          this.setValidationError('project_code', 'project_code', 'Project has been archived')
        }
        if (this.project.end_date !== null) {
          let endDate = parse(this.project.end_date)
          if (date > endDate) {
            this.setValidationError('project_code', 'project_code', `Project has ended on ${format(endDate, this.$dateFormat)}`)
          }
        }
      }
    },
    'fields.project_code.values.project_code' () {
      /*
      Selected project changed
       */
      if (this.fields.project_code.values.project_code !== undefined) {
        this.loadProjectDetails()
      }
    },
    'fields.time_range.values.start' () {
      if (!isEmpty(this.fields.time_range.values.start)) {
        if (this.fields.duration.values.duration !== undefined) {
          this.setValue('duration', 'duration', undefined)
        }
      }
    },
    'fields.time_range.values.end' () {
      if (!isEmpty(this.fields.time_range.values.end)) {
        if (this.fields.duration.values.duration !== undefined) {
          this.setValue('duration', 'duration', undefined)
        }
      }
    },
    'fields.duration.values.duration' () {
      if (!isEmpty(this.fields.duration.values.duration)) {
        if (this.fields.time_range.values.start !== undefined) {
          this.setValue('time_range', 'start', undefined)
        }
        if (this.fields.time_range.values.end !== undefined) {
          this.setValue('time_range', 'end', undefined)
        }
      }
    },
    'timeFormat' () {
      switch (this.timeFormat) {
        case 'duration': {
          this.setRequiredField('duration', true)
          this.setRequiredField('time_range', false)
          if (this.fields.time_range.values.start !== undefined) {
            this.setValue('time_range', 'start', undefined)
          }
          if (this.fields.time_range.values.end !== undefined) {
            this.setValue('time_range', 'end', undefined)
          }
          break
        }
        case 'time': {
          this.setRequiredField('time_range', true)
          this.setRequiredField('duration', false)
          if (this.fields.duration.values.duration !== undefined) {
            this.setValue('duration', 'duration', undefined)
          }
          break
        }
      }
    },
    'record' () {
      if (!this.loadingFormData) {
        this.loadForm()
      }
      if (isEmpty(this.record)) {
        this.resetForm()
        this.setStaticFields()
        this.parent.$emit('reset')
      }
      this.detectTimeFormat()
    }
  },

  mounted () {
    // When form is ready, scroll it to viewport center
    this.$nextTick(() => {
      this.detectTimeFormat()
    })
  },

  methods: {
    detectTimeFormat () {
      if (!isEmpty(this.record)) {
        if (this.record.start !== null) {
          this.timeFormat = 'time'
        } else {
          this.timeFormat = 'duration'
        }
      } else {
        this.timeFormat = this.preferredTimeFormat
      }
      switch (this.timeFormat) {
        case 'time': {
          this.parent.$emit('reload', 'time-range')
          break
        }
        case 'duration': {
          this.parent.$emit('reload', 'duration')
          break
        }
      }
    },

    setStaticFields () {
      /*
      Set values for fields that never change
       */
      this.setValue('date', 'date', format(this.day.date, this.$apiDateFormat))
      this.setValue('email', 'email', this.loggedInEmployee.email)
    },

    loadFormOptions () {
      /*
      Load any options for dropdown fields
       */
      this.updateProjectChoices()
      this.setStaticFields()
    },

    toggleClicked () {
      /*
      Toggle time entry format between 'time' and 'duration'
       */
      this.timeFormat = this.timeFormat === 'duration' ? 'time' : 'duration'
    },

    updateProjectChoices () {
      /*
      Project was changed in form. Load project specific data:
      - project record (for date limits)
      - record type options
      - project task choices

      Groups recent and other (no recent hours) projects for v-select
      */
      let choices = []

      // Add recent projects to choices
      choices = choices.concat(
        this.getRecentEmployeeProjects.map(project => ({
          id: project.project_code,
          label: `${project.project_code} ${project.name}`
        }))
      )

      // TODO - Add divider / heading (v-select does not seem to have direct support for this)

      // Add other projects
      choices = choices.concat(
        this.getEmployeeNonRecentProjects.map(project => ({
          id: project.project_code,
          label: `${project.project_code} ${project.name}`
        }))
      )
      this.updateOptions('project_code', choices)
    },

    loadProjectDetails () {
      /*
      Load details for project after project selection
       */
      let $vm = this
      this.$store.dispatch('loadProjectDetails', this.projectCode)
        .then((data) => {
          $vm.project = data
          $vm.updateProjectTaskChoices()
          $vm.updateRecordtypeChoices()
        })
    },

    updateProjectTaskChoices () {
      /*
      Update available project tasks
       */
      let $vm = this
      if (this.project) {
        this.$store.dispatch('loadProjectTasks', {
          project_code: this.project.project_code
        })
          .then((response) => {
            if ($vm.getProjectTasks.find((task) => $vm.project.project_code === task.project) === undefined) {
              $vm.fields.task.values.task = null
            }
            // Note: tasks have both label and name, we want to show name in dropdown
            let taskSelections = $vm.getProjectTasks.map(task => {
              return {
                id: task.id,
                label: !isEmpty(task.name) ? task.name : task.label
              }
            })
            $vm.updateOptions('task', taskSelections)
          })
      } else {
        this.fields.task.values.task = null
        this.updateOptions('task', [])
      }
    },

    updateRecordtypeChoices () {
      /*
      Update available project tasks
       */
      let $vm = this
      if (this.project !== undefined) {
        this.$store.dispatch('loadTimesheetRecordTypeChoices', {
          project_code: this.projectCode
        })
          .then(() => {
            let selected
            if (!isEmpty($vm.fields.record_type.values.record_type)) {
              // Check if selected record type is valid
              selected = $vm.getTimesheetRecordTypeChoices.find(item =>
                item.id === $vm.fields.record_type.values.record_type.name
              )
              if (selected === undefined) {
                selected = $vm.getDefaultRecordType()
              }
            } else {
              selected = $vm.getDefaultRecordType()
            }
            $vm.fields.record_type.values.record_type = selected.id
            $vm.updateOptions('record_type', $vm.getTimesheetRecordTypeChoices)
          })
      }
    },

    getDefaultRecordType () {
      if (this.project !== undefined) {
        if (this.project.is_billable) {
          return this.getTimesheetRecordTypeChoices.find(function (item) {
            return item.is_billable && item.category === 'work'
          })
        } else {
          return this.getTimesheetRecordTypeChoices.find(function (item) {
            return !item.is_billable && item.category === 'work'
          })
        }
      } else {
        return undefined
      }
    },

    updateTimeSelectors () {
      /*
      Update time selectors

      A record can have either start / end time range or duration, not both
       */
    }

  }
}
</script>
