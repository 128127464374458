<!--
Show listing of project billing reports
-->

<template>
  <BrowsableListingView
    :parent="bus"
    title="Monthly Billing Reports"
    :loading="loadingProjectBillingReports"
    :error="errorLoadingProjectBillingReports"
    :navigation-filters="navigationFilters"
    :navigation-actions="navigationActions"
    :rows="getProjectBillingReports"
    :fields="fields"
    :search-fields="searchFields"
    :show-heading="false"
    @events="eventHandler"
  >
    <template slot="sub-nav-heading">
      <h5 class="crop-overflow">
        Monthly Billing Reports {{ periodStartDay() | monthString }}
      </h5>
    </template>

    <template v-slot:cell(total_hours)="row">
      {{ row.item.total_hours | durationString }}
    </template>

    <template v-slot:cell(total_billable_hours)="row">
      {{ row.item.total_billable_hours | durationString }}
    </template>

    <template v-slot:cell(reporting_employees)="row">
      <div
        v-for="email in row.item.reporting_employees"
        :key="email"
      >
        {{ email }}
      </div>
    </template>
  </BrowsableListingView>
</template>

<script>
import { mapGetters } from 'vuex'
import { format, startOfMonth } from 'date-fns'

import BrowsableListingView from '../../components/elements/BrowsableListingView.vue'
import TimeRangeBrowser from '../../components/base/TimeRangeBrowser.vue'

export default {
  name: 'BillingReports',

  components: {
    BrowsableListingView
  },

  extends: TimeRangeBrowser,

  data () {
    return {
      defaultPeriod: 'month',
      searchFields: [
        'name'
      ],
      fields: [
        'project_code',
        'name',
        'total_hours',
        'total_billable_hours',
        'reporting_employees'
      ]
    }
  },

  computed: {
    ...mapGetters([
      'getProjectBillingReports',
      'loadingProjectBillingReports',
      'errorLoadingProjectBillingReports'
    ]),
    navigationFilters  () {
      return [
        {
          key: 'previous',
          label: 'Previous',
          callback: this.showPreviousPeriod
        },
        {
          key: 'next',
          label: 'Next',
          callback: this.showNextPeriod
        }
      ]
    }
  },

  methods: {

    eventHandler (action, ...args) {
      switch (action) {
        case 'row-clicked': {
          this.$router.push({
            name: 'billing-report-details',
            params: {
              projectCode: args[0].project_code,
              month: this.$route.query.month
            }
          })
          break
        }
      }
    },

    setViewingPeriod (period) {
      /*
      Set report viewing period.

      For this view period is always month
       */
      this.period = 'month'
      if (this.$route.query.month === undefined) {
        this.start_date = new Date()
        this.$router.push({
          name: 'monthly-billing-reports',
          query: {
            month: format(this.previousPeriodStart, 'YYYY-MM')
          }
        })
      } else {
        this.start_date = startOfMonth(this.$route.query.month, { weekStartsOn: this.$weekStartsOn })
      }
    },

    getDateQueryArgs (period, start, end) {
      /*
      Override default getDateQueryArgs (this view always uses 'month' query arg)
       */
      start = start !== undefined ? start : this.start_date
      let args = {
        'month': format(start, this.$apiMonthFormat)
      }
      return args
    },

    loadRecords () {
      let params = this.getDateQueryArgs()
      params.is_billable = true
      this.$store.dispatch('resertProjectBillingReports')
      this.$store.dispatch('loadProjectBillingReports', params)
    }
  }

}
</script>
