<!--
Common base component for paged editor views
-->

<template>
  <div />
</template>

<script>
import Vue from 'vue'

import BaseComponent from '@/components/base/BaseComponent.vue'
import PagedEditorView from '@/components/elements/PagedEditorView.vue'

export default {
  name: 'PagedEditor',

  /* eslint-disable vue/no-unused-components */
  components: {
    PagedEditorView
  },
  /* eslint-enable */

  extends: BaseComponent,

  data: function () {
    return {
      selectedPage: undefined,
      listRouteName: undefined,
      createRouteName: undefined,
      detailRouteName: undefined,
      editing: false,
      neverEdit: [ 'details', 'new' ],
      record: {}
    }
  },

  computed: {

    listRouteTitle () {
      return 'List'
    },

    detailRouteTitle () {
      return 'Details'
    },

    showPageNavigation () {
      if (this.selectedPage === 'details') {
        return !this.newRecord
      } else if (this.selectedPage === 'new') {
        return false
      } else {
        return true
      }
    },

    navigationFilters () {
      /*
      Default paged editor has no filters
       */
      return []
    },

    navigationActions () {
      /*
      Default paged editor has no buttons
       */
      return []
    },

    canEdit () {
      return false
    },

    noEdit () {
      let pages = this.neverEdit
      if (!this.canEdit) {
        // Disable current page dynamically
        pages.push(this.selectedPage)
      }
      return pages
    },

    detailRouteParams () {
      /*
      Params to set to route when going to detail route
       */
      return {}
    },

    breadcrumbs () {
      /*
      Default breadcrumbs constructed from page details
       */
      let path = [
        {
          text: this.listRouteTitle,
          to: {
            name: this.listRouteName
          }
        }
      ]
      if (this.selectedPage !== 'new') {
        path.push(
          {
            text: this.detailRouteTitle,
            to: {
              name: this.detailRouteName,
              params: this.detailRouteParams,
              query: {
                page: 'details'
              }
            }
          }
        )
      }
      if (this.selectedPage !== undefined && this.selectedPage !== 'details') {
        path.push(
          {
            text: this.pageName,
            to: {
              name: this.detailRouteName,
              params: this.detailRouteParams,
              query: {
                page: this.selectedPage
              }
            }
          }
        )
      }
      return path
    }
  },

  watch: {
    '$route.params' () {
      const $vm = this
      Vue.nextTick(function () {
        $vm.bus.$emit('notification', 'page-changed', $vm.selectedPage)
        $vm.clearEditedRecord()
      })
    },
    '$route.query.page' () {
      if (this.selectedPage !== this.$route.query.page) {
        this.selectedPage = this.$route.query.page
      }
    },
    selectedPage () {
      const $vm = this
      Vue.nextTick(function () {
        $vm.bus.$emit('notification', 'page-changed', $vm.selectedPage)
        $vm.clearEditedRecord()
      })
    }
  },

  mounted () {
    const $vm = this
    this.loadData()
    Vue.nextTick(function () {
      if ($vm.$route.name === $vm.createRouteName) {
        $vm.selectedPage = 'new'
        $vm.editNewRecord()
      } else {
        $vm.selectedPage = $vm.$route.query.page !== undefined ? $vm.$route.query.page : 'details'
      }
    })
  },

  methods: {

    eventHandler (action, ...args) {
      /*
      Common event handler for actions in paged editor views
       */
      switch (action) {
        case 'edit-new-record': {
          switch (this.selectedPage) {
            case 'details': {
              this.clearEditedRecord()
              this.editNewRecord()
              break
            }
            default: {
              this.clearEditedRecord()
              this.bus.$emit('notification', 'edit-new-record')
            }
          }
          break
        }
        case 'toggle-editing': {
          this.editing = !this.editing
          break
        }
        case 'switch-page': {
          this.selectedPage = args[0]
          break
        }
        case 'created': {
          this.recordCreated(args[0])
          break
        }
        case 'updated': {
          this.recordUpdated(args[0])
          break
        }
        case 'deleted': {
          this.recordDeleted()
          break
        }
        case 'canceled': {
          this.editCanceled()
          break
        }
      }
    },

    loadData () {
      /*
      Dummy data load function. Implement in child classs
       */
    },

    editNewRecord () {
      /*
      Start editing new record
      */
      this.editedRecord = {}
      this.processing = false
      this.newRecord = true
      this.editing = true
    },

    clearEditedRecord () {
      /*
      Cancel record editing
      */
      this.editing = false
      this.processing = false
      this.newRecord = false
      this.editedRecord = undefined
    },

    editCanceled () {
      /*
      Cancel editing and reload data
      */
      if (this.selectedPage === 'new') {
        this.$router.push({
          name: this.listRouteName
        })
      } else if (this.newRecord && (this.selectedPage === 'details')) {
        this.$router.push({
          name: this.listRouteName
        })
      } else {
        this.clearEditedRecord()
        this.loadData()
      }
    },

    recordCreated (record) {
      /*
      A new record was created. Clear form and load data.
       */
      this.editing = !this.editing
      this.clearEditedRecord()
      this.loadData()
    },

    recordUpdated (record) {
      /*
      Record was updated. Clear form and reload data.
       */
      this.editing = !this.editing
      this.clearEditedRecord()
      this.loadData()
    },

    recordDeleted () {
      /*
      Action after record has been deleted

      By default move to list route
       */
      this.clearEditedRecord()
      if (this.listRouteName) {
        if (this.selectedPage === 'details') {
          this.$router.push({
            name: this.listRouteName
          })
        } else {
          this.loadData()
        }
      }
    }
  }
}
</script>
