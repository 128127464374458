/*
Employee contract period state
 */

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadEmploymentContractPeriods: ({ commit }, params = {}) => {
    /*
     Load employment contract periods
     */
    return helpers.loadListing(
      commit,
      'timesheet/employment-contract-periods',
      params,
      types.CONTRACT_PERIOD_LIST_LOAD,
      types.CONTRACT_PERIOD_LIST_LOAD_SUCCESS,
      types.CONTRACT_PERIOD_LIST_LOAD_ERROR
    )
  },
  createEmploymentContracPeriod: ({ commit }, params) => {
    /*
    Create new employment contract period
     */
    return helpers.postRecord(
      commit,
      'timesheet/employment-contract-periods',
      params,
      types.CONTRACT_PERIOD_CREATE,
      types.CONTRACT_PERIOD_CREATE_SUCCESS,
      types.CONTRACT_PERIOD_CREATE_ERROR
    )
  },
  updateEmploymentContracPeriod: ({ commit }, params) => {
    /*
    Update existing timesheet record
     */
    return helpers.updateRecord(
      commit,
      `timesheet/employment-contract-periods/${params.id}`,
      params,
      types.CONTRACT_PERIOD_UPDATE,
      types.CONTRACT_PERIOD_UPDATE_SUCCESS,
      types.CONTRACT_PERIOD_UPDATE_ERROR
    )
  },
  deleteEmploymentContracPeriod: ({ commit }, params) => {
    /*
    Delete existing timesheet record
     */
    return helpers.deleteRecord(
      commit,
      `timesheet/employment-contract-periods/${params.id}`,
      types.CONTRACT_PERIOD_DELETE,
      types.CONTRACT_PERIOD_DELETE_SUCCESS,
      types.CONTRACT_PERIOD_DELETE_ERROR
    )
  },
  resetEmploymentContractPeriods: ({ commit }, params) => {
    commit(types.CONTRACT_PERIOD_LIST_RESET)
  }
}

const mutations = {
  [types.CONTRACT_PERIOD_LIST_RESET] (state) {
    helpers.mutateResetData(state)
  },
  [types.CONTRACT_PERIOD_LIST_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.CONTRACT_PERIOD_LIST_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.CONTRACT_PERIOD_LIST_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  },
  [types.CONTRACT_PERIOD_CREATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.CONTRACT_PERIOD_CREATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.CONTRACT_PERIOD_CREATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.CONTRACT_PERIOD_UPDATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.CONTRACT_PERIOD_UPDATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.CONTRACT_PERIOD_UPDATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.CONTRACT_PERIOD_DELETE] (state) {
    helpers.mutateItemDeleting(state)
  },
  [types.CONTRACT_PERIOD_DELETE_SUCCESS] (state) {
    helpers.mutateItemDeleted(state)
  },
  [types.CONTRACT_PERIOD_DELETE_ERROR] (state, error) {
    helpers.mutateItemDeleteFailed(state, error)
  }
}

const getters = {
  getEmploymentContractPeriods: () => {
    /*
    Return all employment contract periods
    */
    return state.items
  },
  getEmploymentContractPeriodDetails: () => {
    /*
    Return all employment contract periods
    */
    return state.details
  },
  loadingEmploymentContractPeriods: () => {
    /*
    Check if employment contract period list is loading
     */
    return state.loading
  },
  loadingEmploymentContractPeriodDetails: () => {
    /*
    Check if employment contract period details are loading
     */
    return state.loadingDetails
  },
  updatingEmploymentContractPeriodDetails: () => {
    /*
    Check if employment contract period record is being updated
     */
    return state.updating
  },
  deletingEmploymentContractPeriodDetails: () => {
    /*
    Check if employment contract period record is being deleted
     */
    return state.deleting
  },
  errorLoadingEmploymentContractPeriods: () => {
    /*
    Check if loading employment contract period list caused errors
     */
    return state.errors.loading
  },
  errorLoadingEmploymentContractPeriodDetails: () => {
    /*
    Check if loading employment contract period details caused errors
     */
    return state.errors.loadingDetails
  },
  errorUpdatingEmploymentContractPeriod: () => {
    /*
    Check if updating employment contract period details caused errors
     */
    return state.errors.updating
  },
  errorDeletingEmploymentContractPeriod: () => {
    /*
    Check if deleting employment contract period details caused errors
     */
    return state.errors.deleting
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
