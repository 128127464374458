
<!--
Common base class for fields
-->

<template>
  <div />
</template>

<script>
import FieldLegend from './FieldLegend.vue'

export default {
  name: 'Field',

  components: {
    FieldLegend, // eslint-disable-line
  },

  props: {
    // Events from parent component
    parent: {
      type: Object,
      required: true
    },
    // ID for input item
    id: {
      type: String,
      default: undefined
    },
    // Field to be rendered
    field: {
      type: Object,
      required: true
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    // Checks if field entry should be disabled
    disabled: {
      type: Boolean,
      default: false
    },
    tooltipVariant: {
      type: String,
      default: 'info'
    },
    tooltipPlacement: {
      type: String,
      default: 'top'
    }
  },

  data: function () {
    return {
      value: undefined,
      defaultValidators: []
    }
  },

  computed: {
    required () {
      return this.field.required[this.field.name]
    },
    error () {
      /*
      Return error from field for active key
       */
      return this.field.errors[this.field.name]
    }
  },

  created () {
    this.parent.$on('reset', this.loadData)
    this.parent.$on('reload', this.reload)
    this.parent.$on('reload-options', this.reloadFieldOptions)
    this.$on('input', this.updateData)
    this.loadData()
  },

  methods: {

    reload (name) {
      /*
      Reload data from field values
       */
      if (name === undefined || name === this.field.name) {
        this.loadData()
      }
    },

    save () {
      /*
      Emit save-clicked event to parent form
       */
      this.$emit('update', 'save-clicked')
    },

    reloadFieldOptions (name) {
      /*
      Reload available field options
       */
      if (name === this.field.name) {
        this.loadData()
      }
    },

    loadData () {
      /*
      Load data from field. Also used to reload from reset signal
       */
      this.$set(this, 'value', this.formatValue(this.field.name, this.field.values[this.field.name]))
    },

    formatValue (key, value) {
      /*
      Method to format loaded value from API data. Normally returns value as is,
      can be used to apply custom formatting as required
       */
      return value
    },

    toggleClicked () {
      this.$emit('update', 'toggle-clicked')
    },

    setFieldValue (key, value) {
      /*
       Set value back to field
       */
      this.$set(this, 'value', value)
      this.$emit('update', 'update-value', this.field.name, key, value)
    },

    setPendingField (key, pending) {
      /*
      Mark field value as incomplete, i.e. no validation error but not yet acceptable value
       */
      this.$emit('update', 'set-pending', this.field.name, key, pending)
    },

    getInputValidators (key) {
      /*
      Get validators for specified key
       */
      let validators = this.field.validators[key]
      if (validators === undefined) {
        // Try to lookup default validators for field. Returns empty list if not specified
        validators = this.defaultValidators
      }
      // Return the validators
      // TODO - we may some more logic here?
      return validators
    },

    validatorCallback (callback, field, key, value) {
      /*
      Wrapper for calling a field validator callback

      Override to mangle arguments how the callback is called
       */
      return callback(field, key, value)
    },

    validateData (key, value) {
      /*
      Validate field data, pass validation status to parent and validation status
      back to caller (normally this.updateData)
       */

      let validators = this.getInputValidators(key)
      let response = {
        key: key,
        error: undefined
      }

      if (validators !== undefined) {
        if (Array.isArray(validators)) {
          validators.some(callback => {
            if (typeof (callback) === 'function') {
              response = this.validatorCallback(callback, this.field, key, value)
              this.$emit('update', 'validation-error', this.field.name, key, response.error)
              if (response.error !== undefined) {
                return response
              }
            }
          })
        } else if (typeof (validators) === 'function') {
          response = this.validatorCallback(validators, this.field, key, value)
          this.$emit('update', 'validation-error', this.field.name, response.key, response.error)
          if (response.error !== undefined) {
            return response
          } else {}
        }
      }
      if (response.error === undefined) {
        // No errors detected, reset error
        this.$emit('update', 'validation-error', this.field.name, response.key, response.error)
      }
      return response
    },

    parseInputValue (key, value) {
      /*
      Parse value from form input

      Allows doing fancy reformatting if needed, normally just returns value as-is

      See TimeField for an example (allows inexact entry of time)
       */
      return value
    },

    checkIncompleteValue (key, value) {
      /*
      Check for incomplete values. By default does nothing.

      Override this in child class. Called in updateData
       */
      return false
    },

    updateValidationStatus (key, value) {
      return this.validateData(key, value)
    },

    updateData (key, value) {
      /*
      Data in form was updated, emit to parent
       */
      let pending
      value = this.parseInputValue(key, value)
      pending = this.checkIncompleteValue(key, value)
      this.setFieldValue(key, value)
      this.setPendingField(key, pending)
      if (!pending) {
        this.updateValidationStatus(key, value)
      }
    }

  }

}
</script>
