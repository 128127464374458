<!--
Common script component for items browsed as time ranges

This component handles navigation between paginated time ranges.

Calling component needs to implement template to show the data.
-->

<template>
  <div />
</template>

<script>
import {
  format, parse, isFuture, getDay, getISOWeek, eachDay, addDays, addMonths, addYears,
  startOfWeek, startOfMonth, startOfYear, endOfWeek, endOfMonth, endOfYear
} from 'date-fns'
import { isEqual, sortBy } from 'lodash'
import { mapGetters } from 'vuex'

import BaseComponent from '@/components/base/BaseComponent.vue'

export default {
  name: 'TimeRangeBrowser',

  extends: BaseComponent,

  data () {
    return {
      // Start weeks on monday
      period: undefined,
      defaultPeriod: 'week',
      selected_date: undefined,
      start_date: undefined,
      end_date: undefined,
      weekPeriodStartDate: undefined,
      monthPeriodStartDate: undefined,
      yearPeriodStartDate: undefined,
      dateOpenForEditing: undefined,
      allDaysExpanded: false,
      expandedDays: []
    }
  },

  computed: {
    ...mapGetters([
      'loggedInEmployeeEmail'
    ]),

    navigationFilters () {
      /*
      Filters for sub navigation bar
       */
      return []
    },
    navigationActions () {
      /*
      Action buttons for sub navigation bar
       */
      return []
    },

    email () {
      /*
      Return email address from query arguments or for logged in employee
      */
      if (this.$route.query.email !== undefined) {
        return this.$route.query.email
      } else {
        return this.loggedInEmployeeEmail
      }
    },

    previousPeriodStart () {
      /*
      Return start date for previous period
      */
      let start
      switch (this.period) {
        case 'week':
          start = addDays(this.periodStartDay(), -7)
          break
        case 'month':
          start = addMonths(this.periodStartDay(), -1)
          break
        case 'year':
          start = addYears(this.periodStartDay(), -1)
          break
        default:
          alert(`Period switching not supported for period type ${this.period}`)
          return
      }
      return start
    },

    nextPeriodStart () {
      /*
      Return start date for next period
      */
      let start
      switch (this.period) {
        case 'week':
          start = addDays(this.periodStartDay(), 7)
          break
        case 'month':
          start = addMonths(this.periodStartDay(), 1)
          break
        case 'year':
          start = addYears(this.periodStartDay(), 1)
          break
        default:
          alert(`Period switching not supported for period type ${this.period}`)
          return
      }
      return start
    },

    periodDates () {
      /*
      Return list of days for specified time period
      */
      return eachDay(this.periodStartDay(), this.periodEndDay())
    },

    daysForView () {
      /*
      Return list of days in selected time range
      */
      return this.periodDates.map(date => ({
        date: date,
        weekDay: {
          number: getDay(date),
          name: format(date, 'ddd')
        },
        isExpanded: this.isDayExpanded(date),
        isEditing: this.isDayOpenForEditing(date)
      }))
    },

    datesForView () {
      /*
      Returns all dates for relevant days in view
      */
      return this.daysForView.map(day => day.date)
    },

    periodHeading () {
      /*
      Return heading string for selected time period
      */
      let value
      switch (this.period) {
        case 'week':
          value = `Week ${getISOWeek(this.periodStartDay())}`
          break
        case 'month':
          value = format(this.periodStartDay(), 'YYYY-MM')
          break
        case 'year':
          value = format(this.periodStartDay(), 'YYYY')
          break
        default:
          value = ''
      }
      return value
    },

    anyDayOpenForEditing () {
      /*
      Checks if any day is open for editing

      This is used to open today by default if no other date is open
      */
      return this.dateOpenForEditing !== undefined
    },

    nextPeriodInFuture () {
      /*
      Check if next period is in future
      */
      return isFuture(this.nextPeriodStart)
    }

  },

  watch: {
    '$route.query' () {
      /*
      Update time period data when query parameters change
      */
      this.setViewingPeriod()
      this.updateTimePeriodData()
    },
    'start_date' () {
      /*
      Update attributes based on start date changes
       */
      if (this.allDaysExpanded) {
        this.expandAllDates()
      } else {
        this.collapseAllDates()
      }
    }
  },

  mounted () {
    /*
    Parse time range arguments from query parameters during init
     */
    this.setViewingPeriod()
    this.updateTimePeriodData()
  },

  methods: {

    updateTimePeriodData () {
      /*
      Update data related to selected time range

      Note: component inheriting this must implement this.loadRecords()
      */
      this.loadEmployeeDetails()
      this.loadHolidays()
      this.loadRecords()
    },

    loadRecords () {
      /*
      Load records for view.

      Implement this in child component.
      */
    },

    loadHolidays () {
      /*
      Load holidays for specified time range
      */
      this.$store.dispatch('loadHolidays', this.getDateQueryArgs())
    },

    loadEmployeeDetails (email) {
      /*
      Load profile and work days for specified email

      If no email is given, use logged in user
      */
      if (email === undefined) {
        email = this.email
      }
      this.$store.dispatch('loadEmployeeDetails', email)
      this.$store.dispatch('loadEmployeeWorkDays', email)
    },

    periodStartDay (period) {
      /*
      First date for selected date period
      */
      let value
      period = period !== undefined ? period : this.period
      switch (period) {
        case 'week':
          value = startOfWeek(this.start_date, { weekStartsOn: this.$weekStartsOn })
          break
        case 'month':
          value = startOfMonth(this.start_date, { weekStartsOn: this.$weekStartsOn })
          break
        case 'year':
          value = startOfYear(this.start_date, { weekStartsOn: this.$weekStartsOn })
          break
        default:
          value = this.start_date
      }
      return value
    },

    periodEndDay (period) {
      /*
      Last date for selected date period
      */
      let value
      period = period !== undefined ? period : this.period
      switch (period) {
        case 'week':
          value = endOfWeek(this.end_date, { weekStartsOn: this.$weekStartsOn })
          break
        case 'month':
          value = endOfMonth(this.end_date, { weekStartsOn: this.$weekStartsOn })
          break
        case 'year':
          value = endOfYear(this.end_date, { weekStartsOn: this.$weekStartsOn })
          break
        default:
          value = this.end_date
      }
      return value
    },

    setViewingPeriod (period) {
      /*
      Set specified viewing period, period based on query arguments or default period
       */
      let query = Object.assign({}, this.$route.query)
      let args = {}
      if (period === undefined) {
        if (this.$route.query.period !== undefined) {
          period = this.$route.query.period
        } else {
          period = this.defaultPeriod
        }
      }
      if (this.$validTimePeriods.indexOf(period) === -1) {
        alert(`Unexpected time period value "${period}"`)
      }
      this.period = period
      if (this.$route.query.start_date !== undefined) {
        this.start_date = parse(this.$route.query.start_date).valueOf()
      } else {
        this.start_date = new Date()
      }
      if (this.$route.query.end_date !== undefined) {
        this.end_date = parse(this.$route.query.end_date).valueOf()
      } else {
        this.end_date = this.periodStartDay()
      }
      args = this.getDateQueryArgs(this.period, this.start_date, this.end_date, false)
      args.period = period
      Object.keys(args).forEach(key => { query[key] = args[key] })
      if (!isEqual(this.$route.query, query)) {
        this.$router.push({
          name: this.$route.name,
          query: query
        })
      }
    },

    getQueryDate () {
      /*
      Get date from query arguments and set it open if found
       */
      if (this.$route.query.date !== undefined) {
        return parse(this.$route.query.date, this.$apiDateFormat)
      }
    },

    getDateQueryArgs (period, start, end, ignoreRequestedDate = true) {
      /*
      Format query arguments for given time period and start / end dates
      */
      let args = {
        start_date: start !== undefined ? start : this.periodStartDay(),
        end_date: end !== undefined ? end : this.periodEndDay()
      }
      // Use specified date as viewing period if found
      if (ignoreRequestedDate === false) {
        const requestedDate = this.getQueryDate()
        if (!ignoreRequestedDate && requestedDate !== undefined) {
          start = parse(requestedDate)
        }
      }
      switch (period) {
        case 'week':
          args['start_date'] = startOfWeek(start, { weekStartsOn: this.$weekStartsOn })
          args['end_date'] = endOfWeek(start, { weekStartsOn: this.$weekStartsOn })
          break
        case 'month':
          args['start_date'] = startOfMonth(start, { weekStartsOn: this.$weekStartsOn })
          args['end_date'] = endOfMonth(start, { weekStartsOn: this.$weekStartsOn })
          break
        case 'year':
          args['start_date'] = startOfYear(start, { weekStartsOn: this.$weekStartsOn })
          args['end_date'] = endOfYear(start, { weekStartsOn: this.$weekStartsOn })
          break
      }
      // Remove undefined arguments
      Object.keys(args).forEach(key => args[key] === undefined && delete args[key])

      // Ensure expected query args format
      if (args.start_date !== undefined) {
        args.start_date = format(args.start_date, this.$apiDateFormat)
      }
      if (args.end_date !== undefined) {
        args.end_date = format(args.end_date, this.$apiDateFormat)
      }

      // Append any query keys not in args
      Object.keys(this.$route.query).forEach(key => {
        if (args[key] === undefined) {
          if (key === 'date') {
            if (!ignoreRequestedDate) {
              args[key] = this.$route.query[key]
            }
          } else {
            args[key] = this.$route.query[key]
          }
        }
      })
      return args
    },

    switchPeriod (period) {
      /*
      Switch to named viewing period
      */
      if (this.period === period) {
        return
      }
      // Store current period start date. This is used when switching back to the period type
      switch (this.period) {
        case 'week':
          this.weekPeriodStartDate = this.periodStartDay().valueOf()
          break
        case 'month':
          this.monthPeriodStartDate = this.periodStartDay().valueOf()
          break
        case 'year':
          this.yearPeriodStartDate = this.periodStartDay().valueOf()
          break
      }

      // Lookup suitable start date for new period
      let startDate
      switch (period) {
        case 'week':
          startDate = this.weekPeriodStartDate !== undefined ? this.weekPeriodStartDate : undefined
          break
        case 'month':
          startDate = this.monthPeriodStartDate !== undefined ? this.monthPeriodStartDate : undefined
          break
        case 'year':
          startDate = this.yearPeriodStartDate !== undefined ? this.yearPeriodStartDate : undefined
          break
      }
      if (startDate === undefined) {
        startDate = this.periodStartDay(period)
      }

      this.period = period
      this.$router.push({
        name: this.$route.name,
        query: {
          period: period,
          start_date: format(startDate, this.$apiDateFormat)
        }
      })
    },

    showPreviousPeriod (event) {
      /*
      Move view to previous similar period view
       */
      if (event.target) {
        event.target.blur()
      }
      let query = this.getDateQueryArgs(this.period, this.previousPeriodStart)
      query.period = this.period
      this.$router.push({
        name: this.$route.name,
        query: query
      })
    },

    showNextPeriod () {
      /*
      Move view to next similar period view
      */
      if (event.target) {
        event.target.blur()
      }
      let query = this.getDateQueryArgs(this.period, this.nextPeriodStart)
      query.period = this.period
      this.$router.push({
        name: this.$route.name,
        query: query
      })
    },

    getDaysRecords (records, date) {
      /*
       Get records for specified date
       */
      const daysRecords = records.filter(
        record => record.date === format(date, this.$apiDateFormat)
      )
      return sortBy(daysRecords, 'date', 'start')
    },

    showDayDetails (date) {
      /*
      Show details for specified date
      */
      this.expandedDays.push(date)
    },

    hideDayDetails (date) {
      /*
      Hide details for specified date
      */
      this.expandedDays = this.expandedDays.filter(
        item => item.valueOf() !== date.valueOf()
      )
    },

    isDayOpenForEditing (date) {
      /*
      Checks if day is open for editing
      */
      return this.dateOpenForEditing === date.valueOf()
    },

    isDayExpanded (date) {
      /*
      Return true if day is expanded (details visible)
      */
      return this.expandedDays.map(openDay => openDay.valueOf()).includes(date.valueOf())
    },

    toggleIsDayExpanded (date) {
      /*
      Toggle day expanded status
       */
      if (this.isDayExpanded(date)) {
        this.hideDayDetails(date)
      } else {
        this.showDayDetails(date)
      }
    },

    jumptoDate (date) {
      /*
      Jump to specified date in this browser
       */
      this.hideAllDays()
      this.$router.push({
        name: this.$route.name,
        query: {
          period: this.period,
          date: format(date, this.$apiDateFormat)
        }
      })
    },

    setDayOpenForEditing (date) {
      /*
      Mark specified date open for editing
       */
      this.dateOpenForEditing = date.valueOf()
      this.bus.$emit('events', 'day-open-for-editing', date)
    },

    hideAllDays () {
      /*
      Hide all opened days
       */
      this.expandedDays = []
    },

    collapseAllDates () {
      /*
     Mark all dates expanded
      */
      this.allDaysExpanded = false
      this.datesForView.forEach(date => this.hideDayDetails(date))
    },

    expandAllDates () {
      /*
     Mark all dates expanded
      */
      this.allDaysExpanded = true
      this.dateOpenForEditing = undefined
      this.daysForView.forEach(day => {
        if (!this.isDayExpanded(day.date)) {
          this.showDayDetails(day.date)
        }
      })
    },

    toggleAllDatesExpanded () {
      /*
      Hide or show all dates
       */
      if (this.allDaysExpanded) {
        this.collapseAllDates()
      } else {
        this.expandAllDates()
      }
    }

  }
}
</script>
