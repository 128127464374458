<!--
Number field for percentages
-->

<script>
import NumberField from './NumberField.vue'
import { validatePercentageField } from '../validators'

export default {
  name: 'PercentageField',

  extends: NumberField,

  data: function () {
    return {
      defaultValidators: [
        validatePercentageField
      ]
    }
  }
}
</script>
