<!--
Component to show API response errors nicely

Normally used with error being the 'error' field from store state
-->

<template>
  <b-container
    fluid
    class="d-flex flex-column justify-content-center align-items-center h-100"
  >
    <h3>{{ title }}</h3>
    <div class="api-error text-center">
      <slot name="message">
        <div v-if="error && error.response">
          {{ error.response.data }}
        </div>
        <div v-else>
          {{ error }}
        </div>
        <b-button
          v-if="showCloseButton"
          @click="closeErrorClicked"
        >
          Close
        </b-button>
        <b-button
          v-if="showReloadButton"
          @click="reloadClicked"
        >
          Reload
        </b-button>
      </slot>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'Error',

  props: {
    title: {
      type: String,
      default: 'Error processing request'
    },
    error: {
      type: [String, Error],
      default: undefined,
      required: false
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    showReloadButton: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    closeErrorClicked () {
      this.$emit('events', 'close-errors')
    },
    reloadClicked () {
      this.$emit('events', 'reload-page')
    }
  }
}
</script>
