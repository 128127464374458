<!--
Time range entry field form component
-->

<template>
  <b-row class="align-top">
    <b-col
      sm="6"
      class="time"
    >
      <field-legend
        v-if="range_start_label"
        :id="range_start_label"
        :label="range_start_label"
        :description="range_start_description"
        :tooltip-variant="tooltipVariant"
        :tooltip-placement="tooltipPlacement"
      />
      <b-form-input
        v-model="range_start"
        :class="{ 'form-inline': true, 'validation-error': error }"
        name="input-range_start"
        :autofocus="autofocus"
        :placeholder="placeholder"
        :disabled="disabled"
        @keydown.backspace="deleteChar(
          field.inputs['range_start'].key,
          field.values[field.inputs['range_start'].key]
        )"
        @keyup.enter="save"
        @input="$emit('input', field.inputs['range_start'].key, $event)"
      />
      <div
        v-if="range_start_error"
        class="field-validation-error"
      >
        {{ range_start_error }}
      </div>
    </b-col>
    <b-col
      sm="6"
      class="time"
    >
      <b-input-group>
        <field-legend
          v-if="range_end_label"
          :id="range_end_label"
          :label="range_end_label"
          :description="range_end_description"
          :tooltip-variant="tooltipVariant"
          :tooltip-placement="tooltipPlacement"
        />
        <b-form-input
          v-model="range_end"
          :class="{ 'form-inline': true, 'validation-error': error }"
          name="input-range_end"
          :autofocus="autofocus"
          :placeholder="placeholder"
          :disabled="disabled"
          @keydown.backspace="deleteChar(
            field.inputs['range_end'].key,
            field.values[field.inputs['range_end'].key]
          )"
          @keyup.enter="save"
          @input="$emit('input', field.inputs['range_end'].key, $event)"
        />
        <b-input-group-text
          slot="append"
          class="cursor-pointer"
          @click="toggleClicked"
        >
          <font-awesome-icon icon="exchange-alt" />
        </b-input-group-text>
      </b-input-group>
      <div
        v-if="range_end_error"
        class="field-validation-error"
      >
        {{ range_end_error }}
      </div>
    </b-col>
    <b-col
      v-if="range_error"
      class="mb-2 field-validation-error"
    >
      {{ range_error }}
    </b-col>
  </b-row>
</template>

<script>
import Vue from 'vue'
import RangeField from './RangeField.vue'
import TimeField from './TimeField.vue'
import {
  validateTimeField,
  validateTimeRangeField
} from '../validators'

export default {
  name: 'TimeRangeField',

  mixins: [
    RangeField,
    TimeField
  ],

  data: function () {
    return {
      defaultValidators: [
        validateTimeField,
        validateTimeRangeField
      ]
    }
  },

  methods: {

    loadData () {
      /*
      Load range start and end values from configurable keys for field
       */ this.range_start = this.formatValue(
        this.field.inputs['range_start'].key,
        this.field.values[this.field.inputs['range_start'].key]
      )
      this.range_end = this.formatValue(
        this.field.inputs['range_end'].key,
        this.field.values[this.field.inputs['range_end'].key]
      )
    },

    deleteChar (key) {
      /*
      Parse backspace (delete character) corner cases

      Note: this must use internal value, not value from field
       */
      let value
      switch (key) {
        case this.field.inputs.range_start.key: {
          value = this.range_start
          break
        }
        case this.field.inputs.range_end.key: {
          value = this.range_end
          break
        }
      }
      let match = value.match(['[0-9]*:'])
      if (match && match[0] === value) {
        value = value.replace(/:/, '')
        if (value === '0') {
          value = ''
        }
        // We had a foo:: value, drop not only : but also previous character
        switch (key) {
          case this.field.inputs.range_start.key: {
            this.range_start = value
            break
          }
          case this.field.inputs.range_end.key: {
            this.range_end = value
            break
          }
        }
      }
    },

    setFieldValue (key, value) {
      /*
       Set value back to field
       */
      const $vm = this
      let match = value.match(['[0-9]*::'])
      if (match && match[0] === value) {
        value = value.replace('::', ':')
      }

      Vue.nextTick(function () {
        $vm.field.values[key] = $vm.formatTimeForAPI(value)
      })
      switch (key) {
        case this.field.inputs.range_start.key:
          Vue.nextTick(function () {
            $vm.range_start = value
          })
          break
        case this.field.inputs.range_end.key:
          Vue.nextTick(function () {
            $vm.range_end = value
          })
          break
      }
      this.$emit('update', 'update-value', this.field.name, key, this.formatTimeForAPI(value))
    },

    updateValidationStatus (key, value) {
      const response = this.validateData(key, value)
      if (response.error === undefined) {
        const keys = [
          'range_error',
          this.field.inputs.range_start.key,
          this.field.inputs.range_end.key
        ]
        keys.forEach(item => {
          this.$emit('update', 'validation-error', this.field.name, item, undefined)
        })
      }
      return response
    }

  }

}
</script>
