<!--
View to show summary of reported records by employee
-->

<template>
  <b-container fluid>
    <h3>
      Logged work Summmary by Employee
    </h3>
    <b-row
      v-for="(item, employee) in summary.employee_hours"
      :key="employee"
    >
      <b-col cols="3">
        {{ employee }}
      </b-col>
      <b-col cols="9">
        {{ item.billable | durationString }}
        <span v-if="item.billable_travel.hours || item.billable_travel.minutes">
          ({{ item.billable_travel | durationString }} billable travel)
        </span>
        <span v-if="item.non_billable.hours || item.non_billable.minutes">
          ({{ item.non_billable | durationString }} non-billable)
        </span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'EmployeeRecordsSummary',

  props: {
    summary: {
      type: Object,
      required: true
    },
    containsBillableTravel: {
      type: Boolean,
      default: true
    },
    containsNonBillable: {
      type: Boolean,
      default: true
    }
  }

}
</script>
