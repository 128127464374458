<!--
Show listing of all projects
-->

<template>
  <BrowsableListingView
    :parent="bus"
    :title="title"
    :loading="loadingProjects"
    :error="errorLoadingProjects"
    :navigation-filters="navigationFilters"
    :navigation-actions="navigationActions"
    :rows="getProjects"
    :fields="fields"
    :search-fields="searchFields"
    @events="eventHandler"
  >
    <template v-slot:cell(actions)="row">
      <div
        class="action-list"
      >
        <span
          v-b-tooltip.hover
          title="Show Tasks"
          class="action"
          @click.stop="showProjectTasks(row.item)"
        >
          <font-awesome-icon icon="tasks" />
        </span>
        <span
          v-b-tooltip.hover
          title="Show Members"
          class="action"
          @click.stop="showProjectMembers(row.item, $event)"
        >
          <font-awesome-icon icon="users" />
        </span>
        <span
          v-if="row.item.can_join"
          v-b-tooltip.hover
          title="Join Project"
          class="action"
          @click.stop="joinProject(row.item)"
        >
          <font-awesome-icon icon="user-plus" />
        </span>
        <span
          v-if="row.item.can_edit"
          v-b-tooltip.hover
          title="Edit Project"
          class="action"
        >
          <font-awesome-icon icon="edit" />
        </span>
      </div>
    </template>

    <template slot="modal">
      <b-modal
        id="project-join-modal"
        v-model="showModal"
        hide-header
        hide-footer
        centered
        no-close-on-backdrop
        @hide="projectJoinCanceled"
      >
        <div
          v-if="projectToJoin"
        >
          <ProjectMemberEditForm
            :parent="bus"
            :employee="loggedInEmployee"
            :project="projectToJoin"
            @events="eventHandler"
            @created="projectJoined"
            @canceled="projectJoinCanceled"
          />
        </div>
      </b-modal>
    </template>
  </BrowsableListingView>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'

import BrowsableListingView from '@/components/elements/BrowsableListingView.vue'
import FilteredList from '@/components/base/FilteredList.vue'
import ProjectMemberEditForm from '@/components/project/ProjectMemberEditForm.vue'

export default {
  name: 'Projects',

  components: {
    BrowsableListingView,
    ProjectMemberEditForm
  },

  extends: FilteredList,

  data () {
    return {
      queryFilters: {
        is_archived: undefined,
        is_billable: undefined
      },
      defaultFilters: {
        is_archived: false,
        is_billable: undefined
      },
      showModal: false,
      projectToJoin: undefined,
      searchFields: [
        'project_code',
        'name',
        'category',
      ],
      fields: [
        {
          key: 'project_code',
          label: 'Code',
          sortable: true
        },
        {
          key: 'name',
          label: 'Project name',
          sortable: true
        },
        {
          key: 'start_date',
          label: 'Start',
          sortable: true
        },
        {
          key: 'end_date',
          label: 'End',
          sortable: true
        },
        {
          key: 'latest_record_date',
          label: 'Latest Record',
          sortable: true
        },
        {
          key: 'category',
          label: 'Category',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      filterGroups: {
        active: {
          label: 'Active',
          title: 'Active Projects',
          query: {
            is_archived: false
          }
        },
        internal: {
          label: 'Internal',
          title: 'Internal Projects',
          query: {
            is_archived: false,
            is_billable: false
          }
        },
        billable: {
          label: 'Billable',
          title: 'Billable Projects',
          query: {
            is_archived: false,
            is_billable: true
          }
        },
        archived: {
          label: 'Archived',
          title: 'Archived Projcets',
          query: {
            is_archived: true
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'loggedInEmployee',
      'getProjects',
      'loadingProjects',
      'errorLoadingProjects'
    ]),

    loading () {
      return this.loadingProjects
    },

    error () {
      return this.errorLoadingProjects
    },

    navigationActions () {
      /*
      Navigation bar actions

      Only internal employees can create projects
       */
      let actions = []
      if (this.isInternal) {
        actions.push(
          {
            key: 'create',
            label: 'Create Project',
            route: {
              name: 'project-create'
            }
          }
        )
      }
      return actions
    }
  },

  mounted () {
    /*
    Use default query fields if none is specified
     */
    if (isEmpty(this.activeFilters)) {
      this.$router.push({
        name: this.$route.name,
        query: this.defaultFilters
      })
    } else {
      this.loadRecords()
    }
  },

  methods: {
    loadRecords () {
      /*
      Load records based on active filters
      */
      let filters = !isEmpty(this.activeFilters) ? this.activeFilters : this.defaultFilters
      this.$store.dispatch('loadProjects', filters)
    },

    eventHandler (action, ...args) {
      switch (action) {
        case 'row-clicked': {
          this.$router.push({
            name: 'project-details',
            params: {
              projectCode: args[0].project_code
            },
            query: {
              page: 'details'
            }
          })
          break
        }
        case 'canceled': {
          if (this.showModal && this.projectToJoin) {
            this.projectJoinCanceled()
          }
          break
        }
        case 'created': {
          if (this.showModal && this.projectToJoin) {
            this.projectJoined()
          }
          break
        }
      }
    },

    joinProject (project) {
      /*
      Show form to join project
      */
      this.showModal = true
      this.projectToJoin = project
    },

    projectJoinCanceled () {
      this.showModal = false
      this.projectToJoin = undefined
      this.$root.$emit('bv::hide::modal', 'project-join-modal')
    },

    projectJoined () {
      this.$root.$emit('bv::hide::modal', 'project-join-modal')
      this.projectToJoin = undefined
      this.loadRecords()
    },

    showProjectTasks (project) {
      /*
      Show tasks for project
      */
      this.$router.push({
        name: 'project-details',
        params: {
          projectCode: project.project_code.toString()
        },
        query: {
          page: 'tasks'
        }
      })
    },

    showProjectMembers (project) {
      /*
      Show tasks for project
      */
      this.$router.push({
        name: 'project-details',
        params: {
          projectCode: project.project_code.toString()
        },
        query: {
          page: 'members'
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
div.action-list {
  span.action {
    width: 20px;
    padding-right: 0.5em;
    cursor: pointer;
  }
}
</style>
