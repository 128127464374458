<!--
Component to show a summary of hours for a day / week per employee
-->

<template>
  <b-container
    v-if="dates"
    fluid
    class="d-flex"
  >
    <div
      v-for="record in recordDateGroups"
      :key="record.date | date"
      class="day text-center my-3"
      :class="{
        'holiday': record.holiday,
        'weekend': record.weekend,
        'future': record.future,
        'warning': record.warning
      }"
    >
      <span
        v-b-tooltip.hover
        :title="record.date | date"
      >
        {{ record.totalDuration }}
      </span>
    </div>
  </b-container>
</template>

<script>
import { orderBy } from 'lodash'
import { isFuture, getDay, parse } from 'date-fns'
import { getTotalDuration, formatDurationString } from '../utils/timeUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'DailyHourSummary',

  props: {
    dates: {
      type: Array,
      required: true
    },
    holidays: {
      type: Array,
      required: true
    },
    records: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters([
      'getHolidayForDate'
    ]),
    recordDateGroups () {
      /*
      Return records grouped by dates
       */
      let dategroups = {}
      this.dates.forEach(date => {
        date = parse(date)
        dategroups[date] = {
          date: date,
          warning: false,
          weekend: this.$weekendDays.indexOf(getDay(date)) !== -1,
          holiday: this.isHoliday(date),
          future: isFuture(date),
          records: []
        }
      })
      this.records.forEach(item => {
        let date = parse(item.date)
        if (dategroups[date]) {
          dategroups[date].records.push(item)
        }
      })
      // Sort by raw dates
      dategroups = orderBy(Object.values(dategroups), ['date'])
      dategroups.forEach(item => {
        if (item.records.length > 0) {
          item.totalDuration = formatDurationString(
            getTotalDuration(item.records.map(record => record.duration))
          )
        } else {
          item.warning = !item.weekend && !item.holiday && !item.future
          item.totalDuration = ''
        }
      })
      return dategroups
    }
  },

  methods: {
    isHoliday (date) {
      /*
      Check if page should be marked as holiday.

      Holiday calendar may contain national celebrations that are NOT paid holidays
       */
      let holiday = this.getHolidayForDate(date.valueOf())
      if (holiday !== undefined) {
        return holiday.is_public_holiday || holiday.is_company_holiday
      } else {
        return false
      }
    }
  }

}

</script>

<style lang="scss" scoped>
.day {
  padding: 0;
  margin: 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  width: 3rem;
}
.holiday {
  background: #eee;
}
.weekend {
  background: #eee;
}
.future {
  background-color: #fafafa;
}
.warning {
  background: #faa;
}
</style>
