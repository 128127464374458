<!--
Row for details of a single timesheet record inside day (not editing view)
-->

<template>
  <b-col
    class="record"
    :class="{ 'hovered': hovered, 'disabled': record.is_locked }"
    @mouseover="mouseOver"
    @mouseleave="hovered = false"
  >
    <b-row class="mx-1">
      <div class="project-code">
        {{ record.project_code }}
      </div>
      <div class="project-name">
        {{ record.name }}
      </div>
      <div
        v-if="record.task"
        class="project-task ml-2"
      >
        {{ record.task.name }}
      </div>

      <div class="info ml-auto">
        <div class="icons">
          <span
            v-if="record.is_locked"
            class="lock-icon"
          >
            <font-awesome-icon icon="lock" />
          </span>
          <span
            v-if="record.is_billable"
            class="billable-icon"
          >
            <font-awesome-icon icon="euro-sign" />
          </span>
          <span
            v-if="record.record_type.category == 'travel'"
            class="non-billable-icon"
          >
            <font-awesome-icon icon="plane-departure" />
          </span>
          <span
            v-if="record.record_type.is_billable"
            class="non-billable-icon"
          >
            <font-awesome-icon icon="euro-sign" />
          </span>
        </div>
        <div class="duration">
          {{ record.start === null ? duration : timerange }}
        </div>
      </div>
    </b-row>

    <b-row class="comments">
      <div
        v-for="comment in record.comments"
        :key="comment"
        class=""
      >
        {{ comment }}
      </div>
    </b-row>
  </b-col>
</template>

<script>
import { formatDurationValue, formatTimeRange } from '@/components/utils/timeUtils'

export default {

  name: 'RecordRow',

  props: {
    record: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      hovered: false
    }
  },

  computed: {
    timerange () {
      /*
      Return total working time range (start/end times) as formatted string
       */
      return formatTimeRange(this.record.start, this.record.end)
    },
    duration () {
      /*
      Return formatted work duration (HH:MM) as formatted string
       */
      return formatDurationValue(this.record.duration)
    }
  },

  methods: {
    mouseOver () {
      this.hovered = !this.record.is_locked
    }
  }

}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/theme_constants.scss';

div.record {
  border-top: 1px solid $gray-4;
  background-color: $background-color;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  min-height: 3rem;
  &.hovered {
    border: 1px solid $primary-color;
  }
  &.opened {
    border: 1px solid $primary-color;
  }
  &.disabled {
    color: $gray-1;
    background-color: $gray-6;
  }
  div.project-code {
    min-width: 2em;
    margin-right: 0.5em;
    font-weight: bold;
  }
  div.project-name {
    font-weight: bold;
  }
  div.project-task {
    min-width: 8em;
    font-style: italic;
  }
  div.comments {
    display: block;
    margin-left: 3em;
    margin-right: 1.5em;
  }
  div.info {
    display: flex;
    flex-direction: row;

    div.duration {
      font-weight: bold;
      margin-left: 1em;
    }
    div.icons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      padding: 0;
      color: $primary-color-dark;
      .lock-icon {
        color: $primary-color-dark;
      }
    }
  }
}
</style>
