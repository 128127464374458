<!--
Show employee details for a single employee, based on route parameters or logged in employee
-->

<template>
  <PagedEditorView
    :parent="bus"
    :loading="loading"
    :error="error"
    :editing="editing"
    :show-page-navigation="showPageNavigation"
    :navigation-filters="navigationFilters"
    :navigation-actions="navigationActions"
    :breadcrumbs="breadcrumbs"
    :employee="record"
    :title="employeeName"
    :can-edit="canEdit"
    :no-edit="noEdit"
    :page-name="pageName"
    :navigation-heading="navigationHeading"
    @events="eventHandler"
  >
    <template slot="sub-nav-heading">
      <div
        v-if="showPageNavigation"
        class="profile"
      >
        <ProfileImage
          :url="record.profile_image"
        />
        <h5>{{ employeeName }}</h5>
      </div>
    </template>

    <template slot="editor-controls">
      <span
        v-if="selectedPage === 'projects'"
      >
        <b-navbar-nav
          class="sub-nav-buttons"
        >
          <b-nav-item
            key="active"
          >
            <div
              :class="{ 'selected': false }"
            >
              Active
            </div>
          </b-nav-item>
          <b-nav-item
            key="inactive"
          >
            <div
              :class="{ 'selected': false }"
            >
              Inactive
            </div>
          </b-nav-item>
          <b-nav-item
            key="all"
          >
            <div
              :class="{ 'selected': false }"
            >
              all
            </div>
          </b-nav-item>
        </b-navbar-nav>
      </span>
      <span
        v-if="selectedPage === 'unpaid'"
        @click="bus.$emit('notification', 'lock-unpaid-hours')"
      >
        Lock unpaid hours
      </span>
    </template>

    <template slot="details-view">
      <EmployeeDetails
        v-if="!loading && selectedPage === 'details'"
        :parent="bus"
        :record="record"
      />
    </template>

    <template slot="details-editor">
      <EmployeeEditForm
        v-if="!loading && selectedPage === 'details'"
        :parent="bus"
        :record="record"
        :editing="editing"
        @events="eventHandler"
      />
      <EmployeeEditForm
        v-if="selectedPage === 'new'"
        :parent="bus"
        :editing="editing"
        @events="eventHandler"
      />
    </template>

    <template slot="details-listing">
      <ContractPeriods
        v-if="selectedPage === 'contracts'"
        :parent="bus"
        :employee="record"
      />
      <Remunerations
        v-if="selectedPage === 'remunerations'"
        :parent="bus"
        :employee="record"
      />
      <UnpaidHours
        v-if="selectedPage === 'unpaid'"
        :parent="bus"
        :employee="record"
      />
    </template>
  </PagedEditorView>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import PagedEditor from '@/components/base/PagedEditor.vue'
import EmployeeDetails from '@/components/employee/EmployeeDetails.vue'
import ContractPeriods from '@/components/employee/ContractPeriods.vue'
import Remunerations from '@/components/employee/Remunerations.vue'
import UnpaidHours from '@/components/employee/UnpaidHours.vue'
import EmployeeEditForm from '@/components/employee/EmployeeEditForm.vue'
import ProfileImage from '@/components/employee/ProfileImage.vue'

export default {
  name: 'Employee',

  components: {
    ContractPeriods,
    Remunerations,
    EmployeeDetails,
    EmployeeEditForm,
    UnpaidHours,
    ProfileImage
  },

  extends: PagedEditor,

  data: function () {
    return {
      listRouteName: 'employee-list',
      detailRouteName: 'employee-details',
      createRouteName: 'employee-create',
      neverEdit: ['details', 'new', 'unpaid']
    }
  },

  computed: {
    ...mapGetters([
      'getEmployeeDetails',
      'loadingEmployeeDetails',
      'errorLoadingEmployeeDetails'
    ]),

    loading () {
      return this.loadingEmployeeDetails
    },

    error () {
      return this.errorLoadingEmployeeDetails
    },

    listRouteTitle () {
      return 'Employees'
    },

    detailRouteTitle () {
      return this.employeeName
    },

    email () {
      /*
      Returns email of current employee.

      Note: this comes from query parameters, NOT from record. Here may be more logic.
       */
      if (this.$route.params.email !== undefined) {
        return this.$route.params.email
      } else {
        return undefined
      }
    },

    employeeName () {
      /*
      Return employee name

      Note: this does not come from record, it's defined in store
       */
      return this.record !== undefined && this.record.name ? this.record.name : ''
    },

    activeContractType () {
      /*
      Returns type of active contract for employee.

      May return undefined for past and future employees
       */
      if (this.record !== undefined) {
        return this.record.active_contract_period ? this.record.active_contract_period.type : undefined
      } else {
        return undefined
      }
    },

    detailRouteParams () {
      /*
      Params to set to route when going to detail route
       */
      return {
        email: this.email
      }
    },

    navigationFilters () {
      if (!this.email) {
        return []
      }
      return [
        {
          key: 'new',
          label: 'Create employee',
          hidden: true,
          selected: this.selectedPage === 'new'
        },
        {
          key: 'details',
          label: 'Details',
          selected: this.selectedPage === 'details'
        },
        {
          key: 'contracts',
          label: 'Contracts',
          selected: this.selectedPage === 'contracts'
        },
        {
          key: 'remunerations',
          label: 'Remunerations',
          selected: this.selectedPage === 'remunerations'
        },
        {
          key: 'unpaid',
          label: 'Unpaid Hours',
          hidden: this.activeContractType !== 'parttime',
          selected: this.selectedPage === 'unpaid'
        }
      ]
    },

    navigationHeading () {
      /*
      Page navigation heading for browser history
       */
      if (this.record !== undefined) {
        return `${this.pageName} - ${this.employeeName}`
      } else {
        return this.pageName
      }
    },

    pageName () {
      switch (this.selectedPage) {
        case 'new': {
          return 'Create Employee'
        }
        case 'details': {
          return this.editing ? 'Edit Employee' : 'Employee Details'
        }
        case 'contracts': {
          return 'Employment Contracts'
        }
        case 'remunerations': {
          return 'Remunerations'
        }
        case 'unpaid': {
          return 'Unpaid hours'
        }
        default: {
          return 'Employee'
        }
      }
    },

    canSetAdminRole () {
      /*
      Check if logged in employee can set employee admin status
       */
      return this.isAdminUser
    },

    canDelete () {
      /*
      Check if logged in employee can edit employee record
       */
      return this.isAdminUser
    },

    canEdit () {
      /*
      Check if logged in emplyoee can edit employee record
       */
      switch (this.selectedPage) {
        case 'new': {
          // This does not refer to the form
          return false
        }
        case 'details': {
          return this.isAdminUser || this.isHRUser
        }
        case 'contracts': {
          return this.isAdminUser || this.isHRUser
        }
        case 'projects': {
          return false
        }
        case 'remunerations': {
          return this.isAdminUser || this.isHRUser || this.isFinanceUser
        }
        case 'unpaid': {
          return this.isAdminUser || this.isFinanceUser
        }
        default: {
          return false
        }
      }
    }
  },

  watch: {
    '$route.params.email' () {
      this.loadData()
    }
  },

  mounted () {
    const $vm = this
    Vue.nextTick(function () {
      $vm.newRecord = $vm.email === undefined
      $vm.editing = !!$vm.newRecord
    })
  },

  methods: {

    loadData () {
      /*
      Update form data
       */
      if (this.email) {
        this.$store.dispatch(
          'loadEmployeeDetails',
          this.email
        )
          .then((data) => {
            this.record = this.getEmployeeDetails
          })
          .catch((error) => {
            this.$emit('error', error.response)
          })
      } else {
        this.editNewRecord()
      }
    },

    loadCreatedRecord (record) {
      /*
      Load new employee record
       */
      this.$router.push({
        name: this.detailRouteName,
        params: {
          email: record.email
        },
        query: {
          page: 'details'
        }
      })
      this.selectedPage = 'details'
    }
  }
}

</script>
