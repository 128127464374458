<!--
Common row of form buttons (cancel / save / delete)
-->

<template>
  <b-row>
    <slot name="button-row-items" />
    <b-col
      :sm="width"
      class="mt-2"
    >
      <div class="float-right">
        <b-button
          v-if="canCancel"
          class="mr-2"
          name="cancel-button"
          :disabled="cancelDisabled"
          @click="cancelClicked"
        >
          Cancel
        </b-button>
        <b-button
          v-if="canDelete"
          class="mr-2"
          name="delete-button"
          :disabled="deleteDisabled"
          @click="deleteClicked"
        >
          Delete
        </b-button>
        <b-button
          class="mr-2"
          name="save-button"
          :disabled="saveDisabled"
          @click="saveClicked"
        >
          Save
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'FormButtons',

  props: {
    processing: {
      type: Boolean,
      required: true
    },
    width: {
      type: Number,
      default: 12
    },
    canCancel: {
      type: Boolean,
      required: true
    },
    canDelete: {
      type: Boolean,
      required: true
    },
    canSave: {
      type: Boolean,
      required: true
    },
    cancelDisabled: {
      type: Boolean,
      required: true
    },
    deleteDisabled: {
      type: Boolean,
      required: true
    },
    saveDisabled: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    saveClicked () {
      this.$emit('events', 'save-clicked')
    },

    deleteClicked () {
      this.$emit('events', 'delete-clicked')
    },

    cancelClicked () {
      this.$emit('events', 'cancel-clicked')
    }
  }

}
</script>
