<!--
List of employees
-->

<template>
  <BrowsableListingView
    :parent="bus"
    :title="title"
    :loading="loadingEmployees"
    :error="errorLoadingEmployees"
    :rows="getEmployees"
    :fields="fields"
    :navigation-filters="navigationFilters"
    :navigation-actions="navigationActions"
    :search-fields="searchFields"
    :sort-compare="sortCompare"
    @events="eventHandler"
  >
    <template v-slot:cell(flexible_hours)="row">
      <FlexibleHours
        v-if="flexibleHoursAllowed(row.item)"
        :data="row.item.flexible_hours"
      />
    </template>

    <template v-slot:cell(contract)="row">
      <ContractPeriodInfo
        v-if="visibleContractPeriod(row.item)"
        class="contract-period-info"
        :parent="bus"
        :record="visibleContractPeriod(row.item)"
      />
    </template>

    <template v-slot:cell(roles)="row">
      <div v-if="row.item.is_admin">
        Admin
      </div>
      <div v-if="row.item.is_finance">
        Finance
      </div>
      <div v-if="row.item.is_hr">
        HR
      </div>
    </template>
  </BrowsableListingView>
</template>

<script>
import { parse, startOfDay } from 'date-fns'
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

import BrowsableListingView from '../../components/elements/BrowsableListingView.vue'
import FilteredList from '@/components/base/FilteredList.vue'
import ContractPeriodInfo from '../../components/employee/ContractPeriodInfo.vue'
import FlexibleHours from '../../components/employee/FlexibleHours.vue'

export default {
  name: 'Employees',

  components: {
    BrowsableListingView,
    ContractPeriodInfo,
    FlexibleHours
  },

  extends: FilteredList,

  data () {
    return {
      queryFilters: {
        is_active: undefined,
        contract_type: undefined,
        type: undefined
      },
      defaultFilters: {
        is_active: true
      },
      searchFields: [
        'name',
        'email',
        'type'
      ],
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'flexible_hours',
          label: 'Flexible Hours',
          sortable: true
        },
        {
          key: 'contract',
          label: 'Contract',
          sortable: true
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true
        },
        {
          key: 'roles',
          label: 'Roles'
        }
      ],
      filterGroups: {
        active: {
          label: 'Active',
          title: 'Active Employees',
          query: {
            is_active: true
          }
        },
        internal: {
          label: 'Internal',
          title: 'Internal Employees',
          query: {
            type: 'internal',
            is_active: true
          }
        },
        fulltime: {
          label: 'Full-time',
          title: 'Full-time employees',
          query: {
            type: 'internal',
            contract_type: 'fulltime',
            is_active: true
          }
        },
        parttime: {
          label: 'Part-time',
          title: 'Part-time Employees',
          query: {
            type: 'internal',
            contract_type: 'parttime',
            is_active: true
          }
        },
        contractor: {
          label: 'Contractors',
          title: 'External Contractors',
          query: {
            type: 'contractor',
            is_active: true
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'isAdminUser',
      'isHRUser',
      'getEmployees',
      'loadingEmployees',
      'errorLoadingEmployees'
    ]),

    loading () {
      return this.loadingEmployees
    },

    error () {
      return this.errorLoadingEmployees
    },

    navigationActions () {
      /*
      Allow admin and hr roles to create employees
       */
      let actions = []
      if (this.isAdmin || this.isHRUser) {
        actions.push(
          {
            key: 'create',
            label: 'Create Employee',
            route: {
              name: 'employee-create'
            }
          }
        )
      }
      return actions
    }

  },

  mounted () {
    /*
    Use default query fields if none is specified
     */
    if (isEmpty(this.activeFilters)) {
      this.$router.push({
        name: this.$route.name,
        query: this.defaultFilters
      })
    } else {
      this.loadRecords()
    }
  },

  methods: {

    loadRecords () {
      /*
      Load employee records matching current filter parameters
       */
      let filters = !isEmpty(this.activeFilters) ? this.activeFilters : this.defaultFilters
      this.$store.dispatch('loadEmployees', filters)
    },

    eventHandler (action, ...args) {
      switch (action) {
        case 'row-clicked': {
          this.$router.push({
            name: 'employee-details',
            params: {
              email: args[0].email,
              query: {
                page: 'details'
              }
            }
          })
          break
        }
      }
    },

    visibleContractPeriod (employee) {
      /*
      Active contract or if not found, latest contract period by date
       */
      let contractPeriod = this.activeContractPeriod(employee)
      if (contractPeriod !== undefined) {
        return contractPeriod
      } else {
        let selected
        employee.contract_periods.find(period => {
          if (selected === undefined || parse(selected.start_date) < parse(period.start_date)) {
            selected = period
          }
        })
        return selected
      }
    },

    activeContractPeriod (employee) {
      /*
      Return active contract period for given employee
      */
      let today = startOfDay(new Date())
      let start
      let end
      return employee.contract_periods.find(period => {
        start = parse(period.start_date)
        end = period.end_date !== null ? parse(period.end_date) : null
        if (start <= today) {
          if (end === null || end >= today) {
            return period
          }
        }
      })
    },

    flexibleHoursAllowed (employee) {
      /*
      Returns true if flexible hours are allowed (current contract is full time)
       */
      let period = this.activeContractPeriod(employee)
      return (!isEmpty(period) && period.type === 'fulltime')
    },

    sortCompare (a, b, key, sortDesc) {
      /*
      Sorting for custom fields

      - sort name by last name, first name
      - sort flexible hours by total value in minutes
      - sort contract periods by start date

      Returning null uses b-table default sort/compare method
       */
      switch (key) {
        case 'name':
          a = [a.last_name, a.first_name]
          b = [b.last_name, b.first_name]
          break
        case 'flexible_hours':
          a = a.flexible_hours.hours * 60 + a.flexible_hours.minutes
          b = b.flexible_hours.hours * 60 + b.flexible_hours.minutes
          break
        case 'contract':
          a = this.activeContractPeriod(a)
          b = this.activeContractPeriod(b)
          a = !isEmpty(a) ? parse(a.start_date).valueOf() : null
          b = !isEmpty(b) ? parse(b.start_date).valueOf() : null
          break
        default:
          return null
      }
      return a < b ? -1 : a > b ? 1 : 0
    }
  }

}
</script>

<style lang="scss" scoped>
.contract-period-info {
  display: flex;
  flex-direction: column;
}
</style>
