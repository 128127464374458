/*
Employee list
 */

import { orderBy } from 'lodash'

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadEmployees: ({ commit }, params = {}) => {
    /*
    Load list of employees from API
    */
    return helpers.loadListing(
      commit,
      'timesheet/employees',
      params,
      types.EMPLOYEE_LIST_LOAD,
      types.EMPLOYEE_LIST_LOAD_SUCCESS,
      types.EMPLOYEE_LIST_LOAD_ERROR
    )
  },
  loadEmployeeDetails: ({ commit }, email) => {
    return helpers.loadDetails(
      commit,
      `timesheet/employees/${email}`,
      types.EMPLOYEE_DETAILS_LOAD,
      types.EMPLOYEE_DETAILS_LOAD_SUCCESS,
      types.EMPLOYEE_DETAILS_LOAD_ERROR
    )
  },
  createEmployee: ({ commit }, params = {}) => {
    /*
    Create a employee record
     */
    return helpers.postRecord(
      commit,
      'timesheet/employees',
      params,
      types.EMPLOYEE_CREATE,
      types.EMPLOYEE_CREATE_SUCCESS,
      types.EMPLOYEE_CREATE_ERROR
    )
  },
  updateEmployee: ({ commit }, params = {}) => {
    /*
     Update existing employee
     */
    return helpers.updateRecord(
      commit,
      `timesheet/employees/${params.email}`,
      params,
      types.EMPLOYEE_UPDATE,
      types.EMPLOYEE_UPDATE_SUCCESS,
      types.EMPLOYEE_UPDATE_ERROR
    )
  },
  deleteEmployee: ({ commit }, params = {}) => {
    /*
     Delete existing employee
     */
    return helpers.deleteRecord(
      commit,
      `timesheet/employees/${params.email}`,
      types.EMPLOYEE_DELETE,
      types.EMPLOYEE_DELETE_SUCCESS,
      types.EMPLOYEE_DELETE_ERROR
    )
  }
}

const mutations = {
  [types.EMPLOYEE_LIST_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.EMPLOYEE_LIST_LOAD_SUCCESS] (state, data) {
    data = orderBy(data, ['last_name', 'first_name'])
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.EMPLOYEE_LIST_LOAD_ERROR] (state, error) {
    helpers.mutateAPIDetailsLoadFailed(state, error)
  },
  [types.EMPLOYEE_DETAILS_LOAD] (state) {
    helpers.mutateAPIDetailsLoad(state)
  },
  [types.EMPLOYEE_DETAILS_LOAD_SUCCESS] (state, data) {
    data.missing_dates = data.missing_dates.map(item => {
      return new Date(item)
    })
    helpers.mutateAPIDetailsLoaded(state, data)
  },
  [types.EMPLOYEE_DETAILS_LOAD_ERROR] (state, error) {
    helpers.mutateAPIDetailsLoadFailed(state, error)
  },
  [types.EMPLOYEE_CREATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.EMPLOYEE_CREATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.EMPLOYEE_CREATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.EMPLOYEE_UPDATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.EMPLOYEE_UPDATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.EMPLOYEE_UPDATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.EMPLOYEE_DELETE] (state, error) {
    helpers.mutateItemDeleting(state)
  },
  [types.EMPLOYEE_DELETE_SUCCESS] (state, error) {
    helpers.mutateItemDeleted(state)
  },
  [types.EMPLOYEE_DELETE_ERROR] (state, error) {
    helpers.mutateItemDeleteFailed(state)
  }

}

const getters = {
  getEmployees: () => {
    return state.items
  },
  getEmployeeDetails: () => {
    return state.details
  },
  getEmployeeHasRecentMissingDates: () => {
    /*
    Return boolean to check if employee has recent missing dates
     */
    if (state.details) {
      return state.details.has_recent_missing_dates
    } else {
      return false
    }
  },
  getEmployeeRecentMissingDates: () => {
    /*
    Return list of recent missing dates for employee

    Limit how for back missing dates are considered recent is configured in backend settings
     */
    if (state.details) {
      return state.details.recent_missing_dates
    } else {
      return undefined
    }
  },
  getEmployeeFlexibleHours: () => {
    /*
    Return employee flexible hours data
    */
    if (state.details) {
      return state.details.flexible_hours
    } else {
      return undefined
    }
  },
  loadingEmployees: () => {
    /*
    Check if employee list is loading
     */
    return state.loading
  },
  loadingEmployeeDetails: () => {
    /*
    Check if employee details are loading
     */
    return state.loadingDetails
  },
  updatingEmployeeDetails: () => {
    /*
    Check if employee record is being updated
     */
    return state.updating
  },
  deletingEmployeeDetails: () => {
    /*
    Check if employee record is being deleted
     */
    return state.deleting
  },
  errorLoadingEmployees: () => {
    /*
    Check if loading employees list caused errors
     */
    return state.errors.loading
  },
  errorLoadingEmployeeDetails: () => {
    /*
    Check if loading employee details caused errors
     */
    return state.errors.loadingDetails
  },
  errorUpdatingEmployee: () => {
    /*
    Check if updating employee details caused errors
     */
    return state.errors.updating
  },
  errorDeletingEmployee: () => {
    /*
    Check if deleting employee details caused errors
     */
    return state.errors.deleting
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
