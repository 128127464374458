<!--
Time entry field form component

Can also be used to input time duration in range 00:00 to 23:59

Note: this field does not handle durations longer than one day
-->

<template>
  <div>
    <field-legend
      :id="field.name"
      :label="label"
      :description="description"
      :tooltip-variant="tooltipVariant"
      :tooltip-placement="tooltipPlacement"
    />
    <b-input-group>
      <b-form-input
        v-model="value"
        :class="{ 'form-inline': true, 'validation-error': error }"
        :name="`input-${field.name}`"
        :placeholder="placeholder"
        :disabled="disabled"
        @keydown.backspace="deleteChar(field.name, value)"
        @keyup.enter="save"
        @input="$emit('input', field.name, $event)"
      />
      <b-input-group-text
        slot="append"
        class="cursor-pointer"
        @click="toggleClicked"
      >
        <font-awesome-icon icon="exchange-alt" />
      </b-input-group-text>
    </b-input-group>
    <div
      v-if="error"
      class="field-validation-error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { isEmpty } from 'lodash'
import Field from './Field.vue'
import { validateTimeField } from '../validators'

export default {
  name: 'TimeField',

  extends: Field,

  props: {
    placeholder: {
      type: String,
      default: 'HH:MM'
    },
    // Time field can be in HH:MM or HH:MM:SS formats. Default is HH:MM
    withSeconds: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      defaultValidators: [
        validateTimeField
      ]
    }
  },

  computed: {
    label () {
      return this.field.inputs[this.field.name] !== undefined ? this.field.inputs[this.field.name].label : undefined
    },
    description () {
      return this.field.inputs[this.field.name] !== undefined ? this.field.inputs[this.field.name].description : undefined
    }
  },

  methods: {

    formatValue (key, value) {
      /*
      Format time field value
      We may receive seconds from API but let's drop that value is without seconds
       */
      if (!this.withSeconds) {
        // Drop seconds from value
        value = !isEmpty(value) ? value.split(':').splice(0, 2).join(':') : ''
      }
      if (value.charAt(0) === '0') {
        value = value.substring(1)
      }
      return value
    },

    formatTimeForAPI (value) {
      /*
      Ensure time sent to API is always with seconds
       */
      let match

      match = value.match('[0-9]*:')
      if (match && match[0] === value) {
        // Plain hour with prefilled : - send only hours to backend
        value = value.replace(':', '')
      }

      match = value.match('[0-9]*')
      if (match && match[0] === value) {
        // Plain hours value without minutes, add :00
        value = `${value}:00`
      }

      if (!this.withSeconds) {
        let match = value.match('[0-9]*:[0-9]*')
        if (match && match[0] === value) {
          // Add missing :00 to make value HH:MM:SS as api requires
          value = `${value}:00`
        }
      }
      return value
    },

    setFieldValue (key, value) {
      /*
       Set value back to field in form
       */
      const $vm = this

      // Remove double ::
      value = value.replace(/::/g, ':')

      Vue.nextTick(function () {
        $vm.value = value
        $vm.$emit('update', 'update-value', $vm.field.name, key, $vm.formatTimeForAPI(value))
      })
    },

    deleteChar (key, value) {
      /*
      Parse backspace (delete character) corner cases

      Note: this must use internal value, not value from field
       */
      let match = this.value.match(['[0-9]*:'])
      if (match && match[0] === this.value) {
        let value = this.value.replace(/:/, '')
        if (value === '0') {
          value = ''
        }
        this.value = value
      }
    },

    parseInputValue (key, value) {
      /*
      'Lazy patterns' allow entering number with space and . instead of only :
       */
      // Check if we entered hour value with > 2 -> we can add : directly because hours
      // value can be max 24,
      let match

      if (this.withSeconds) {
        const lazyPatterns = [ '[0-9]* ', '[0-9]*\\.', '[0-9]*:[0-9]* ', '[0-9]*:[0-9]*\\.' ]
        lazyPatterns.forEach(pattern => {
          match = value.match(pattern)
          if (match && match[0] === value) {
            value = `${value.replace(/[^0-9]$/, '')}:`
          }
        })
      } else {
        const lazyPatterns = [ '[0-9]* ', '[0-9]*\\.' ]
        lazyPatterns.forEach(pattern => {
          match = value.match(pattern)
          if (match && match[0] === value) {
            value = `${value.replace(/[^0-9]$/, '')}:`
          }
        })
      }

      let smallHoursMatch = value.match('[0-2][0-9]')
      let largeHoursMatch = value.match('[3-9]')
      if (largeHoursMatch && largeHoursMatch[0] === value) {
        // Add : to large hours immediately if <= 24. Don't bother with larger
        // values, raises validation error anyway
        if (parseInt(value) <= 10 && parseInt(value) < 25) {
          value = `${value}:`
        }
      } else if (smallHoursMatch && smallHoursMatch[0] === value) {
        // Add : to small hours immediately if > 9
        if (parseInt(value) >= 10 && parseInt(value) < 25) {
          value = `${value}:`
        }
      } else if (value === '0') {
        // Add : immediately after entering 0 to enter just minutes
        value = `${value}:`
      }
      // Remove double ::
      value = value.replace(/::/g, ':')

      return value
    },

    checkIncompleteValue (key, value) {
      /*
      'Pending patterns' indicate incomplete value which must not raise validation error but
      prevents form submission
       */
      let pending = false

      if (this.withSeconds) {
        const pendingPatterns = [ '[0-9]*:', '[0-9]*:[0-9]', '[0-9]*:[0-9]*:', '[0-9]*:[0-9]*:[0-9]' ]
        pendingPatterns.forEach(pattern => {
          let match = value.match(pattern)
          if (match && match[0] === value) {
            pending = true
          }
        })
      } else {
        const pendingPatterns = [ '[0-9]*:[0-9]' ]
        pendingPatterns.forEach(pattern => {
          let match = value.match(pattern)
          if (match && match[0] === value) {
            pending = true
          }
        })
      }
      return pending
    }
  }

}
</script>
