<!--
Form to edit project members
-->

<template>
  <div>
    <b-form v-if="!loadingFormData">
      <b-row>
        <b-col v-if="!employee">
          <dropdown-field
            :parent="bus"
            :field="fields.employee"
            :disabled="processing"
            :options="fields.employee.options"
            @update="updateField"
          />
        </b-col>
        <b-col v-else>
          <b>Employee</b>
          {{ employee.email }}
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <dropdown-field
            :parent="bus"
            :field="fields.role"
            :disabled="processing"
            :options="fields.role.options"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <date-range-field
            :parent="bus"
            :field="fields.date_range"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-input-group>
            <number-field
              v-if="allocation_type === 'days'"
              :parent="bus"
              :field="fields.allocation_days"
              :disabled="processing"
              :type-indicator="allocationTypeString"
              @toggleType="toggleAllocationType"
              @update="updateField"
            />
            <percentage-field
              v-if="allocation_type === 'percentage'"
              :parent="bus"
              :field="fields.allocation_rate"
              :disabled="processing"
              :type-indicator="allocationTypeString"
              @toggleType="toggleAllocationType"
              @update="updateField"
            />
          </b-input-group>
        </b-col>

        <b-col>
          <number-field
            :parent="bus"
            :field="fields.hourly_price"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <FormButtons
        :processing="processing"
        :can-cancel="canCancel"
        :can-delete="canDelete"
        :can-save="canSave"
        :cancel-disabled="cancelDisabled"
        :delete-disabled="deleteDisabled"
        :save-disabled="saveDisabled"
        @events="updateField"
      />
    </b-form>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

import Form from '@/forms/Form.vue'
import FormButtons from '@/forms/FormButtons.vue'

export default {
  name: 'ProjectMemberEditForm',

  components: {
    FormButtons
  },

  mixins: [
    Form
  ],

  props: {
    employee: {
      type: Object,
      default: undefined
    },
    project: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      // Store actions to dispatch
      actions: {
        create: 'createProjectMember',
        update: 'updateProjectMember',
        delete: 'deleteProjectMember'
      },
      // Allocation type
      allocation_type: this.record.allocation_rate !== undefined ? 'percentage' : 'days',
      // All form fields
      'formFields': {
        id: {
          label: 'Record ID'
        },
        project: {
          label: 'Project Code'
        },
        employee: {
          label: 'Employee',
          inputs: {
            employee: {
              label: 'Select Employee',
              description: 'Employee for membership'
            }
          },
          required: this.employee === undefined,
          options: []
        },
        role: {
          label: 'Role',
          inputs: {
            role: {
              label: 'Employee Role',
              description: 'Role of employee in project'
            }
          },
          required: true,
          options: []
        },
        date_range: {
          label: 'Dates',
          inputs: {
            range_start: {
              key: 'start_date',
              label: 'Start date',
              description: 'Project membership start date'
            },
            range_end: {
              key: 'end_date',
              label: 'End date',
              description: 'Project membership end date'
            }
          },
          minimum: this.project.start_date,
          maximum: this.project.end_date,
          required: false
        },
        allocation_days: {
          inputs: {
            allocation_days: {
              label: 'Allocation (days)'
            }
          }
        },
        allocation_rate: {
          inputs: {
            allocation_rate: {
              label: 'Allocation (%)'
            }
          }
        },
        hourly_price: {
          inputs: {
            hourly_price: {
              label: 'Hourly Price'
            }
          }
        }
      }
    }
  },

  computed: {
    // Add getters for data and data load status
    ...mapGetters([
      'loggedInEmployeeEmail',
      'getProjectJoinableEmployeeChoices',
      'getProjectMemberRoleChoices',
      'loadingProjectMemberRoleChoices',
      'loadingEmployeeJoinableProjects'
    ]),
    allocationTypeString () {
      /*
      String to show in allocation type toggle
       */
      return this.allocation_type === 'percentage' ? '%' : 'days'
    },
    loadingOptions () {
      /*
      Check additional form options getters and load form when ready
       */
      return this.loadingEmployeeJoinableProjects || this.loadingProjectMemberRoleChoices
    },
    canDelete () {
      if (!isEmpty(this.record)) {
        return this.project.can_edit || this.record.employee === this.loggedInEmployeeEmail
      } else {
        return false
      }
    }
  },

  methods: {

    finishLoading () {
      this.setValue('project', 'project', this.project.project_code)
      if (!isEmpty(this.employee)) {
        this.setValue('employee', 'employee', this.employee.email)
      }
    },

    toggleAllocationType () {
      /*
      Toggle membership allocation type
       */
      this.allocation_type = this.allocation_type === 'days' ? 'percentage' : 'days'
    },

    loadFormOptions () {
      /*
      Load any options for dropdown fields
       */
      this.$store.dispatch('loadProjectJoinableEmployeeChoices', {
        project_code: this.project.project_code
      })
        .then((data) => {
          this.updateOptions('employee', this.getProjectJoinableEmployeeChoices)
        })
      this.$store.dispatch('loadProjectMemberRoleChoices', {
        project_code: this.project.project_code
      })
        .then((data) => {
          this.updateOptions('role', this.getProjectMemberRoleChoices)
        })
    }
  }

}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
