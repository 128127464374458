<!--
View to show active project member row in project members list
</template>
-->

<template>
  <div>
    <b-modal
      v-model="editModalVisible"
      name="record-edit-modal"
      hide-header
      centered
      hide-footer
      no-close-on-backdrop
      @hide="editCanceled"
    >
      <ProjectMemberEditForm
        v-if="editModalVisible"
        :parent="bus"
        :project="project"
        :employee="employee"
        @events="eventHandler"
      />
    </b-modal>

    <div class="member">
      <div class="name">
        {{ employee.name }}
      </div>
      <div class="membership">
        {{ timerange(membership ) }}
      </div>
      <div class="actions">
        <div
          v-if="canEdit"
          @click.stop="editNewMembership"
        >
          <font-awesome-icon icon="plus-circle" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { formatDateRange } from '@/components/utils/timeUtils'

import Editor from '@/components/base/Editor.vue'
import ProjectMemberEditForm from '@/components/project/ProjectMemberEditForm.vue'

export default {
  name: 'ProjectMember',

  components: {
    ProjectMemberEditForm
  },

  extends: Editor,

  props: {
    project: {
      type: Object,
      required: true
    },
    employee: {
      type: Object,
      required: true
    },
    membership: {
      type: Object,
      required: true
    }
  },

  computed: {
    canEdit () {
      return this.project.can_edit || this.loggedInEmployeeEmail === this.membership.employee.email
    }
  },

  methods: {
    loadData () {
    },

    timerange (membership) {
      /*
      Return formatted membership time range
      */
      if (!isEmpty(membership)) {
        return formatDateRange(membership.start_date, membership.end_date)
      } else {
        return ''
      }
    },

    editNewMembership () {
      this.editNewRecord()
    }
  }

}
</script>

<style lang="scss" scoped>
.member {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .name {
    margin-right: auto;
    min-width: 22em;
  }
  .membership {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: auto;
    min-width: 12em;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 1rem;
    min-width: 4em;
  }
}
</style>
