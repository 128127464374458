<!--
Form to edit employee contract period
-->

<template>
  <div>
    <b-form
      v-if="!loadingFormData"
    >
      <h3>{{ title }}</h3>

      <b-row class="mt-2 mb-2">
        <b-col>
          <h5>{{ employee.name }}</h5>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <dropdown-field
            :parent="bus"
            :field="fields.work_week"
            :disabled="processing"
            :options="fields.work_week.options"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <dropdown-field
            :parent="bus"
            :field="fields.type"
            :disabled="processing"
            :options="fields.type.options"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <date-range-field
            :parent="bus"
            :field="fields.date_range"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <percentage-field
            :parent="bus"
            :field="fields.percentage"
            :disabled="processing"
            @update="updateField"
          />
        </b-col>
      </b-row>

      <FormButtons
        :processing="processing"
        :can-cancel="canCancel"
        :can-delete="canDelete"
        :can-save="canSave"
        :cancel-disabled="cancelDisabled"
        :delete-disabled="deleteDisabled"
        :save-disabled="saveDisabled"
        @events="updateField"
      />
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

import Form from '@/forms/Form.vue'
import FormButtons from '@/forms/FormButtons.vue'
export default {
  name: 'ContractPeriodEditForm',

  components: {
    FormButtons
  },

  mixins: [
    Form
  ],

  props: {
    employee: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      // Store actions to dispatch
      actions: {
        create: 'createEmploymentContracPeriod',
        update: 'updateEmploymentContracPeriod',
        delete: 'deleteEmploymentContracPeriod'
      },
      // All form fields
      'formFields': {
        id: {
          label: 'Record ID'
        },
        employee: {
          label: 'Employee Email',
          required: true
        },
        work_week: {
          label: 'Work Week Type',
          required: true,
          options: this.getWorkWeekChoices
        },
        type: {
          label: 'Contract Type',
          required: true,
          options: this.getEmploymentContractTypeChoices
        },
        date_range: {
          label: 'Dates',
          inputs: {
            range_start: {
              key: 'start_date',
              label: 'Start date',
              description: 'Project start date'
            },
            range_end: {
              key: 'end_date',
              label: 'End date',
              description: 'Project end date'
            }
          },
          required: {
            start_date: true
          }
        },
        percentage: {
          label: 'Percentage',
          inputs: {
            percentage: {
              label: 'Percentage',
              description: 'Percent of full time for contract'
            }
          }
        }
      }
    }
  },

  computed: {
    // Add getters for data and data load status
    ...mapGetters([
      'isAdminUser',
      'isHRUser',
      'getWorkWeekChoices',
      'getEmploymentContractTypeChoices',
      'loadingWorkWeekChoices',
      'loadingEmploymentContractTypeChoices',
      'errorLoadingWorkWeekChoices',
      'errorLoadingEmploymentContractTypeChoices'
    ]),
    canDelete () {
      /*
      Contracts can be edited only by HR or superuser
       */
      return this.isAdminUser || this.isHRUser
    },
    loadingOptions () {
      return this.loadingEmploymentContractTypeChoices ||
             this.loadingWorkWeekChoices
    },
    errorLoadingOptions () {
      return this.errorLoadingEmploymentContractTypeChoices ||
             this.errorLoadingWorkWeekChoices
    },
    title () {
      /*
      Computed form title based on editing state
       */
      if (!isEmpty(this.record)) {
        return 'Edit Contract Period'
      } else {
        return 'Add Contract Period'
      }
    }
  },

  methods: {
    finishLoading () {
      /*
      Set static fields to form data
       */
      this.setValue('employee', 'employee', this.employee.email)
    },

    loadFormOptions () {
      /*
      Load any options for dropdown fields
       */
      const $vm = this
      this.$store.dispatch('loadWorkWeekChoices')
        .then((data) => {
          $vm.fields.work_week.options = $vm.getWorkWeekChoices
          $vm.fields.work_week.values.work_week = $vm.record.work_week
          $vm.bus.$emit('reload-options', 'work_week')
        })
      this.$store.dispatch('loadEmploymentContractTypeChoices')
        .then((data) => {
          $vm.fields.type.options = $vm.getEmploymentContractTypeChoices
          $vm.fields.type.values.type = $vm.record.type
          $vm.bus.$emit('reload-options', 'type')
        })
    }
  }

}
</script>
