<!--
Show a percentage line chart with div boxes of project allocation percentages
-->

<template>
  <div class="project-allocation-container">
    <div
      v-for="(project, index) in projects"
      :key="project.project_code"
      class="project-box"
      :style="{'background-color': palette[index], 'width': project.percent + '%'}"
    ></div>
  </div>
</template>

<script>

export default {
  name: 'ProjectPercentages',

  props: {
    projects: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      palette: ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"],
    }
  },

  mounted () {
    console.log('load projects', this.projects)
  }

}
</script>

<style lang="scss">
div.project-allocation-container {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
}
div.project-box {
  font-size: small;
  font-weight: bold;
  padding-left: 0.2em;
  height: 1.5em;
}
</style>
