/*
Timesheet record state
 */

import { has, orderBy } from 'lodash'

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadTimesheetRecords: ({ commit }, params = {}) => {
    /*
    Load list of timesheet records
    */
    return helpers.loadListing(
      commit,
      'timesheet/records',
      params,
      types.TIMESHEET_RECORD_LIST_LOAD,
      types.TIMESHEET_RECORD_LIST_LOAD_SUCCESS,
      types.TIMESHEET_RECORD_LIST_LOAD_ERROR
    )
  },
  createTimesheetRecord: ({ commit }, params) => {
    /*
    Add new timesheet record
     */
    return helpers.postRecord(
      commit,
      'timesheet/records',
      params,
      types.TIMESHEET_RECORD_CREATE,
      types.TIMESHEET_RECORD_CREATE_SUCCESS,
      types.TIMESHEET_RECORD_CREATE_ERROR
    )
  },
  updateTimesheetRecord: ({ commit }, params) => {
    /*
    Update existing timesheet record
     */
    return helpers.updateRecord(
      commit,
      `timesheet/records/${params.id}`,
      params,
      types.TIMESHEET_RECORD_UPDATE,
      types.TIMESHEET_RECORD_UPDATE_SUCCESS,
      types.TIMESHEET_RECORD_UPDATE_ERROR
    )
  },
  deleteTimesheetRecord: ({ commit }, params) => {
    /*
    Delete timesheet record
     */
    return helpers.deleteRecord(
      commit,
      `timesheet/records/${params.id}`,
      types.TIMESHEET_RECORD_DELETE,
      types.TIMESHEET_RECORD_DELETE_SUCCESS,
      types.TIMESHEET_RECORD_DELETE_ERROR
    )
  },
  timesheetRecordBulkUpdate: ({ commit }, params) => {
    /*
    Bulk update timesheet record flags

    This can be used to update 'is_billed' and 'is_paid' flags for now.
     */
    return helpers.postRecord(
      commit,
      'timesheet/record-bulk-update',
      params,
      types.TIMESHEET_RECORD_BULK_UPDATE,
      types.TIMESHEET_RECORD_BULK_UPDATE_SUCCESS,
      types.TIMESHEET_RECORD_BULK_UPDATE_ERROR
    )
  },
  resetTimesheetRecords: ({ commit }, params = {}) => {
    /*
    Reset loaded records
     */
    commit(types.TIMESHEET_RECORD_LIST_CLEAR)
  },
  clearTimesheetAPIErrors: ({ commit }, params) => {
    /*
    Clear errors from timesheets API
    */
    commit(types.TIMESHEET_RECORD_CLEAR_ERRORS)
  }
}

const mutations = {
  [types.TIMESHEET_RECORD_LIST_CLEAR] (state) {
    helpers.mutateResetData(state)
  },
  [types.TIMESHEET_RECORD_CLEAR_ERRORS] (state) {
    helpers.mutateClearAPIErrors(state)
  },
  [types.TIMESHEET_RECORD_LIST_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.TIMESHEET_RECORD_LIST_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.TIMESHEET_RECORD_LIST_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  },
  [types.TIMESHEET_RECORD_CREATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.TIMESHEET_RECORD_CREATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.TIMESHEET_RECORD_CREATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.TIMESHEET_RECORD_UPDATE] (state) {
    helpers.mutateItemUpdating(state)
  },
  [types.TIMESHEET_RECORD_UPDATE_SUCCESS] (state) {
    helpers.mutateItemUpdated(state)
  },
  [types.TIMESHEET_RECORD_UPDATE_ERROR] (state, error) {
    helpers.mutateItemUpdateFailed(state, error)
  },
  [types.TIMESHEET_RECORD_DELETE] (state) {
    helpers.mutateItemDeleting(state)
  },
  [types.TIMESHEET_RECORD_DELETE_SUCCESS] (state) {
    helpers.mutateItemDeleted(state)
  },
  [types.TIMESHEET_RECORD_DELETE_ERROR] (state, error) {
    helpers.mutateItemDeleteFailed(state, error)
  },
  [types.TIMESHEET_RECORD_BULK_UPDATE] (state) {
    helpers.mutateAPIBulkUpdating(state)
  },
  [types.TIMESHEET_RECORD_BULK_UPDATE_SUCCESS] (state) {
    helpers.mutateAPIBulkUpdateSuccess(state)
  },
  [types.TIMESHEET_RECORD_BULK_UPDATE_ERROR] (state, error) {
    helpers.mutateAPIBulkUpdateError(state, error)
  }
}

const getters = {
  getTimesheetRecords: () => {
    /*
    Get all loaded timesheet records
    */
    return state.items
  },
  timesheetRecordsByEmployee: () => {
    /*
    Return loaded timesheet records grouped by employee email
     */
    let data = {}
    state.items.forEach(item => {
      if (!has(data, item.employee.email)) {
        data[item.employee.email] = {
          name: `${item.employee.first_name} ${item.employee.last_name}`,
          first_name: item.employee.last_name,
          last_name: item.employee.last_name,
          email: item.employee.email,
          records: []
        }
      }
      data[item.employee.email].records.push(item)
    })
    data = orderBy(Object.values(data), ['last_name', 'first_name'])
    return data
  },
  loadingTimesheetRecords: () => {
    /*
    Check if timesheet record list is loading
     */
    return state.loading
  },
  loadingTimesheetRecordDetails: () => {
    /*
    Check if timesheet record details are loading
     */
    return state.loadingDetails
  },
  updatingTimesheetRecordDetails: () => {
    /*
    Check if timesheet record record is being updated
     */
    return state.updating
  },
  deletingTimesheetRecordDetails: () => {
    /*
    Check if timesheet record record is being deleted
     */
    return state.deleting
  },
  errorLoadingTimesheetRecords: () => {
    /*
    Check if loading timesheet record list caused errors
     */
    return state.errors.loading
  },
  errorLoadingTimesheetRecordDetails: () => {
    /*
    Check if loading timesheet record details caused errors
     */
    return state.errors.loadingDetails
  },
  errorUpdatingTimesheetRecord: () => {
    /*
    Check if updating timesheet record details caused errors
     */
    return state.errors.updating
  },
  errorDeletingTimesheetRecord: () => {
    /*
    Check if deleting timesheet record details caused errors
     */
    return state.errors.deleting
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
