<!--
View to show employee details
-->

<template>
  <b-container
    v-if="loaded"
  >
    <b-row class="mb-1">
      <b-col cols="4">
        <b>Email</b>
      </b-col>
      <b-col>
        {{ record.email }}
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col cols="4">
        <b>First Name</b>
      </b-col>
      <b-col>
        {{ record.first_name }}
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col cols="4">
        <b>Last Name</b>
      </b-col>
      <b-col>
        {{ record.last_name }}
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col cols="4">
        <b>Status</b>
      </b-col>
      <b-col
        :class="{
          'text-success': record.is_active,
          'text-danger': !record.is_active
        }"
        class="text-left"
      >
        {{ record.is_active ? 'Active' : 'Inactive' }}
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col cols="4">
        <b>Employee Type</b>
      </b-col>
      <b-col sm="4">
        {{ record.type }}
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col cols="4 mb-1">
        <b>Active Contract</b>
      </b-col>
      <b-col
        v-if="hasActiveContractPeriod"
      >
        <ContractPeriodInfo
          :parent="bus"
          :record="record.active_contract_period"
        />
      </b-col>
      <b-col v-else>
        No active contract
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col cols="4">
        <b>Preferred Time Format</b>
      </b-col>
      <b-col v-if="record.preferred_time_format === 'duration'">
        Duration
      </b-col>
      <b-col v-if="record.preferred_time_format === 'time'">
        Time Range
      </b-col>
    </b-row>

    <b-row
      v-if="roleChoicesVisible"
      class="mb-1"
    >
      <b-col cols="4">
        <b>Roles</b>
      </b-col>
      <b-col>
        <div>{{ record.is_admin? 'Has full admin permissions' : '' }}</div>
        <div>{{ record.is_hr ? 'Has HR role permissions' : '' }}</div>
        <div>{{ record.is_finance? 'Has finance role permissions' : '' }}</div>
        <div>{{ record.is_billing_reporter ? 'Has billing reporting role permissions' : '' }}</div>
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col cols="4"></b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col cols="4">
        <b>Flexible Hours Calculation Start Date</b>
      </b-col>
      <b-col sm="4">
        {{ record.flexible_hours_reset_date }}
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="4">
        <b>Flexible Hours</b>
      </b-col>
      <b-col class="text-left">
        <span>{{ record.flexible_hours.hours }}</span> h
        <span>{{ record.flexible_hours.minutes }}</span> min
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="4">
        <b>Total Unpaid Hourly Records</b>
      </b-col>
      <b-col class="text-left">
        <span>{{ record.total_unpaid_hours.hours }}</span> h
        <span>{{ record.total_unpaid_hours.minutes }}</span> min
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

import DataView from '@/forms/DataView.vue'
import ContractPeriodInfo from './ContractPeriodInfo.vue'
export default {
  name: 'EmployeeDetails',

  components: {
    ContractPeriodInfo
  },

  mixins: [
    DataView
  ],

  computed: {
    ...mapGetters([
      'isAdminUser',
      'isHRUser'
    ]),
    hasActiveContractPeriod () {
      /*
      Check if employee has active contract period
       */
      return !isEmpty(this.record.active_contract_period)
    },
    hasFlexibleHours () {
      /*
      Check if employee has flexible hours to show
      */
      if (!this.record.flexible_hours) {
        return false
      }
      return this.record.flexible_hours.hours > 0 || this.record.flexible_hours.minutes > 0
    },
    hasUnpaidHours () {
      /*
      Check if employee has unpaid hourly paid hours (relevant for part time employees)
      */
      if (!this.record.total_unpaid_hours) {
        return false
      }
      return this.record.total_unpaid_hours.hours > 0 || this.record.total_unpaid_hours.minutes > 0
    },
    roleChoicesVisible () {
      /*
      Hide things which are not public information about employee roles
       */
      return this.isAdminUser === true || this.isHRUser === true
    }
  }

}
</script>
