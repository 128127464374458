
import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadEmployeeTypeChoices: ({ commit }, params = {}) => {
    /*
    Load employee type choices
    */
    return helpers.loadListing(
      commit,
      'timesheet/autocomplete/employee-types',
      params,
      types.EMPLOYEE_TYPES_CHOICES_LOAD,
      types.EMPLOYEE_TYPES_CHOICES_LOAD_SUCCESS,
      types.EMPLOYEE_TYPES_CHOICES_LOAD_ERROR
    )
  }
}

const mutations = {
  [types.EMPLOYEE_TYPES_CHOICES_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.EMPLOYEE_TYPES_CHOICES_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.EMPLOYEE_TYPES_CHOICES_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  }
}

const getters = {
  getEmployeeTypeChoices: () => {
    /*
    Return all retrieved employee type autocompletions for v-select
    */
    return state.items
  },
  loadingEmployeeTypeChoices: () => {
    /*
    Check if employee type Choices are loading
     */
    return state.loading
  },
  errorLoadingEmployeeTypeChoices: () => {
    /*
    Check if employee type Choices caused errors
     */
    return state.errors.loading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
