<!--
Show details for one project billing report
-->

<template>
  <BaseView
    :parent="bus"
    :loading="loading"
    :error="error"
    :title="title"
    :breadcrumbs="breadcrumbs"
    :navigation-filters="navigationFilters"
    :navigation-actions="navigationActions"
    :close-error-clicked="closeErrorClicked"
    page-name="billing-report-details"
  >
    <template slot="sub-nav-heading">
      <div
        class="monthly-billing-heading"
      >
        Period {{ month }}
      </div>
    </template>

    <template slot="content">
      <div v-if="!loading">
        <ReportedRecordsSummary
          v-if="summary"
          :summary="summary"
          :contains-billable-travel="containsBillableTravel"
          :contains-non-billable="containsNonBillable"
          class="mt-4 mx-0"
        />

        <EmployeeRecordsSummary
          v-if="summary"
          :summary="summary"
          :contains-billable-travel="containsBillableTravel"
          :contains-non-billable="containsNonBillable"
          class="mt-4 mx-0"
        />

        <b-container
          fluid
          class="mt-4 mx-0"
        >
          <div class="heading-with-icons">
            <h3>
              Detailed records
            </h3>
            <span
              v-if="!canBeLocked"
              class="icons"
            >
              <font-awesome-icon icon="lock" />
            </span>
          </div>

          <!-- Tables when summary is grouped by task -->
          <div
            v-if="!loading && itemsGroupedByTask"
          >
            <b-row class="mt-2 mb-2">
              <b-col>
                List of records grouped by task.
              </b-col>
            </b-row>

            <div
              v-for="task in detailsGroupedByTask"
              :key="task.id"
            >
              <b-row class="mt-4">
                <b-col>
                  <h5>Task: {{ task.name }}</h5>
                  {{ task.description }}
                </b-col>
                <b-col class="text-right">
                  Total: {{ taskTotalBilledDuration(task) }}
                </b-col>
              </b-row>

              <ReportedRecords
                :items="task.records"
                :fields="visibleDetailsFields"
                :contains-billable-travel="containsBillableTravel"
                :contains-non-billable="containsNonBillable"
              />
            </div>
          </div>

          <!-- Normal table when not grouped by tasks or when no tasks exist -->
          <div
            v-if="!loading && !itemsGroupedByTask"
            class="mt-4"
          >
            <ReportedRecords
              :items="summary.timesheet_records"
              :fields="visibleDetailsFields"
              :contains-billable-travel="containsBillableTravel"
              :contains-non-billable="containsNonBillable"
            />
          </div>
        </b-container>
      </div>
      <Loading
        v-if="loading"
        message="Loading Report Details"
      />
    </template>
  </BaseView>
</template>

<script>
import { isEmpty, cloneDeep, orderBy } from 'lodash'
import { mapGetters } from 'vuex'
import { format, parse } from 'date-fns'
import { getTotalDuration, formatDurationString } from '@/components/utils/timeUtils'
import { createMonthlyBillingReport } from '@/pdf/projectBillingReports'

import BaseView from '@/components/elements/BaseView.vue'
import TimeRangeBrowser from '@/components/base/TimeRangeBrowser.vue'
import Loading from '@/components/common/Loading.vue'
import EmployeeRecordsSummary from '@/components/report/EmployeeRecordsSummary.vue'
import ReportedRecords from '@/components/report/ReportedRecords.vue'
import ReportedRecordsSummary from '@/components/report/ReportedRecordsSummary.vue'

export default {

  components: {
    BaseView,
    Loading,
    EmployeeRecordsSummary,
    ReportedRecords,
    ReportedRecordsSummary
  },

  extends: TimeRangeBrowser,

  data: function () {
    return {
      period: 'month',
      projectCode: this.$route.params.projectCode,
      summary: {
        timesheet_records: [],
        employee_hours: [],
        total_billable_hours: {
          hours: 0,
          minutes: 0
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'isAdminUser',
      'isFinanceUser',
      'getProjectBillingReportDetails',
      'loadingProjectBillingReportDetails',
      'errorLoadingProjectBillingReportDetails'
    ]),

    loading () {
      return this.loadingProjectBillingReportDetails
    },

    error () {
      return this.errorLoadingProjectBillingReportDetails
    },
    breadcrumbs () {
      return [
        {
          text: 'Monthly Billing Reports',
          to: { name: 'monthly-billing-reports' }
        },
        {
          text: this.breadCrumbTitle,
          to: {
            name: 'billing-report-details',
            params: this.breadCrumbTitle,
            month: this.month
          }
        }
      ]
    },

    breadCrumbTitle () {
      if (this.summary.name) {
        return `${this.projectCode} ${this.summary.name}`
      }
      return `Loading summary for ${this.projectCode}`
    },

    title () {
      return `Monthly Billing Report - ${this.month} - ${this.projectCode} ${this.projectName}`
    },

    groupDetailsByTask () {
      /*
      Boolean to check if project details records should be grouped by task
       */
      if (!isEmpty(this.summary)) {
        return this.summary.group_billing_details_by_task
      } else {
        return false
      }
    },

    hasTasks () {
      /*
      Checks if project has tasks
       */
      if (!isEmpty(this.summary)) {
        return this.summary.has_tasks
      } else {
        return false
      }
    },

    itemsGroupedByTask () {
      /*
      Checks if project is configured to group items by tasks and also has tasks
       */
      return this.groupDetailsByTask && this.hasTasks
    },

    detailsGroupedByTask () {
      /*
      Return details grouped by task, ordered by task name
      */
      let ungrouped = {
        id: 0,
        label: undefined,
        name: 'Records not linked to a task',
        description: 'This list contains records not linked to any task',
        records: []
      }
      let data = {}
      if (!isEmpty(this.summary)) {
        this.summary.timesheet_records.forEach(record => {
          if (record.task) {
            if (!data[record.task.id]) {
              data[record.task.id] = cloneDeep(record.task)
              data[record.task.id].records = []
            }
            data[record.task.id].records.push(record)
          } else {
            // Item without a task. Use the dummy entry
            ungrouped.records.push(record)
          }
        })
        data = orderBy(data, ['name'])
        if (ungrouped.records.length > 0) {
          // Push records not linked to tasks to end of lists
          data.push(ungrouped)
        }
      }
      return data
    },

    canBeLocked () {
      /*
      Check if period contains unlocked hours
       */
      if (!isEmpty(this.summary)) {
        return (this.isFinanceUser || this.isAdminUser) && this.summary.timesheet_records.filter(
          record => record.is_locked === false
        ).length > 0
      } else {
        return false
      }
    },

    containsBillableTravel () {
      /*
      Boolean to check if period contains non-billable hours
       */
      if (!isEmpty(this.summary)) {
        return this.summary.timesheet_records.filter(
          record => record.record_type.is_billable === true && record.record_type.category == 'travel'
        ).length > 0
      } else {
        return false
      }
    },

    containsNonBillable () {
      /*
      Boolean to check if period contains non-billable hours
       */
      if (!isEmpty(this.summary)) {
        return this.summary.timesheet_records.filter(
          record => record.record_type.is_billable === false
        ).length > 0
      } else {
        return false
      }
    },

    month () {
      return this.$route.params.month
    },

    previousPeriodParams () {
      /*
      Return route params for previous month
       */
      return {
        projectCode: this.projectCode,
        month: format(this.previousPeriodStart, this.$monthFormat)
      }
    },

    nextPeriodParams () {
      /*
      Return route params for previous month
       */
      return {
        projectCode: this.projectCode,
        month: format(this.nextPeriodStart, this.$monthFormat)
      }
    },

    navigationFilters () {
      return [
        {
          key: 'previous',
          label: 'Previous',
          route: {
            name: this.$route.name,
            params: this.previousPeriodParams
          }
        },
        {
          key: 'next',
          label: 'Next',
          disabled: this.nextPeriodInFuture,
          route: {
            name: this.$route.name,
            params: this.nextPeriodParams
          }
        }
      ]
    },

    navigationActions () {
      return [
        {
          key: 'download',
          label: 'Download PDF',
          callback: this.renderPDF
        },
        {
          key: 'lock',
          label: 'Lock Records',
          hidden: !this.canBeLocked,
          callback: this.lockRecords
        }
      ]
    },

    detailsFields () {
      return [
        {
          key: 'date',
          label: 'Date',
          tdClass: 'date',
          sortable: true
        },
        {
          key: 'duration',
          label: 'Duration',
          tdClass: 'duration'
        },
        {
          key: 'employee',
          label: 'Employee',
          tdClass: 'employee',
          sortable: true
        },
        {
          key: 'task',
          label: 'Task',
          hidden: !this.hasTasks || this.groupDetailsByTask,
          sortable: true
        },
        {
          key: 'comments',
          label: 'Comments'
        }
      ]
    },

    visibleDetailsFields () {
      /*
      Return visible details fields for loaded data
      */
      return this.detailsFields.filter(
        field => field.hidden !== undefined ? field.hidden === false : true
      )
    }

  },

  watch: {
    '$route.params' () {
      /*
      Update time period data when query parameters change
      */
      this.loadData()
    }
  },

  mounted () {
    this.loadData()
  },

  methods: {

    loadData () {
      /*
      Load billing summary details data
       */

      this.$store.dispatch('loadProjectBillingReportDetails', {
        projectCode: this.projectCode,
        month: this.month
      })
        .then((record) => {
          this.summary = this.getProjectBillingReportDetails
        })
    },

    taskTotalBilledDuration (task) {
      return formatDurationString(
        getTotalDuration(task.records.map(record => record.billed_duration))
      )
    },

    lockRecords () {
      /*
      Mark visible records as locked with API
       */
      if (!isEmpty(this.summary)) {
        let ids = this.summary.timesheet_records.map(record => record.id)
        this.$store.dispatch('timesheetRecordBulkUpdate', {
          project_code: this.projectCode,
          is_billed: true,
          ids: ids
        })
          .then((data) => {
            this.loadData()
          })
          .catch(err => {
            alert('Error locking records', err)
            Promise.reject(err)
          })
      } else {
        alert('No data available')
      }
    },

    renderPDF () {
      /*
      Render billing summary as pdf. Triggers download of the resulting file
       */
      if (!isEmpty(this.summary)) {
        createMonthlyBillingReport({
          filename: `${this.summary.name} ${this.month}.pdf`,
          title: `${this.summary.name} - hours for ${this.month}`,
          startDate: format(this.summary.reporting_period_start, 'DD.MM.YYYY'),
          endDate: format(this.summary.reporting_period_end, 'DD.MM.YYYY'),
          billable_hours: this.summary.total_billable_hours.hours,
          billable_minutes: this.summary.total_billable_hours.minutes,
          billable_travel_hours: this.summary.total_billable_travel_hours.hours,
          billable_travel_minutes: this.summary.total_billable_travel_hours.minutes,
          totals: this.summary.employee_hours,
          grouped: this.itemsGroupedByTask,
          details: this.itemsGroupedByTask ? this.detailsGroupedByTask : this.summary.timesheet_records
        })
      } else {
        alert('No data available')
      }
    },

    setViewingPeriod () {
      this.period = 'month'
      this.start_date = parse(this.$route.params.month)
      this.end_date = this.periodStartDay()
    },

    showPreviousPeriod () {
      /*
      Show previous month
       */
      this.$router.push({
        name: this.$route.name,
        params: {
          projectCode: this.projectCode,
          month: format(this.previousPeriodStart, this.$monthFormat)
        }
      })
    },

    showNextPeriod () {
      /*
      Show next month
      */
      this.$router.push({
        name: this.$route.name,
        params: {
          projectCode: this.projectCode,
          month: format(this.nextPeriodStart, this.$monthFormat)
        }
      })
    },

    closeErrorClicked () {
      // TODO - trigger clearing correct API errors
      // this.$store.dispatch('clearTimesheetAPIErrors')
    }
  }

}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/bootstrap_overrides.scss';
@import '../../assets/scss/theme_constants.scss';

.heading-with-icons {
  display: flex;
  flex-direction: row;
  align-items: center;

  h3, h4, h5 {
    padding: 0;
    margin: 0;
  }
  span.icons {
    padding-left: 1rem;
  }
}
.monthly-billing-heading {
  color: $secondary-color;
  margin: 0;
  padding: 0;
}
</style>
