<!--
Details for Work during summary period view

This component reflects the periods and allows 'zooming in and out' between
year, month and week periods.
-->

<template>
  <b-container
    fluid
    class="px-0"
  >
    <Loading
      v-if="loading"
      message="Loading employee work summary"
    />
    <div
      v-if="!loading && !summary"
      class="summary px-4 py-2"
    >
      <div class="heading-row my-2">
        <h3 class="heading mr-auto">
          Work Summary
        </h3>
      </div>
      No records available
    </div>
    <div
      v-if="!loading && summary"
      class="summary px-4 py-2"
    >
      <div class="heading-row my-2">
        <h3 class="heading mr-auto">
          Work Summary {{ dateRange(summary.summaries[0].start, summary.summaries[0].end) }}
        </h3>
        <div class="actions">
          <span
            v-if="!showSummaries"
            @click="toggleSummaries"
          >
            <font-awesome-icon icon="angle-down" />
          </span>
          <span
            v-if="showSummaries"
            @click="toggleSummaries"
          >
            <font-awesome-icon icon="angle-up" />
          </span>
        </div>
      </div>
      <div
        v-if="showSummaries"
        class="summary-row"
      >
        <PeriodCategoryDetails
          :employee="employee"
          :start-date="startDate"
          :end-date="endDate"
          :period="period"
          :summary="summary.summaries[0]"
        />
        <PeriodProjectDetails
          :employee="employee"
          :start-date="startDate"
          :end-date="endDate"
          :period="period"
          :projects="summary.summaries[0].projects"
        />
      </div>
      <div
        v-if="showTimesheetRecords"
        class="summary-row"
      >
        <PeriodTimesheetRecordDetails
          :employee="employee"
          :start-date="startDate"
          :end-date="endDate"
          :period="childPeriod"
          :periods="summary.periods"
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { durationDeltaInMinutes, formatDurationString } from '@/components/utils/timeUtils'

import BaseComponent from '@/components/base/BaseComponent.vue'
import Loading from '@/components/common/Loading.vue'
import PeriodCategoryDetails from '@/components/report/PeriodCategoryDetails.vue'
import PeriodProjectDetails from '@/components/report/PeriodProjectDetails.vue'
import PeriodTimesheetRecordDetails from '@/components/report/PeriodTimesheetRecordDetails.vue'

export default {
  name: 'PeriodWorkDetails',

  components: {
    Loading,
    PeriodCategoryDetails,
    PeriodProjectDetails,
    PeriodTimesheetRecordDetails
  },

  extends: BaseComponent,

  props: {
    employee: {
      type: Object,
      required: true
    },
    period: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    hideTotals: {
      type: Boolean,
      default: true
    },
    hideTimesheetRecords: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      summary: undefined,
      showSummaries: !this.hideTotals,
      showTimesheetRecords: !this.hideTimesheetRecords
    }
  },

  computed: {
    ...mapGetters([
      'getEmployeeWorkSummaryDetails',
      'loadingEmployeeWorkSummaryDetails',
      'errorLoadingEmployeeWorkSummaryDetails'
    ]),
    loading () {
      return this.loadingEmployeeWorkSummaryDetails
    },
    error () {
      return this.errorLoadingEmployeeWorkSummaryDetails
    },
    parentPeriod () {
      /*
      Parent level period
       */
      switch (this.period) {
        case 'week': {
          return 'month'
        }
        case 'month': {
          return 'year'
        }
        default: {
          return undefined
        }
      }
    },
    childPeriod () {
      /*
      Child level period
       */
      switch (this.period) {
        case 'year': {
          return 'month'
        }
        case 'month': {
          return 'week'
        }
        default: {
          return undefined
        }
      }
    },
    summaryCategories () {
      return this.summary.map(item => item.category)
    },
    totalDeltaWarningHours () {
      /*
      Get warning level for queried period
       */
      switch (this.period) {
        case 'year': {
          return 20
        }
        case 'month': {
          return 10
        }
        case 'week': {
          return 2
        }
        default: {
          return undefined
        }
      }
    },
    totalDeltaCriticalHours () {
      /*
      Get warning level for queried period
       */
      switch (this.period) {
        case 'year': {
          return 40
        }
        case 'month': {
          return 20
        }
        case 'week': {
          return 4
        }
        default: {
          return undefined
        }
      }
    }
  },

  watch: {
    'startDate' () {
      /*
      Watch for changes in start date
       */
      this.loadData()
    }
  },

  mounted () {
    this.loadData()
  },

  methods: {
    loadData () {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        email: this.employee.email,
        period: this.childPeriod,
        project_details: true,
        timesheet_records: true
      }
      this.$store.dispatch('loadEmployeeWorkSummaryDetails', params)
        .then((records) => {
          this.summary = this.getEmployeeWorkSummaryDetails
        })
    },

    toggleSummaries () {
      this.showSummaries = !this.showSummaries
    },

    durationDeltaClass (a, b) {
      /*
      Return css class for delta between two values as a duration string
       */
      const deltaHours = Math.abs(durationDeltaInMinutes(a, b)) / 60
      let critical = false
      let warning = false
      if (deltaHours > this.totalDeltaCriticalHours) {
        critical = true
      } else if (deltaHours > this.totalDeltaWarningHours) {
        warning = true
      }
      return {
        critical: critical,
        warning: warning
      }
    },

    durationDelta (a, b) {
      /*
      Return delta between two values as a duration string
       */
      return formatDurationString(durationDeltaInMinutes(a, b), { showSign: true })
    },

    getPercentage (category) {
      /*
      Get percenage for category
       */
      const item = this.summary.find(item => item.category === category)
      if (item !== undefined) {
        return item.percentage
      } else {
        return undefined
      }
    },

    getDuration (category) {
      /*
      Get duration for category
       */
      const item = this.summary.find(item => item.category === category)
      if (item !== undefined) {
        return item.duration
      } else {
        return { hours: 0, minutes: 0 }
      }
    }

  }

}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/bootstrap_overrides.scss';
@import '../../assets/scss/theme_constants.scss';

.summary {
  display: flex;
  flex-direction: column;

  .heading-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .actions {
      span {
        cursor: pointer;
        color: $secondary-color;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .summary-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}
</style>
