<!--
Component to show box with periodic timesheet record details from PeriodWorkDetails
-->

<template>
  <b-container
    fluid
    class="period-records px-0"
  >
    <div
      v-for="item in filteredPeriods"
      :key="item.start_date"
      class="mb-4"
    >
      <div class="period-heading mt-4 mb-2">
        <div class="title mr-auto">
          {{ periodTitle }} {{ dateRange(item.start, item.end) }}
        </div>
        <div
          class="total-delta mr-2"
        >
          {{ getTotalPeriodDelta(item ) }}
        </div>
        <div class="total-duration mr-2">
          Total: {{ totalDuration(item.timesheet_records) }}
        </div>
      </div>

      <div
        v-for="group in dailyTimesheetRecords(item.timesheet_records)"
        :key="group.date"
        class="period mt-2"
      >
        <div class="heading my-1">
          <div class="title mr-auto">
            {{ group.date }}
          </div>
          <div class="actions mr-2">
            <router-link
              v-if="employee.email === loggedInEmployeeEmail"
              :to="{
                name: 'employee-hours',
                query: {
                  period: 'week',
                  start_date: item.start,
                  end_date: item.end,
                  date: group.date
                }
              }"
              class="link"
            >
              <font-awesome-icon icon="pen" />
            </router-link>
            <div class="duration pl-2">
              {{ totalDuration(group.records) }}
            </div>
          </div>
        </div>
        <TimesheetRecord
          v-for="record in group.records"
          :key="record.id"
          :record="record"
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import { orderBy } from 'lodash'
import { formatDurationString, durationInMinutes, getTotalDuration } from '../utils/timeUtils'

import BaseComponent from '@/components/base/BaseComponent.vue'
import TimesheetRecord from '@/components/timetracking/TimesheetRecord.vue'

export default {
  name: 'PeriodTimesheetRecordDetails',

  components: {
    TimesheetRecord
  },

  extends: BaseComponent,

  props: {
    employee: {
      type: Object,
      required: true
    },
    period: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    periods: {
      type: Array,
      required: true
    }
  },

  computed: {
    filteredPeriods () {
      /*
      Filter periods with timesheet records
       */
      return this.periods.filter(
        period => getTotalDuration(period.timesheet_records.map(item => item.duration)) > 0
      )
    }
  },

  methods: {
    getTotalPeriodDelta (period) {
      /*
      Get total delta against period expected hours
       */
      let delta = 0
      let total = period.summary.find(item => item.category === 'total_hours')
      let expected = period.summary.find(item => item.category === 'total_expected')
      delta = durationInMinutes(total.duration) - durationInMinutes(expected.duration)
      expected = durationInMinutes(expected.duration)
      return delta !== 0 && expected !== 0 ? formatDurationString(delta, true, true) : ''
    },
    getCategory (period, category) {
      /*
      Return category data from period summary data
       */
      return period.summary.find(item => item.category === category)
    },

    totalDuration (records) {
      /*
      Total duration in specified records
       */
      return formatDurationString(getTotalDuration(records.map(item => item.duration)), false, true)
    },
    dailyTimesheetRecords (records) {
      /*
      Group list of timesheet records by date
       */
      let grouped = {}
      records.forEach(record => {
        const date = record.date
        if (grouped[date] === undefined) {
          grouped[date] = {
            date: date,
            records: []
          }
        }
        grouped[date].records.push(record)
      })
      return orderBy(grouped, ['date'])
    }
  }

}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/bootstrap_overrides.scss';
@import '../../assets/scss/theme_constants.scss';

div.period-records {
  padding: 0.5rem;
  .period-heading {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    font-weight: bold;
    border-bottom: 1px solid $primary-color;
    .total-delta {
    }
  }
  .period {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    .heading {
      padding-left: 0.5rem;
      display: flex;
      flex-direction: row;
      .title {
      }
      .actions {
        margin-right: 1rem;
        display: flex;
        flex-direction: row;
      }
    }
  }
}
</style>
