
<!--
Logout form

Normally not really visible to user, just triggers 'logout' and redirects to homepage
-->

<template>
  <b-container
    fluid
    class="d-flex flex-row justify-content-center align-items-center h-100"
  >
    <h3 class="text-danger">
      Logging out ...
    </h3>
  </b-container>
</template>

<script>

export default {
  name: 'Logout',

  data () {
    return {
      email: '',
      password: ''
    }
  },

  created () {
    this.$store.dispatch('logout', {})
      .then(() => {
        this.$router.push({ name: 'Index' })
      })
  }

}

</script>
