/*
Projects for an employee
 */

import { orderBy } from 'lodash'
import { parse, startOfToday, subDays } from 'date-fns'

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const recentDays = 30

const actions = {
  loadEmployeeProjects: ({ commit }, params = {}) => {
    /*
     Load projects linked to logged in employee
     */
    return helpers.loadListing(
      commit,
      'timesheet/employee/projects',
      params,
      types.EMPLOYEE_PROJECTS_LOAD,
      types.EMPLOYEE_PROJECTS_LOAD_SUCCESS,
      types.EMPLOYEE_PROJECTS_LOAD_ERROR
    )
  }
}

const mutations = {
  [types.EMPLOYEE_PROJECTS_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.EMPLOYEE_PROJECTS_LOAD_SUCCESS] (state, data) {
    data.forEach(item => {
      item.latest = item.latest !== null ? parse(item.latest) : null
    })
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.EMPLOYEE_PROJECTS_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  }
}

const getters = {
  getEmployeeProjects: () => {
    /*
    Return all retrieved employee projects.

    Order projects by latest date
    */
    let recent = []
    let other = []

    let recentLimit = subDays(startOfToday(), recentDays)
    state.items.forEach(item => {
      if (item.latest !== null && item.latest >= recentLimit) {
        recent.push(item)
      } else {
        other.push(item)
      }
    })
    recent = orderBy(recent, ['latest', 'project_code'], ['desc', 'asc'])
    other = orderBy(other, ['project_code'])
    return recent.concat(other)
  },
  getRecentEmployeeProjects: () => {
    /*
    Return employee projects with recently logged hours

    Items are sorted by latest date and project codee
     */
    let recentLimit = subDays(startOfToday(), recentDays)
    let items = []
    items = state.items.filter(item => {
      return item.latest !== null && item.latest >= recentLimit
    })
    return orderBy(items, ['latest', 'project_code'], ['desc', 'asc'])
  },
  getEmployeeNonRecentProjects: () => {
    /*
    Get employee projects where no recent hours have been logged

    Items are sorted by project code
     */
    let items = []
    let recentLimit = subDays(startOfToday(), recentDays)
    items = state.items.filter(item => {
      return item.latest === null || item.latest < recentLimit
    })
    return orderBy(items, ['project_code'], ['asc'])
  },
  loadingEmployeeProjects: () => {
    /*
    Check if employee project list is loading
     */
    return state.loading
  },
  loadingEmployeeProjectDetails: () => {
    /*
    Check if employee project details are loading
     */
    return state.loadingDetails
  },
  updatingEmployeeProjectDetails: () => {
    /*
    Check if employee project record is being updated
     */
    return state.updating
  },
  deletingEmployeeProjectDetails: () => {
    /*
    Check if employee project record is being deleted
     */
    return state.deleting
  },
  errorLoadingEmployeeProjects: () => {
    /*
    Check if loading employee project list caused errors
     */
    return state.errors.loading
  },
  errorLoadingEmployeeProjectDetails: () => {
    /*
    Check if loading employee project details caused errors
     */
    return state.errors.loadingDetails
  },
  errorUpdatingEmployeeProject: () => {
    /*
    Check if updating employee project details caused errors
     */
    return state.errors.updating
  },
  errorDeletingEmployeeProject: () => {
    /*
    Check if deleting employee project details caused errors
     */
    return state.errors.deleting
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
