/*
Vue template filters for time and duration values
 */

import Vue from 'vue'

import { formatDurationValue } from '@/components/utils/timeUtils'

Vue.filter('durationValue', function (value) {
  /*
  Format a duration string as received from API
   */
  return formatDurationValue(value)
})

Vue.filter('durationString', function (value) {
  /*
  Duration formatted as 'HH h MM min'
   */
  return `${value.hours} h ${value.minutes} min`
})
