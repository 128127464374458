<!--
View to show short contract period details
-->

<template>
  <div class="contract-period">
    <div class="line description">
      {{ description }} {{ workWeek }}
    </div>
    <div class="line value">
      <div class="percentage">
        {{ percentage }}
      </div>
      <div class="daterange">
        {{ dateRange(record.start_date, record.end_date) }}
      </div>
    </div>
  </div>
</template>

<script>
import DataView from '@/forms/DataView.vue'

export default {
  name: 'ContractPeriodInfo',

  mixins: [
    DataView
  ],

  computed: {
    percentage () {
      /*
      Return percentage of work of fulltime employement included in this contract period
       */
      switch (this.record.type) {
        case 'leave':
          return ''
        case 'studyleave':
          return ''
      }
      return this.record.percentage !== null ? `${this.record.percentage} %` : ''
    },

    workWeek () {
      /*
      Return work week definition
       */
      if (this.record.work_week != undefined && this.record.work_week.name) {
        return `(${this.record.work_week.name})`
      } else {
        return ''
      }
    },

    description () {
      /*
      Return a description string based on contract period type

      TODO -  we should pick the description from API /timesheet/autocomplete/employment-contract-types
       */
      let description
      switch (this.record.type) {
        case 'fulltime':
          description = 'Full time'
          break
        case 'parttime':
          description = 'Part time'
          break
        case 'leave':
          description = 'Personal leave'
          break
        case 'studyleave':
          description = 'Study leave'
          break
        case 'maternity-leave':
          description = 'Maternity leave'
          break
        default:
          description = this.record.type
          break
      }
      return description
    }
  }
}

</script>

<style lang="scss" scoped>
div.contract-period {
  div.line {
    display: flex;
    flex-direction: row;
    div {
      margin-right: 0.5em;
    }
    div.percentage {
      min-width: 3em;
    }
  }
}
</style>
