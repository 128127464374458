<!--
Common component for listings items with title bar, toolbar buttons and pagination.
-->

<template>
  <BaseView
    :parent="bus"
    :loading="loading"
    :error="error"
    :title="title"
    :navigation-filters="navigationFilters"
    :navigation-actions="navigationActions"
    :search-visible="hasSearchFields"
    @events="eventHandler"
  >
    <template slot="sub-nav-heading">
      <slot name="sub-nav-heading" />
    </template>
    <template
      slot="modal"
    >
      <slot name="modal" />
    </template>

    <template
      slot="content"
    >
      <Loading
        v-if="loading"
        :message="`Loading ${title}`"
      />

      <slot name="content-heading" />

      <Notification
        v-if="!loading && rowsWithDetails.length === 0"
        class=""
        title="No data"
        message="No results to view"
      />

      <b-table
        v-if="dataVisible"
        :items="rowsWithDetails"
        :fields="fields"
        :keyword="keyword"
        :thead-class="headingClass"
        tbody-tr-class="table-row"
        tbody-class="table-body"
        responsive
        hover
        :sort-compare="sortCompare"
        @row-clicked="rowClickedCallback"
      >
        <template
          v-for="(_, slot) of $scopedSlots"
          v-slot:[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </b-table>
    </template>
  </BaseView>
</template>

<script>
import BaseComponent from '@/components/base/BaseComponent.vue'
import BaseView from '@/components/elements/BaseView.vue'
import Loading from '@/components/common/Loading.vue'
import Notification from '@/components/common/Notification.vue'
import { cloneDeep, isEmpty } from 'lodash'

export default {
  name: 'BrowsableListingView',

  components: {
    BaseView,
    Loading,
    Notification
  },

  extends: BaseComponent,

  props: {
    navigationFilters: {
      type: Array,
      default: () => ([])
    },
    navigationActions: {
      type: Array,
      default: () => ([])
    },
    searchFields: {
      type: Array,
      default: () => ([])
    },
    fields: {
      type: Array,
      default: () => ([])
    },
    rows: {
      type: Array,
      default: () => ([])
    },
    sortCompare: {
      type: Function,
      default: function () {}
    },
    rowDetailsAllowed: {
      type: Function,
      default: function () { return true }
    },
    showHeading: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    // Property to indicate if page is loading
    loading: {
      type: Boolean,
      required: true
    },
    // Property to show page loading errors
    error: {
      type: [String, Error],
      default: ''
    }
  },

  data: function () {
    return {
      // Keyword in search
      keyword: ''
    }
  },

  computed: {

    headingClass () {
      return this.showHeading ? '' : 'd-none'
    },

    dataVisible () {
      /*
      Decides if the data for period should be visible
       */
      // No data to show yet
      if (this.loading && isEmpty(this.rows)) {
        return false
      }
      // There may be other cases to consider
      return true
    },

    rowsWithDetails () {
      /*
      Clone rows to have _showDetails flag for b-table
      */
      const rows = cloneDeep(this.rows)
      rows.map(function (row) {
        row._showDetails = false
        return row
      })
      return this.filterRows(rows)
    },

    hasSearchFields () {
      /*
      Check if there are any defined search fields
      */
      return !isEmpty(this.searchFields)
    }
  },

  methods: {

    eventHandler (action, ...args) {
      /*
      Handle search events from base view
       */
      switch (action) {
        case 'search': {
          this.$set(this, 'keyword', args[0])
          break
        }
      }
    },

    filterRows (rows) {
      /*
      Filter rows by search field value
      */
      const matchKeywords = (item, keyword, fields) => {
        keyword = keyword.toLowerCase()
        return fields.find(field => {
          if (item[field] !== null && item[field].toString().toLowerCase().includes(keyword)) {
            return true
          }
        })
      }

      if (this.searchFields === undefined || this.keyword === '') {
        return rows
      }
      return rows.filter(row => {
        return matchKeywords(row, this.keyword, this.searchFields)
      })
    },

    rowClickedCallback (row) {
      /*
      Default row click handler to toggle viewing off row details
       */
      if (this.rowDetailsAllowed(row)) {
        this.$emit('events', 'row-clicked', row)
        this.$set(row, '_showDetails', !row._showDetails)
      }
    },

    closeErrorClicked () {
    }

  }

}
</script>
