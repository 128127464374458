/*
Project billing contact state
 */

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadBillingContacts: ({ commit }, params = {}) => {
    return helpers.loadListing(
      commit,
      'timesheet/billing-contacts',
      params,
      types.BILLING_CONTACT_LIST_LOAD,
      types.BILLING_CONTACT_LIST_LOAD_SUCCESS,
      types.BILLING_CONTACT_LIST_LOAD_SUCCESS
    )
  }
}

const mutations = {
  [types.BILLING_CONTACT_LIST_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.BILLING_CONTACT_LIST_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.BILLING_CONTACT_LIST_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  }
}

const getters = {
  getBillingContacts: () => {
    return state.items
  },
  loadingBillingContacts: () => {
    /*
    Check if billing contact list is loading
     */
    return state.loading
  },
  loadingBillingContactDetails: () => {
    /*
    Check if billing contact details are loading
     */
    return state.loadingDetails
  },
  updatingBillingContactDetails: () => {
    /*
    Check if billing contact record is being updated
     */
    return state.updating
  },
  deletingBillingContactDetails: () => {
    /*
    Check if billing contact record is being deleted
     */
    return state.deleting
  },
  errorLoadingBillingContacts: () => {
    /*
    Check if loading billing contact list caused errors
     */
    return state.errors.loading
  },
  errorLoadingBillingContactDetails: () => {
    /*
    Check if loading billing contact details caused errors
     */
    return state.errors.loadingDetails
  },
  errorUpdatingBillingContact: () => {
    /*
    Check if updating billing contact details caused errors
     */
    return state.errors.updating
  },
  errorDeletingBillingContact: () => {
    /*
    Check if deleting billing contact details caused errors
     */
    return state.errors.deleting
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
