<!--
View to show employee contract period list with editor
-->

<template>
  <div
    v-if="!loading"
  >
    <b-modal
      v-model="editModalVisible"
      name="record-edit-modal"
      hide-header
      centered
      hide-footer
      no-close-on-backdrop
      @hide="editCanceled"
    >
      <ContractPeriodEditForm
        v-if="editModalVisible"
        :parent="bus"
        :employee="employee"
        :record="record"
        @events="eventHandler"
      />
    </b-modal>

    <b-table
      :fields="['contract']"
      :items="rows"
      responsive
      hover
      show-empty
      thead-class="d-none"
      @row-clicked="rowClickedCallback"
    >
      <template v-slot:cell(contract)="row">
        <ContractPeriod
          :employee="employee"
          :contract="row.item"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

import EmployeeDataEditor from '@/components/employee/EmployeeDataEditor.vue'
import ContractPeriodEditForm from '@/components/employee/ContractPeriodEditForm.vue'
import ContractPeriod from '@/components/employee/ContractPeriod.vue'

export default {
  name: 'ContractPeriods',

  components: {
    ContractPeriod,
    ContractPeriodEditForm
  },

  extends: EmployeeDataEditor,

  computed: {
    ...mapGetters([
      'getEmployeeDetails',
      'getEmploymentContractPeriods',
      'loadingEmploymentContractPeriods',
      'loadingEmployeeDetails',
      'errorLoadingEmploymentContractPeriods',
      'errorLoadingEmployeeDetails'
    ]),
    loading () {
      return this.loadingEmployeeDetails || this.loadingEmploymentContractPeriods
    },
    error () {
      return this.errorLoadingEmployeeDetails || this.errorLoadingEmploymentContractPeriods
    },
    rows () {
      return cloneDeep(this.getEmploymentContractPeriods)
    }
  },

  methods: {

    loadData () {
      /*
      Load contract period details for specified employee
        */
      if (this.employee) {
        this.$store.dispatch('resetEmploymentContractPeriods')
        this.$store.dispatch('loadEmploymentContractPeriods', {
          employee: this.email
        })
      }
    }

  }
}
</script>
