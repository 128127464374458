import { render, staticRenderFns } from "./EmployeeList.vue?vue&type=template&id=160b3808&scoped=true"
import script from "./EmployeeList.vue?vue&type=script&lang=js"
export * from "./EmployeeList.vue?vue&type=script&lang=js"
import style0 from "./EmployeeList.vue?vue&type=style&index=0&id=160b3808&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "160b3808",
  null
  
)

export default component.exports