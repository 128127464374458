<!--
Index page form for unauthenticated clients
-->

<template>
  <b-container
    fluid
    class="login d-flex flex-column justify-content-center align-items-center h-100 w-100 mx-0"
  >
    <vue-headful title="Jaggers" />
    <div>

      <b-row v-if="error">
        <b-col>
          <ErrorMessage
            :error="error"
            :reload-button-clicked="$emit('notifications', 'reload-page')"
            :close-error-clicked="$emit('notifications', 'close-errors')"
            @events="eventHandler"
          />
        </b-col>
      </b-row>

      <b-row
        align-v="center"
        align-h="center"
        class="login-from"
      >
        <b-col class="col-md-16 B">
          <h3>Time Tracking</h3>
          <div id="google-login" />
        </b-col>
      </b-row>

    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Index',

  props: {
    error: {
      type: [String, Error],
      default: undefined
    },
  },

  data () {
    return {
      processing: false
    }
  },

  computed: {
    ...mapGetters([
      'isAuthenticated',
      'loginInProgress'
    ])
  },

  watch: {
    'isAuthenticated' () {
      if (this.loginInProgress) {
        return
      }
      if (!this.processing) {
        this.processing = true
        this.$router.push({ 'name': 'employee-hours' })
      } else {
        this.processing = false
      }
    }
  },

  mounted () {
    if (this.$store.getters.hasAuthenticationToken(this.$store)) {
      this.$router.push({ name: 'employee-hours' })
    } else {
      this.showGoogleLogin()
    }
  },

  methods: {
    showGoogleLogin (event) {
      if (window.google === undefined) {
        return
      }
      window.google.accounts.id.renderButton(
        document.getElementById('google-login'),
        {
          theme: 'outline',
          size: 'large'
        }
      )
      window.google.accounts.id.prompt()
    }
  }
}

</script>

<style lang="scss" scoped>
@import '../assets/scss/theme_constants';

div.login {
  background-image: url('~@/assets/cettu-light.svg');
  background-size: 22rem;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 24em;
}

h3, h4 {
  text-align: center;
}

h3 {
  margin-bottom: 0.5em;
}

</style>
