/*
Holiday calendar state
 */

import { parse } from 'date-fns'

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadHolidays: ({ commit }, params = {}) => {
    /*
    Load holidays
    */
    return helpers.loadListing(
      commit,
      'timesheet/holidays',
      params,
      types.HOLIDAY_LIST_LOAD,
      types.HOLIDAY_LIST_LOAD_SUCCESS,
      types.HOLIDAY_LIST_LOAD_ERROR
    )
  }
}

const mutations = {
  [types.HOLIDAY_LIST_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.HOLIDAY_LIST_LOAD_SUCCESS] (state, data) {
    data = data.map(function (item) {
      item.date = parse(item.date).valueOf()
      return item
    })
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.HOLIDAY_LIST_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  }
}

const getters = {
  getHolidays: () => {
    /*
    Return all retrieved holiday entries
    */
    return state.items
  },
  getHolidayForDate: state => (date) => {
    /*
     Return holiday record for specified date
     */
    return state.items.find(function (item) {
      return item.date === date
    })
  },
  loadingHolidays: () => {
    /*
    Check if holidays are loading
     */
    return state.loading
  },
  errorLoadingHolidays: () => {
    /*
    Check if loading holidays failed
     */
    return state.errors.loading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
