<!--
Common form component with field validation

This form is intended to replace all previous editor form implementations when ready
-->

<template>
  <div />
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

import FieldLabel from './fields/FieldLabel.vue'
import CheckboxField from './fields/CheckboxField.vue'
import CheckboxGroupField from './fields/CheckboxGroupField.vue'
import DateField from './fields/DateField.vue'
import DateRangeField from './fields/DateRangeField.vue'
import DurationField from './fields/DurationField.vue'
import DropdownField from './fields/DropdownField.vue'
import MultiLineTextField from './fields/MultiLineTextField.vue'
import NumberField from './fields/NumberField.vue'
import PercentageField from './fields/PercentageField.vue'
import TextField from './fields/TextField.vue'
import TextAreaField from './fields/TextAreaField.vue'
import TimeField from './fields/TimeField.vue'
import TimeRangeField from './fields/TimeRangeField.vue'

export default {
  name: 'Form',

  // Available field types to use in form fields of child components
  /* eslint-disable vue/no-unused-components */
  components: {
    FieldLabel,
    CheckboxField,
    CheckboxGroupField,
    DateField,
    DateRangeField,
    DropdownField,
    DurationField,
    MultiLineTextField,
    NumberField,
    PercentageField,
    TextField,
    TextAreaField,
    TimeField,
    TimeRangeField
  },
  /* eslint-enable */

  props: {
    // Property indicating form data is loading
    parent: {
      type: Object,
      default: new Vue()
    },
    // Boolean to check if form is loading. Not same as 'loading form data', by default not used
    loading: {
      type: Boolean,
      default: false
    },
    // Record to edit. Form adds new record if not specified
    record: {
      type: Object,
      default: function () { return {} }
    }
  },

  data: function () {
    return {
      bus: new Vue(),
      // Form fields to be processed. This will be filled by mounted hook.
      fields: {},
      // Vue store actions to perform with API (create / update / delete and so on)
      actions: {},
      // Flag to indicate all strings in data are set to undefined
      setTrimmedStringsUndefined: true,
      // Flag to indicate form is being processed with API
      processing: false,
      // Boolean to check if form has any pending fields
      hasPendingFields: false
    }
  },

  computed: {
    ...mapGetters([
      'loggedInEmployee',
      'loggedInEmployeeEmail'
    ]),
    loadingFormData () {
      /*
      Checks if record or form options are loading
       */
      return this.loading || this.loadingOptions
    },
    errorLoadingFormData () {
      /*
      Checks for errors loading form data
       */
      return false
    },
    loadingOptions () {
      /*
      Return form option API loading status. Override in child class with actions
       */
      return false
    },
    hasData () {
      /*
      Checks if form has any data in fields
      */
      return !isEmpty(Object.values(this.values).filter(value => value !== undefined))
    },
    cancelDisabled () {
      /*
      Checks if cancel button is disabled
       */
      return !this.canCancel || this.processing
    },
    deleteDisabled () {
      /*
      Checks if delete button is disabled
       */
      return !this.canDelete || this.processing
    },
    saveDisabled () {
      /*
      Checks if save button is disabled
       */
      return !this.canSave || this.processing
    },
    canDelete () {
      /*
      Checks if delete button is available
       */
      return false
    },
    canCancel () {
      /*
      Checks if cancel button is available
       */
      return true
    },
    canSave () {
      /*
      Checks if save button is available
       */
      return this.hasData && !this.hasPendingFields && this.formReady
    },
    inputs () {
      /*
      Return all form inputs, including nested inputs
       */
      let inputs = {}
      Object.values(this.fields).forEach(field => {
        Object.keys(field.inputs).forEach(key => {
          inputs[key] = field.inputs[key]
        })
      })
      return inputs
    },
    values () {
      /*
      Return raw form values (without field specific API formatting)

      Note: a field can contain multiple keys
       */
      let values = {}
      Object.values(this.fields).forEach(field => {
        Object.keys(field.values).forEach(key => {
          values[key] = field.values[key]
        })
      })
      return values
    },
    formReady () {
      /*
      Checks if form is ready for create / update
       */
      if (!this.hasData) {
        // No data in form yet, can't be ready
        return false
      }
      // Validate all form fields and check for errors
      return this.isFormDataValid()
    }
  },

  watch: {
    'loadingFormData' () {
      /*
      Watch for 'loading form data computed propery'
       */
      if (!this.loadingFormData) {
        this.loadForm()
      }
    },
    'record' () {
      /*
      Watch for changed in record linked to form
       */
      if (!this.loadingFormData) {
        this.loadForm()
      }
    }
  },

  created () {
    /*
    Initialize this.fields from child class formFields data argument
     */
    this.initializeFields()
  },

  mounted () {
    /*
    Load form from specified record
     */
    const $vm = this
    this.loadFormOptions()
    Vue.nextTick(function () {
      $vm.loadForm()
    })
  },

  methods: {

    loadFormOptions () {
      /*
      Load options for form fields from API

      Override in child class to load whatever is needed
       */
    },

    initializeFields () {
      /*
      Initialize field validation error value undefined for all fields
       */
      if (this.formFields) {
        Object.keys(this.formFields).forEach(name => {
          let item = this.formFields[name]
          let required = item.required !== undefined ? item.required : {}
          let formatter = item.formatter !== undefined ? item.formatter : {}
          let validators = item.validators !== undefined ? item.validators : {}
          let inputs
          let values = {}
          if (item.inputs === undefined) {
            inputs = [name].reduce((items, item) => {
              items[name] = {
                key: name,
                visible: true,
                pending: false,
                label: undefined,
                description: undefined
              }
              return items
            }, {})
          } else {
            inputs = item.inputs
          }
          // Allow passing required as boolean or object per fied
          if (typeof (required) === 'boolean') {
            required = Object.keys(inputs).reduce((items, item) => {
              items[name] = required
              return items
            }, {})
          }
          // Ensure inputs have keys
          Object.keys(inputs).forEach(input => {
            if (inputs[input].key === undefined) {
              inputs[input].key = input
            }
            if (inputs[input].visible === undefined) {
              inputs[input].visible = true
            }
            if (inputs[input].pending === undefined) {
              inputs[input].pending = false
            }
            if (item.default !== undefined) {
              values[inputs[input].key] = inputs[input].default
            } else if (inputs[input].default !== undefined) {
              values[inputs[input].key] = inputs[input].default
            } else {
              values[inputs[input].key] = undefined
            }
            if (required[inputs[input].key] === undefined) {
              required[inputs[input].key] = false
            }
          })
          // Allow passing formatter as function (for single input fields) or object
          if (typeof (formatter) === 'function') {
            formatter = Object.keys(inputs).reduce((items, item) => {
              items[name] = formatter
              return items
            }, {})
          }
          // Allow passing validator as function, array (for single input fields) or object
          if (typeof (validators) === 'function' || Array.isArray(validators)) {
            validators = Object.keys(inputs).reduce((items, item) => {
              items[name] = validators
              return items
            }, {})
          }
          let field = {
            name: name,
            label: item.label,
            description: item.description,
            loader: item.loader,
            inputs: inputs,
            values: values,
            options: item.options,
            minimum: item.minimum,
            maximum: item.maximum,
            required: required,
            formatter: formatter,
            validators: validators,
            errors: {}
          }
          this.$set(this.fields, name, field)
        })
      }
    },

    updateOptions (name, options) {
      /*
      Update options for field
       */
      let field = this.fields[name]
      field.options = options
      this.bus.$emit('reload-options', field.name)
    },

    resetForm () {
      /*
      Reset all form field values to defaults
       */
      Object.values(this.fields).forEach(field => {
        Object.values(field.inputs).forEach(input => {
          let value = input.default !== undefined ? input.default : undefined
          this.$set(this.fields[field.name].values, input.key, value)
        })
      })
    },

    loadForm () {
      /*
      Load edited record based on  form field key definitions
       */
      if (this.record !== undefined && !isEmpty(this.record)) {
        Object.values(this.fields).forEach(field => {
          Object.values(field.inputs).forEach(input => {
            let value = input.default
            if (field.loader !== undefined) {
              // Use custom callback for field loading. Takes record, not field value
              value = field.loader(this.record)
            } else {
              // Pick field from record if available, do not override default

              if (this.record[input.key] !== undefined) {
                value = this.record[input.key]
              }
            }
            // Set value to field
            this.$set(this.fields[field.name].values, input.key, value)
          })
        })
      }
      this.finishLoading()
      this.bus.$emit('reload')
    },

    finishLoading () {
      /*
      Callback to finish loading of data. Override in child class
       */
    },

    formatRecordForAPI () {
      /*
      Format data in record for API calls (create / update)

      Calls field specific format callback and sets empty trimmed strings to
      undefined to be sent to APIs
       */
      let record = {}
      Object.values(this.fields).forEach(field => {
        Object.values(field.inputs).forEach(input => {
          let value = field.values[input.key]
          const formatter = field.formatter[input.key]
          if (formatter !== undefined) {
            // Call custom formatter for field
            value = formatter(field, input.key, value)
          }
          if (this.setTrimmedStringsUndefined) {
            // Set whitespace / empty strings to undefined in API data. We never send
            // empty or untrimmed strings to API
            if ((typeof (value) === 'string' || value instanceof String) && value.trim() === '') {
              value = null
            }
          }
          record[input.key] = value
        })
      })
      return record
    },

    setStaticFields () {
      /*
      Set fields with static values

      Override in child class when required
       */
    },

    updateField (action, name, key, data) {
      /*
      Update field details from emited action
       */
      switch (action) {
        case 'update-value': {
          this.setValue(name, key, data)
          break
        }
        case 'set-pending': {
          this.setPendingField(name, key, data)
          break
        }
        case 'validation-error': {
          this.setValidationError(name, key, data)
          break
        }
        case 'delete-clicked': {
          this.deleteClicked()
          break
        }
        case 'save-clicked': {
          this.saveClicked()
          break
        }
        case 'cancel-clicked': {
          this.cancelClicked()
          break
        }
        case 'toggle-clicked': {
          this.toggleClicked()
          break
        }
      }
    },

    lookupInput (key) {
      /*
      Find input by key

      This requires looping because we may have multiple input fields
       */
      let input
      Object.values(this.fields).forEach(field => {
        Object.values(field.inputs).forEach(item => {
          if (item.key === key) {
            input = item
          }
        })
      })
      return input
    },

    toggleClicked () {
      /*
      Callback for toggle event from field
       */
    },

    setValue (name, key, value) {
      /*
      Set value for specifie input
       */
      let field = this.fields[name]
      this.$set(this.fields[field.name].values, key, value)
    },

    setRequiredField (name, required) {
      /*
      Change a field's required status
       */
      let field = this.fields[name]
      Object.keys(field.required).forEach(key => {
        field.required[key] = required
      })
    },

    setPendingField (name, key, pending) {
      /*
      Set field value as pending, i.e. value being entered and not having a validated error
       */
      let input = this.lookupInput(key)
      if (input !== undefined && input.pending !== pending) {
        input.pending = pending
      }
      this.hasPendingFields = !isEmpty(Object.values(this.inputs).filter(input => input.pending === true))
    },

    setValidationError (name, key, error) {
      /*
      Set validation error message for specified field

      If error is not specified the field's error is cleared
       */
      const field = this.fields[name]
      let input = this.lookupInput(key)
      if (error !== undefined) {
        if (this.fields[field.name].errors[key] !== error) {
          // Do not raise error if field value is pending (being entered)
          if (input === undefined || (input !== undefined && !input.pending)) {
            this.$set(this.fields[field.name].errors, key, error)
          }
        }
      } else {
        if (this.fields[field.name].errors[key] !== undefined) {
          this.$set(this.fields[field.name].errors, key, undefined)
          this.setPendingField(name, key, false)
        }
      }
    },

    isFormDataValid () {
      /*
      Validate data in all form fields

      Checks validator errors, if field value is incomplete and if a required
      field is missing value
       */
      let formValid = true
      Object.values(this.fields).forEach(field => {
        // Check all field errors. May contain othes than input fields
        Object.values(field.errors).forEach(error => {
          if (error !== undefined) {
            // Detected error in form
            formValid = false
          }
        })
        Object.keys(field.inputs).forEach(input => {
          let key = field.inputs[input].key
          if (field.inputs[key] !== undefined && field.inputs[key].pending) {
            // Entry to field is incomplete
            formValid = false
          }
          if (field.values[key] === undefined || field.values[key] === null) {
            if (field.required[key]) {
              // Field is required and has no value
              formValid = false
            }
          }
        })
      })
      return formValid
    },

    processAPIFieldErrors (error) {
      /*
      Try to pick field specified errors from API error response.

      If detected, mark the field errors with these error messages. Does nothing
      with other than field specific errors.
       */
      if (error) {
        Object.keys(error.response.data).forEach(key => {
          let errors = error.response.data[key]
          // Just report first error. We don't expect many but it's a list from django
          if (errors) {
            let message = errors[0]
            Object.values(this.fields).forEach(field => {
              Object.values(field.inputs).forEach(input => {
                if (input.key === key) {
                  this.setValidationError(field.name, input.key, message)
                }
              })
            })
          }
        })
      }
    },

    saveClicked () {
      /*
      Handler for form 'save' button click

      - set processing flag to prevent further actions while set
      - validate form fields before submitting data
      - format record data for API
      - emit 'created' or 'updated' signal to parent and unset 'processing' flag
       */
      if (!this.formReady) {
        return
      }
      this.processing = true
      this.$emit('clear-errors')
      let data = this.formatRecordForAPI()
      if (isEmpty(Object.keys(this.record))) {
        this.createRecord(data)
      } else {
        this.updateRecord(data)
      }
    },

    deleteClicked () {
      /*
      Handler for form 'delete' button click

      - set processing flag to prevent further actions while set
      - emit 'deleted' flag to parent and set 'processing' flag to false
      */
      this.processing = true
      this.$emit('clear-errors')
      this.deleteRecord(this.record)
    },

    cancelClicked (event) {
      /*
      Handler for form 'cancel' button click

      - reloads current form data and fields
      - clears all errors
      - emits 'canceled' to parent. It's up to parent to decide action
       */
      this.resetForm()
      this.setStaticFields()
      this.bus.$emit('reset')
      this.$emit('events', 'canceled')
      this.$emit('events', 'clear-errors')
    },

    createRecord (record) {
      /*
      Create record with API and load data when successful

      Emits 'created' signal to parent after successful create
       */
      let action = this.actions.create
      if (!action) {
        alert('no action specified for "create"')
        this.processing = false
        return
      }
      return new Promise((resolve, reject) => {
        this.processing = true
        this.$store.dispatch(action, record)
          .then((response) => {
            this.processing = false
            this.$emit('events', 'created', response.data)
            resolve(response)
          })
          .catch((error) => {
            this.processAPIFieldErrors(error)
            this.$emit('events', 'api-error', error.response)
            this.processing = false
            reject(error)
          })
      })
    },

    updateRecord (record) {
      /*
      Update record with API and load data when successful

      Emits 'updated' signal to parent after successful update
      */
      let action = this.actions.update
      if (!action) {
        alert('no action specified for "update"')
        this.processing = false
        return
      }
      this.processing = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, record)
          .then((response) => {
            this.processing = false
            this.$emit('events', 'updated', response.data)
            resolve(response)
          })
          .catch((error) => {
            this.processAPIFieldErrors(error)
            this.$emit('events', 'api-error', error.response)
            this.processing = false
            reject(error)
          })
      })
    },

    deleteRecord (params) {
      /*
      Delete specified record with API and load data when successful

      Emits 'deleted' signal to parent after successful delete
      */
      let action = this.actions.delete
      if (!action) {
        alert('no action specified for "delete"')
        this.processing = false
        return
      }
      this.processing = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, params)
          .then((response) => {
            this.processing = false
            this.$emit('events', 'deleted', this.record)
            resolve(response)
          })
          .catch((error) => {
            this.$emit('events', 'api-error', error.response)
            this.processing = false
            reject(error)
          })
      })
    }

  }

}
</script>
