<!--
Component to show employee's profile image from url
-->

<template>
  <div class="profile-image">
    <img
      v-if="url"
      :height="height"
      :src="url"
    >
  </div>
</template>

<script>
export default {
  name: 'ProfileImage',

  props: {
    url: {
      type: String,
      default: undefined
    },
    height: {
      type: Number,
      default: 64
    }
  }
}
</script>

<style lang="scss" scoped>
div.profile-image {
  min-height: 64;
  img {
      margin-bottom: 0.5rem;
      border-radius: 15px;
  }
}
</style>
