
import Vue from 'vue'

import axios from 'axios'

/* eslint-disable promise/param-names */
import API from '../api'
import * as types from '../mutationTypes'

const AUTH_TOKEN_KEY = 'jaggers_api_token'

const state = {
  loggedIn: false,
  loginFailed: false,
  inProgress: false,
  profile: null,
  expires: null,
  error: null,
  updated: null
}

const actions = {

  googleLoginSuccess: ({ commit, dispatch }, data) => {
    /*
    User logged in successfully with google account
     */
    commit(types.GOOGLE_AUTH_REQUEST)

    const params = {
      credential: data.credential
    }
    return new Promise((resolve, reject) => {
      API.post('auth/google/', params)
        .then(response => {
          commit(types.GOOGLE_AUTH_SUCCESS, response)
          resolve(response)
        })
        .catch(error => {
          commit(types.GOOGLE_AUTH_ERROR, error)
          reject(error)
        })
    })
  },

  googleLoginFailed: ({ commit, dispatch }, error) => {
    /*
    Google session login failed
     */
    commit(types.GOOGLE_AUTH_ERROR, error)
  },

  logout: ({ commit, dispatch }) => {
    /*
    Logout with local session, deleting JWT cookies
     */
    commit(types.GOOGLE_AUTH_SIGNOUT)
  },

  loadUserProfile: ({ commit, dispatch }, error) => {
    /*
    Fetch user profile.

    Note: this handles authorization header from cookie itself, since it may be
    done before logging in.
     */
    commit(types.AUTH_PROFILE_LOAD_REQUEST)

    return new Promise((resolve, reject) => {
      if (localStorage.getItem(AUTH_TOKEN_KEY)) {
        API.get('auth/profile')
          .then(response => {
            commit(types.AUTH_PROFILE_LOAD_SUCCESS, response)
            resolve(response)
          })
          .catch(error => {
            commit(types.AUTH_PROFILE_LOAD_ERROR, error)
            reject(error)
          })
      } else {
        reject(hasToken)
      }
    })
  }

}

const mutations = {

  [types.GOOGLE_AUTH_REQUEST]: (state) => {
    Vue.set(state, 'loggedIn', false)
    Vue.set(state, 'loginFailed', false)
    Vue.set(state, 'inProgress', true)
    Vue.set(state, 'error', undefined)
    Vue.set(state, 'profile', null)
    localStorage.removeItem(AUTH_TOKEN_KEY)
  },

  [types.GOOGLE_AUTH_SUCCESS]: (state, response) => {
    let accessToken
    if (response.data.access_token !== undefined) {
      accessToken = response.data.access_token
    } else {
      Vue.set(state, 'error', 'Invalid google login response')
      Vue.set(state, 'loggedIn', false)
      Vue.set(state, 'loginFailed', true)
      Vue.set(state, 'inProgress', false)
      return
    }
    localStorage.setItem(AUTH_TOKEN_KEY, accessToken)
    Vue.set(state, 'loggedIn', true)
    Vue.set(state, 'error', undefined)
    Vue.set(state, 'loginFailed', false)
    Vue.set(state, 'inProgress', false)
  },

  [types.GOOGLE_AUTH_SIGNOUT]: (state) => {
    Vue.set(state, 'loggedIn', false)
    Vue.set(state, 'loginFailed', false)
    Vue.set(state, 'inProgress', true)
    Vue.set(state, 'error', undefined)
    Vue.set(state, 'profile', null)
    localStorage.removeItem(AUTH_TOKEN_KEY)
  },

  [types.GOOGLE_AUTH_ERROR]: (state, error) => {
    Vue.set(state, 'loggedIn', false)
    Vue.set(state, 'loginFailed', true)
    Vue.set(state, 'inProgress', false)
    Vue.set(state, 'error', error)
    Vue.set(state, 'profile', null)
  },

  [types.AUTH_PROFILE_LOAD_REQUEST]: (state) => {
    Vue.set(state, 'loggedIn', false)
    Vue.set(state, 'loginFailed', false)
    Vue.set(state, 'error', undefined)
    Vue.set(state, 'inProgress', true)
  },

  [types.AUTH_PROFILE_LOAD_SUCCESS]: (state, response) => {
    Vue.set(state, 'profile', response)
    Vue.set(state, 'loggedIn', true)
    Vue.set(state, 'error', undefined)
    Vue.set(state, 'loginFailed', false)
    Vue.set(state, 'inProgress', true)
  },

  [types.AUTH_PROFILE_LOAD_ERROR]: (state, error) => {
    Vue.set(state, 'loggedIn', false)
    Vue.set(state, 'inProgress', false)
    Vue.set(state, 'error', error)
    Vue.set(state, 'profile', null)
  },

  [types.LOCAL_AUTH_REQUEST]: (state) => {
    Vue.set(state, 'loggedIn', false)
    Vue.set(state, 'loginFailed', false)
    Vue.set(state, 'inProgress', true)
    Vue.set(state, 'error', undefined)
    Vue.set(state, 'profile', null)
  },

  [types.LOCAL_AUTH_SUCCESS]: (state, response) => {
    const accessToken = response.data.token
    localStorage.setItem(AUTH_TOKEN_KEY, accessToken)
    Vue.set(state, 'loggedIn', true)
    Vue.set(state, 'loginFailed', false)
    Vue.set(state, 'inProgress', false)
    Vue.set(state, 'error', undefined)
  },

  [types.LOCAL_AUTH_ERROR]: (state, error) => {
    /*
    Error authenticating.

    Note: this should NOT clear existing cookies. This mutation is also used
    when there is network error connecting to backend and we don't want logout
    users because of that.
     */
    Vue.set(state, 'loggedIn', false)
    Vue.set(state, 'loginFailed', true)
    Vue.set(state, 'inProgress', false)
    Vue.set(state, 'profile', null)
    Vue.set(state, 'error', error)
  }

}

const getters = {
  hasAuthenticationToken (state) {
    /*
    Check if user has auth token for login
     */
    return function () {
      return localStorage.getItem(AUTH_TOKEN_KEY) !== null
    }
  },
  getUserProfile (state) {
    /*
    Check for user profile
    */
    return function () {
      return state.profile !== undefined ? state.profile : null
    }
  },
  isAuthenticated: () => state.loggedIn,
  loginInProgress: () => state.inProgress,
  loginFailed: () => state.loginFailed,
  getAuthenticationError: () => state.error,
  loggedInEmployee: () => state.profile,
  preferredTimeFormat: () => state.profile !== null ? state.profile.preferred_time_format : undefined,
  loggedInEmployeeEmail: () => state.profile !== null ? state.profile.email : undefined,
  getFullName: (s) => state.profile !== null ? `${state.profile.first_name} ${state.profile.last_name}` : undefined,
  isAdminUser: () => state.profile !== null ? state.profile.is_admin : false,
  isHRUser: () => state.profile !== null ? state.profile.is_hr : false,
  isFinanceUser: () => state.profile !== null ? state.profile.is_finance : false,
  isInternal: () => state.profile !== null ? state.profile.type === 'internal' : false,
  isContractor: () => state.profile !== null ? state.profile.type === 'contractor' : false,
  isUnconfigured: () => state.profile !== null ? state.profile.type === 'unconfigured' : false
}

export default {
  state,
  getters,
  actions,
  mutations
}
