<!--
Component to render employee flexible hours with chart component
-->

<template>
  <div
    class="flexi"
  >
    <span
      :class="flexibleHoursStatusClass"
    >
      {{ formattedValue }}
    </span>
  </div>
</template>

<script>
import { formatDurationString } from '../utils/timeUtils'

export default {
  name: 'EmployeeFlexibleHours',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    flexibleHoursStatusClass () {
      /*
      Return status class for flexible hours
      */
      let value = ''
      if (this.data !== undefined) {
        let delta = Math.abs(this.data.hours)
        if (delta > this.$flexiHoursCritical) {
          value = 'flexi-critical'
        } else if (delta > this.$flexiHoursWarning) {
          value = 'flexi-warning'
        } else {
          value = 'flexi-ok'
        }
      }
      return value
    },
    formattedValue () {
      /*
      Return flexible hours as formatted string value
       */
      if (this.data !== undefined) {
        let deltaMinutes = this.data.hours * 60 + this.data.minutes
        if (deltaMinutes !== 0) {
          return formatDurationString(deltaMinutes, true)
        } else {
          return '0'
        }
      } else {
        return '-'
      }
    }
  }

}
</script>

<style lang="scss" scoped>
div.flexi {
  padding-left: 0;
  span.flexi-critical, span.flexi-warning, span.flexi-ok {
    font-weight: bold;
    white-space: nowrap;
    padding-left: 0;
  }
  span.flexi-critical {
    color: red;
  }
  span.flexi-warning {
    color: #F88818;
  }
  span.flexi-ok {
    color: #006600;
  }
}
</style>
