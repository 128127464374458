/*
Project billing summary state
 */

import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadProjectBillingReports: ({ commit }, params = {}) => {
    /*
    Load project billing summaries
     */
    return helpers.loadListing(
      commit,
      'reports/project-billing-summaries',
      params,
      types.PROJECT_BILLING_REPORT_LIST_LOAD,
      types.PROJECT_BILLING_REPORT_LIST_LOAD_SUCCESS,
      types.PROJECT_BILLING_REPORT_LIST_LOAD_ERROR
    )
  },
  loadProjectBillingReportDetails: ({ commit }, params = {}) => {
    /*
    Load details for a single project billing summary for specified month
     */
    return helpers.loadDetails(
      commit,
      `reports/project-billing-summaries/${params['projectCode']}?month=${params['month']}`,
      types.PROJECT_BILLING_REPORT_DETAILS_LOAD,
      types.PROJECT_BILLING_REPORT_DETAILS_LOAD_SUCCESS,
      types.PROJECT_BILLING_REPORT_DETAILS_LOAD_ERROR
    )
  },
  resertProjectBillingReports: ({ commit }, params = {}) => {
    commit(types.PROJECT_BILLING_REPORT_LIST_RESET)
  }
}

const mutations = {
  [types.PROJECT_BILLING_REPORT_LIST_RESET] (state) {
    helpers.mutateResetData(state)
  },
  [types.PROJECT_BILLING_REPORT_LIST_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.PROJECT_BILLING_REPORT_LIST_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.PROJECT_BILLING_REPORT_LIST_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  },
  [types.PROJECT_BILLING_REPORT_DETAILS_LOAD] (state) {
    helpers.mutateAPIDetailsLoad(state)
  },
  [types.PROJECT_BILLING_REPORT_DETAILS_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIDetailsLoaded(state, data)
  },
  [types.PROJECT_BILLING_REPORT_DETAILS_LOAD_ERROR] (state, error) {
    helpers.mutateAPIDetailsLoadFailed(state, error)
  }
}

const getters = {
  getProjectBillingReports: () => {
    return state.items
  },
  getProjectBillingReportDetails: () => {
    return state.details
  },
  loadingProjectBillingReports: () => {
    /*
    Check if project billing report list is loading
     */
    return state.loading
  },
  loadingProjectBillingReportDetails: () => {
    /*
    Check if project billing report details are loading
     */
    return state.loadingDetails
  },
  errorLoadingProjectBillingReports: () => {
    /*
    Check if loading project billing report list caused errors
     */
    return state.errors.loading
  },
  errorLoadingProjectBillingReportDetails: () => {
    /*
    Check if loading project billing report details caused errors
     */
    return state.errors.loadingDetails
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
