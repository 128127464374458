var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"period-records px-0",attrs:{"fluid":""}},_vm._l((_vm.filteredPeriods),function(item){return _c('div',{key:item.start_date,staticClass:"mb-4"},[_c('div',{staticClass:"period-heading mt-4 mb-2"},[_c('div',{staticClass:"title mr-auto"},[_vm._v(" "+_vm._s(_vm.periodTitle)+" "+_vm._s(_vm.dateRange(item.start, item.end))+" ")]),_c('div',{staticClass:"total-delta mr-2"},[_vm._v(" "+_vm._s(_vm.getTotalPeriodDelta(item ))+" ")]),_c('div',{staticClass:"total-duration mr-2"},[_vm._v(" Total: "+_vm._s(_vm.totalDuration(item.timesheet_records))+" ")])]),_vm._l((_vm.dailyTimesheetRecords(item.timesheet_records)),function(group){return _c('div',{key:group.date,staticClass:"period mt-2"},[_c('div',{staticClass:"heading my-1"},[_c('div',{staticClass:"title mr-auto"},[_vm._v(" "+_vm._s(group.date)+" ")]),_c('div',{staticClass:"actions mr-2"},[(_vm.employee.email === _vm.loggedInEmployeeEmail)?_c('router-link',{staticClass:"link",attrs:{"to":{
              name: 'employee-hours',
              query: {
                period: 'week',
                start_date: item.start,
                end_date: item.end,
                date: group.date
              }
            }}},[_c('font-awesome-icon',{attrs:{"icon":"pen"}})],1):_vm._e(),_c('div',{staticClass:"duration pl-2"},[_vm._v(" "+_vm._s(_vm.totalDuration(group.records))+" ")])],1)]),_vm._l((group.records),function(record){return _c('TimesheetRecord',{key:record.id,attrs:{"record":record}})})],2)})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }