/*
Common helpers for vue storre
 */

import Vue from 'vue'
import API from './api'

function resetErrors (state) {
  /*
  Reset errors in state
  */
  Vue.set(state, 'error', undefined)
  Vue.set(state, 'errors',
    {
      loading: undefined,
      loadingDetails: undefined,
      updating: undefined,
      deleting: undefined
    }
  )
}

function setErrorState (state, errorType, value) {
  /*
  Update error in state
  */
  let errors = state.errors
  errors[errorType] = value
  Vue.set(state, 'error', undefined)
  Vue.set(state, 'errors', errors)
}

function initializeAPIState (endpoint) {
  /*
   Initialize a common state object for API calls
   */
  const state = {
    params: undefined,
    loading: false,
    loadingDetails: false,
    updating: false,
    deleting: false,
    items: [],
    details: undefined
  }
  resetErrors(state)
  return state
}

function loadListing (commit, url, params, action, finished, error) {
  /*
    Vue store action to load a listing of items from API
   */
  commit(action, params)
  return API.get(url, params)
    .then((data) => {
      commit(finished, data)
      return data
    })
    .catch(err => {
      commit(error, err)
      return Promise.reject(err)
    })
}

function loadDetails (commit, url, action, finished, error) {
  /*
    Vue store action to load a details record from API

    These records are loaded without parameters from django
   */
  commit(action)
  return API.get(url)
    .then((data) => {
      commit(finished, data)
      return data
    })
    .catch(err => {
      commit(error, err)
      return Promise.reject(err)
    })
}

function postRecord (commit, url, params, action, success, error) {
  /*
   Vue store action to POST data with API
   */
  commit(action)
  return API.post(url, params)
    .then((data) => {
      commit(success)
      return data
    })
    .catch(err => {
      commit(error, err)
      return Promise.reject(err)
    })
}

function updateRecord (commit, url, params, action, success, error) {
  /*
   Vue store action to UPDATE data with API
   */
  commit(action)
  return API.put(url, params)
    .then((data) => {
      commit(success)
      return data
    })
    .catch(err => {
      commit(error, err)
      return Promise.reject(err)
    })
}

function deleteRecord (commit, url, action, success, error) {
  /*
   Vue store action to DELETE data with API
   */
  commit(action)
  return API.delete(url)
    .then((data) => {
      commit(success)
    })
    .catch(err => {
      commit(error, err)
      return Promise.reject(err)
    })
}

function mutateClearAPIErrors (state) {
  /*
  Clear API errors in state
  */
  resetErrors(state)
  Vue.set(state, 'loading', false)
  Vue.set(state, 'loadingDetails', false)
  Vue.set(state, 'deleting', false)
  Vue.set(state, 'updating', false)
}

function mutateResetData (state, params) {
  /*
   State setter for listing items from API
   */
  Vue.set(state, 'params', [])
  Vue.set(state, 'loading', false)
  Vue.set(state, 'loadingDetails', false)
  Vue.set(state, 'updating', false)
  Vue.set(state, 'deleting', false)
  Vue.set(state, 'items', [])
  Vue.set(state, 'details', undefined)
  resetErrors(state)
}

function mutateAPIListingLoading (state, params) {
  /*
   State setter for listing items from API
   */
  setErrorState(state, 'loading', undefined)
  Vue.set(state, 'loading', true)
  Vue.set(state, 'params', params)
}

function mutateAPIListingLoaded (state, data) {
  /*
   State setter after items from API have been loaded
   */
  Vue.set(state, 'items', data)
  Vue.set(state, 'loading', false)
  setErrorState(state, 'loading', undefined)
}

function mutateAPIListingLoadFailed (state, error) {
  /*
   State setter after loading API listing failed
   */
  Vue.set(state, 'items', [])
  Vue.set(state, 'loading', false)
  setErrorState(state, 'loading', error)
}

function mutateAPIDetailsLoad (state) {
  /*
   State setter for listing items from API
    */
  Vue.set(state, 'loadingDetails', true)
  setErrorState(state, 'loadingDetails', undefined)
}

function mutateAPIDetailsLoaded (state, data) {
  /*
   State setter after items from API have been loaded
   */
  Vue.set(state, 'details', data)
  Vue.set(state, 'loadingDetails', false)
  setErrorState(state, 'loadingDetails', undefined)
}

function mutateAPIDetailsLoadFailed (state, error) {
  /*
   State setter after loading API listing failed
   */
  Vue.set(state, 'details', undefined)
  Vue.set(state, 'loadingDetails', false)
  setErrorState(state, 'loadingDetails', error)
}

function mutateItemUpdating (state) {
  /*
   State setter for updating item with API
   */
  Vue.set(state, 'updating', true)
  setErrorState(state, 'updating', undefined)
}

function mutateItemUpdated (state) {
  /*
   State setter after update is successful for item with API
   */
  Vue.set(state, 'updating', false)
  setErrorState(state, 'updating', undefined)
}

function mutateItemUpdateFailed (state, error) {
  /*
   State setter after update is successful for item with API
   */
  Vue.set(state, 'updating', false)
  setErrorState(state, 'updating', error)
}

function mutateItemDeleting (state) {
  /*
   State setter for deleting item with API
   */
  Vue.set(state, 'deleting', true)
  setErrorState(state, 'deleting', undefined)
}

function mutateItemDeleted (state) {
  /*
   State setter for deleting item with API
   */
  Vue.set(state, 'deleting', false)
  setErrorState(state, 'deleting', undefined)
}

function mutateItemDeleteFailed (state, error) {
  /*
   State setter after update is successful for item with API
   */
  Vue.set(state, 'deleting', false)
  setErrorState(state, 'deleting', error)
}

function mutateAPIBulkUpdating (state) {
  /*
   State setter for bulk updating items from API
    */
  Vue.set(state, 'updating', true)
  setErrorState(state, 'updating', undefined)
}

function mutateAPIBulkUpdateSuccess (state, data) {
  /*
   State setter after items from API have been updated in bulk

   After calling this you need to re-fetch the list from backend
   */
  Vue.set(state, 'items', [])
  Vue.set(state, 'updating', false)
  setErrorState(state, 'updating', undefined)
}

function mutateAPIBulkUpdateError (state, error) {
  /*
   State setter after loading API listing have been updated in bluk

   After calling this you need to re-fetch the list from backend
   */
  Vue.set(state, 'items', [])
  Vue.set(state, 'updating', false)
  setErrorState(state, 'updating', error)
}

export {
  initializeAPIState,
  loadListing,
  loadDetails,
  postRecord,
  updateRecord,
  deleteRecord,
  mutateResetData,
  mutateClearAPIErrors,
  mutateAPIListingLoading,
  mutateAPIListingLoaded,
  mutateAPIListingLoadFailed,
  mutateAPIDetailsLoad,
  mutateAPIDetailsLoaded,
  mutateAPIDetailsLoadFailed,
  mutateItemUpdating,
  mutateItemUpdated,
  mutateItemUpdateFailed,
  mutateItemDeleting,
  mutateItemDeleted,
  mutateItemDeleteFailed,
  mutateAPIBulkUpdating,
  mutateAPIBulkUpdateSuccess,
  mutateAPIBulkUpdateError
}
