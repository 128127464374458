
<!--
Component to show a centered notification message
-->

<template>
  <b-container
    fluid
    class="d-flex flex-column justify-content-center align-items-center px-0"
  >
    <h3>
      {{ title }}
    </h3>
    <div>
      {{ message }}
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'Notification',

  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  }

}
</script>
