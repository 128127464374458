<!--
View to show list of remunerations for employee
-->

<template>
  <div
    v-if="!loading"
  >
    <b-modal
      v-model="editModalVisible"
      name="record-edit-modal"
      hide-header
      centered
      hide-footer
      no-close-on-backdrop
      @hide="editCanceled"
    >
      <RemunerationEditForm
        v-if="editModalVisible"
        :parent="bus"
        :employee="employee"
        :record="record"
        @events="eventHandler"
      />
    </b-modal>

    <b-table
      :fields="['remuneration']"
      :items="rows"
      responsive
      hover
      show-empty
      thead-class="d-none"
      @row-clicked="rowClickedCallback"
    >
      <template v-slot:cell(remuneration)="row">
        <Remuneration
          :employee="employee"
          :remuneration="row.item"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

import EmployeeDataEditor from '@/components/employee/EmployeeDataEditor.vue'
import Remuneration from '@/components/employee/Remuneration.vue'
import RemunerationEditForm from '@/components/employee/RemunerationEditForm.vue'

export default {
  name: 'Remunerations',

  components: {
    Remuneration,
    RemunerationEditForm
  },

  extends: EmployeeDataEditor,

  computed: {
    ...mapGetters([
      'getEmployeeDetails',
      'getRemunerations',
      'loadingEmployeeDetails',
      'loadingRemunerations',
      'errorLoadingEmployeeDetails',
      'errorLoadingRemunerations'
    ]),
    loading () {
      return this.loadingEmployeeDetails || this.loadingRemunerations
    },
    error () {
      return this.errorLoadingEmployeeDetails || this.errorLoadingRemunerations
    },
    canEdit () {
      return this.isAdminUser || this.isHRUser || this.isFinanceUser
    },
    canDelete () {
      return this.isAdminUser || this.isHRUser || this.isFinanceUser
    },
    rows () {
      return cloneDeep(this.getRemunerations)
    }
  },

  methods: {

    loadData () {
      /*
      Load leaves for specified employee
       */
      if (this.email) {
        this.$store.dispatch('resetRemunerations')
        this.$store.dispatch('loadRemunerations', {
          email: this.email
        })
      }
    }

  }

}
</script>
