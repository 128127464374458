
import * as helpers from '../../helpers'
import * as types from '../../mutationTypes'

const state = helpers.initializeAPIState()

const actions = {
  loadProjectJoinableEmployeeChoices: ({ commit }, params = {}) => {
    /*
     Load employees that can be joined to specified project
     */
    return helpers.loadListing(
      commit,
      'timesheet/autocomplete/project-joinable-employees',
      params,
      types.JOINABLE_PROJECT_MEMBER_CHOICES_LOAD,
      types.JOINABLE_PROJECT_MEMBER_CHOICES_LOAD_SUCCESS,
      types.JOINABLE_PROJECT_MEMBER_CHOICES_LOAD_ERROR
    )
  }
}

const mutations = {
  [types.JOINABLE_PROJECT_MEMBER_CHOICES_LOAD] (state) {
    helpers.mutateAPIListingLoading(state)
  },
  [types.JOINABLE_PROJECT_MEMBER_CHOICES_LOAD_SUCCESS] (state, data) {
    helpers.mutateAPIListingLoaded(state, data)
  },
  [types.JOINABLE_PROJECT_MEMBER_CHOICES_LOAD_ERROR] (state, error) {
    helpers.mutateAPIListingLoadFailed(state, error)
  }
}

const getters = {
  getProjectJoinableEmployeeChoices: (state) => {
    /*
    Return all employee choices joinable to project
    */
    return state.items
  },
  loadingProjectJoinableEmployeeChoices: () => {
    /*
    Check if project joinable employee choices are loading
     */
    return state.loading
  },
  errorLoadingProjectJoinableEmployeeChoices: () => {
    /*
    Check if project joinable employee choices caused errors
     */
    return state.errors.loading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
