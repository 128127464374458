/*
Common date and time manipulation utilities
 */

import Vue from 'vue'
import { format } from 'date-fns'
import { sumBy, isEmpty } from 'lodash'

export const getHoursAndMinutes = (time) => {
  /*
  Utility function to get hours and minutes from entered value

  Valid example string forms for time-parameter: "07:30:00", "7:30", "7", 7:30:00"

  TODO - this should also handle values with number of days ('4 10:30:00' -> 106:30)
   */
  const timeParts = time.split(':')
  const hours = parseInt(timeParts[0])
  const minutes = timeParts.length > 1 ? parseInt(timeParts[1]) : 0
  return {
    hours,
    minutes
  }
}

export const parseHoursAndMinutesFromMinutes = (value) => {
  /*
  Return hours and minutes parsed from minutes as integer as returned by getTotalDuration
   */
  let hours = parseInt(value / 60)
  let minutes = parseInt(value % 60)
  return [hours, minutes]
}

export const durationInMinutes = (value) => {
  /*
  Return total duration in minutes from a tuple with hours and minutes properties
   */
  if (isEmpty(value)) {
    return 0
  }
  return parseInt(value.hours) * 60 + parseInt(value.minutes)
}

export const durationDeltaInMinutes = (a, b) => {
  /*
  Return delta as minutes between two values with hours and minutes properties
   */
  return durationInMinutes(a) - durationInMinutes(b)
}

export const parseDurationInMinutes = (durationString) => {
  /*
   Return value of duration field in minutes
   */
  const hoursAndMinutes = getHoursAndMinutes(durationString)
  return parseInt(hoursAndMinutes.hours) * 60 + parseInt(hoursAndMinutes.minutes)
}

export const getTotalDuration = (durationStrings) => {
  /*
  Return sum of total minutes in specified duration strings
   */
  return sumBy(durationStrings.map(function (v) { return parseDurationInMinutes(v) }))
}

export const formatDurationString = (durationInMinutes, showSign = false, hoursAndMinutes = false) => {
  /*
  Format a duration string from total minutes integer value

  Optionally prefix value with +- sign if showSign is true
   */
  let absDuration = Math.abs(durationInMinutes)
  let minutes = absDuration % 60
  let hours = (absDuration - minutes) / 60

  if (durationInMinutes > 0) {
    hours = showSign ? `+${hours}` : hours
  } else if (durationInMinutes < 0) {
    hours = showSign ? `-${hours}` : hours
  }
  if (hoursAndMinutes) {
    if (hours !== 0 && minutes !== 0) {
      return `${hours} h ${minutes} min`
    } else if (hours !== 0 && minutes === 0) {
      return `${hours} h`
    } else if (hours === 0 && minutes !== 0) {
      return `${minutes} min`
    } else {
      return '0 h'
    }
  } else {
    return hours + ':' + (minutes < 10 ? '0' : '') + minutes
  }
}

export const formatDurationValue = (duration) => {
  /*
  Format duration value with seconds to HH:MM

  Returns empty string if value is empty
   */
  let days
  let hours
  let minutes

  if (!isEmpty(duration)) {
    const withDaysMatch = duration.match('[0-9]* [0-9]*:[0-9]*:[0-9]*')
    if (withDaysMatch && withDaysMatch[0] === duration) {
      days = parseInt(duration.split(' ')[0])
      duration = duration.split(' ')[1]
    }
    // Remove seconds from duration
    duration = duration.split(':').splice(0, 2).join(':')
    hours = parseInt(duration.split(':')[0])
    minutes = parseInt(duration.split(':')[1])
    minutes = minutes < 10 ? `0${minutes}` : minutes
    if (days) {
      hours = hours + 24 * days
    }
    return `${hours}:${minutes}`
  } else {
    return ''
  }
}

export const formatDateRange = (start, end, dateFormat) => {
  /*
  Format start and end time range (HH:MM) as string

  Dates are formatted by dateFormat global setting from config.js
   */
  if (dateFormat === undefined) {
    dateFormat = Vue.prototype.$dateFormat
  }
  if (start === null || start === '') {
    start = undefined
  }
  if (end === null || end === '') {
    end = undefined
  }
  if (start !== undefined && end !== undefined) {
    // We have both values, render as 'HH:MM - HH:MM'
    return `${format(start, dateFormat)} - ${format(end, dateFormat)}`
  } else if (start === undefined && end !== undefined) {
    // Start time missing, render as '- HH:MM'
    return `- ${format(start, dateFormat)}`
  } else if (start !== undefined && end === undefined) {
    // End time missing, render as 'HH-MM -'
    return `${format(start, dateFormat)} -`
  } else {
    // Both values are empty, return empty string
    return ''
  }
}

export const formatTimeRange = (start, end) => {
  /*
  Format start and end date range (YYYY-MM-DD) as string
   */
  if (!isEmpty(start) && !isEmpty(end)) {
    // We have both values, render as 'date - date'
    return `${formatDurationValue(start)} - ${formatDurationValue(end)}`
  } else if (isEmpty(start) && !isEmpty(end)) {
    // Start time missing, render as '- date'
    return `- ${formatDurationValue(end)}`
  } else if (!isEmpty(start) && isEmpty(end)) {
    // End time missing, render as 'date -'
    return `${formatDurationValue(start)} -`
  } else {
    // Both values are empty, return empty string
    return ''
  }
}

export const durationDelta = (a, b) => {
  /*
  Return delta between two values as a duration string. Always includes +- sign
   */
  return formatDurationString(durationDeltaInMinutes(a, b), { showSign: true })
}
