<!--
Show the main employee hours view for listing and editing logged hours

This is also the home view for normal users who can log hours
-->

<template>
  <BaseView
    :parent="bus"
    class="hours-view"
    :loading="dataVisible"
    :error="error"
    :title="title"
    :show-page-navigation="false"
    page-name="Employee Hours"
    @events="eventHandler"
  >
    <template slot="page-navigation">
      <div
        v-touch:swipe.left="showNextPeriod"
        v-touch:swipe.right="showPreviousPeriod"
        class="mt-4 d-flex flex-column justify-content-flex-start align-items-center"
      >
        <b-row
          class="w-100 d-flex flex-row justify-content-center align-items-start"
        >
          <div class="flex-shrink-1">
            <b-button
              :key="start_date"
              ref="previous_period"
              class="float-left"
              @click="showPreviousPeriod"
            >
              <font-awesome-icon icon="arrow-alt-circle-left" />
            </b-button>
          </div>

          <div class="flex-grow-1 mb-2">
            <div class="summary text-center">
              <div class="period-dates">
                <h5>{{ periodHeading }}</h5>
                <div class="daterange">
                  {{ periodStartDay() | date }} - {{ periodEndDay() | date }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex-shrink-1">
            <b-button
              :key="start_date"
              ref="next_period"
              class="float-right"
              @click="showNextPeriod"
            >
              <font-awesome-icon icon="arrow-alt-circle-right" />
            </b-button>
          </div>
        </b-row>

        <b-row
          v-if="view === 'hours'"
          class="summary-totals my-1"
        >
          <div class="total-hours">
            Total: {{ totalHours }}
          </div>
          <div
            v-if="hasConsumedFlexiHours"
            class="consumed-flexi"
          >
            Used flexi: {{ totalConsumedFlexibleHours }}
          </div>
          <div
            v-if="getEmployeeFlexibleHours"
            class="flexi"
          >
            <div>
              Total flexi:
              <span :class="flexibleHoursStatusClass">
                {{ getEmployeeFlexibleHours.hours }} h {{ getEmployeeFlexibleHours.minutes }} min
              </span>
            </div>
          </div>
        </b-row>

        <PeriodProjectTotals
          v-if="allDaysExpanded"
          class="project-totals my-1"
          :projects="getEmployeeProjects"
          :records="getTimesheetRecords"
        />

        <b-row
          v-if="getEmployeeHasRecentMissingDates"
        >
          <div
            class="warning"
          >
            Please enter timesheet data for following dates
            <div
              class="missing-dates d-flex flex-row justify-content-center align-items-center"
              @click="hideAllDays"
            >
              <router-link
                v-for="date in getEmployeeRecentMissingDates"
                :key="date"
                class="mx-1"
                :to="{
                  name: $route.name,
                  query: {
                    period: period,
                    date: date
                  }
                }"
              >
                {{ date }}
              </router-link>
            </div>
          </div>
        </b-row>

        <b-row
          class="w-100 d-flex flex-row justify-content-end align-items-start"
        >
          <b-col
            v-if="hasReportableProjects"
            class="heading-container button-container"
          >
            <span
              v-if="period !== 'week'"
              id="switch-period-week"
              @click="switchView('hours')"
            >
              Weekly Hours
            </span>
            <span
              v-if="period === 'week'"
              id="expand-all"
              @click="toggleAllDatesExpanded()"
            >
              {{ allDaysExpanded ? 'Collapse all' : 'Expand all' }}
            </span>
            <span
              v-if="period !== 'month'"
              id="switch-period-month"
              @click="switchView('month')"
            >
              Monthly report
            </span>
          </b-col>
        </b-row>
      </div>
    </template>

    <template slot="content">
      <div
        v-if="view === 'hours'"
        class="mt-2 mx-2"
        :class="{ 'invisible': !dataVisible }"
      >
        <b-col
          v-if="hasReportableProjects"
          v-for="item in daysForView"
          :key="item.date | date"
          class="day my-0 px-0"
        >
          <Day
            :parent="bus"
            :work-days="getEmployeeWorkDays"
            :day="item"
            :loading="loading"
            :period="period"
            :records="getDaysRecords(getTimesheetRecords, item.date)"
            :any-day-open-for-editing="anyDayOpenForEditing"
            :all-days-expanded="allDaysExpanded"
            @events="eventHandler"
          />
        </b-col>
      </div>

      <div
        v-if="view === 'report'"
        class="mt-2 mx-2"
        :class="{ 'invisible': !dataVisible }"
      >
        <PeriodWorkDetails
          :employee="loggedInEmployee"
          :period="period"
          :start-date="periodStartDay() | apiDate"
          :end-date="periodEndDay() | apiDate"
          :hide-totals="true"
        />
      </div>

      <error-message
        v-if="errorVisible"
        title="No projects available for reporting"
        @events="eventHandler"
      >
        <template slot="message">
          <div>
            Your account has no projects you can report to. Please contact adminstrators.
          </div>
        </template>
      </error-message>
    </template>
  </BaseView>
</template>

<script>

import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'
import { formatDurationString, getTotalDuration } from '@/components/utils/timeUtils'

import BaseView from '@/components/elements/BaseView.vue'
import Day from '@/components/timetracking/Day.vue'
import ErrorMessage from '@/components/common/ErrorMessage.vue'
import TimeRangeBrowser from '@/components/base/TimeRangeBrowser.vue'
import PeriodProjectTotals from '@/components/report/PeriodProjectTotals.vue'
import PeriodWorkDetails from '@/components/report/PeriodWorkDetails.vue'

export default {
  name: 'EmployeeHours',

  components: {
    BaseView,
    Day,
    ErrorMessage,
    PeriodProjectTotals,
    PeriodWorkDetails
  },

  extends: TimeRangeBrowser,

  props: {
    // Parent component for receiving events to child
    parent: {
      type: Object,
      default: undefined
    }
  },

  data: function () {
    return {
      // View to show. Either 'hours' or 'report'
      view: 'hours'
    }
  },

  computed: {
    ...mapGetters([
      'loggedInEmployee',
      'loadingEmployeeWorkDays',
      'loadingTimesheetRecords',
      'loadingEmployeeProjects',
      'errorLoadingEmployeeWorkDays',
      'errorLoadingTimesheetRecords',
      'errorLoadingEmployeeProjects',
      'getEmployeeWorkDays',
      'getTimesheetRecords',
      'getEmployeeProjects',
      'getEmployeeHasRecentMissingDates',
      'getEmployeeRecentMissingDates',
      'getEmployeeFlexibleHours'
    ]),

    dataVisible () {
      /*
      Decides if the data for period should be visible
       */
      // No data to show yet
      if (this.loading && isEmpty(this.getTimesheetRecords)) {
        return false
      }
      // There may be other cases to consider
      return true
    },

    errorVisible () {
      /*
      Decides if error about no reportable projects should be visible
       */
      return !this.loading && !this.error && !this.hasReportableProjects
    },

    loading () {
      return this.loadingTimesheetRecords ||
             this.loadingEmployeeProjects ||
             this.loadingEmployeeWorkDays
    },

    error () {
      if (this.errorLoadingEmployeeWorkDays) {
        return this.errorLoadingEmployeeWorkDays
      }
      if (this.errorLoadingTimesheetRecords) {
        return this.errorLoadingTimesheetRecords
      }
      if (this.errorLoadingEmployeeProjects) {
        return this.errorLoadingEmployeeProjects
      }
      return undefined
    },

    title () {
      /*
      Return dynamic title based on visible period
       */
      return `Employee Time Tracking ${this.dateRange(this.periodStartDay(), this.periodEndDay())}`
    },

    reportTitle () {
      /*
      Return title for report page
       */
      switch (this.period) {
        case 'month': {
          return 'Monthly hours report'
        }
        case 'year': {
          return 'Yearly hours report'
        }
        default: {
          return 'Hours report'
        }
      }
    },

    totalHours () {
      /*
      Total hours in data for viewing period
       */
      const durations = this.getTimesheetRecords.filter(record => {
        return record.project_code !== this.$flexibleHoursProjectCode
      }).map(record => record.duration)
      return formatDurationString(getTotalDuration(durations), false, true)
    },

    hasConsumedFlexiHours () {
      /*
      Check if there are any consumed flexible hours
       */
      const matches = this.getTimesheetRecords.find(
        record => record.project_code === this.$flexibleHoursProjectCode
      )
      return matches !== undefined
    },

    totalConsumedFlexibleHours () {
      /*
      Total hours in data for viewing period
       */
      const durations = this.getTimesheetRecords.filter(record => {
        return record.project_code === this.$flexibleHoursProjectCode
      }).map(record => record.duration)
      return formatDurationString(getTotalDuration(durations), false, true)
    },

    hasReportableProjects () {
      /*
      Check if logged in employee has any projects they can report to
      */
      return !isEmpty(this.getEmployeeProjects)
    },

    flexibleHoursStatusClass () {
      /*
      Return status class for flexible hours
      */
      let value = ''
      if (this.getEmployeeFlexibleHours !== undefined) {
        let delta = Math.abs(this.getEmployeeFlexibleHours.hours)
        if (delta > this.$flexiHoursCritical) {
          value = 'flexi-critical'
        } else if (delta > this.$flexiHoursWarning) {
          value = 'flexi-warning'
        } else {
          value = 'flexi-ok'
        }
      }
      return value
    }
  },

  watch: {
    'period' () {
      if (this.period === 'week') {
        this.view = 'hours'
        this.loadRecords()
      } else {
        this.view = 'report'
      }
    }
  },

  created () {
    /*
    Listen on notifications from parent if parent is specified
     */
    if (this.parent) {
      this.parent.$on('notification', this.notificationHandler)
    }
  },

  mounted () {
    /*
    If type 'admin' user attempts to use the entry form, redirect to /admin
     */
    if (this.loggedInEmployee.type === 'admin') {
      this.$router.replace({ 'name': 'admin' })
    }
    this.loadEmployeeProjects()
  },

  methods: {

    eventHandler (action, ...args) {
      switch (action) {
        case 'record-created': {
          this.updateRecords()
          break
        }
        case 'record-updated': {
          this.updateRecords()
          break
        }
        case 'record-deleted': {
          this.updateRecords()
          break
        }
        case 'set-day-open-for-editing': {
          this.setDayOpenForEditing(args[0])
          break
        }
        case 'show-day-details': {
          this.showDayDetails(args[0])
          break
        }
        case 'toggle-day-expanded': {
          this.toggleIsDayExpanded(args[0])
          break
        }
        case 'clear-errors': {
          this.$store.dispatch('clearTimesheetAPIErrors')
          break
        }
      }
    },

    loadRecords () {
      /*
       Load records for employee for current date range
       */
      if (this.view === 'hours') {
        const requestedDate = this.getQueryDate()
        let params = this.getDateQueryArgs()
        params.email = this.email
        this.$store.dispatch('loadTimesheetRecords', params)
          .then((response) => {
            if (requestedDate !== undefined) {
              this.bus.$emit('notification', 'open-day-for-editing', requestedDate)
            }
            this.blurNavigationButtons()
          })
      }
    },

    blurNavigationButtons () {
      /*
      Blur navigation buttons
       */
      this.$refs.previous_period.blur()
      this.$refs.next_period.blur()
    },

    loadEmployeeProjects () {
      /*
      Load projects available for logged in employee

      This is called during initializing and when record edit form is opened
      */
      this.$store.dispatch('loadEmployeeProjects')
    },

    updateRecords () {
      /*
      Load employee profile and records after updating data
      */
      this.loadEmployeeDetails()
      this.loadEmployeeProjects()
      this.loadRecords()
    },

    switchView (view) {
      /*
      Switch view type and period
       */
      switch (view) {
        case 'hours': {
          this.view = 'hours'
          this.switchPeriod('week')
          break
        }
        case 'month': {
          this.view = 'report'
          this.switchPeriod('month')
          break
        }
        case 'year': {
          this.view = 'report'
          this.switchPeriod('year')
          break
        }
      }
    }

  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/bootstrap_overrides.scss';
@import '../../assets/scss/theme_constants.scss';

.hours-view {
  max-width: 1024px;
}
.arrow-button {
  background-color: $secondary-color;
  color: $background-color;
}
.btn-secondary {
  border: none;
  font-size: 28px;
  color: $primary-color-dark;
  background-color: $background-color;
  &:hover {
    color: #fff;
    background-color: $primary-color;
  }
}
.summary {
  .period-dates {
    h5 {
      margin-top: 0.5em;
      margin-bottom: 0;
    }
    .daterange {
      font-size: smaller;
      font-style: italic;
    }
  }
}
.summary-totals {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  flex-wrap: 1;
  .flexi, .consumed-flexi {
    margin-left: 1em;
    min-height: 2em;
    .flexi-warning {
      font-weight: bold;
      color: $brand-warning;
    }
    .flexi-critical {
      font-weight: bold;
      color: $brand-danger;
    }
  }
}
.project-totals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  flex-wrap: 1;
}
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  span {
    cursor: pointer;
    padding-right: 15px;
    &:hover {
      color: $primary-color;
    }
  }
}
</style>
